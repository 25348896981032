/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { BottomSheet, Button, Code, Input } from '@finanso/react-uikit';
import { useWindowSize } from '../../../hooks/useWindowSize';
import ModalWrapper from '../../ModalWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { instructions as instructionsSelector } from '../../../../states/UserTask/state/selectors';
import { Instructions } from '../../../enum/Instructions.enum';

import styles from './ConfirmEmailModal.module.scss';
import { Translate } from 'react-localize-redux';
import { useForm } from 'react-hook-form';
import { StepWrapper } from './components/StepWrapper';
import { userSettingsChangeEmailPutRequest } from '../../../../states/UserSettingsChangeEmail/state/duck';
import {
  dataPut as dataPutSelector,
  isFetchingPut as isFetchingPutSelector,
} from '../../../../states/UserSettingsChangeEmail/state/selectors';

const isEmail = (str: string) => /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str);

interface ContentProps {
  onClose: () => void
}

const Content: React.FC<ContentProps> = ({
  onClose,
}) => {
  const dataPut = useSelector(dataPutSelector);
  const loading = useSelector(isFetchingPutSelector);
  const dispatch = useDispatch();
  const { watch, setValue } = useForm({
    defaultValues: {
      step: 1,
      email: '',
    },
  });
  const { step, email } = watch();

  const handlerSendCode = () => {
    dispatch({
      type: userSettingsChangeEmailPutRequest.toString(),
      payload: { email },
    });
    setValue('step', 2);
  };

  return (
    <StepWrapper
      title={`instruction.email.title.${step}`}
      description={`instruction.email.description.${step}`}
      onBack={step === 2 ? (() => setValue('step', 1)) : undefined}
    >
      {step === 1 &&
        <>
          <Input
            className={styles.input}
            title={<Translate id="instruction.email.input.title" />}
            value={email}
            disabled={loading}
            onChange={(e) => setValue('email', e.target.value)}
          />

          <Button
            onClick={handlerSendCode}
            fullWidth
            disabled={!isEmail(email) || loading}
          >
            <Translate id={`instruction.email.button.${step}`} />
          </Button>
        </>
      }

      {step === 2 &&
        <>
          <div className={styles.icon}>
            <svg width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" width="120" height="120" rx="20" fill="#F5F7FA" />
              <path fillRule="evenodd" clipRule="evenodd" d="M33.8343 30H87.1676C92.6562 30 97.1365 34.4614 97.1674 39.9429C97.168 39.9754 97.168 40.0078 97.1676 40.0403V80C97.1676 85.5076 92.6752 90 87.1676 90H33.8343C28.3267 90 23.8343 85.5076 23.8343 80V40.0408C23.8338 40.008 23.8339 39.9751 23.8344 39.9422C23.8658 34.461 28.3459 30 33.8343 30ZM30.5009 46.4022V80C30.5009 81.8257 32.0086 83.3333 33.8343 83.3333H87.1676C88.9933 83.3333 90.5009 81.8257 90.5009 80V46.4022L62.4125 66.0641C61.2647 66.8675 59.7371 66.8675 58.5894 66.0641L30.5009 46.4022ZM90.1457 38.5132L60.5009 59.2645L30.8562 38.5132C31.4075 37.4231 32.5411 36.6667 33.8343 36.6667H87.1676C88.4608 36.6667 89.5944 37.4231 90.1457 38.5132Z" fill="#1373E5" />
              <rect x="79.5" y="22" width="25" height="24" rx="3" fill="#FBE3E7" />
              <path d="M91.5524 39V30.55L92.2934 31.317H89.6284V29.9H93.2424V39H91.5524Z" fill="#E3405E" />
            </svg>
          </div>

          <Button
            color="success"
            fullWidth
            onClick={onClose}
          >
            <Translate id={`instruction.email.button.${step}`} />
          </Button>
        </>
      }
    </StepWrapper>
  );
};

export const ConfirmEmailModal = () => {
  const [open, setOpen] = useState(false);
  const { width } = useWindowSize();

  const instructions = useSelector(instructionsSelector);
  const instruction = instructions
    .find(({ handler: { alias } }: Record<string, any>) => alias === Instructions.EMAIL_CONFIRM);

  useEffect(() => {
    setOpen(Boolean(instruction));
  }, [instruction?.id]);

  return (
    <>
      {width <= 1200
        ? <BottomSheet
            open={open}
            onDismiss={() => setOpen(false)}
        >
          <Content onClose={() => setOpen(false)} />
        </BottomSheet>
        :
        <ModalWrapper
          isModalVisible={open}
          handleModalStatusChange={setOpen}
          width={400}
        >
          <Content onClose={() => setOpen(false)} />
        </ModalWrapper>
      }
    </>
  );
};
