import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
// ISF-123
import useGTM from '@elgorditosalsero/react-gtm-hook';
// ISF-176
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import AppInit from '../AppInit';

import { environment } from '../../../environment';
import { useDispatch } from 'react-redux';
import { setDispatchFx } from '../../states/App/event';

const ApplicationWrapper = () => {
  const { init, UseGTMHookProvider } = useGTM();
  const dispatch = useDispatch();

  useEffect(() => {
    init({ id: 'GTM-W9SSFJP' });
  }, []);

  useEffect(() => {
    setDispatchFx(dispatch);
  }, [dispatch]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={environment.RECAPTCHA_SITE_KEY}>
      <BrowserRouter>
        <>
          {environment.PRODUCTION ? (
            <>
              {/* Google Tag Manager */}
              <UseGTMHookProvider>
                {/* Init */}
                <AppInit />
              </UseGTMHookProvider>
            </>
          ) : (
            <>
              {/* Init */}
              <AppInit />
            </>
          )}
        </>
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  );
};

export default ApplicationWrapper;
