/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import SelectReact from 'react-select';
import MaskedInput from 'react-input-mask';
import { Translate } from 'react-localize-redux';

import styles from '../OfferElementCard/OfferElementCard.module.scss';

import Select from '../Select';
import { IField } from '../../../states/Products/state/IProducts';
import Input from '../Input';
import { Input as InputDefault } from 'antd';
import { NoOptionsMessage, customStyles } from '../FormSelectParams';

import { makeRequest } from '../../../core/api/dadata';
import moment from 'moment';
import DatePicker from '../DatePicker';
import cx from 'classnames';

interface IProps {
  name: string,
  field: IField,
  errors: any,
  mask: string,
  patternField: string,
  placeholder: string,
  getValues: any,
  offerCompendiums: any,
  isRequired: boolean,
  control?: any,
  keyValue: string,
  label: string;
  currentProps?: any;
  address?: any;
  changeAddress?: any;
  defaultValue?: any,
  onBlur?: any,
}

const OfferAllFields: React.FC<IProps> = props => {
  const {
    field,
    label,
    errors,
    patternField,
    placeholder,
    getValues,
    offerCompendiums,
    isRequired,
    keyValue,
    address,
    defaultValue,
    changeAddress,
    onBlur,
  } = props;

  const { setValue, control } = useFormContext();

  const [state, setState] = React.useState([]);

  const handleInputChange = (
    newValue: string,
    query: { query:
        { region: string; }[] |
        { city: string; }[] |
        { street: string; }[] |
        { house: string; }[];
      field: string;
      isRestrict: boolean}) => {
    const result = Promise.resolve(
      makeRequest(
        newValue,
        'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
        'address',
        query.query,
        query.isRestrict,
        query.field,
      ),
    );
    result.then((suggestionsList: any) => {
      const suggestionsListParse = JSON.parse(suggestionsList);
      const fieldName = `${query.field}_fias_id`;
      const valuesList = suggestionsListParse.suggestions.reduce((acc: any, curr: any) => {
        acc = [...acc, { label: curr.value, value: query.field, [fieldName]: curr.data[fieldName],
        }];
        return acc;
      }, []);
      setState(valuesList);
    });
  };

  const [selectValue, setSelectValue] = useState();

  const handleChangeAddress = (selectValue: any) => {
    if (!selectValue) {
      return;
    }
    const value = selectValue?.label || '';
    setValue(keyValue, value);
    setSelectValue(selectValue);
    changeAddress(selectValue.value, selectValue.label, selectValue[`${selectValue.value}_fias_id`]);
  };

  React.useEffect(() => {
    return (setState([]));
  }, []);

  const handleChangeSelect = (value: any, key: any) => {
    setValue(keyValue, key.value);
    setSelectValue(value);
  };

  const handlerDatePicker = (e: any) => {
    setValue(keyValue, e && e.format('YYYY-MM-DD'));
  };

  return (
    <>
      <Controller
        control={control}
        name={keyValue}
        defaultValue={defaultValue}
        rules={{
          required: isRequired
            ? 'error_field_required'
            : undefined,
          maxLength: {
            value: 255,
            message: 'error_field_max_length_is_255',
          },
        }}
        render={(prop) => {
          const fieldProps = prop.field;

          if (field?.type === 'date') {
            return (
              <>
                {label && <div
                  className={styles.inputLabel}
                >
                  {label}
                </div>}
                <DatePicker
                  value={fieldProps.value}
                  onChange={(value) => fieldProps.onChange(value?.format('YYYY-MM-DD'))}
                  onBlur={onBlur}
                  name={fieldProps.name}
                  className={cx(styles.input, errors?.[keyValue] && styles.inputError)}
                  showToday={false}
                  picker="date"
                  placeholder={placeholder}
                  format="DD.MM.YYYY"
                  allowClear={false}
                />
              </>
            );
          }

          if (field?.type === 'select') {
            return (
              <Select
                {...fieldProps}
                data={offerCompendiums}
                isError={errors?.[keyValue] ? true : false}
                isGood={!errors?.[keyValue] && getValues(keyValue) !== '' ? true : false}
                label={label}
                placeholder={placeholder}
                value={selectValue}
                onChange={handleChangeSelect}
              />
            );
          }

          if (patternField) {
            return (
              <>
                {label && <div
                  className={styles.inputLabel}
                >
                  {label}
                </div>}
                <MaskedInput
                  {...fieldProps}
                  mask={patternField}
                  placeholder={placeholder}
                  inputRef={fieldProps.ref}
                >
                  {(props: any) => {
                    return (
                      <InputDefault {...props} className={styles.input} />
                    );
                  }}
                </MaskedInput>
              </>
            );
          }

          if (field.handler['name' as any] === 'dadata_address') {
            let query: any;
            if (field.name.includes('region')) {
              query = {
                field: 'region',
                isRestrict: false,
              };
            }
            if (field.name.includes('city')) {
              query = {
                query: address.region_fias_id && [{ region_fias_id: address.region_fias_id }],
                isRestrict: address.region_fias_id ? true : false,
                field: 'city',
              };
            }
            if (field.name.includes('street')) {
              query = {
                query: address.city_fias_id && [{ city_fias_id: address.city_fias_id }],
                isRestrict: address.city_fias_id ? true : false,
                field: 'street',
              };
            }
            if (field.name.includes('house')) {
              query = {
                query: address.street_fias_id && [{ street_fias_id: address.street_fias_id }],
                isRestrict: address.street_fias_id ? true : false,
                field: 'house',
              };
            }

            return (
              <>
                <p className={styles.label_dadata}>{label}</p>
                <SelectReact
                  {...fieldProps}
                  className={styles.select}
                  value={selectValue}
                  isClearable
                  isSearchable
                  closeMenuOnSelect={false}
                  name="dadata"
                  components={{ NoOptionsMessage }}
                  options={state}
                  placeholder={placeholder}
                  onChange={handleChangeAddress}
                  onInputChange={value => handleInputChange(value, query)}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#1373e5',
                      neutral20: 'rgba(214,220,227,0.5)',
                      neutral30: '#1373e5',
                    },
                  })}
                  styles={customStyles}
                />
              </>
            );
          }
          if (field?.type === 'number') {
            return (
              <Input
                {...fieldProps}
                type="text"
                label={label}
                placeholder={placeholder}
                inputMode="numeric"
              />
            );
          }

          return (
            <Input
              {...fieldProps}
              type="text"
              label={label}
              placeholder={placeholder}
            />
          );
        }}
      />
      <div className={styles.errorMessage}>
        {errors?.[keyValue]?.message && (
          <Translate id={`${errors?.[keyValue]?.message}`} />
        )}
      </div>
    </>
  );
};

export default OfferAllFields;
