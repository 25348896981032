import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * Masks API
 */
export default {
  /**
   * Получить объект со масками для валидации
   *
   * @returns
   */
  getData() {
    return AxiosInstance('').get(`${apiPrefix}${versionOnePrefix}/masks/list`);
  },
};
