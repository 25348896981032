import styled from 'styled-components';
import { Input } from 'antd';

export const InputStyled = styled(Input)`
  .ant-input[disabled] {
    color: #73797f;
    cursor: text;
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
  }
`;
