import React from 'react';
import {
  // Button,
  Input,
} from 'antd';
// import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';

import { SearchOutlined } from '@ant-design/icons';

import RatingBadge from '../RatingBadge';
import BalanceBadge from '../BalanceBadge';
import UserAvatar from '../UserAvatar';
import LevelBadge from '../LevelBadge';

// import headphonesImage from './assets/images/headphones.svg';

import { HeaderStyled } from './theme';

import {
// SELECTION_LOAN_PRODUCT_URL,
// HELP_SUPPORT_URL,
} from '../../constants/Routes/constants';

const SIDEBAR_WIDTH = '225px';

// FIXME: Временно для продакшена
const TEMP_UNDER_CONSTRUCTION = true;

interface IProps {
  handleShowUserSettings: (status: boolean) => void;
}

/**
 * Хеадер приложения. Десктоп версия
 *
 * @param {IProps} props
 * @returns
 */
const AppHeader: React.FC<IProps> = props => {
  const { handleShowUserSettings } = props;

  return (
    <HeaderStyled
      style={{
        position: 'fixed',
        width: `calc(100% - ${SIDEBAR_WIDTH})`,
      }}
    >
      {/* Search */}
      <Translate>
        {({ translate }) => (
          <div className={cx('row', 'rowSearch')}>
            {!TEMP_UNDER_CONSTRUCTION && (
              <Input
                className="searchInput"
                placeholder={translate(
                  'shared.componets.appheader.searchinput',
                ).toString()}
                suffix={<SearchOutlined />}
              />
            )}
          </div>
        )}
      </Translate>

      {/* Support */}
      {/* NB: классы в виде строк проставляются для styled-components.
      Так сложилось исторически. */}
      {/* <div className={cx('row', 'rowSupport')}> */}
      {/*  <Button */}
      {/*    type="default" */}
      {/*    shape="round" */}
      {/*    className={cx('supportBtnLinkText')} */}
      {/*  > */}
      {/*    <Link to={HELP_SUPPORT_URL}> */}
      {/*      <img src={headphonesImage} style={{ marginRight: 10 }} alt="Help" /> */}
      {/*      <Translate id="shared.componets.appheader.support" /> */}
      {/*    </Link> */}
      {/*  </Button> */}
      {/* </div> */}

      {/* Selection loan product */}
      {/* <div className={cx('row', 'rowLoanSelectionButton')}> */}
      {/*  <Button type="primary" shape="round"> */}
      {/*    <Link to={SELECTION_LOAN_PRODUCT_URL}> */}
      {/*      <Translate id="shared.componets.appheader.loanselection" /> */}
      {/*    </Link> */}
      {/*  </Button> */}
      {/* </div> */}

      {/* UserData */}
      <div className={cx('row', 'rowUserData')}>
        <UserAvatar handleShowUserSettings={handleShowUserSettings} />
        <BalanceBadge />
        <RatingBadge />
        <LevelBadge />
      </div>
    </HeaderStyled>
  );
};

export default AppHeader;
