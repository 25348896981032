import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

const initData = { data: [] };

// Actions ==========================

const ARTICLES = 'ARTICLES';

// Запрос информации из АПИ
export const articlesRequest = createAction(`${ARTICLES}/REQUEST`);
export const articlesSuccess = createAction(`${ARTICLES}/SUCCESS`);
export const articlesFailure = createAction(`${ARTICLES}/FAILURE`);

// Запрос информации из АПИ
export const articlesFilteredRequest = createAction(
  `${ARTICLES}/FILTERED_REQUEST`,
);
export const articlesFilteredSuccess = createAction(
  `${ARTICLES}/FILTERED_SUCCESS`,
);
export const articlesFilteredFailure = createAction(
  `${ARTICLES}/FILTERED_FAILURE`,
);

// Запрос информации из АПИ
export const articlesArticleRequest = createAction(
  `${ARTICLES}/ARTICLE_REQUEST`,
);
export const articlesArticleSuccess = createAction(
  `${ARTICLES}/ARTICLE_SUCCESS`,
);
export const articlesArticleFailure = createAction(
  `${ARTICLES}/ARTICLE_FAILURE`,
);

// Запрос информации из АПИ
export const articlesCategoriesRequest = createAction(
  `${ARTICLES}/CATEGORIES_REQUEST`,
);
export const articlesCategoriesSuccess = createAction(
  `${ARTICLES}/CATEGORIES_SUCCESS`,
);
export const articlesCategoriesFailure = createAction(
  `${ARTICLES}/CATEGORIES_FAILURE`,
);

// Результат запроса
export const articlesResult = createAction(`${ARTICLES}/RESULT`);

// Результат запроса отфильтрованных статей
export const articlesFilteredResult = createAction(
  `${ARTICLES}/FILTERED_RESULT`,
);

// Результат запроса данных одной статьи
export const articlesArticleResult = createAction(`${ARTICLES}/ARTICLE_RESULT`);

// Результат запроса данных категорий
export const articlesCategoriesResult = createAction(
  `${ARTICLES}/CATEGORIES_RESULT`,
);

// Запрос данных при скроллинге
export const articlesSecondRequest = createAction(`${ARTICLES}/SECOND_REQUEST`);

// Результат запроса при скроллинге
export const articlesSecondResult = createAction(`${ARTICLES}/SECOND_RESULT`);

// Запрос данных при скроллинге
export const articlesSecondFilteredRequest = createAction(
  `${ARTICLES}/SECOND_FILTERED_REQUEST`,
);

// Результат запроса при скроллинге
export const articlesSecondFilteredResult = createAction(
  `${ARTICLES}/SECOND_FILTERED_RESULT`,
);

// Reducers ==========================

// Статус запроса данных из АПИ
const isFetching = createReducer(false, {
  [articlesRequest.toString()]: () => true,
  [articlesResult.toString()]: () => false,
  [articlesSuccess.toString()]: () => false,
  [articlesFailure.toString()]: () => false,

  [articlesFilteredRequest.toString()]: () => true,
  [articlesFilteredResult.toString()]: () => false,
  [articlesFilteredSuccess.toString()]: () => false,
  [articlesFilteredFailure.toString()]: () => false,
});

// Статус кеша данных
const isAlreadyChashed = createReducer(false, {
  [articlesRequest.toString()]: () => false,
  [articlesResult.toString()]: () => true,
  [articlesSuccess.toString()]: () => true,
  [articlesFailure.toString()]: () => false,

  // Не кешировать при запросе отфильтрованных статей
  [articlesFilteredRequest.toString()]: () => false,
  [articlesFilteredResult.toString()]: () => false,
  [articlesFilteredSuccess.toString()]: () => false,
  [articlesFilteredFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isFetchingSecond = createReducer(false, {
  [articlesSecondRequest.toString()]: () => true,
  [articlesSecondResult.toString()]: () => false,
  [articlesSuccess.toString()]: () => false,
  [articlesFailure.toString()]: () => false,

  [articlesSecondFilteredRequest.toString()]: () => true,
  [articlesSecondFilteredResult.toString()]: () => false,
  [articlesFilteredSuccess.toString()]: () => false,
  [articlesFilteredFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isFetchingArticle = createReducer(false, {
  [articlesArticleRequest.toString()]: () => true,
  [articlesArticleResult.toString()]: () => false,
  [articlesArticleSuccess.toString()]: () => false,
  [articlesArticleFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isFetchingCategories = createReducer(false, {
  [articlesCategoriesRequest.toString()]: () => true,
  [articlesCategoriesResult.toString()]: () => false,
  [articlesCategoriesSuccess.toString()]: () => false,
  [articlesCategoriesFailure.toString()]: () => false,
});

// Аккумулятор полезных данных АПИ
const data = createReducer(
  { ...initData },
  {
    [articlesRequest.toString()]: (state = initData) => ({
      ...state,
    }),
    [articlesFilteredRequest.toString()]: (state = initData) => ({
      ...state,
    }),
    [articlesSuccess.toString()]: (_state, action) => action.payload,
    [articlesFilteredSuccess.toString()]: (_state, action) => action.payload,
  },
);

// Данные со статьями
const dataArticle = createReducer(
  {},
  {
    [articlesArticleRequest.toString()]: (state = {}) => ({ ...state }),
    [articlesArticleSuccess.toString()]: (_state, action) => action.payload,
  },
);

// Результат запроса данных из АПИ (содержит монаду либо с успешным результатом, либо с ошибкой)
const result = createReducer(
  {},
  {
    [articlesRequest.toString()]: () => ({}),
    [articlesResult.toString()]: (_state, action) => action.payload,

    [articlesSecondRequest.toString()]: () => ({}),
    [articlesSecondResult.toString()]: (_state, action) => action.payload,

    [articlesFilteredRequest.toString()]: () => ({}),
    [articlesFilteredResult.toString()]: (_state, action) => action.payload,

    [articlesSecondFilteredRequest.toString()]: () => ({}),
    [articlesSecondFilteredResult.toString()]: (_state, action) =>
      action.payload,
  },
);

// Результат запроса данных статьи из АПИ
// (содержит монаду либо с успешным результатом, либо с ошибкой)
const resultArticle = createReducer(
  {},
  {
    [articlesArticleRequest.toString()]: () => ({}),
    [articlesArticleResult.toString()]: (_state, action) => action.payload,
  },
);

// Результат запроса данных категорий из АПИ
// (содержит монаду либо с успешным результатом, либо с ошибкой)
const resultCategories = createReducer(
  {},
  {
    [articlesCategoriesRequest.toString()]: () => ({}),
    [articlesCategoriesResult.toString()]: (_state, action) => action.payload,
  },
);

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  result,
  isFetchingSecond,
  isFetchingArticle,
  dataArticle,
  isAlreadyChashed,
  resultArticle,
  resultCategories,
  isFetchingCategories,
});

export default reducer;
