import { put, call, all, cancel, takeLeading } from 'redux-saga/effects';

import {
  userLimitsRequest,
  userLimitsFailure,
  userLimitsSuccess,
} from '../duck';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import {
  LIMIT__DATA_RES,
} from './constants';

import { userLimits as userLimitsApi } from '../../../../services/UserLimits/api';

import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { logoutRequest } from '../../../Logout/state/duck';

export function* getData(action: any) {
  const completePayload: any = yield call(checkToken, action);

  const [
    userLimitsData,
  ]: any = yield all([
    call(userLimitsApi.getData, completePayload),
  ]);

  const input = [
    { ...userLimitsData, init: LIMIT__DATA_RES },
  ];

  const result = pipe(input, A.map(createResultV2), A.separate);
  const { left: errors, right: success } = result;

  if (errors.length > 0) {
    // NB: Если есть хоть одна 401, то разлогингить пользователя
    // и закончить работу саги
    for (let index = 0; index < errors.length; index++) {
      const element = errors[index];
      const { status } = element;

      if (status === 401) {
        yield put({
          type: logoutRequest.toString(),
        });
        yield cancel();
      }
    }

    yield put({
      type: userLimitsFailure.toString(),
    });
    yield cancel();
  }

  if (success.length > 0 && success.length === input.length) {
    const { data: limitsData } = userLimitsData;

    const payload = {
      limitsData,
    };

    yield put({
      type: userLimitsSuccess.toString(),
      payload,
    });
  }
} // getData =========

// Root Saga
export default function* rootSaga() {
  yield takeLeading(
    userLimitsRequest,
    safe(onError, getData, { terminator: userLimitsFailure }),
  );
}
