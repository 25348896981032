import { createEffect } from 'effector';
import { APIError, APIResult } from '../../core/api/Result';
import { SetTariffReq, TariffAPI } from '../../services/Tariff/api/api';
import { userTaskGetInstructionsRequest } from '../UserTask/state/duck';
import { $dispatch } from '../App/store';

export const setTariffFx = createEffect<SetTariffReq, APIResult<typeof TariffAPI['setTariff']>, APIError>(
  TariffAPI.setTariff,
);

setTariffFx.done.watch(({ result }) => {
  const dispatch = $dispatch.getState();
  dispatch({
    type: userTaskGetInstructionsRequest.toString(),
  });
  if (result.data.link) {
    const form = document.createElement('form');
    form.setAttribute('method', 'GET');
    form.setAttribute('action', result.data.link);

    document.body.appendChild(form);
    form.submit();
  }
});
