import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { logoutSuccess } from '../../../Logout/state/duck';

// Actions ==========================

const USER_SUBSCRIPTION = 'USER_SUBSCRIPTION';

export const userSubscriptionRequest = createAction(
  `${USER_SUBSCRIPTION}/REQUEST`,
);
export const userSubscriptionSuccess = createAction(
  `${USER_SUBSCRIPTION}/SUCCESS`,
);
export const userSubscriptionFailure = createAction(
  `${USER_SUBSCRIPTION}/FAILURE`,
);

export const userSubscriptionResult = createAction(
  `${USER_SUBSCRIPTION}/RESULT`,
);

export const userSubscriptionPutRequest = createAction(
  `${USER_SUBSCRIPTION}/PUT_REQUEST`,
);
export const userSubscriptionPutSuccess = createAction(
  `${USER_SUBSCRIPTION}/PUT_SUCCESS`,
);
export const userSubscriptionPutFailure = createAction(
  `${USER_SUBSCRIPTION}/PUT_FAILURE`,
);

export const userSubscriptionPutResult = createAction(
  `${USER_SUBSCRIPTION}/PUT_RESULT`,
);

// Reducers ==========================

const isFetching = createReducer(false, {
  [userSubscriptionRequest.toString()]: () => true,
  [userSubscriptionSuccess.toString()]: () => false,
  [userSubscriptionFailure.toString()]: () => false,
});

const data = createReducer(
  {},
  {
    [userSubscriptionRequest.toString()]: () => ({}),
    [userSubscriptionSuccess.toString()]: (_state, action) => action.payload,
    [userSubscriptionPutSuccess.toString()]: (_state, action) => action.payload,
    [logoutSuccess.toString()]: () => ({}),
  },
);

const result = createReducer(
  {},
  {
    [userSubscriptionRequest.toString()]: () => ({}),
    [userSubscriptionResult.toString()]: (_state, action) => action.payload,
    [logoutSuccess.toString()]: () => ({}),
  },
);

const error = createReducer(
  {},
  {
    [userSubscriptionRequest.toString()]: () => ({}),
    [userSubscriptionSuccess.toString()]: () => ({}),
    [userSubscriptionFailure.toString()]: (_state, action) => action.payload,
    [logoutSuccess.toString()]: () => ({}),
  },
);

const isFetchingPut = createReducer(false, {
  [userSubscriptionPutRequest.toString()]: () => true,
  [userSubscriptionPutSuccess.toString()]: () => false,
  [userSubscriptionPutFailure.toString()]: () => false,
});

const resultPut = createReducer(
  {},
  {
    [userSubscriptionPutRequest.toString()]: () => ({}),
    [userSubscriptionPutResult.toString()]: (_state, action) => action.payload,
    [logoutSuccess.toString()]: () => ({}),
  },
);

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  error,
  result,
  isFetchingPut,
  resultPut,
});

export default reducer;
