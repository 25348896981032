import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const USER_SETTINGS_CHANGE_AVATAR = 'USER_SETTINGS_CHANGE_AVATAR';

export const userSettingsChangeAvatarRequest = createAction(
  `${USER_SETTINGS_CHANGE_AVATAR}/REQUEST`,
);
export const userSettingsChangeAvatarSuccess = createAction(
  `${USER_SETTINGS_CHANGE_AVATAR}/SUCCESS`,
);
export const userSettingsChangeAvatarFailure = createAction(
  `${USER_SETTINGS_CHANGE_AVATAR}/FAILURE`,
);

export const userSettingsChangeAvatarResult = createAction(
  `${USER_SETTINGS_CHANGE_AVATAR}/RESULT`,
);

export const userSettingsChangeAvatarPostRequest = createAction(
  `${USER_SETTINGS_CHANGE_AVATAR}/PUT_REQUEST`,
);
export const userSettingsChangeAvatarPostSuccess = createAction(
  `${USER_SETTINGS_CHANGE_AVATAR}/PUT_SUCCESS`,
);
export const userSettingsChangeAvatarPostFailure = createAction(
  `${USER_SETTINGS_CHANGE_AVATAR}/PUT_FAILURE`,
);

export const userSettingsChangeAvatarPostResult = createAction(
  `${USER_SETTINGS_CHANGE_AVATAR}/PUT_RESULT`,
);

export const userSettingsChangeAvatarClearForm = createAction(
  `${USER_SETTINGS_CHANGE_AVATAR}/CLEAR_FORM`,
);

// Reducers ==========================

const isFetching = createReducer(false, {
  [userSettingsChangeAvatarRequest.toString()]: () => true,
  [userSettingsChangeAvatarSuccess.toString()]: () => false,
  [userSettingsChangeAvatarFailure.toString()]: () => false,
});

const data = createReducer(
  {},
  {
    [userSettingsChangeAvatarRequest.toString()]: () => ({}),
    [userSettingsChangeAvatarSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

const result = createReducer(
  {},
  {
    [userSettingsChangeAvatarRequest.toString()]: () => ({}),
    [userSettingsChangeAvatarResult.toString()]: (_state, action) =>
      action.payload,
  },
);

const isFetchingPost = createReducer(false, {
  [userSettingsChangeAvatarPostRequest.toString()]: () => true,
  [userSettingsChangeAvatarPostSuccess.toString()]: () => false,
  [userSettingsChangeAvatarPostFailure.toString()]: () => false,
});

const dataPost = createReducer(
  {},
  {
    [userSettingsChangeAvatarClearForm.toString()]: () => ({}),
    [userSettingsChangeAvatarPostRequest.toString()]: () => ({}),
    [userSettingsChangeAvatarPostSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

const resultPost = createReducer(
  {},
  {
    [userSettingsChangeAvatarPostRequest.toString()]: () => ({}),
    [userSettingsChangeAvatarPostResult.toString()]: (_state, action) =>
      action.payload,
  },
);

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  result,
  isFetchingPost,
  dataPost,
  resultPost,
});

export default reducer;
