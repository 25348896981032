import AxiosInstance from '../../../core/api/axiosinstance';
import paramsSerializer from '../../../shared/utils/paramsSerializer';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

export default {
  /**
   * HelpChat ChatListSupport API
   */
  getData(data: any) {
    const { token, perPage, page = 1 } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/support/messages?${paramsSerializer({
        page,
        'per-page': perPage,
        sort: '-id',
      })}`,
    );
  },

  // FIXME: На текущий момент не поддреживается в АПИ
  // т.к. ручка принимает только `contentType = 'multipart/form-data';`
  // NB: Основной метод реализован в `postFormData` ниже
  postData(data: any) {
    const { token } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/support/messages`,
      data,
    );
  },

  /**
   * Отправка вложений
   *
   * @param {*} data
   * @returns
   */
  postFormData(data: any) {
    const { token, formData } = data;
    const type = 'FORM_DATA';

    return AxiosInstance(token, type).post(
      `${apiPrefix}${versionOnePrefix}/support/messages`,
      formData,
    );
  },

  getDataLastMsg(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/support/messages/last`,
    );
  },

  // deleteNote(noteId) {
  //   return AxiosInstance(token).delete(`${apiPrefix}${versionOnePrefix}/notes/${noteId}`);
  // },
  // HelpChat ChatListSupport API
};
