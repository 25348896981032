import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

const preFetchedInitialState = {
  countOfFetches: 0,
  iteration: 0,
  total: 0,
  messages: [],
};

// Actions ==========================

const CHAT_LIST_SUPPORT = 'CHAT_LIST_SUPPORT';
const CHAT = 'CHAT';
const SOCKET = 'SOCKET';

// Запрос информации обо всех сообщениях в чате из АПИ
export const chatListSupportFetchChatRequest = createAction(
  `${CHAT_LIST_SUPPORT}/FETCH_CHAT_REQUEST`,
);
export const chatListSupportFetchChatRequestFirstTime = createAction(
  `${CHAT_LIST_SUPPORT}/FETCH_CHAT_REQUEST_FIRST_TIME`,
);
export const chatListSupportFetchChatSuccess = createAction(
  `${CHAT_LIST_SUPPORT}/FETCH_CHAT_SUCCESS`,
);
export const chatListSupportFetchChatFailure = createAction(
  `${CHAT_LIST_SUPPORT}/FETCH_CHAT_FAILURE`,
);

// PRE-FETCH Запрос информации обо всех сообщениях в системе из АПИ
export const chatListSupportPreFetchChatRequest = createAction(
  `${CHAT_LIST_SUPPORT}/PRE_FETCH_CHAT_REQUEST`,
);
export const chatListSupportPreFetchChatSuccess = createAction(
  `${CHAT_LIST_SUPPORT}/PRE_FETCH_CHAT_SUCCESS`,
);
export const chatListSupportPreFetchChatFailure = createAction(
  `${CHAT_LIST_SUPPORT}/PRE_FETCH_CHAT_FAILURE`,
);

// Обновить данные в редьюсере
export const chatListSupportUpdateDataRequest = createAction(
  `${CHAT_LIST_SUPPORT}/UPDATE_DATA_REQUEST`,
);
export const chatListSupportUpdateDataSuccess = createAction(
  `${CHAT_LIST_SUPPORT}/UPDATE_DATA_SUCCESS`,
);

export const chatListSupportFirstPreFetchChatRequest = createAction(
  `${CHAT_LIST_SUPPORT}/FIRST_PRE_FETCH_CHAT_REQUEST`,
);
export const chatListSupportFirstPreFetchChatSuccess = createAction(
  `${CHAT_LIST_SUPPORT}/FIRST_PRE_FETCH_CHAT_SUCCESS`,
);
export const chatListSupportFirstPreFetchChatFailure = createAction(
  `${CHAT_LIST_SUPPORT}/FIRST_PRE_FETCH_CHAT_FAILURE`,
);

// PRE-FETCH удалить все предзагруженные данные
export const chatListSupportPreFetchChatClear = createAction(
  `${CHAT_LIST_SUPPORT}/PRE_FETCH_CHAT_CLEAR`,
);

// Отправить сообшение чата
export const chatListSupportSendMsgRequest = createAction(
  `${CHAT_LIST_SUPPORT}/SEND_MSG_REQUEST`,
);
export const chatListSupportSendMsgSuccess = createAction(
  `${CHAT_LIST_SUPPORT}/SEND_MSG_SUCCESS`,
);
export const chatListSupportSendMsgFailure = createAction(
  `${CHAT_LIST_SUPPORT}/SEND_MSG_FAILURE`,
);

// Создать структуру, на основе кторой будет формироваться строка ввода в чате
export const chatListSupportChatInputRequest = createAction(
  `${CHAT_LIST_SUPPORT}/CHAT_INPUT_REQUEST`,
);
export const chatListSupportChatInputSuccess = createAction(
  `${CHAT_LIST_SUPPORT}/CHAT_INPUT_SUCCESS`,
);
export const chatListSupportChatInputFailure = createAction(
  `${CHAT_LIST_SUPPORT}/CHAT_INPUT_FAILURE`,
);

// Создать структуру, на основе кторой будет формироваться строка ввода в чате
export const chatListSupportActiveButtonRequest = createAction(
  `${CHAT_LIST_SUPPORT}/ACTIVE_BUTTON_REQUEST`,
);
export const chatListSupportActiveButtonSuccess = createAction(
  `${CHAT_LIST_SUPPORT}/ACTIVE_BUTTON_SUCCESS`,
);
export const chatListSupportActiveButtonFailure = createAction(
  `${CHAT_LIST_SUPPORT}/ACTIVE_BUTTON_FAILURE`,
);

// Запрос информации о самом последнем сообщении в чате
export const chatListSupportLastMessageRequest = createAction(
  `${CHAT_LIST_SUPPORT}/LAST_MESSAGE_REQUEST`,
);
export const chatListSupportLastMessageSuccess = createAction(
  `${CHAT_LIST_SUPPORT}/LAST_MESSAGE_SUCCESS`,
);
export const chatListSupportLastMessageFailure = createAction(
  `${CHAT_LIST_SUPPORT}/LAST_MESSAGE_FAILURE`,
);

// Отправить сообшение чата c вложенными файлами
export const chatSendAttachRequest = createAction(
  `${CHAT}/SEND_ATTACH_REQUEST`,
);
export const chatSendAttachSuccess = createAction(
  `${CHAT}/SEND_ATTACH_SUCCESS`,
);
export const chatSendAttachFailure = createAction(
  `${CHAT}/SEND_ATTACH_FAILURE`,
);

// Soket server Action
export const chatListSupportResMsgFromSocket = createAction(
  `${CHAT_LIST_SUPPORT}/RES_MSG_FROM_SOCKET`,
);
export const socketSupportClearBeforeRes = createAction(
  `${SOCKET}/SUPPORT_CLEAR_BEFORE_RES`,
);
export const socketSupportAllowedAcceptData = createAction(
  `${SOCKET}/SUPPORT_ALLOWED_ACCEPT_DATA`,
);

// Reducers ==========================

// Статус запроса данных из АПИ
const isFetching = createReducer(false, {
  [chatListSupportFetchChatRequestFirstTime.toString()]: () => true,
  [chatListSupportFetchChatSuccess.toString()]: () => false,
  [chatListSupportFetchChatFailure.toString()]: () => false,
});

// PRE FETCH Статус запроса данных из АПИ
const isFetchingPreFetch = createReducer(false, {
  [chatListSupportPreFetchChatRequest.toString()]: () => true,
  [chatListSupportPreFetchChatSuccess.toString()]: () => false,
  [chatListSupportPreFetchChatFailure.toString()]: () => false,
});

// FIRST PRE FETCH Статус запроса данных из АПИ
const isFetchingFirstPreFetch = createReducer(false, {
  [chatListSupportFirstPreFetchChatRequest.toString()]: () => true,
  [chatListSupportFirstPreFetchChatSuccess.toString()]: () => false,
  [chatListSupportFirstPreFetchChatFailure.toString()]: () => false,
  // Если сфейлится один из пяти первых запросов, то сбросить статус
  [chatListSupportPreFetchChatFailure.toString()]: () => false,
});

// SEND CHAT MESSAGE Статус запроса данных из АПИ
const isFetchingChatSendMsg = createReducer(false, {
  [chatListSupportResMsgFromSocket.toString()]: () => true,
  [chatListSupportSendMsgRequest.toString()]: () => true,
  [chatSendAttachRequest.toString()]: () => true,
  [chatListSupportSendMsgSuccess.toString()]: () => false,
  [chatListSupportSendMsgFailure.toString()]: () => false,
});

// Обновление данных после отправки сообщения
const isUpdatingDataAfterChatSendMsg = createReducer(false, {
  [chatListSupportResMsgFromSocket.toString()]: () => true,
  [chatListSupportSendMsgRequest.toString()]: () => true,
  [chatSendAttachRequest.toString()]: () => true,
  [chatListSupportUpdateDataSuccess.toString()]: () => false,
  [chatListSupportSendMsgFailure.toString()]: () => false,
});

// Успешный результат запроса из АПИ. Влияет на состояние инпута в чате
const isChatInputNeedClear = createReducer(false, {
  [chatListSupportChatInputRequest.toString()]: () => false,
  [chatListSupportChatInputSuccess.toString()]: () => true,
  [chatListSupportChatInputFailure.toString()]: () => false,
  [chatListSupportSendMsgRequest.toString()]: () => false,
  [chatListSupportResMsgFromSocket.toString()]: () => false,
  [chatListSupportSendMsgSuccess.toString()]: () => true,
});

// Запрос данных о последнем сообщении
const isFetchingLastMsg = createReducer(false, {
  [chatListSupportLastMessageRequest.toString()]: () => true,
  [chatListSupportLastMessageSuccess.toString()]: () => false,
  [chatListSupportLastMessageFailure.toString()]: () => false,
});

// Успешный результат запроса из АПИ
const data = createReducer([], {
  [chatListSupportFetchChatRequest.toString()]: () => [],
  [chatListSupportFetchChatSuccess.toString()]: (_state, action) =>
    action.payload,
});

// Успешный результат запроса из АПИ
const dataLastMsg = createReducer([], {
  [chatListSupportLastMessageRequest.toString()]: () => [],
  [chatListSupportLastMessageSuccess.toString()]: (_state, action) =>
    action.payload,
});

// Успешный результат запроса из АПИ
const dataChatInput = createReducer([], {
  [chatListSupportChatInputRequest.toString()]: () => [],
  [chatListSupportChatInputSuccess.toString()]: (_state, action) =>
    action.payload,
});

// Успешный результат запроса из АПИ
const dataActiveButton = createReducer('', {
  [chatListSupportChatInputSuccess.toString()]: () => '',
  [chatListSupportActiveButtonRequest.toString()]: () => '',
  [chatListSupportActiveButtonSuccess.toString()]: (_state, action) =>
    action.payload,
});

// Ошибка при запросе данных из АПИ
const error = createReducer([], {
  [chatListSupportFetchChatRequest.toString()]: () => [],
  [chatListSupportFetchChatFailure.toString()]: (_state, action) =>
    action.payload,
  [chatListSupportPreFetchChatFailure.toString()]: (_state, action) =>
    action.payload,
});

// Ошибка при запросе данных из АПИ
const errorLastMsg = createReducer([], {
  [chatListSupportLastMessageRequest.toString()]: () => [],
  [chatListSupportLastMessageFailure.toString()]: (_state, action) =>
    action.payload,
});

// PRE FETCHED DATA
const preFetchedData = createReducer(
  { ...preFetchedInitialState },
  {
    [chatListSupportPreFetchChatRequest.toString()]: (
      state = preFetchedInitialState,
    ) => ({
      ...state,
    }),
    [chatListSupportPreFetchChatSuccess.toString()]: (_state, action) =>
      action.payload,
    [chatListSupportPreFetchChatClear.toString()]: () => ({
      ...preFetchedInitialState,
    }),
    // Есть информация о высоте от псевдорендера, обновить данные в редьюсере
    [chatListSupportUpdateDataSuccess.toString()]: (
      state = preFetchedInitialState,
      action,
    ) => ({
      ...state,
      messages: [...action.payload.messages],
    }),
    // Успешно пришел ответ от сервера, на текушее сообщение пользователя
    [chatListSupportSendMsgSuccess.toString()]: (
      state = preFetchedInitialState,
      action,
    ) => ({
      ...state,
      callFrom: 'createNewMessage',
      messages: [...action.payload.messages],
      total: action.payload.total,
    }),
  },
);

// Статус обработки сообщения от сокет сервера
const isResponseFromSocketServer = createReducer(false, {
  [chatListSupportResMsgFromSocket.toString()]: () => true,
  [socketSupportClearBeforeRes.toString()]: () => false,
});

// Статус обработки сообщения от сокет сервера
const isAllowedAcceptFromSocketServer = createReducer(false, {
  [socketSupportAllowedAcceptData.toString()]: () => true,
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  error,
  preFetchedData,
  isFetchingPreFetch,
  isFetchingFirstPreFetch,
  isFetchingChatSendMsg,
  dataChatInput,
  dataActiveButton,
  isUpdatingDataAfterChatSendMsg,
  isChatInputNeedClear,
  isFetchingLastMsg,
  dataLastMsg,
  errorLastMsg,
  isResponseFromSocketServer,
  isAllowedAcceptFromSocketServer,
});

export default reducer;
