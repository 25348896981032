import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

const initData = { data: [] };

// Actions ==========================

const FEEDBACKS = 'FEEDBACKS';

// Запрос инициализации из АПИ
export const feedbacksInitRequest = createAction(`${FEEDBACKS}/INIT_REQUEST`);
export const feedbacksInitSuccess = createAction(`${FEEDBACKS}/INIT_SUCCESS`);
export const feedbacksInitFailure = createAction(`${FEEDBACKS}/INIT_FAILURE`);

// Запрос инициализации из АПИ
export const feedbacksInitDrawerRequest = createAction(
  `${FEEDBACKS}/INIT_DRAWER_REQUEST`,
);
export const feedbacksInitDrawerSuccess = createAction(
  `${FEEDBACKS}/INIT_DRAWER_SUCCESS`,
);
export const feedbacksInitDrawerFailure = createAction(
  `${FEEDBACKS}/INIT_DRAWER_FAILURE`,
);

// Запрос информации по разделу "Отзывы" из АПИ
export const feedbacksRequest = createAction(`${FEEDBACKS}/REQUEST`);
export const feedbacksSuccess = createAction(`${FEEDBACKS}/SUCCESS`);
export const feedbacksFailure = createAction(`${FEEDBACKS}/FAILURE`);

// Запрос информации по разделу "Отзывы" из АПИ
export const feedbacksDrawerRequest = createAction(
  `${FEEDBACKS}/DRAWER_REQUEST`,
);
export const feedbacksDrawerSuccess = createAction(
  `${FEEDBACKS}/DRAWER_SUCCESS`,
);
export const feedbacksDrawerFailure = createAction(
  `${FEEDBACKS}/DRAWER_FAILURE`,
);

// Пропатчить уже загруженные комментарии новым добавленным комментарием пользователя
export const feedbacksPatchCommentsRequest = createAction(
  `${FEEDBACKS}/PATCH_COMMENTS_REQUEST`,
);
export const feedbacksPatchCommentsSuccess = createAction(
  `${FEEDBACKS}/PATCH_COMMENTS_SUCCESS`,
);
export const feedbacksPatchCommentsDrawerSuccess = createAction(
  `${FEEDBACKS}/PATCH_COMMENTS_DRAWER_SUCCESS`,
);
export const feedbacksPatchCommentsFailure = createAction(
  `${FEEDBACKS}/PATCH_COMMENTS_FAILURE`,
);

// Пропатчить уже загруженные лайки новым значением лайка пользователя
export const feedbacksPatchLikesRequest = createAction(
  `${FEEDBACKS}/PATCH_LIKES_REQUEST`,
);
export const feedbacksPatchLikesSuccess = createAction(
  `${FEEDBACKS}/PATCH_LIKES_SUCCESS`,
);
export const feedbacksPatchLikesDrawerSuccess = createAction(
  `${FEEDBACKS}/PATCH_LIKES_DRAWER_SUCCESS`,
);
export const feedbacksPatchLikesFailure = createAction(
  `${FEEDBACKS}/PATCH_LIKES_FAILURE`,
);

// Запрос информации о кредитных продуктах
export const feedbacksCredProdsRequest = createAction(
  `${FEEDBACKS}/CRED_PRODS_REQUEST`,
);
export const feedbacksCredProdsSuccess = createAction(
  `${FEEDBACKS}/CRED_PRODS_SUCCESS`,
);
export const feedbacksCredProdsFailure = createAction(
  `${FEEDBACKS}/CRED_PRODS_FAILURE`,
);

// Запрос данных при скроллинге
export const feedbacksSecondRequest = createAction(
  `${FEEDBACKS}/SECOND_REQUEST`,
);

// Запрос данных при скроллинге
export const feedbacksSecondDrawerRequest = createAction(
  `${FEEDBACKS}/SECOND_DRAWER_REQUEST`,
);

// Создать новый "Отзыв"
export const feedbacksCreateReviewRequest = createAction(
  `${FEEDBACKS}/CREATE_REVIEW_REQUEST`,
);
export const feedbacksCreateReviewSuccess = createAction(
  `${FEEDBACKS}/CREATE_REVIEW_SUCCESS`,
);
export const feedbacksCreateReviewFailure = createAction(
  `${FEEDBACKS}/CREATE_REVIEW_FAILURE`,
);

// Создать новый "Комментарий"
export const feedbacksCreateCommentRequest = createAction(
  `${FEEDBACKS}/CREATE_COMMENT_REQUEST`,
);
export const feedbacksCreateCommentSuccess = createAction(
  `${FEEDBACKS}/CREATE_COMMENT_SUCCESS`,
);
export const feedbacksCreateCommentFailure = createAction(
  `${FEEDBACKS}/CREATE_COMMENT_FAILURE`,
);

export const feedbacksFormClear = createAction(`${FEEDBACKS}/FORM_CLEAR`);
export const feedbacksCommentFormClear = createAction(
  `${FEEDBACKS}/COMMENT_FORM_CLEAR`,
);

// Запрос информации: организации
export const feedbacksOrgRequest = createAction(`${FEEDBACKS}/ORG_REQUEST`);
export const feedbacksOrgSuccess = createAction(`${FEEDBACKS}/ORG_SUCCESS`);
export const feedbacksOrgFailure = createAction(`${FEEDBACKS}/ORG_FAILURE`);

// Запрос информации: города
export const feedbacksCitiesRequest = createAction(
  `${FEEDBACKS}/CITIES_REQUEST`,
);
export const feedbacksCitiesSuccess = createAction(
  `${FEEDBACKS}/CITIES_SUCCESS`,
);
export const feedbacksCitiesFailure = createAction(
  `${FEEDBACKS}/CITIES_FAILURE`,
);

// Добавить лайк статье
export const feedbacksAddLikeRequest = createAction(
  `${FEEDBACKS}/ADD_LIKE_REQUEST`,
);
export const feedbacksAddLikeSuccess = createAction(
  `${FEEDBACKS}/ADD_LIKE_SUCCESS`,
);
export const feedbacksAddLikeFailure = createAction(
  `${FEEDBACKS}/ADD_LIKE_FAILURE`,
);

// Получить данные обо всех отзывах в которых пользователь поставил лайк
export const feedbacksAllUserLikesRequest = createAction(
  `${FEEDBACKS}/ALL_USER_LIKES_REQUEST`,
);
export const feedbacksAllUserLikesSuccess = createAction(
  `${FEEDBACKS}/ALL_USER_LIKES_SUCCESS`,
);
export const feedbacksAllUserLikesFailure = createAction(
  `${FEEDBACKS}/ALL_USER_LIKES_FAILURE`,
);

export const feedbacksRemoveLikeRequest = createAction(
  `${FEEDBACKS}/REMOVE_LIKE_REQUEST`,
);
export const feedbacksRemoveLikeSuccess = createAction(
  `${FEEDBACKS}/REMOVE_LIKE_SUCCESS`,
);
export const feedbacksRemoveLikeFailure = createAction(
  `${FEEDBACKS}/REMOVE_LIKE_FAILURE`,
);

export const feedbacksInitResult = createAction(`${FEEDBACKS}/INIT_RESULT`);
export const feedbacksInitDrawerResult = createAction(
  `${FEEDBACKS}/INIT_DRAWER_RESULT`,
);
export const feedbacksResult = createAction(`${FEEDBACKS}/RESULT`);
export const feedbacksSecondResult = createAction(`${FEEDBACKS}/SECOND_RESULT`);
export const feedbacksSecondDrawerResult = createAction(
  `${FEEDBACKS}/SECOND_DRAWER_RESULT`,
);
export const feedbacksCreateReviewResult = createAction(
  `${FEEDBACKS}/CREATE_REVIEW_RESULT`,
);
export const feedbacksOrgResult = createAction(`${FEEDBACKS}/ORG_RESULT`);
export const feedbacksCitiesResult = createAction(`${FEEDBACKS}/CITIES_RESULT`);
export const feedbacksCreateCommentResult = createAction(
  `${FEEDBACKS}/CREATE_COMMENT_RESULT`,
);
export const feedbacksAddLikeResult = createAction(
  `${FEEDBACKS}/ADD_LIKE_RESULT`,
);
export const feedbacksRemoveLikeResult = createAction(
  `${FEEDBACKS}/REMOVE_LIKE_RESULT`,
);

// Reducers ==========================

// Статус запроса данных из АПИ
const isFetching = createReducer(false, {
  [feedbacksRequest.toString()]: () => true,
  [feedbacksSuccess.toString()]: () => false,
  [feedbacksFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isFetchingSecond = createReducer(false, {
  [feedbacksSecondRequest.toString()]: () => true,
  [feedbacksSuccess.toString()]: () => false,
  [feedbacksFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isFetchingInit = createReducer(false, {
  [feedbacksInitRequest.toString()]: () => true,
  [feedbacksInitSuccess.toString()]: () => false,
  [feedbacksInitFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isFetchingDrawer = createReducer(false, {
  [feedbacksDrawerRequest.toString()]: () => true,
  [feedbacksDrawerSuccess.toString()]: () => false,
  [feedbacksDrawerFailure.toString()]: () => false,
});
const isFetchingInitDrawer = createReducer(false, {
  [feedbacksInitDrawerRequest.toString()]: () => true,
  [feedbacksInitDrawerSuccess.toString()]: () => false,
  [feedbacksInitDrawerFailure.toString()]: () => false,
});
// Статус запроса данных из АПИ
const isFetchingSecondDrawer = createReducer(false, {
  [feedbacksSecondDrawerRequest.toString()]: () => true,
  [feedbacksDrawerSuccess.toString()]: () => false,
  [feedbacksDrawerFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isFetchingInitStatus = createReducer(false, {
  [feedbacksInitRequest.toString()]: () => false,
  [feedbacksInitSuccess.toString()]: () => true,
  [feedbacksInitFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isFetchingInitDrawerStatus = createReducer(false, {
  [feedbacksInitDrawerRequest.toString()]: () => false,
  [feedbacksInitDrawerSuccess.toString()]: () => true,
  [feedbacksInitDrawerFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isCreatingReview = createReducer(false, {
  [feedbacksCreateReviewRequest.toString()]: () => true,
  [feedbacksCreateReviewSuccess.toString()]: () => false,
  [feedbacksCreateReviewFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isCreatingComment = createReducer(false, {
  [feedbacksCreateCommentRequest.toString()]: () => true,
  [feedbacksCreateCommentSuccess.toString()]: () => false,
  [feedbacksCreateCommentFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isCreateCommentStatus = createReducer(false, {
  [feedbacksCommentFormClear.toString()]: () => false,
  [feedbacksPatchCommentsRequest.toString()]: () => false,
  [feedbacksCreateCommentRequest.toString()]: () => false,
  [feedbacksCreateCommentSuccess.toString()]: () => true,
  [feedbacksCreateCommentFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isCreateCommentErrorStatus = createReducer(false, {
  [feedbacksCreateCommentRequest.toString()]: () => false,
  [feedbacksCreateCommentSuccess.toString()]: () => false,
  [feedbacksCreateCommentFailure.toString()]: () => true,
});

// Статус запроса данных из АПИ
const isCreateStatus = createReducer(false, {
  [feedbacksFormClear.toString()]: () => false,
  [feedbacksCreateReviewRequest.toString()]: () => false,
  [feedbacksCreateReviewSuccess.toString()]: () => true,
  [feedbacksCreateReviewFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isFetchingOrg = createReducer(false, {
  [feedbacksOrgRequest.toString()]: () => true,
  [feedbacksOrgSuccess.toString()]: () => false,
  [feedbacksOrgFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isFetchingCities = createReducer(false, {
  [feedbacksCitiesRequest.toString()]: () => true,
  [feedbacksCitiesSuccess.toString()]: () => false,
  [feedbacksCitiesFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isAddLike = createReducer(false, {
  [feedbacksAddLikeRequest.toString()]: () => true,
  [feedbacksAddLikeSuccess.toString()]: () => false,
  [feedbacksAddLikeFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isAddLikeStatus = createReducer(false, {
  [feedbacksRemoveLikeRequest.toString()]: () => false,
  [feedbacksAddLikeRequest.toString()]: () => false,
  [feedbacksAddLikeSuccess.toString()]: () => true,
  [feedbacksAddLikeFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isFetchingAllUserLikes = createReducer(false, {
  [feedbacksAllUserLikesRequest.toString()]: () => true,
  [feedbacksAllUserLikesSuccess.toString()]: () => false,
  [feedbacksAllUserLikesFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isRemoveLike = createReducer(false, {
  [feedbacksRemoveLikeRequest.toString()]: () => true,
  [feedbacksRemoveLikeSuccess.toString()]: () => false,
  [feedbacksRemoveLikeFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isRemoveLikeStatus = createReducer(false, {
  [feedbacksAddLikeRequest.toString()]: () => false,
  [feedbacksRemoveLikeRequest.toString()]: () => false,
  [feedbacksRemoveLikeSuccess.toString()]: () => true,
  [feedbacksRemoveLikeFailure.toString()]: () => false,
});

// Успешный результат запроса из АПИ
const data = createReducer(
  { ...initData },
  {
    [feedbacksRequest.toString()]: (state = initData) => ({
      ...state,
    }),
    [feedbacksSuccess.toString()]: (_state, action) => action.payload,
    [feedbacksPatchCommentsSuccess.toString()]: (_state, action) =>
      action.payload,
    [feedbacksPatchLikesSuccess.toString()]: (_state, action) => action.payload,
  },
);

// Успешный результат запроса из АПИ
const dataDrawer = createReducer(
  { ...initData },
  {
    [feedbacksDrawerRequest.toString()]: (state = initData) => ({
      ...state,
    }),
    [feedbacksDrawerSuccess.toString()]: (_state, action) => action.payload,
    [feedbacksPatchCommentsDrawerSuccess.toString()]: (_state, action) =>
      action.payload,
    [feedbacksPatchLikesDrawerSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

// Результат запроса данных из АПИ
const result = createReducer(
  {},
  {
    [feedbacksRequest.toString()]: () => ({}),
    [feedbacksResult.toString()]: (_state, action) => action.payload,
  },
);

// Успешный результат запроса из АПИ
const newReviewData = createReducer(
  {},
  {
    [feedbacksCreateReviewRequest.toString()]: () => ({}),
    [feedbacksCreateReviewSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

// Результат запроса данных из АПИ
const newReviewResult = createReducer(
  {},
  {
    [feedbacksCreateReviewRequest.toString()]: () => ({}),
    [feedbacksCreateReviewResult.toString()]: (_state, action) =>
      action.payload,
  },
);

// Успешный результат запроса из АПИ
const newCommentData = createReducer(
  {},
  {
    [feedbacksCreateCommentRequest.toString()]: () => ({}),
    [feedbacksCreateCommentSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

// Успешный результат запроса из АПИ
const newCommentResult = createReducer(
  {},
  {
    [feedbacksCreateCommentRequest.toString()]: () => ({}),
    [feedbacksCreateCommentResult.toString()]: (_state, action) =>
      action.payload,
  },
);

// Успешный результат запроса из АПИ
const orgData = createReducer(
  {},
  {
    [feedbacksOrgRequest.toString()]: () => ({}),
    [feedbacksOrgSuccess.toString()]: (_state, action) => action.payload,
  },
);

// Успешный результат запроса из АПИ
const orgDataResult = createReducer(
  {},
  {
    [feedbacksOrgRequest.toString()]: () => ({}),
    [feedbacksOrgResult.toString()]: (_state, action) => action.payload,
  },
);

// Успешный результат запроса из АПИ
const citiesData = createReducer(
  {},
  {
    [feedbacksCitiesRequest.toString()]: () => ({}),
    [feedbacksCitiesSuccess.toString()]: (_state, action) => action.payload,
  },
);

// Успешный результат запроса из АПИ
const citiesDataResult = createReducer(
  {},
  {
    [feedbacksCitiesRequest.toString()]: () => ({}),
    [feedbacksCitiesResult.toString()]: (_state, action) => action.payload,
  },
);

// Успешный результат запроса из АПИ
const credProdsData = createReducer(
  {},
  {
    [feedbacksCredProdsRequest.toString()]: () => ({}),
    [feedbacksCredProdsSuccess.toString()]: (_state, action) => action.payload,
  },
);

// Успешный результат запроса из АПИ
const addLikeData = createReducer(
  {},
  {
    [feedbacksAddLikeRequest.toString()]: () => ({}),
    [feedbacksAddLikeSuccess.toString()]: (_state, action) => action.payload,
  },
);

// Успешный результат запроса из АПИ
const addLikeDataResult = createReducer(
  {},
  {
    [feedbacksAddLikeRequest.toString()]: () => ({}),
    [feedbacksAddLikeResult.toString()]: (_state, action) => action.payload,
  },
);

// Успешный результат запроса из АПИ
const allUserLikesData = createReducer(
  { data: [] },
  {
    [feedbacksAllUserLikesRequest.toString()]: () => ({ data: [] }),
    [feedbacksAllUserLikesSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

// Успешный результат запроса из АПИ
const removeLikeData = createReducer(
  {},
  {
    [feedbacksRemoveLikeRequest.toString()]: () => ({}),
    [feedbacksRemoveLikeSuccess.toString()]: (_state, action) => action.payload,
  },
);

// Успешный результат запроса из АПИ
const removeLikeDataResult = createReducer(
  {},
  {
    [feedbacksRemoveLikeRequest.toString()]: () => ({}),
    [feedbacksRemoveLikeResult.toString()]: (_state, action) => action.payload,
  },
);

// Ошибка при запросе данных из АПИ
const error = createReducer([], {
  [feedbacksRequest.toString()]: () => [],
  [feedbacksSuccess.toString()]: () => [],
  [feedbacksFailure.toString()]: (_state, action) => action.payload,
});

// Ошибка при запросе данных из АПИ
const errorCreateReview = createReducer([], {
  [feedbacksCreateReviewRequest.toString()]: () => [],
  [feedbacksCreateReviewSuccess.toString()]: () => [],
  [feedbacksCreateReviewFailure.toString()]: (_state, action) => action.payload,
});

// Ошибка при запросе данных из АПИ
const errorCreateComment = createReducer([], {
  [feedbacksCreateCommentRequest.toString()]: () => [],
  [feedbacksCreateCommentSuccess.toString()]: () => [],
  [feedbacksCreateCommentFailure.toString()]: (_state, action) =>
    action.payload,
});

// Ошибка при запросе данных из АПИ
const errorFetchOrgs = createReducer([], {
  [feedbacksOrgRequest.toString()]: () => [],
  [feedbacksOrgSuccess.toString()]: () => [],
  [feedbacksOrgFailure.toString()]: (_state, action) => action.payload,
});

// Ошибка при запросе данных из АПИ
const errorFetchCities = createReducer([], {
  [feedbacksCitiesRequest.toString()]: () => [],
  [feedbacksCitiesSuccess.toString()]: () => [],
  [feedbacksCitiesFailure.toString()]: (_state, action) => action.payload,
});

// Ошибка при запросе данных из АПИ
const errorFetchCredProds = createReducer([], {
  [feedbacksCredProdsRequest.toString()]: () => [],
  [feedbacksCredProdsSuccess.toString()]: () => [],
  [feedbacksCredProdsFailure.toString()]: (_state, action) => action.payload,
});

// Ошибка при запросе данных из АПИ
const errorAddLike = createReducer([], {
  [feedbacksAddLikeRequest.toString()]: () => [],
  [feedbacksAddLikeSuccess.toString()]: () => [],
  [feedbacksAddLikeFailure.toString()]: (_state, action) => action.payload,
});

// Ошибка при запросе данных из АПИ
const errorAllUserLikes = createReducer([], {
  [feedbacksAllUserLikesRequest.toString()]: () => [],
  [feedbacksAllUserLikesSuccess.toString()]: () => [],
  [feedbacksAllUserLikesFailure.toString()]: (_state, action) => action.payload,
});

// Ошибка при запросе данных из АПИ
const errorRemoveLike = createReducer([], {
  [feedbacksRemoveLikeRequest.toString()]: () => [],
  [feedbacksRemoveLikeSuccess.toString()]: () => [],
  [feedbacksRemoveLikeFailure.toString()]: (_state, action) => action.payload,
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  result,
  error,
  isCreatingReview,
  newReviewData,
  errorCreateReview,
  isFetchingOrg,
  orgData,
  errorFetchOrgs,
  isFetchingCities,
  citiesData,
  errorFetchCities,
  isFetchingInit,
  credProdsData,
  errorFetchCredProds,
  isCreateStatus,
  isCreatingComment,
  isCreateCommentStatus,
  newCommentData,
  errorCreateComment,
  isCreateCommentErrorStatus,
  isAddLike,
  addLikeData,
  errorAddLike,
  isFetchingAllUserLikes,
  allUserLikesData,
  errorAllUserLikes,
  isRemoveLike,
  removeLikeData,
  errorRemoveLike,
  isAddLikeStatus,
  isRemoveLikeStatus,
  newReviewResult,
  orgDataResult,
  citiesDataResult,
  newCommentResult,
  addLikeDataResult,
  removeLikeDataResult,
  isFetchingSecond,
  isFetchingInitStatus,
  // Drawer
  isFetchingDrawer,
  isFetchingInitDrawer,
  isFetchingSecondDrawer,
  dataDrawer,
  isFetchingInitDrawerStatus,
});

export default reducer;
