import { IEnv } from './env.type';

export const environment: IEnv = {
  PRODUCTION: true,
  API_PREFIX: 'https://api.finanso.com/',
  STATIC_PREFIX: 'https://api.finanso.com',
  API_VERSION_ONE_PREFIX: 'v1',
  SITE_PREFIX: 'https://my.finanso.com/',
  MAIN_LANDING_URL: 'https://my.finanso.com/',
  MAIN_LANDING_STATIC_URL: 'https://finanso.com',
  WEB_SOCKET_SUFFIX: '/connection/websocket',
  RECAPTCHA_SITE_KEY: '6LfNFH4aAAAAABlUOFjyDT7Gc1CTniCV4YP3ZwOt',
  EXCHANGE_RECOMPUTE: true,
};
