import { createSelector } from 'reselect';

export const isFetching = (store: any) => store.faqReducer.isFetching;

export const data = createSelector(
  (store: any) => store.faqReducer.data,
  data => data,
);

export const error = createSelector(
  (store: any) => store.faqReducer.error,
  error => error,
);

export const isCached = createSelector(
  (store: any) => store.faqReducer.isCached,
  isCached => isCached,
);
