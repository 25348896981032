import { createEffect } from 'effector';
import { userSubscriptionAPI } from '../../services/UserSubscription/api';
import { APIError, APIResult } from '../../core/api/Result';
import { DeleteSubscribeReq } from '../../services/UserSubscription/api/api';

export const $getSubscribe = createEffect<unknown, APIResult<typeof userSubscriptionAPI['getSubscribe']>, APIError>(
  userSubscriptionAPI.getSubscribe,
);

export const $deleteSubscribe = createEffect<DeleteSubscribeReq, APIResult<typeof userSubscriptionAPI['deleteSubscribe']>, APIError>(
  userSubscriptionAPI.deleteSubscribe,
);

$deleteSubscribe.done.watch(() => {
  $getSubscribe(null);
});
