import { put, takeLatest, call } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';

import {
  balanceDepositRateFailure,
  balanceDepositRateRequest,
  balanceDepositRateSuccess, balanceDepositRequest, balanceDepositFailure, balanceDepositSuccess,
} from '../duck';

import { shopAPI } from '../../../../services/Shop/api';
import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';

import { createResultV2 } from '../../../../core/state/utils/createResult/saga';
import { IResultEiterAction } from '../../../../models/ResultEiterAction';

export function* getPriceRate(action: IResultEiterAction) {
  const completePayload: any = yield call(checkToken, action.payload || {});
  const res = yield call(shopAPI.getPriceList, { ...completePayload });

  const result = createResultV2(res);

  if (E.isRight(result)) {
    const { right: successData }: any = result;
    const data = successData?.data ?? [];
    yield put({
      type: balanceDepositRateSuccess.toString(),
      payload: data,
    });
  }
  if (E.isLeft(result)) {
    yield put({
      type: balanceDepositRateFailure.toString(),
    });
  }
}

export function* getDeposit(action: IResultEiterAction) {
  const completePayload: any = yield call(checkToken, action);
  const res = yield call(shopAPI.getDeposit, completePayload);

  const result = createResultV2(res);

  if (E.isRight(result)) {
    const { right: successData }: any = result;
    const data = successData?.data ?? [];
    yield put({
      type: balanceDepositSuccess.toString(),
      payload: data,
    });
  }
  if (E.isLeft(result)) {
    yield put({
      type: balanceDepositFailure.toString(),
    });
  }
}

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    balanceDepositRateRequest,
    safe(onError, getPriceRate, {
      terminator: balanceDepositRateFailure,
    }),
  );

  yield takeLatest(
    balanceDepositRequest,
    safe(onError, getDeposit, {
      terminator: balanceDepositFailure,
    }),
  );
}
