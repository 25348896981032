import React from 'react';

const WarningImage = () => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.25 9C0.25 13.5563 3.94365 17.25 8.5 17.25C13.0563 17.25 16.75 13.5563 16.75 9C16.75 4.44365 13.0563 0.75 8.5 0.75C3.94365 0.75 0.25 4.44365 0.25 9ZM15.25 9C15.25 12.7279 12.2279 15.75 8.5 15.75C4.77208 15.75 1.75 12.7279 1.75 9C1.75 5.27208 4.77208 2.25 8.5 2.25C12.2279 2.25 15.25 5.27208 15.25 9ZM7.75 6V9C7.75 9.41421 8.08579 9.75 8.5 9.75C8.91421 9.75 9.25 9.41421 9.25 9V6C9.25 5.58579 8.91421 5.25 8.5 5.25C8.08579 5.25 7.75 5.58579 7.75 6ZM9.03033 10.7197C9.32322 11.0126 9.32322 11.4874 9.03033 11.7803C8.73744 12.0732 8.26256 12.0732 7.96967 11.7803C7.67678 11.4874 7.67678 11.0126 7.96967 10.7197C8.26256 10.4268 8.73744 10.4268 9.03033 10.7197Z"
      fill="#E3405E"
    />
  </svg>
);

export default WarningImage;
