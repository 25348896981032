import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { Button } from 'antd';

import Balance from './components/Balance';

import styles from './PayModal.module.scss';

import SpeedometerImage from './assets/images/SpeedometerImage';
import ArrowImage from './assets/images/ArrowImage';
import WalletImage from './assets/images/WalletImage';
import { ModalContext } from '../ModalWrapper/ModalWrapper';
import WarningImage from './assets/images/WarningImage';
import Preloader from '../../Preloader';

import {
  shopGetPriceRequest,
  shopPurchaseRequest,
} from '../../../../states/Shop/state/duck';
import {
  currentPrice as currentPriceSelector,
  isFetchingCurrentPrice as isFetchingCurrentPriceSelector,
} from '../../../../states/Shop/state/selectors';
import {
  data as dataUserSelector,
} from '../../../../states/UserProfile/state/selectors';
import { useHistory } from 'react-router-dom';

interface IProps {
  type: string;
  tag: string;
}

/**
 * Модальное для покупки в шопе
 *
 * @param {*} props
 * @returns
 */
const PayModal: React.FC<IProps> = props => {
  const { type } = props;

  const history = useHistory();

  const lot = useSelector(currentPriceSelector);
  const isFetching = useSelector(isFetchingCurrentPriceSelector);
  const { balance } = useSelector(dataUserSelector);
  const dispatch = useDispatch();

  const { onClose, callbackType } = useContext(ModalContext);

  const { price } = lot;
  const remains = balance - price;
  const isSmallBalance = balance < price;

  useEffect(() => {
    dispatch({
      type: shopGetPriceRequest.toString(),
      payload: { type },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const buyLot = () => {
    dispatch({
      type: shopPurchaseRequest.toString(),
      payload: {
        id: lot.id,
        callbackType,
      },
    });
    onClose();
  };

  const goDeposit = () => {
    onClose();
    history.push('/cabinet/balance/deposit');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.speedometer}>
        <SpeedometerImage />
      </div>
      <div className={styles.title}>
        {isFetching ?
          <Preloader size="default" /> :
          <Translate id={lot.title_lang_mark} />
          }
      </div>
      <Balance number={balance} type="success" title="pay.modal.balance" isFetching={isFetching} />
      <Balance number={-price} type="danger" title="pay.modal.price" isFetching={isFetching} />
      <div className={styles.line}>
        <ArrowImage />
      </div>
      <Balance number={remains} title="pay.modal.result" isFetching={isFetching} />
      {isSmallBalance ?
        <div className={styles.smallBalance}>
          <WarningImage />
          <Translate id="pay.modal.negative" />
        </div>
        :
        <Button
          className={styles.buy}
          type="primary"
          onClick={buyLot}
        >
          {isFetching ?
            <Preloader size="small" /> :
            <Translate id="pay.modal.positive" data={{ price }} />
            }
        </Button>
        }

      <Button
        className={styles.wallet}
        type="text"
        onClick={goDeposit}
      >
        <WalletImage />
        <Translate id="pay.modal.deposit" data={{ price }} />
      </Button>
    </div>
  );
};

export default PayModal;
