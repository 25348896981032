/* eslint no-use-before-define: 0 */
import React from 'react';

import styles from './Header.module.scss';

import logo from '../../../../../assets/images/logo.svg';
import iconSearch from './assets/images/icon_search.svg';
import iconBlackUser from './assets/images/icon_blackUser.svg';
import iconMenuBtn from './assets/images/icon_menuBtn.svg';
import LanguageSelect from '../LanguageSelect';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import {
  activeRegion as activeRegionSelect,
} from '../../../../states/Languages/state/selectors';
import { isAuthorized as isAuthorizedSelector } from '../../../../states/Auth/state/selectors';
import closeImg from '../../../../../assets/images/close.svg';
import { useHistory } from 'react-router-dom';

export interface HeaderProps {
  hideClose?: boolean
}

const Header: React.FC<HeaderProps> = ({
  hideClose,
}) => {
  const history = useHistory();

  const activeRegion = useSelector(activeRegionSelect);
  const isAuthorized = useSelector(isAuthorizedSelector);

  return (
    <header className={styles.header}>
      <div className={styles.wrapper}>
        <div className={styles.headerInner}>
          <Translate>
            {({ translate }) => (
              <a
                href={translate(`auth.logo.link.${activeRegion.toLowerCase()}`).toString()}
                className={styles.headerLogo}
              >
                <img src={logo} alt="" />
              </a>
            )}
          </Translate>
          <div className={styles.menu}>
            {/* <div className={styles.menuSignIn}> */}
            {/*  <img src={iconUserBlue} alt="" /> */}
            {/*  Войти */}
            {/* </div> */}
            {!isAuthorized &&
            <div className={styles.menuOption}>
              <LanguageSelect />
            </div>
            }
            {(isAuthorized && !hideClose) &&
              <div
                role="button"
                tabIndex={0}
                className={styles.close}
                onClick={() => history.push('/')}
                onKeyPress={() => history.push('/')}
              >
                <img src={closeImg} alt="" />
              </div>
            }
            {/* <div className={styles.menuSearch}> */}
            {/*  <img src={iconSearch} alt="" /> */}
            {/* </div> */}
          </div>
          <nav
            className={styles.nav}
            style={{
              display: 'none',
            }}
          >
            <div className={styles.navItem}>
              <img src={iconSearch} alt="" />
              <span>Поиск</span>
            </div>
            <div className={styles.navItem}>
              <img src={iconBlackUser} alt="" />
              <span>Войти</span>
            </div>
            <div className={styles.navItem}>
              <img src={iconMenuBtn} alt="" />
              <span>Меню</span>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
