import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { logoutSuccess } from '../../../Logout/state/duck';

// Actions ==========================

const USER_TASK = 'USER_TASK';

// Запрос информации по разделу "Бонусные Задания" из АПИ
export const userTaskRequest = createAction(`${USER_TASK}/REQUEST`);
export const userTaskSuccess = createAction(`${USER_TASK}/SUCCESS`);
export const userTaskFailure = createAction(`${USER_TASK}/FAILURE`);
export const userTaskResult = createAction(`${USER_TASK}/RESULT`);

// Запрос на выполнения задания
export const userTaskCancelRequest = createAction(
  `${USER_TASK}/CANCEL_REQUEST`,
);
export const userTaskObserverRequest = createAction(
  `${USER_TASK}/OBSERVER_REQUEST`,
);
export const userTaskObserverFailure = createAction(
  `${USER_TASK}/OBSERVER_FAILURE`,
);

// Запрос реф линка
export const userTaskRefUrlRequest = createAction(`${USER_TASK}/REF_URL_REQUEST`);
export const userTaskRefUrlSuccess = createAction(`${USER_TASK}/REF_URL_SUCCESS`);
export const userTaskRefUrlFailure = createAction(`${USER_TASK}/REF_URL_FAILURE`);

// Запрос инструкций
export const userTaskGetInstructionsRequest = createAction(`${USER_TASK}/GET_INSTRUCTIONS_REQUEST`);
export const userTaskGetInstructionsSuccess = createAction(`${USER_TASK}/GET_INSTRUCTIONS_SUCCESS`);
export const userTaskGetInstructionsFailure = createAction(`${USER_TASK}/GET_INSTRUCTIONS_FAILURE`);

export const userTaskPutInstructionsRequest = createAction(`${USER_TASK}/PUT_INSTRUCTIONS_REQUEST`);
export const userTaskPutInstructionsSuccess = createAction(`${USER_TASK}/PUT_INSTRUCTIONS_SUCCESS`);
export const userTaskPutInstructionsFailure = createAction(`${USER_TASK}/PUT_INSTRUCTIONS_FAILURE`);

export const userTaskInstructionsApiOffer = createAction(`${USER_TASK}/API_OFFER`);

// Reducers ==========================

// Статус запроса данных из АПИ
const isFetching = createReducer(false, {
  [userTaskRequest.toString()]: () => true,
  [userTaskSuccess.toString()]: () => false,
  [userTaskFailure.toString()]: () => false,
  [userTaskCancelRequest.toString()]: () => false,
});

// Успешный результат запроса из АПИ
const data = createReducer({}, {
  [userTaskSuccess.toString()]: (_state, action) => action.payload,
});

const isCached = createReducer(false, {
  [logoutSuccess.toString()]: () => false,
  [userTaskSuccess.toString()]: () => true,
});

const isFetchingRefUrl = createReducer(true, {
  [userTaskRefUrlRequest.toString()]: () => true,
  [userTaskRefUrlSuccess.toString()]: () => false,
  [userTaskRefUrlFailure.toString()]: () => false,
});

const dataRefUrl = createReducer({}, {
  [userTaskRefUrlSuccess.toString()]: (_state, action) => action.payload,
});

const isFetchingInstructions = createReducer(false, {
  [userTaskGetInstructionsRequest.toString()]: () => true,
  [userTaskGetInstructionsSuccess.toString()]: () => false,
  [userTaskGetInstructionsFailure.toString()]: () => false,
});

// Успешный результат запроса из АПИ
const instructions = createReducer([], {
  [userTaskGetInstructionsRequest.toString()]: () => ([]),
  [userTaskGetInstructionsSuccess.toString()]: (_state, action) => action.payload,
  [userTaskGetInstructionsFailure.toString()]: () => ([]),
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  isCached,
  isFetchingRefUrl,
  dataRefUrl,
  isFetchingInstructions,
  instructions,
});

export default reducer;
