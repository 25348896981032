import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Menu, Input } from 'antd';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

import { SearchOutlined } from '@ant-design/icons';

import {
  data as dataSelector,
} from '../../../states/UserProfile/state/selectors';

import AdBlockDispatcher from '../../containers/AdBlockDispatcher';

import { pathname as pathnameSelector } from '../../../states/Router/state/selectors';

import logoImage from '../../../../assets/images/logo.svg';
import plugImage from './assets/images/FFFFFF-0.png';
import userImage from './assets/images/user.svg';
import userActiveImage from './assets/images/user-a.svg';
import articleImage from './assets/images/file.svg';
import articleActiveImage from './assets/images/file-a.svg';
import howItWorksImage from './assets/images/gear.svg';
import howItWorksActiveImage from './assets/images/gear-a.svg';
import helpImage from './assets/images/question-circle.svg';
import helpActiveImage from './assets/images/question-circle-a.svg';
import commentImage from './assets/images/comment.svg';
import commentActiveImage from './assets/images/comment-a.svg';

import { SiderStyled } from './theme';

import styles from './Sidebar.module.scss';

import { environment } from '../../../../environment';
import {
  CABINET__CREDIT_RATING_URL,
  HELP_URL,
  CABINET__USER_DEBTS_URL,
  FEEDBACKS_URL,
  ARTICLES_URL,
  FAQ_URL,
  CABINET__CASHBACK_URL,
  SELECTION_LOAN_PRODUCT_URL,
  CABINET__DEFENDER_URL,
  CABINET__MULTTILOGIN_URL,
  CABINET__DOCUMENT_URL,
  CABINET__MULTTILOGIN_MY_URL,
} from '../../constants/Routes/constants';

interface IProps {
  isMobile: boolean;
}

// FIXME: Временно для продакшена
const TEMP_UNDER_CONSTRUCTION = true;

/**
 * Кастомный сайдбар на основе Ant Design
 *
 * @returns
 */
const Sidebar: React.FC<IProps> = props => {
  const { isMobile = false } = props;
  const [selectedMenuItemKey, setSelectedMenuItem] = useState();
  const pathname = useSelector(pathnameSelector);

  const data = useSelector(dataSelector);
  const { country, hasMfoCabinets } = data.profile;

  function handleMenuItemChange(params: any) {
    const { key } = params;

    setSelectedMenuItem(key);
  }

  useEffect(() => {
    const splittedUrl = pathname.split('/');
    const [, parentUrl] = splittedUrl;

    let tempPathname = pathname;
    if (parentUrl === 'help') {
      tempPathname = '/help';
    }

    handleMenuItemChange({ key: tempPathname });
  }, [pathname]);

  return (
    <SiderStyled
      theme="light"
      className={styles.sidebar}
      style={{
        overflow: 'auto',
        position: isMobile ? 'relative' : 'fixed',
        left: 0,
      }}
    >
      {!isMobile && (
        <div className={styles.logoWrapper}>
          <a href={environment.MAIN_LANDING_URL}>
            <img src={logoImage} className={styles.logo} alt="my.finanso.com" />
          </a>
        </div>
      )}

      {isMobile && !TEMP_UNDER_CONSTRUCTION && (
        <Translate>
          {({ translate }) => (
            <div className={styles.searchInputWrapper}>
              <Input
                style={{ margin: 0, marginRight: '20px' }}
                className={styles.searchInput}
                placeholder={translate(
                  'shared.componets.sidebar.searchinput',
                ).toString()}
                suffix={<SearchOutlined />}
              />
            </div>
          )}
        </Translate>
      )}

      {/* Ad block */}
      <AdBlockDispatcher
        type="main.left.top"
        wrapperStyle={{ marginBottom: '30px' }}
      />

      <Menu
        style={{ width: '100%', height: '100vh' }}
        defaultSelectedKeys={pathname}
        mode="inline"
        onSelect={params => handleMenuItemChange({ ...params, pathname })}
        selectedKeys={selectedMenuItemKey}
      >
        <Menu.Item key="MY_CABINET">
          <Link className={styles.menuImageWrapper} to={CABINET__CREDIT_RATING_URL}>
            {selectedMenuItemKey === 'MY_CABINET' ? (
              <img src={userActiveImage} alt="👤" />
            ) : (
              <img src={userImage} alt="👤" />
            )}
          </Link>
          <Link to={CABINET__CREDIT_RATING_URL}>
            <Translate id="shared.componets.sidebar.mycabinet" />
          </Link>
        </Menu.Item>

        {/* Кредитный рейтинг */}
        <Menu.Item key={CABINET__CREDIT_RATING_URL}>
          <div className={styles.menuImageWrapper}>
            <img src={plugImage} width="16" alt="•" />
          </div>

          <Link to={CABINET__CREDIT_RATING_URL}>
            <Translate id="shared.componets.sidebar.creditrating" />
          </Link>
        </Menu.Item>

        {/* Мультилогин */}
        {!environment.PRODUCTION && (
          <Menu.Item key={hasMfoCabinets ? CABINET__MULTTILOGIN_MY_URL : CABINET__MULTTILOGIN_URL}>
            <div className={styles.menuImageWrapper}>
              <img src={plugImage} width="16" alt="•" />
            </div>

            <Link to={hasMfoCabinets ? CABINET__MULTTILOGIN_MY_URL : CABINET__MULTTILOGIN_URL}>
              <Translate id="shared.componets.sidebar.multilogin" />
            </Link>
          </Menu.Item>
        )}

        {/* Кешбек */}
        <Menu.Item key={CABINET__CASHBACK_URL}>
          <div className={styles.menuImageWrapper}>
            <img src={plugImage} width="16" alt="•" />
          </div>

          <Link to={CABINET__CASHBACK_URL}>
            <Translate id="shared_componets_sidebar_cashback" />
          </Link>
        </Menu.Item>

        {/* Мои задолженности */}
        {(country === 'Russia' || country === 'Kazakhstan') ?
          <Menu.Item key={CABINET__USER_DEBTS_URL}>
            <div className={styles.menuImageWrapper}>
              <img src={plugImage} width="16" alt="•" />
            </div>

            <Link to={CABINET__USER_DEBTS_URL}>
              <Translate id="shared.componets.sidebar.mydebt" />
            </Link>
          </Menu.Item>
          : null
        }

        {/* Подбор кредита */}
        <Menu.Item key={SELECTION_LOAN_PRODUCT_URL}>
          <div className={styles.menuImageWrapper}>
            <img src={plugImage} width="16" alt="•" />
          </div>

          <Link to={SELECTION_LOAN_PRODUCT_URL}>
            <Translate id="shared_componets_sidebar_selection_loan" />
          </Link>
        </Menu.Item>

        {/* Защитник */}
        {!environment.PRODUCTION && (
          <Menu.Item key={CABINET__DEFENDER_URL}>
            <div className={styles.menuImageWrapper}>
              <img src={plugImage} width="16" alt="•" />
            </div>

            <Link to={CABINET__DEFENDER_URL}>
              <Translate id="shared_componets_sidebar_defender" />
            </Link>
          </Menu.Item>
        )}

        <Menu.Item key={CABINET__DOCUMENT_URL}>
          <div className={styles.menuImageWrapper}>
            <img src={plugImage} width="16" alt="•" />
          </div>

          <Link to={CABINET__DOCUMENT_URL}>
            <Translate id="shared.componet.sidebar.document" />
          </Link>
        </Menu.Item>

        {/* Статьи */}
        <Menu.Item key={ARTICLES_URL} style={{ display: 'none' }}>
          <div className={styles.menuImageWrapper}>
            {selectedMenuItemKey === `${ARTICLES_URL}` ? (
              <img src={articleActiveImage} alt="📄" />
            ) : (
              <img src={articleImage} alt="📄" />
            )}
          </div>

          <Link to={ARTICLES_URL} target="_blank">
            <Translate id="shared.componets.sidebar.articles" />
          </Link>
        </Menu.Item>

        {/* Отзывы */}
        {!environment.PRODUCTION &&
        <Menu.Item key={FEEDBACKS_URL}>
          <div className={styles.menuImageWrapper}>
            {selectedMenuItemKey === `${FEEDBACKS_URL}` ? (
              <img src={commentActiveImage} alt="🗨" />
            ) : (
              <img src={commentImage} alt="🗨" />
            )}
          </div>

          <Link to={FEEDBACKS_URL}>
            <Translate id="shared.componets.sidebar.feedback" />
          </Link>
        </Menu.Item>
        }

        {/* Как это работает */}
        <Menu.Item key={FAQ_URL}>
          <div className={styles.menuImageWrapper}>
            {selectedMenuItemKey === `${FAQ_URL}` ? (
              <img src={howItWorksActiveImage} alt="⚙" />
            ) : (
              <img src={howItWorksImage} alt="⚙" />
            )}
          </div>

          <Link to={FAQ_URL}>
            <Translate id="shared.componets.sidebar.howitworks" />
          </Link>
        </Menu.Item>

        {/* Помощь */}
        <Menu.Item key={HELP_URL} id="help_d">
          <div className={styles.menuImageWrapper}>
            {(selectedMenuItemKey === HELP_URL && selectedMenuItemKey === false) ? (
              <img src={helpActiveImage} alt="?" />
            ) : (
              <img src={helpImage} alt="?" />
            )}
          </div>

          {/* <Link to={HELP_URL}> */}
          <Translate id="shared.componets.sidebar.help" />
          {/* </Link> */}
        </Menu.Item>

        {/* Ad block */}
        <AdBlockDispatcher
          type="main.left.bottom"
          wrapperStyle={{
            marginBottom: '0px',
            width: 'fit-content',
            height: 'fit-content',
            boxShadow:
              '0px 1px 3px rgb(13 14 72 / 5%), 0px 1px 1px rgb(13 14 72 / 3%)',
            border: '1px solid rgba(115, 121, 127, 0.116068)',
            borderRadius: '4px',
          }}
        />
      </Menu>
    </SiderStyled>
  );
};

export default Sidebar;
