import { fork, all } from 'redux-saga/effects';

import { loginSaga } from '../../states/Login/state/saga';
import { authSaga } from '../../states/Auth/state/saga';
import { logoutSaga } from '../../states/Logout/state/saga';
import { loanSelectionChatSaga } from '../../states/LoanSelection/state/saga';
import { creditRatingSaga } from '../../states/CreditRating/state/saga';
import { userProfileSaga } from '../../states/UserProfile/state/saga';
import { selectionLoanProductSaga } from '../../states/SelectionLoanProduct/state/saga';
import { userDebtsSaga } from '../../states/UserDebts/state/saga';
import { feedbacksSaga } from '../../states/Feedbacks/state/saga';
import { faqSaga } from '../../states/Faq/state/saga';
import { initSaga } from '../../states/Init/state/saga';
import { chatListSupportSaga } from '../../states/ChatListSupport/state/saga';
import { notificationSystemSaga } from '../../states/NotificationSystem/state/saga';
import { articlesSaga } from '../../states/Articles/state/saga';
import { socketSaga } from '../../states/Socket/state/saga';
import { userSubscriptionSaga } from '../../states/UserSubscription/state/saga';
import { routerSaga } from '../../states/Router/state/saga';
import { userSettingsChangePswdSaga } from '../../states/UserSettingsChangePswd/state/saga';
import { userSettingsActivitySaga } from '../../states/UserSettingsActivity/state/saga';
import { userSettingsLogsFormSaga } from '../../states/UserSettingsLogsForm/state/saga';
import { userSettingsChangeEmailSaga } from '../../states/UserSettingsChangeEmail/state/saga';
import { emailConfirmationSaga } from '../../states/EmailConfirmation/state/saga';
import { userSettingsChangeAvatarSaga } from '../../states/UserSettingsChangeAvatar/state/saga';
import { userSettingsChangePhoneSaga } from '../../states/UserSettingsChangePhone/state/saga';
import { recoveryPasswordSaga } from '../../states/RecoveryPassword/state/saga';
import { chatListLawyerSaga } from '../../states/ChatListLawyer/state/saga';
import { languagesSaga } from '../../states/Languages/state/saga';
import { registerSaga } from '../../states/Register/state/saga';
import { paymentSaga } from '../../states/Payment/state/saga';
import { productsSaga } from '../../states/Products/state/saga';
import { modalSystemSaga } from '../../states/ModalSystem/state/saga';
import { shopSaga } from '../../states/Shop/state/saga';
import { userTaskSaga } from '../../states/UserTask/state/saga';
import { balanceSaga } from '../../states/Balance/state/saga';
import { userLimitsSaga } from '../../states/UserLimits/state/saga';
import { userSettingsChangeDataSaga } from '../../states/UserSettingsChangeData/state/saga';
import { offerFormSaga } from '../../states/OfferForm/state/saga';

export default function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(authSaga),
    fork(logoutSaga),
    fork(loanSelectionChatSaga),
    fork(creditRatingSaga),
    fork(userProfileSaga),
    fork(selectionLoanProductSaga),
    fork(userDebtsSaga),
    fork(feedbacksSaga),
    fork(faqSaga),
    fork(initSaga),
    fork(chatListSupportSaga),
    fork(notificationSystemSaga),
    fork(articlesSaga),
    fork(socketSaga),
    fork(userSubscriptionSaga),
    fork(routerSaga),
    fork(userSettingsActivitySaga),
    fork(userSettingsLogsFormSaga),
    fork(emailConfirmationSaga),
    fork(recoveryPasswordSaga),
    fork(chatListLawyerSaga),
    fork(languagesSaga),
    fork(registerSaga),
    fork(paymentSaga),
    fork(productsSaga),
    fork(modalSystemSaga),
    fork(shopSaga),
    fork(userTaskSaga),
    fork(balanceSaga),
    fork(userLimitsSaga),
    fork(userSettingsChangePswdSaga),
    fork(userSettingsChangeEmailSaga),
    fork(userSettingsChangeAvatarSaga),
    fork(userSettingsChangePhoneSaga),
    fork(userSettingsChangeDataSaga),
    fork(offerFormSaga),
  ]);
}
