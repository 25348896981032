import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './Card.module.scss';

const CardSkeleton: React.FC = () => {
  return (
    <div className={styles.root}>
      <Skeleton className={styles.logo} />
      <div className={styles.col}>
        <div className={styles.brand}><Skeleton width={100} /></div>
        <div className={styles.site}><Skeleton width={100} /></div>
        <div className={styles.law}><Skeleton width={100} /></div>
      </div>
    </div>
  );
};

export default CardSkeleton;
