import { createSelector } from 'reselect';

const initData = {
  form: {
    name: '',
    is_valid: undefined,
    fields: {
      avatar: {},
    },
    result: {
      avatar: '',
      status: -1,
      moderation_status: -1,
    },
  },
};

export const isFetching = (store: any) =>
  store.userSettingsChangeAvatarReducer.isFetching;

export const isFetchingPost = (store: any) =>
  store.userSettingsChangeAvatarReducer.isFetchingPost;

export const data = createSelector(
  (store: any) => store.userSettingsChangeAvatarReducer.data,
  data => data,
);

export const dataPost = createSelector(
  (store: any) => store.userSettingsChangeAvatarReducer.dataPost,
  dataPost => {
    const result = dataPost?.data ?? initData;

    return result;
  },
);

export const isFormValid = createSelector(
  (store: any) => store.userSettingsChangeAvatarReducer.dataPost,
  dataPost => {
    // eslint-disable-next-line camelcase
    const result = dataPost?.data?.form?.is_valid ?? false;

    return result;
  },
);
