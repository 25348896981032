/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input } from 'antd';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';

import {
  chatListSupportSendMsgRequest,
  chatSendAttachRequest,
} from '../../../states/ChatListSupport/state/duck';
import {
  isChatInputNeedClear as isChatInputNeedClearSelector,
  dataActiveButton as dataActiveButtonSelector,
  isFetchingChatSendMsg as isFetchingChatSendMsgSelector,
} from '../../../states/ChatListSupport/state/selectors';

import FeedbackModalFormWrapper from '../FeedbackModalFormWrapper';
import DropZone from '../DropZone';

import { useWindowSize } from '../../hooks/useWindowSize';

import { DEVICE_NETBOOKS_WIDTH } from '../../constants/Defaults/constants';

import styles from './ChatSupportMobileFooter.module.scss';

import rightArrowImage from './assets/images/rightArrow.svg';

const initState = {
  text: '',
  isModalVisible: false,
};

const { TextArea } = Input;

/**
 * Мобильная версия. Используется в лейауте приложения!
 *
 * Футер выбранного чата в разделе помощь
 *
 * DUPLICATE: Форк от компонента `ChatLoanSelectionFooter`
 * Решает разные задачи
 *
 * @returns
 */
const ChatSupportMobileFooter: React.FC<any> = () => {
  const isChatInputNeedClear = useSelector(isChatInputNeedClearSelector);
  const dataActiveButton = useSelector(dataActiveButtonSelector);
  const isFetchingChatSendMsg = useSelector(isFetchingChatSendMsgSelector);

  const dispatch = useDispatch();

  const windowSize = useWindowSize();
  const { width = 0 } = windowSize;

  // По умолчанию считаем что запущено на десктопе, мобила = false
  let isMobile = false;

  if (width < DEVICE_NETBOOKS_WIDTH) {
    isMobile = true;
  }

  const [state, setState] = React.useState(initState);
  const { text, isModalVisible } = state;
  const formValid = text.trim() !== '' ? true : false;

  function handleChangeState(name: string, value: number | boolean) {
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  const handleChangeText = React.useCallback(event => {
    const { name, value } = event.target;

    handleChangeState(name, value);
  }, []);

  const handleModalStatusChange = React.useCallback((status: boolean) => {
    handleChangeState('isModalVisible', status);
  }, []);

  const handleSendAttachments = React.useCallback((files: any) => {
    dispatch({
      type: chatSendAttachRequest.toString(),
      payload: { files },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSentChatText = React.useCallback((event: any) => {
    const { currentTarget } = event;
    const text = currentTarget.getAttribute('data-input-value');

    dispatch({
      type: chatListSupportSendMsgRequest.toString(),
      payload: { text },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    // NB: Очищать инпут после успешной отправки сообщения на сервер
    if (isChatInputNeedClear && dataActiveButton === '') {
      setState(prevState => ({ ...prevState, text: dataActiveButton }));
    }
  }, [dataActiveButton, isChatInputNeedClear]);

  return (
    <div className={cx(styles.footerChat)}>
      <div className={styles.textArea}>
        <Translate>
          {({ translate }) => (
            <TextArea
              disabled={isFetchingChatSendMsg}
              className={styles.searchInput}
              placeholder={translate(
                'shared.componets.helpselectedchatfooter.message',
              ).toString()}
              name="text"
              value={text}
              data-input-value={text}
              onChange={handleChangeText}
              onPressEnter={handleSentChatText}
              autoSize
            />
          )}
        </Translate>
      </div>
      <Button
        style={{
          height: '40px',
          paddingLeft: isMobile ? '20px' : '30px',
          paddingRight: isMobile ? '20px' : '30px',
        }}
        type="primary"
        shape="round"
        data-input-value={text}
        onClick={handleSentChatText}
        disabled={!formValid || isFetchingChatSendMsg}
      >
        <img src={rightArrowImage} alt="➜" height={18} />
      </Button>

      {/* Attach modal window */}
      <FeedbackModalFormWrapper
        isModalVisible={isModalVisible}
        handleModalStatusChange={handleModalStatusChange}
      >
        <DropZone handleSendAttachments={handleSendAttachments} />
      </FeedbackModalFormWrapper>
    </div>
  );
};

export default ChatSupportMobileFooter;
