import { createSelector } from 'reselect';

const initData = {
  form: {
    name: '',
    is_valid: undefined,
    fields: {
      current: {},
      new: {},
      confirm: {},
    },
  },
};

export const isFetching = (store: any) =>
  store.userSettingsChangeDataReducer.isFetching;

export const isFetchingPut = (store: any) =>
  store.userSettingsChangeDataReducer.isFetchingPut;

export const data = createSelector(
  (store: any) => store.userSettingsChangeDataReducer.data,
  data => data,
);

export const dataPut = createSelector(
  (store: any) => store.userSettingsChangeDataReducer.dataPut,
  dataPut => {
    const result = dataPut?.data ?? initData;

    return result;
  },
);

export const isFormValid = createSelector(
  (store: any) => store.userSettingsChangeDataReducer.dataPut,
  dataPut => {
    // eslint-disable-next-line camelcase
    const result = dataPut?.data?.form?.is_valid ?? false;

    return result;
  },
);
