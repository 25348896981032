import React from 'react';
// import { useSelector } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';
import { Empty } from 'antd';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';

import InfinitePreloader from '../../componets/InfinitePreloader';
import Preloader from '../../componets/Preloader';
import FormWrapper from '../../componets/FormWrapper';

import {
  logList as logListSelector,
  isFetching as isFetchingSelector,
  totalPages as totalPagesSelector,
  currentPage as currentPageSelector,
} from '../../../states/UserSettingsLogsForm/state/selectors';
import {
  userSettingsLogsFormRequest,
  userSettingsLogsFormScrollingRequest,
} from '../../../states/UserSettingsLogsForm/state/duck';

import styles from './UserSettingsLogsForm.module.scss';

/**
 * Форма со списком логов доступа пользователя
 *
 * @returns
 */
const UserSettingsLogsForm: React.FC = () => {
  const logList = useSelector(logListSelector);
  const isFetching = useSelector(isFetchingSelector);
  const totalPages = useSelector(totalPagesSelector);
  const currentPage = useSelector(currentPageSelector);
  const dispatch = useDispatch();

  // cdM
  React.useEffect(
    () => {
      dispatch({
        type: userSettingsLogsFormRequest.toString(),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleFetchNewData = React.useCallback(() => {
    if (currentPage - totalPages !== 0) {
      dispatch({
        type: userSettingsLogsFormScrollingRequest.toString(),
        payload: { page: currentPage + 1 },
      });
    }
  }, [dispatch, currentPage, totalPages]);

  return (
    <>
      <Translate>
        {({ translate }) => (
          <FormWrapper
            name={`${translate(
              'shared_containers_usersettingsprofile_security_logs',
            ).toString()}`}
          >
            <div className={cx(styles.listDesktop)}>
              <div
                className={cx(styles.listDesktopElHeader, styles.listDesktopEl)}
              >
                <div className={styles.flex1}>
                  <Translate id="shared_containers_usersettingsprofile_security_logs_date_time" />
                </div>
                <div className={styles.flex2}>IP</div>
                <div className={styles.flex3}>
                  <Translate id="shared_containers_usersettingsprofile_security_logs_browser" />
                </div>
              </div>

              <>
                {isFetching ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Preloader size="large" />
                  </div>
                ) : (
                  <>
                    <InfiniteScroll
                      dataLength={logList.length}
                      next={handleFetchNewData}
                      hasMore={totalPages - currentPage !== 0}
                      loader={<InfinitePreloader />}
                      height={300}
                    >
                      {logList.length === 0 ? (
                        <Empty />
                      ) : (
                        <>
                          {logList.map((item: any) => {
                            const {
                              id,
                              ip,
                              browser,
                              created_at: dateTime,
                            } = item;

                            return (
                              <div
                                key={id}
                                className={cx(styles.listDesktopEl)}
                              >
                                <div className={styles.flex1}>
                                  {moment(dateTime).format('DD.MM.YYYY')}{' '}
                                  {moment(dateTime).format('hh:mm')}
                                </div>
                                <div className={styles.flex2}>{ip}</div>
                                <div className={styles.flex3}>{browser}</div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </InfiniteScroll>
                  </>
                )}
              </>
            </div>
          </FormWrapper>
        )}
      </Translate>
    </>
  );
};

export default UserSettingsLogsForm;
