import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const USER_SETTINGS_CHANGE_EMAIL = 'USER_SETTINGS_CHANGE_EMAIL';

export const userSettingsChangeEmailRequest = createAction(
  `${USER_SETTINGS_CHANGE_EMAIL}/REQUEST`,
);
export const userSettingsChangeEmailSuccess = createAction(
  `${USER_SETTINGS_CHANGE_EMAIL}/SUCCESS`,
);
export const userSettingsChangeEmailFailure = createAction(
  `${USER_SETTINGS_CHANGE_EMAIL}/FAILURE`,
);

export const userSettingsChangeEmailResult = createAction(
  `${USER_SETTINGS_CHANGE_EMAIL}/RESULT`,
);

export const userSettingsChangeEmailPutRequest = createAction(
  `${USER_SETTINGS_CHANGE_EMAIL}/PUT_REQUEST`,
);
export const userSettingsChangeEmailPutSuccess = createAction(
  `${USER_SETTINGS_CHANGE_EMAIL}/PUT_SUCCESS`,
);
export const userSettingsChangeEmailPutFailure = createAction(
  `${USER_SETTINGS_CHANGE_EMAIL}/PUT_FAILURE`,
);

export const userSettingsChangeEmailPutResult = createAction(
  `${USER_SETTINGS_CHANGE_EMAIL}/PUT_RESULT`,
);

export const userSettingsChangeEmailClearForm = createAction(
  `${USER_SETTINGS_CHANGE_EMAIL}/CLEAR_FORM`,
);

// Reducers ==========================

const isFetching = createReducer(false, {
  [userSettingsChangeEmailRequest.toString()]: () => true,
  [userSettingsChangeEmailSuccess.toString()]: () => false,
  [userSettingsChangeEmailFailure.toString()]: () => false,
});

const data = createReducer(
  {},
  {
    [userSettingsChangeEmailRequest.toString()]: () => ({}),
    [userSettingsChangeEmailSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

const result = createReducer(
  {},
  {
    [userSettingsChangeEmailRequest.toString()]: () => ({}),
    [userSettingsChangeEmailResult.toString()]: (_state, action) =>
      action.payload,
  },
);

const isFetchingPut = createReducer(false, {
  [userSettingsChangeEmailPutRequest.toString()]: () => true,
  [userSettingsChangeEmailPutSuccess.toString()]: () => false,
  [userSettingsChangeEmailPutFailure.toString()]: () => false,
});

const dataPut = createReducer(
  {},
  {
    [userSettingsChangeEmailClearForm.toString()]: () => ({}),
    [userSettingsChangeEmailPutRequest.toString()]: () => ({}),
    [userSettingsChangeEmailPutSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

const resultPut = createReducer(
  {},
  {
    [userSettingsChangeEmailPutRequest.toString()]: () => ({}),
    [userSettingsChangeEmailPutResult.toString()]: (_state, action) =>
      action.payload,
  },
);

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  result,
  isFetchingPut,
  dataPut,
  resultPut,
});

export default reducer;
