import AxiosInstance from '../../../core/api/axiosinstance';
import paramsSerializer from '../../../shared/utils/paramsSerializer';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * i18n API
 */
export default {
  /**
   * Получить объект со всеми переводами в системе
   *
   * @returns
   */
  getData() {
    return AxiosInstance('').get(
      `${apiPrefix}${versionOnePrefix}/translations/index?${paramsSerializer({
        page: -1,
      })}`,
    );
  },
};
