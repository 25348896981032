/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useForm, Controller /* useFormState */ } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { Row, Col, Button } from 'antd';
import { mapKeys } from 'lodash';

// import { useWindowSize } from '../../hooks/useWindowSize';

import Input from '../../componets/Input';
// import FormWrapper from '../../componets/FormWrapper';

import {
  isFetchingPut as isFetchingPutSelector,
  dataPut as dataPutSelector,
  isFetching as isFetchingPostSelector,
  data as dataPostSelector,
} from '../../../states/UserSettingsChangeData/state/selectors';
import {
  userSettingsChangeDataPutRequest,
  userSettingsChangeDataClearForm,
} from '../../../states/UserSettingsChangeData/state/duck';

import styles from './UserSettingsChangeDataForm.module.scss';
import {
  data as userProfileSelector,
  userCountry as userCountrySelector,
} from '../../../states/UserProfile/state/selectors';

interface IFormInputs {
  firstname: string;
  lastname: string;
  midlename: string;
}

/**
 * Форма сменить пароль
 *
 * @returns
 */
const UserSettingsChangePhoneForm: React.FC = () => {
  const userProfile = useSelector(userProfileSelector);
  const { profile } = userProfile;
  const {
    firstname,
    midlename,
    lastname,
  } = profile;

  const userCountry = useSelector(userCountrySelector);

  const dataPut = useSelector(dataPutSelector);
  const isFetchingPut = useSelector(isFetchingPutSelector);
  const isFetchingPost = useSelector(isFetchingPostSelector);
  const dataPost = useSelector(dataPostSelector);
  const dispatch = useDispatch();

  // cdM
  React.useEffect(() => {
    dispatch({
      type: userSettingsChangeDataClearForm.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm<IFormInputs>({ mode: 'all' });
  const {
    handleSubmit,
    control,
    getValues,
    formState,
    setError,
  } = methods;
  const { errors } = formState;

  const onSubmit = (data: IFormInputs) => {
    dispatch({
      type: userSettingsChangeDataPutRequest.toString(),
      payload: { ...data },
    });
  };

  const isValidationUndefined = typeof dataPut?.form?.is_valid === 'undefined';

  // cdU
  React.useEffect(() => {
    if (!isValidationUndefined) {
      const { form } = dataPut;
      const { is_valid: isFormValid, fields } = form;

      if (isFormValid) {
        return undefined;
      }

      mapKeys(fields, (val: any, key: any) => {
        const { is_valid: isFieldValid, error_messages: errMsgs } = val;

        if (!isFieldValid) {
          setError(key, {
            type: 'manual',
            message: errMsgs[0],
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPut]);

  const isValidationPostUndefined =
    typeof dataPost?.form?.is_valid === 'undefined';

  // cdU
  React.useEffect(() => {
    if (!isValidationPostUndefined) {
      const { form } = dataPost;
      const { fields } = form;

      mapKeys(fields, (val: any, key: any) => {
        const { is_valid: isFieldValid, error_messages: errMsgs } = val;

        if (!isFieldValid) {
          setError(key, {
            type: 'manual',
            message: errMsgs[0],
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPost]);

  return (
    <>
      <Translate>
        {({ translate }) => (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              {/* Фамилия */}
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className={styles.fieldWrapper}
              >
                <Controller
                  name="lastname"
                  control={control}
                  defaultValue={lastname}
                  rules={{
                    required: 'error_field_required',
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      isError={errors?.lastname ? true : false}
                      isGood={
                        !errors?.lastname && getValues('lastname') !== ''
                          ? true
                          : false
                      }
                      label={`${translate(
                        'shared_containers_usersettingsprofile_yourprofile_edit_phone_lastname',
                      ).toString()}Фамилия`}
                      placeholder={'Фамилия'}
                      isSuffix={false}
                      suffix=""
                      inputMode="text"
                    />
                  )}
                />
                <div className={styles.errorMessage}>
                  {errors?.lastname?.message && (
                    <Translate id={`${errors?.lastname?.message}`} />
                  )}
                </div>
              </Col>

              {/* Имя */}
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className={styles.fieldWrapper}
              >
                <Controller
                  name="firstname"
                  control={control}
                  defaultValue={firstname}
                  rules={{
                    required: 'error_field_required',
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      isError={errors?.firstname ? true : false}
                      isGood={
                        !errors?.firstname && getValues('name') !== ''
                          ? true
                          : false
                      }
                      label={'Имя'}
                      placeholder="Имя"
                      isSuffix={false}
                      suffix=""
                    />
                  )}
                />
                <div className={styles.errorMessage}>
                  {errors?.firstname?.message && (
                    <Translate id={`${errors?.firstname?.message}`} />
                  )}
                </div>
              </Col>

              {/* Отчество */ }
              {userCountry.code === 'ru' &&
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className={styles.fieldWrapper}
                >
                  <Controller
                    name="midlename"
                    control={control}
                    defaultValue={midlename}
                    rules={{
                      required: 'error_field_required',
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        isError={errors?.midlename ? true : false}
                        isGood={
                    !errors?.midlename && getValues('patronymic') !== ''
                      ? true
                      : false
                  }
                        label={'Отчество'}
                        placeholder="Отчество"
                        isSuffix={false}
                        suffix=""
                      />
                    )}
                  />
                  <div className={styles.errorMessage}>
                    {errors?.midlename?.message && (
                    <Translate id={`${errors?.midlename?.message}`} />
                    )}
                  </div>
                </Col>
              }
            </Row>
            <Button
              type="primary"
              shape="round"
              style={{
                marginTop: 10,
                height: 48,
              }}
              // disabled={!isValid}
              onClick={handleSubmit(onSubmit)}
              loading={isFetchingPut || isFetchingPost}
            >
              <Translate id="shared_containers_usersettingsprofile_yourprofile_edit_save" />
            </Button>
          </form>
        )}
      </Translate>
    </>
  );
};

export default UserSettingsChangePhoneForm;
