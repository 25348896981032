import AxiosInstance from '../../../core/api/axiosinstance';
import paramsSerializer from '../../../shared/utils/paramsSerializer';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * Feedbacks API
 */
export default {
  /**
   * Получить данные всех FAQ
   *
   * @returns
   */
  getData(data: any) {
    const { token, country = 'ru' } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/question-categories?${paramsSerializer({
        country,
      })}`,
    );
  },
};
