import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { logoutSuccess } from '../../../Logout/state/duck';

import {
  SCRIPT,
  SCRIPT_LENGTH,
  PHONE_NUM_REQ,
  PHONE_NUM_VERIFY_REQ,
} from './constants';

// Actions ==========================

const REGISTER = 'REGISTER';

// Получение токена JWT
export const registerGetJwtSuccess = createAction(
  `${REGISTER}/GET_JWT_SUCCESS`,
);

export const registerCreateRecaptchaToken = createAction(
  `${REGISTER}/CREATE_RECAPTCHA_TOKEN`,
);

export const registerValidationRequest = createAction(
  `${REGISTER}/VALIDATION_REQUEST`,
);
export const registerValidationSuccess = createAction(
  `${REGISTER}/VALIDATION_SUCCESS`,
);
export const registerValidationFailure = createAction(
  `${REGISTER}/VALIDATION_FAILURE`,
);

export const registerValidationResult = createAction(
  `${REGISTER}/VALIDATION_RESULT`,
);
export const registerValidationClear = createAction(
  `${REGISTER}/VALIDATION_CLEAR`,
);

export const registerSMSCodeRequest = createAction(
  `${REGISTER}/SMS_CODE_REQUEST`,
);
export const registerSMSCodeSuccess = createAction(
  `${REGISTER}/SMS_CODE_SUCCESS`,
);
export const registerSMSCodeFailure = createAction(
  `${REGISTER}/SMS_CODE_FAILURE`,
);

export const registerSMSCodeResult = createAction(
  `${REGISTER}/SMS_CODE_RESULT`,
);

export const registerCallCodeRequest = createAction(
  `${REGISTER}/CALL_CODE_REQUEST`,
);
export const registerCallCodeSuccess = createAction(
  `${REGISTER}/CALL_CODE_SUCCESS`,
);
export const registerCallCodeFailure = createAction(
  `${REGISTER}/CALL_CODE_FAILURE`,
);

export const registerCallCodeResult = createAction(
  `${REGISTER}/CALL_CODE_RESULT`,
);

export const registerEmailCodeRequest = createAction(
  `${REGISTER}/EMAIL_CODE_REQUEST`,
);
export const registerEmailCodeSuccess = createAction(
  `${REGISTER}/EMAIL_CODE_SUCCESS`,
);
export const registerEmailCodeFailure = createAction(
  `${REGISTER}/EMAIL_CODE_FAILURE`,
);

export const registerEmailCodeResult = createAction(
  `${REGISTER}/EMAIL_CODE_RESULT`,
);

export const registerPhoneInfoRequest = createAction(
  `${REGISTER}/PHONE_INFO_REQUEST`,
);
export const registerPhoneInfoSuccess = createAction(
  `${REGISTER}/PHONE_INFO_SUCCESS`,
);
export const registerPhoneInfoFailure = createAction(
  `${REGISTER}/PHONE_INFO_FAILURE`,
);

export const registerPhoneInfoResult = createAction(
  `${REGISTER}/PHONE_INFO_RESULT`,
);

export const registerRequest = createAction(`${REGISTER}/REQUEST`);
export const registerSuccess = createAction(`${REGISTER}/SUCCESS`);
export const registerFailure = createAction(`${REGISTER}/FAILURE`);
export const registerSetErrorMessage = createAction(
  `${REGISTER}/SET_ERROR_MESSAGE`,
);

export const registerResult = createAction(`${REGISTER}/RESULT`);

export const registerRequestNew = createAction(`${REGISTER}/REQUEST_NEW`);
export const registerResultNew = createAction(`${REGISTER}/RESULT_NEW`);

export const registerAddQuestionsToDialogue = createAction(
  `${REGISTER}/ADD_QUESTIONS_TO_DIALOGUE`,
);
export const registerAddQuestionsToDialogueResult = createAction(
  `${REGISTER}/ADD_QUESTIONS_TO_DIALOGUE_RESULT`,
);
export const registerAddAnswerToDialogue = createAction(
  `${REGISTER}/ADD_ANSWER_TO_DIALOGUE`,
);
export const registerAddAnswerToDialogueResult = createAction(
  `${REGISTER}/ADD_ANSWER_TO_DIALOGUE_RESULT`,
);
export const registerClearValidationStatus = createAction(
  `${REGISTER}/CLEAR_VALIDATION_STATUS`,
);
export const registerSetCurrentStep = createAction(
  `${REGISTER}/SET_CURRENT_STEP`,
);
export const registerAddErrorToDialogueResult = createAction(
  `${REGISTER}/ADD_ERROR_TO_DIALOGUE_RESULT`,
);
export const registerSetActiveButton = createAction(
  `${REGISTER}/SET_ACTIVE_BUTTON`,
);
export const registerSetActiveButtonResult = createAction(
  `${REGISTER}/SET_ACTIVE_BUTTON_RESULT`,
);
export const registerSetHistoryOfActiveButtonsResult = createAction(
  `${REGISTER}/SET_HISTORY_OF_ACTIVE_BUTTONS_RESULT`,
);
export const registerSetListOfActiveButtonsResult = createAction(
  `${REGISTER}/SET_LIST_OF_ACTIVE_BUTTONS_RESULT`,
);

// Reducers ==========================

const recaptchaToken = createReducer('', {
  [registerCreateRecaptchaToken.toString()]: (_state, action) =>
    action.payload.token,
});

const isFetchingValidation = createReducer(false, {
  [registerValidationRequest.toString()]: () => true,
  [registerValidationResult.toString()]: () => false,
  [registerValidationSuccess.toString()]: () => false,
  [registerValidationFailure.toString()]: () => false,
});

const dataValidation = createReducer(
  {},
  {
    [registerValidationRequest.toString()]: () => ({}),
    [registerClearValidationStatus.toString()]: () => ({}),
    [registerValidationSuccess.toString()]: (_state, action) => action.payload,
  },
);

const errorValidation = createReducer(
  {},
  {
    [registerValidationRequest.toString()]: () => ({}),
    [registerValidationFailure.toString()]: (_state, action) => action.payload,
    [registerValidationClear.toString()]: () => ({}),
  },
);

const isFetchingSMSCode = createReducer(false, {
  [registerSMSCodeRequest.toString()]: () => true,
  [registerSMSCodeResult.toString()]: () => false,
  [registerSMSCodeSuccess.toString()]: () => false,
  [registerSMSCodeFailure.toString()]: () => false,
});

const dataSMSCode = createReducer(
  {},
  {
    [registerSMSCodeRequest.toString()]: () => ({}),
    [registerSMSCodeSuccess.toString()]: (_state, action) => action.payload,
  },
);

const errorSMSCode = createReducer(
  {},
  {
    [registerSMSCodeRequest.toString()]: () => ({}),
    [registerSMSCodeFailure.toString()]: (_state, action) => action.payload,
  },
);

const isFetchingCallCode = createReducer(false, {
  [registerCallCodeRequest.toString()]: () => true,
  [registerCallCodeResult.toString()]: () => false,
  [registerCallCodeSuccess.toString()]: () => false,
  [registerCallCodeFailure.toString()]: () => false,
});

const dataCallCode = createReducer(
  {},
  {
    [registerCallCodeRequest.toString()]: () => ({}),
    [registerCallCodeSuccess.toString()]: (_state, action) => action.payload,
  },
);

const errorCallCode = createReducer(
  {},
  {
    [registerCallCodeRequest.toString()]: () => ({}),
    [registerCallCodeFailure.toString()]: (_state, action) => action.payload,
  },
);

const isFetchingEmailCode = createReducer(false, {
  [registerEmailCodeRequest.toString()]: () => true,
  [registerEmailCodeResult.toString()]: () => false,
  [registerEmailCodeSuccess.toString()]: () => false,
  [registerEmailCodeFailure.toString()]: () => false,
});

const dataEmailCode = createReducer(
  {},
  {
    [registerEmailCodeRequest.toString()]: () => ({}),
    [registerEmailCodeSuccess.toString()]: (_state, action) => action.payload,
  },
);

const errorEmailCode = createReducer(
  {},
  {
    [registerEmailCodeRequest.toString()]: () => ({}),
    [registerEmailCodeFailure.toString()]: (_state, action) => action.payload,
  },
);

const isFetchingPhoneInfo = createReducer(false, {
  [registerPhoneInfoRequest.toString()]: () => true,
  [registerPhoneInfoResult.toString()]: () => false,
  [registerPhoneInfoSuccess.toString()]: () => false,
  [registerPhoneInfoFailure.toString()]: () => false,
});

const dataPhoneInfo = createReducer(
  {},
  {
    [registerPhoneInfoRequest.toString()]: () => ({}),
    [registerPhoneInfoSuccess.toString()]: (_state, action) => action.payload,
  },
);

const errorPhoneInfo = createReducer(
  {},
  {
    [registerPhoneInfoRequest.toString()]: () => ({}),
    [registerPhoneInfoFailure.toString()]: (_state, action) => action.payload,
  },
);

const isFetchingRegister = createReducer(false, {
  [registerRequest.toString()]: () => true,
  [registerRequestNew.toString()]: () => true,
  [registerResult.toString()]: () => false,
  [registerSuccess.toString()]: () => false,
  [registerFailure.toString()]: () => false,
});

const dataRegister = createReducer({}, {
  [registerRequest.toString()]: () => ({}),
  [registerSuccess.toString()]: (_state, action) => action.payload,
  [logoutSuccess.toString()]: () => ({}),
});

const errorRegister = createReducer(
  {},
  {
    [registerRequest.toString()]: () => ({}),
    [registerFailure.toString()]: (_state, action) => action.payload,
    [registerSetErrorMessage.toString()]: (_state, action) => action.payload,
  },
);

const currentStep = createReducer([], {
  [registerSetCurrentStep.toString()]: (_state, action) => action.payload,
});

const dialogue = createReducer([], {
  [registerAddQuestionsToDialogueResult.toString()]: (_state, action) =>
    action.payload,
  [registerAddAnswerToDialogueResult.toString()]: (_state, action) =>
    action.payload,
  [registerAddErrorToDialogueResult.toString()]: (_state, action) =>
    action.payload,
});

const questionsStepOne = createReducer(PHONE_NUM_REQ, builder => {
  builder.addDefaultCase(state => state);
});

const questionsStepTwo = createReducer(PHONE_NUM_VERIFY_REQ, builder =>
  builder.addDefaultCase(state => state),
);

const Script = createReducer(SCRIPT, builder =>
  builder.addDefaultCase(state => state),
);

const stepsTotal = createReducer(SCRIPT_LENGTH, builder =>
  builder.addDefaultCase(state => state),
);

const currentActiveButton = createReducer('', {
  [registerSetActiveButtonResult.toString()]: (_state, action) =>
    action.payload,
});

const historyOfActiveButtons = createReducer([], {
  [registerSetHistoryOfActiveButtonsResult.toString()]: (state, action) =>
    action.payload,
});

const listOfActiveButtons = createReducer([], {
  [registerSetListOfActiveButtonsResult.toString()]: (state, action) =>
    action.payload,
});

const registrationData = createReducer(
  {
    phone: '',
  },
  {
    [registerValidationRequest.toString()]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
);

// Root Reducer
const reducer = combineReducers({
  isFetchingValidation,
  recaptchaToken,
  dataValidation,
  errorValidation,
  isFetchingSMSCode,
  dataSMSCode,
  errorSMSCode,
  isFetchingCallCode,
  dataCallCode,
  errorCallCode,
  isFetchingEmailCode,
  dataEmailCode,
  errorEmailCode,
  isFetchingPhoneInfo,
  dataPhoneInfo,
  errorPhoneInfo,
  isFetchingRegister,
  dataRegister,
  errorRegister,
  dialogue,
  questionsStepOne,
  questionsStepTwo,
  Script,
  currentStep,
  stepsTotal,
  currentActiveButton,
  historyOfActiveButtons,
  listOfActiveButtons,
  registrationData,
});

export default reducer;
