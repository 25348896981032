import { createSelector } from 'reselect';
import { get } from 'lodash';

export const isFetching = (store: any) =>
  store.userSettingsLogsFormReducer.isFetching;

export const data = createSelector(
  (store: any) => store.userSettingsLogsFormReducer.data,
  data => data,
);

export const logList = createSelector(data, (data = {}) => {
  const getData = get(data, 'data', []) || [];

  const result = getData.map((item: Record<string, any>) => {
    const type = get(item, 'type', '') || '';
    const id = get(item, 'order_id', '') || '';
    const sum = get(item, 'sum', 0) || 0;
    const currency = get(item, 'currency', '') || '';
    const createdAt = get(item, 'created_at', '') || '';
    const cardType = get(item, 'card_type', '') || '';
    const cardBin = get(item, 'card_bin', '') || '';

    return { id, type, sum, currency, cardBin, cardType, createdAt };
  });

  return result;
});

export const totalPages = createSelector(
  (store: any) => store.userSettingsLogsFormReducer.data,
  data => {
    const result = get(data, 'totalPages', 1) || 1;

    return result;
  },
);

export const currentPage = createSelector(
  (store: any) => store.userSettingsLogsFormReducer.data,
  data => {
    const result = get(data, 'currentPage', 1) || 1;

    return result;
  },
);

export const isFetchingScrolling = (store: any) =>
  store.userSettingsLogsFormReducer.isFetchingScrolling;
