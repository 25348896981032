import { put, takeLatest, call, takeEvery, select, takeLeading } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';

import { userProfile } from '../../../../services/UserProfile/api';

import {
  userProfileRequest,
  userProfileSuccess,
  userProfileFailure,
  userProfileResult,
  userProfileChangeAvatarSuccess,
  userProfileCancelRequest,
  removeProfileRequest,
  removeProfileSuccess,
  removeProfileFailure,
  removeProfileResult,
} from '../duck';
import { userSettingsChangeAvatarPostSuccess } from '../../../UserSettingsChangeAvatar/state/duck';
import { userSettingsChangePhonePutSuccess } from '../../../UserSettingsChangePhone/state/duck';

import {
  data as userProfileSelector,
  isCached as isCachedSelector,
} from '../selectors';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';

import {
// okUserProfileContract,
// errorUserProfileContract,
} from '../../../../contracts/UserProfile';
import { feedbacks } from '../../../../services/UserRemove/api';
import { userTaskGetInstructionsRequest, userTaskRequest } from '../../../UserTask/state/duck';
import { languagesSetActiveRegionRequest, languagesSetAppActiveLangRequest } from '../../../Languages/state/duck';

export function* getData(action: any) {
  const isCached: boolean = yield select(isCachedSelector);
  if (isCached && !action?.payload?.nonCached) {
    yield put({
      type: userProfileCancelRequest.toString(),
    });

    return;
  }

  const completePayload: any = yield call(checkToken, {});
  const userProfileRes: any = yield call(userProfile.getData, completePayload);

  yield put({
    type: userProfileResult.toString(),
    payload: createResultV2(userProfileRes),
  });
} // getData =========

export function* checkResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: userProfileSuccess.toString(),
      payload: data,
    });

    yield put({
      type: userTaskRequest.toString(),
    });

    yield put({
      type: userTaskGetInstructionsRequest.toString(),
    });

    const { profile: { language } } = data;
    const lang = language.code.toLowerCase();

    yield put({
      type: languagesSetAppActiveLangRequest.toString(),
      payload: { lang },
    });

    yield put({
      type: languagesSetActiveRegionRequest.toString(),
      payload: { lang: data?.profile?.country_info?.code.toLowerCase() },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;
    // const { data } = error;

    // yield call(checkContract, {
    //  contract: errorUserProfileContract,
    //  data,
    //  direction: DIRECTION.B_F,
    // });

    yield put({
      type: userProfileFailure.toString(),
      payload: error,
    });
  }
} // checkResult =========

export function* changeUserProfileAvatar(action: any): IterableIterator<any> {
  const { payload } = action;
  const userProfile: any = yield select(userProfileSelector);
  const initAvatar = {
    avatar: '',
    status: -1,
    moderation_status: -1,
  };
  const newAvatar = payload?.data?.form?.result ?? initAvatar;

  yield put({
    type: userProfileChangeAvatarSuccess.toString(),
    payload: { ...userProfile, avatar: newAvatar },
  });
} // changeUserProfileAvatar =========

export function* changeUserProfilePhone(action: any): IterableIterator<any> {
  const { payload } = action;
  const userProfile: any = yield select(userProfileSelector);
  const initLastPhone = {
    phone: '0000000',
    prefix: '0',
    code: '000',
    number: '',
  };
  const newPhone = payload?.data?.form?.result ?? initLastPhone;

  yield put({
    type: userProfileChangeAvatarSuccess.toString(),
    payload: { ...userProfile, lastPhone: newPhone },
  });
} // changeUserProfilePhone =========

export function* removeProfile(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const srvRes = yield call(feedbacks.removeDataProfile, {
    ...completePayload,
  });

  yield put({
    type: removeProfileResult.toString(),
    payload: createResultV2(srvRes),
  });
}

export function* checkResultRemove(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: removeProfileSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: removeProfileFailure.toString(),
    });
  }
}

// Root Saga
export default function* rootSaga() {
  yield takeLeading(
    userProfileRequest,
    safe(onError, getData, { terminator: userProfileFailure }),
  );

  yield takeLatest(
    removeProfileRequest,
    safe(onError, removeProfile, {
      terminator: removeProfileFailure,
    }),
  );

  yield takeEvery(userProfileResult, safe(onError, checkResult));

  yield takeEvery(
    userSettingsChangeAvatarPostSuccess,
    safe(onError, changeUserProfileAvatar),
  );

  yield takeEvery(
    userSettingsChangePhonePutSuccess,
    safe(onError, changeUserProfilePhone),
  );

  yield takeEvery(
    removeProfileResult,
    safe(onError, checkResultRemove),
  );
}
