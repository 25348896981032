import { createSelector } from 'reselect';
import { get } from 'lodash';

export const isFetching = (store: any) =>
  store.userSettingsActivityReducer.isFetching;

export const data = createSelector(
  (store: any) => store.userSettingsActivityReducer.data,
  data => data,
);

export const logList = createSelector(data, (data = {}) => {
  const result = get(data, 'data', []) || [];

  return result;
});

export const totalPages = createSelector(
  (store: any) => store.userSettingsActivityReducer.data,
  data => {
    const result = get(data, 'totalPages', 1) || 1;

    return result;
  },
);

export const currentPage = createSelector(
  (store: any) => store.userSettingsActivityReducer.data,
  data => {
    const result = get(data, 'currentPage', 1) || 1;

    return result;
  },
);
