import { initResult } from '../../../Init/state/duck';
import { faqResult } from '../../../Faq/state/duck';
import { loginGetJwtResult } from '../../../Login/state/duck';
import {
  creditRatingResult,
  creditRatingLiteResult,
  creditRatingStatusResult,
} from '../../../CreditRating/state/duck';
import { languagesResult } from '../../../Languages/state/duck';
import {
  userDebtsResult,
  userDebtsStatusResult,
  userDebtsLimitsResult,
} from '../../../UserDebts/state/duck';
import { userProfileResult } from '../../../UserProfile/state/duck';
import {
  feedbacksInitResult,
  feedbacksResult,
  feedbacksSecondResult,
  feedbacksCreateReviewResult,
  feedbacksOrgResult,
  feedbacksCitiesResult,
  feedbacksCreateCommentResult,
  feedbacksAddLikeResult,
  feedbacksRemoveLikeResult,
  feedbacksSecondDrawerResult,
  feedbacksInitDrawerResult,
} from '../../../Feedbacks/state/duck';
import {
  articlesResult,
  articlesSecondResult,
  articlesArticleResult,
  articlesCategoriesResult,
  articlesFilteredResult,
  articlesSecondFilteredResult,
} from '../../../Articles/state/duck';
import {
  userSubscriptionResult,
  userSubscriptionPutResult,
} from '../../../UserSubscription/state/duck';
import {
  userSettingsChangePswdResult,
  userSettingsChangePswdPutResult,
} from '../../../UserSettingsChangePswd/state/duck';
import { userSettingsActivityResult } from '../../../UserSettingsActivity/state/duck';
import {
  userSettingsChangeEmailResult,
  userSettingsChangeEmailPutResult,
} from '../../../UserSettingsChangeEmail/state/duck';
import { emailConfirmationResult } from '../../../EmailConfirmation/state/duck';
import { userSettingsChangeAvatarPostResult } from '../../../UserSettingsChangeAvatar/state/duck';
import {
  userSettingsChangePhonePutResult,
  userSettingsChangePhonePostResult,
} from '../../../UserSettingsChangePhone/state/duck';
import {
  recoveryPasswordPutResult,
  recoveryPasswordPostResult,
} from '../../../RecoveryPassword/state/duck';
import {
  productsResult,
  productsChecklistResult,
} from '../../../Products/state/duck';
import {
  paymentCheckTransactionResult,
  paymentPrepareResult,
} from '../../../Payment/state/duck';
import { chatListLawyerSendMsgFailure } from '../../../ChatListLawyer/state/duck';
import { userTaskObserverFailure } from '../../../UserTask/state/duck';
import { registerEmailCodeFailure, registerSMSCodeFailure } from '../../../Register/state/duck';
import { userSettingsChangeDataPutResult } from '../../../UserSettingsChangeData/state/duck';

// Список экшенов c результатами из АПИ
// для отслеживания системой уведомлений
export const resultsActions = Object.freeze({
  // Init
  [initResult.toString()]: initResult.toString(),
  // Faq
  [faqResult.toString()]: faqResult.toString(),
  // Login
  [loginGetJwtResult.toString()]: loginGetJwtResult.toString(),
  // Credit Rating
  [creditRatingResult.toString()]: creditRatingResult.toString(),
  [creditRatingLiteResult.toString()]: creditRatingLiteResult.toString(),
  [creditRatingStatusResult.toString()]: creditRatingStatusResult.toString(),
  // I18n
  [languagesResult.toString()]: languagesResult.toString(),
  // UserDebts
  [userDebtsResult.toString()]: userDebtsResult.toString(),
  [userDebtsStatusResult.toString()]: userDebtsStatusResult.toString(),
  [userDebtsLimitsResult.toString()]: userDebtsLimitsResult.toString(),
  // UserProfile
  [userProfileResult.toString()]: userProfileResult.toString(),
  // Feedbacks
  [feedbacksInitResult.toString()]: feedbacksInitResult.toString(),
  [feedbacksResult.toString()]: feedbacksResult.toString(),
  [feedbacksSecondResult.toString()]: feedbacksSecondResult.toString(),
  [feedbacksCreateReviewResult.toString()]: feedbacksCreateReviewResult.toString(),
  [feedbacksOrgResult.toString()]: feedbacksOrgResult.toString(),
  [feedbacksCitiesResult.toString()]: feedbacksCitiesResult.toString(),
  [feedbacksCreateCommentResult.toString()]: feedbacksCreateCommentResult.toString(),
  [feedbacksAddLikeResult.toString()]: feedbacksAddLikeResult.toString(),
  [feedbacksRemoveLikeResult.toString()]: feedbacksRemoveLikeResult.toString(),
  [feedbacksSecondDrawerResult.toString()]: feedbacksSecondDrawerResult.toString(),
  [feedbacksInitDrawerResult.toString()]: feedbacksInitDrawerResult.toString(),
  // Articles
  [articlesResult.toString()]: articlesResult.toString(),
  [articlesSecondResult.toString()]: articlesSecondResult.toString(),
  [articlesArticleResult.toString()]: articlesArticleResult.toString(),
  [articlesCategoriesResult.toString()]: articlesCategoriesResult.toString(),
  [articlesFilteredResult.toString()]: articlesFilteredResult.toString(),
  [articlesSecondFilteredResult.toString()]: articlesSecondFilteredResult.toString(),
  // UserSubscription
  [userSubscriptionResult.toString()]: userSubscriptionResult.toString(),
  [userSubscriptionPutResult.toString()]: userSubscriptionPutResult.toString(),
  // UserSettingsChangePswd
  [userSettingsChangePswdResult.toString()]: userSettingsChangePswdResult.toString(),
  [userSettingsChangePswdPutResult.toString()]: userSettingsChangePswdPutResult.toString(),
  // UserSettingsActivity
  [userSettingsActivityResult.toString()]: userSettingsActivityResult.toString(),
  // UserSettingsChangeEmail
  [userSettingsChangeEmailResult.toString()]: userSettingsChangeEmailResult.toString(),
  [userSettingsChangeEmailPutResult.toString()]: userSettingsChangeEmailPutResult.toString(),
  // EmailConfirmation
  [emailConfirmationResult.toString()]: emailConfirmationResult.toString(),
  // UserSettingsChangeAvatar
  [userSettingsChangeAvatarPostResult.toString()]: userSettingsChangeAvatarPostResult.toString(),
  // UserSettingsChangePhone
  [userSettingsChangePhonePutResult.toString()]: userSettingsChangePhonePutResult.toString(),
  [userSettingsChangePhonePostResult.toString()]: userSettingsChangePhonePostResult.toString(),
  // RecoveryPassword
  [recoveryPasswordPutResult.toString()]: recoveryPasswordPutResult.toString(),
  [recoveryPasswordPostResult.toString()]: recoveryPasswordPostResult.toString(),
  // Products
  [productsResult.toString()]: productsResult.toString(),
  [productsChecklistResult.toString()]: productsChecklistResult.toString(),
  // Payment
  [paymentCheckTransactionResult.toString()]: paymentCheckTransactionResult.toString(),
  [paymentPrepareResult.toString()]: paymentPrepareResult.toString(),
  // ChatListLawyer
  [chatListLawyerSendMsgFailure.toString()]: chatListLawyerSendMsgFailure.toString(),
  // Tasks
  [userTaskObserverFailure.toString()]: userTaskObserverFailure.toString(),
  // Register
  [registerSMSCodeFailure.toString()]: registerSMSCodeFailure.toString(),
  [registerEmailCodeFailure.toString()]: registerEmailCodeFailure.toString(),
  // Edit profile
  [userSettingsChangeDataPutResult.toString()]: userSettingsChangeDataPutResult.toString(),
});
