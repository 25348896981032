import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * Shop API
 */
export default {
  /**
   * Прайс лист шопа
   *
   * @param {*} data
   * @returns
   */
  getList(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/shop/products`,
    );
  },

  getPerice(data: any) {
    const { token, type } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/shop/products?filter[alias]=${type}`,
    );
  },

  postPurchase(data: any) {
    const { token, id } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/shop/purchase/create/${id}`,
    );
  },

  getServicesHandlers(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/app-services/handlers`,
    );
  },

  getRestrictions(data: any) {
    const { token, alias } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/app-services/user-fields?handler_alias=${alias}`,
    );
  },

  postRestrictions(data: any) {
    const { token, ...body } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/app-services/user-fields`,
      body,
    );
  },

  getPriceList(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/prices/site/list`,
    );
  },

  getDeposit(data: any) {
    const { token, ...body } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/prices/site/pay`,
      body,
    );
  },
};
