import { createAction } from '@reduxjs/toolkit';

// Actions ==========================

const LOGOUT = 'LOGOUT';

// Логаут из системы
export const logoutRequest = createAction(`${LOGOUT}/REQUEST`);
export const logoutSuccess = createAction(`${LOGOUT}/SUCCESS`);
export const logoutFailure = createAction(`${LOGOUT}/FAILURE`);
