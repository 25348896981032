import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const USER_SETTINGS_CHANGE_PSWD = 'USER_SETTINGS_CHANGE_PSWD';

export const userSettingsChangePswdRequest = createAction(
  `${USER_SETTINGS_CHANGE_PSWD}/REQUEST`,
);
export const userSettingsChangePswdSuccess = createAction(
  `${USER_SETTINGS_CHANGE_PSWD}/SUCCESS`,
);
export const userSettingsChangePswdFailure = createAction(
  `${USER_SETTINGS_CHANGE_PSWD}/FAILURE`,
);

export const userSettingsChangePswdResult = createAction(
  `${USER_SETTINGS_CHANGE_PSWD}/RESULT`,
);

export const userSettingsChangePswdPutRequest = createAction(
  `${USER_SETTINGS_CHANGE_PSWD}/PUT_REQUEST`,
);
export const userSettingsChangePswdPutSuccess = createAction(
  `${USER_SETTINGS_CHANGE_PSWD}/PUT_SUCCESS`,
);
export const userSettingsChangePswdPutFailure = createAction(
  `${USER_SETTINGS_CHANGE_PSWD}/PUT_FAILURE`,
);

export const userSettingsChangePswdPutResult = createAction(
  `${USER_SETTINGS_CHANGE_PSWD}/PUT_RESULT`,
);

export const userSettingsChangePswdClearForm = createAction(
  `${USER_SETTINGS_CHANGE_PSWD}/CLEAR_FORM`,
);

// Reducers ==========================

const isFetching = createReducer(false, {
  [userSettingsChangePswdRequest.toString()]: () => true,
  [userSettingsChangePswdSuccess.toString()]: () => false,
  [userSettingsChangePswdFailure.toString()]: () => false,
});

const data = createReducer(
  {},
  {
    [userSettingsChangePswdRequest.toString()]: () => ({}),
    [userSettingsChangePswdSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

const result = createReducer(
  {},
  {
    [userSettingsChangePswdRequest.toString()]: () => ({}),
    [userSettingsChangePswdResult.toString()]: (_state, action) =>
      action.payload,
  },
);

const isFetchingPut = createReducer(false, {
  [userSettingsChangePswdPutRequest.toString()]: () => true,
  [userSettingsChangePswdPutSuccess.toString()]: () => false,
  [userSettingsChangePswdPutFailure.toString()]: () => false,
});

const dataPut = createReducer(
  {},
  {
    [userSettingsChangePswdClearForm.toString()]: () => ({}),
    [userSettingsChangePswdPutRequest.toString()]: () => ({}),
    [userSettingsChangePswdPutSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

const resultPut = createReducer(
  {},
  {
    [userSettingsChangePswdPutRequest.toString()]: () => ({}),
    [userSettingsChangePswdPutResult.toString()]: (_state, action) =>
      action.payload,
  },
);

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  result,
  isFetchingPut,
  dataPut,
  resultPut,
});

export default reducer;
