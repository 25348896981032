import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { bindActionCreators, Dispatch } from 'redux';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';
import { Button, Col, Row } from 'antd';
import { createTextMask } from 'redux-form-input-masks';

import { ModalContext } from '../ModalWrapper/ModalWrapper';
import {
  restrictionsFields as restrictionsFieldsSelector,
  fields as fieldsSelector,
  permissions as permissionsSelector,
  isFetchingPermissions as isFetchingPermissionsSelector,
  isFetchingRestrictions as isFetchingRestrictionsSelector,
} from '../../../../states/Shop/state/selectors';
import {
  shopPermissionsRequest,
  shopRestrictionsRequest,
  shopRestrictionsValidationRequest,
} from '../../../../states/Shop/state/duck';
import { data as dataUserSelector } from '../../../../states/UserProfile/state/selectors';
import Preloader from '../../Preloader';
import OfferAllFields from '../../OfferAllFields';

import stylesFields from '../../OfferElementCard/OfferElementCard.module.scss';
import styles from './RequiredFieldsModal.module.scss';

import protection from '../../../../../assets/images/protection.svg';
import metrica from '../../../utils/metrica.helper';
import { parsRulesForm } from '../../../utils/parsRulesForm.helper';

const RequiredFieldsModal = (props: any) => {
  const {
    tag,
    shopPermissionsRequest,
    shopRestrictionsRequest,
    shopRestrictionsValidationRequest,
    permissions,
    restrictionsFields,
    fields,
    isFetchingPermissions,
    isFetchingRestrictions,
    dataUser,
  } = props;
  const [alias, setAlias] = useState('');

  const { callbackType } = useContext(ModalContext);

  useEffect(() => {
    shopPermissionsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (permissions.length) {
      const selected = permissions.find((item: any) => item.type === tag);
      if (selected) {
        const { alias } = selected;
        shopRestrictionsRequest({ alias });
        setAlias(alias);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  const methods = useForm();
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setError,
    formState: { errors: errorsState },
  } = methods;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isValid } = useFormState({ control });

  const categoryFields = Object.entries(restrictionsFields.reduce((curr: any, acc: any) => {
    curr[acc[1].category] = curr[acc[1].category] || [];
    curr[acc[1].category].push(acc);
    return curr;
  }, Object.create(null)));

  const onSubmit = () => {
    shopRestrictionsValidationRequest({
      ...getValues(),
      callbackType,
      handler_alias: alias,
    });

    if (dataUser.clickType) {
      const targetName = `reg-${dataUser.clickType}-sendForm`;
      metrica.event('reg', targetName);
    }
  };

  const [
    title,
    description,
    btn,
    lwc,
  ] = [
    alias ? `auth.options.${alias}.title` : 'auth.options.title',
    alias ? `auth.options.${alias}.description` : 'auth.options.description',
    alias ? `auth.options.${alias}.btn` : 'auth.options.btn',
    alias ? `auth.options.${alias}.lwc` : 'auth.options.lwc',
  ];

  const handlerBlur = (e: React.FocusEvent<HTMLInputElement, Element>): void => {
    const { name } = e.target;
    const value = getValues(name);
    const field = fields[name];

    parsRulesForm({ field, value, name, setError });
  };

  return (
    <>
      {(isFetchingPermissions || isFetchingRestrictions) ?
        <Preloader size="large" />
        :
        <FormProvider {...methods}>
          <Translate>
            {({ translate }) => (

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={stylesFields.infoOffer}>
                  <div className={stylesFields.infoOfferHeader}>
                    <Translate id={title} />
                  </div>
                  <div className={stylesFields.infoOfferSubHeader}>
                    <Translate id={description} />
                  </div>
                </div>

                {categoryFields.map((category: Array<any>, keyIdx: number) => {
                  const categoryName = category[0];

                  return (
                    <div key={`category_${keyIdx + 10}`}>
                      <div className={cx(
                        stylesFields.infoOfferHeader,
                        stylesFields.infoOfferHeaderCategory,
                      )}
                      >
                        <Translate id={categoryName.toLowerCase()} />
                      </div>
                      <Row gutter={15}>
                        {category[1].map((item: [any, any]) => {
                          const [key, field] = item;
                          const name = field?.name;
                          const isRequired = field?.rules?.required ?? false;
                          const placeholder = field?.placeholder || `eg.${name}.placeholder`;
                          const title = field?.title || `eg.${name}.title`;
                          const patternField = field?.mask;
                          const message =
                            errorsState && errorsState[name] && errorsState[name]?.message;
                          const errors =
                            { [name]: { message: message || field?.error_messages[0] } };
                          const offerCompendiums =
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            Object.entries(field?.values).map(([id, value]: any) => (
                              { id, value }),
                            );

                          const value = field?.value ?? undefined;

                          const mask: any = patternField ? createTextMask({
                            pattern: patternField.replace(/[0]/g, '9'),
                            guide: false,
                            stripMask: false,
                          }) : null;

                          // eslint-disable-next-line no-restricted-syntax
                          for (const el of field.visible_conditions) {
                            const value = watch(el.target_field_name);
                            if (value !== el.target_field_value) {
                              return null;
                            }
                          }

                          return (
                            <Col
                              key={key}
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={12}
                              className={stylesFields.fieldWrapper}
                            >
                              <OfferAllFields
                                name={name}
                                field={field}
                                errors={errors}
                                address={{}}
                                label={
                                  title !== ''
                                    ? `${translate(title.toLowerCase()).toString()}`
                                    : ''
                                }
                                changeAddress={() => null}
                                mask={mask}
                                patternField={patternField}
                                placeholder={
                                  placeholder !== ''
                                    ? `${translate(placeholder.toLowerCase()).toString()}`
                                    : ''
                                }
                                getValues={getValues}
                                offerCompendiums={offerCompendiums}
                                isRequired={isRequired}
                                control={control}
                                keyValue={key}
                                defaultValue={value}
                                onBlur={handlerBlur}
                              />
                            </Col>
                          );
                        })
                        }
                      </Row>
                    </div>);
                })}
                <div className={styles.btnGroup}>
                  <Button
                    className={styles.btn}
                    type="primary"
                    // disabled={!isValid}
                    htmlType="submit"
                  >
                    {translate(btn).toString()}
                  </Button>
                  <div className={styles.secure}>
                    <img src={protection} alt="" />
                    <div>
                      <Translate id="deposit.stripe.protection" />
                    </div>
                  </div>
                </div>
                <p className={styles.law}>
                  <Translate id={lwc} />
                </p>
              </form>
            )}
          </Translate>
        </FormProvider>
      }
    </>
  );
};

const mapStateToProps = (state: any) => ({
  permissions: permissionsSelector(state),
  restrictionsFields: restrictionsFieldsSelector(state),
  fields: fieldsSelector(state),
  isFetchingPermissions: isFetchingPermissionsSelector(state),
  isFetchingRestrictions: isFetchingRestrictionsSelector(state),
  dataUser: dataUserSelector(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    shopPermissionsRequest,
    shopRestrictionsRequest,
    shopRestrictionsValidationRequest,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(RequiredFieldsModal);
