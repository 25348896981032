import AxiosInstance from '../../../core/api/axiosinstance';
import paramsSerializer from '../../../shared/utils/paramsSerializer';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * EmailConfirmation
 */
export default {
  /**
   * Получить данные о email
   *
   * @returns
   */
  getData(data: any) {
    const { token: JWToken, emailConfirmationToken: token } = data;

    return AxiosInstance(JWToken).get(
      `${apiPrefix}${versionOnePrefix}/user/confirm-email?${paramsSerializer({
        token,
      })}`,
    );
  },
};
