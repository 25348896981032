import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const LANGUAGES = 'LANGUAGES';

export const languagesRequest = createAction(`${LANGUAGES}/REQUEST`);
export const languagesSuccess = createAction(`${LANGUAGES}/SUCCESS`);
export const languagesFailure = createAction(`${LANGUAGES}/FAILURE`);

export const languagesListRequest = createAction(`${LANGUAGES}/LIST_REQUEST`);
export const languagesListSuccess = createAction(`${LANGUAGES}/LIST_SUCCESS`);
export const languagesListFailure = createAction(`${LANGUAGES}/LIST_FAILURE`);

export const languagesSetAppActiveLangRequest = createAction(
  `${LANGUAGES}/SET_APP_ACTIVE_LANG_REQUEST`,
);

export const languagesInitAlreadyRequest = createAction(
  `${LANGUAGES}/INIT_ALREADY_REQUEST`,
);

export const languagesInitAlreadySucess = createAction(
  `${LANGUAGES}/INIT_ALREADY_SUCESS`,
);

export const languagesSetActiveLangRequest = createAction(
  `${LANGUAGES}/SET_ACTIVE_LANG_REQUEST`,
);
export const languagesSetActiveLangSuccess = createAction(
  `${LANGUAGES}/SET_ACTIVE_LANG_SUCCESS`,
);
export const languagesSetActiveLangFailure = createAction(
  `${LANGUAGES}/SET_ACTIVE_LANG_FAILURE`,
);

// Region

export const languagesSetActiveRegionRequest = createAction(
  `${LANGUAGES}/SET_ACTIVE_REGION_REQUEST`,
);
export const languagesSetActiveRegionSuccess = createAction(
  `${LANGUAGES}/SET_ACTIVE_REGION_SUCCESS`,
);
export const languagesSetActiveRegionFailure = createAction(
  `${LANGUAGES}/SET_ACTIVE_REGION_FAILURE`,
);

export const languagesResult = createAction(`${LANGUAGES}/RESULT`);

// Reducers ==========================

const isLanguagesFetching = createReducer(false, {
  [languagesRequest.toString()]: () => true,
  [languagesSuccess.toString()]: () => false,
  [languagesFailure.toString()]: () => false,
});

const result = createReducer(
  {},
  {
    [languagesResult.toString()]: (_state, action) => action.payload,
  },
);

const isLanguagesAlreadySet = createReducer(false, {
  [languagesSetAppActiveLangRequest.toString()]: () => false,
  [languagesInitAlreadyRequest.toString()]: () => true,
});

const language = createReducer(
  {},
  {
    [languagesSuccess.toString()]: (_state, action) => action.payload,
    [languagesFailure.toString()]: () => ({}),
  },
);

const activeLanguage = createReducer('', {
  [languagesSetActiveLangSuccess.toString()]: (_state, action) =>
    action.payload,
  [languagesSetActiveLangFailure.toString()]: () => '',
});

const activeRegion = createReducer('ru', {
  [languagesSetActiveRegionSuccess.toString()]: (_state, action) =>
    action.payload,
  [languagesSetActiveRegionFailure.toString()]: () => '',
});

const isFetchingList = createReducer(false, {
  [languagesListRequest.toString()]: () => true,
  [languagesListSuccess.toString()]: () => false,
  [languagesListFailure.toString()]: () => false,
});

const list = createReducer([], {
  [languagesListRequest.toString()]: () => ([]),
  [languagesListSuccess.toString()]: (_state, action) => action.payload,
  [languagesListFailure.toString()]: () => ([]),
});

// Root Reducer
const reducer = combineReducers({
  isLanguagesFetching,
  result,
  isLanguagesAlreadySet,
  language,
  activeLanguage,
  activeRegion,
  isFetchingList,
  list,
});

export default reducer;
