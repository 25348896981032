import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { list } from '../../../states/AdBlocks/state/selectors';

import { environment } from '../../../../environment';

interface IProps {
  type: string;
}

interface IAdBlockGoogleAdsStore {
  list: any;
}

const mapStateToProps = (state: any) => ({
  list: list(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

type IAdBlockGoogleAdsTypes = IAdBlockGoogleAdsStore & IProps;

/**
 * Рекламный блок. Гугл
 *
 * @class AdBlockGoogleAds
 * @extends {React.Component<IAdBlockGoogleAdsTypes>}
 */
class AdBlockGoogleAds extends React.Component<IAdBlockGoogleAdsTypes> {
  componentDidMount() {
    if (
      !document.getElementById('googleads-a99459014ce6ccbb68ec9b1c2a5ca215')
    ) {
      const installGoogleAds = () => {
        const elem = document.createElement('script');
        elem.src =
          'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        elem.async = true;
        elem.defer = true;
        elem.id = 'googleads-a99459014ce6ccbb68ec9b1c2a5ca215';
        document.body.insertBefore(elem, document.body.firstChild);
      };
      installGoogleAds();
    }

    if (window) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }

  render() {
    const { type, list } = this.props;

    const adData = list?.[type] ?? null;
    const dataAdClient =
      adData?.item?.google_ad_client ?? 'ca-pub-0000000000000000';
    const dataAdSlot = adData?.item?.google_ad_slot ?? '0000000000';
    const size = adData?.size ?? '100x100';
    const [width, height] = size.split('x');

    return (
      <ins
        className="adsbygoogle"
        style={{
          display: 'inline-block',
          width: `${width}px`,
          height: `${height}px`,
        }}
        data-adtest={environment.PRODUCTION ? 'off' : 'on'}
        data-ad-client={dataAdClient}
        data-ad-slot={dataAdSlot}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdBlockGoogleAds);
