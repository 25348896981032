import React, { useEffect, useState } from 'react';
import { Layout, Grid } from 'antd';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import UserSettings from '../../shared/containers/UserSettings';

import LayoutSidebar from '../../shared/componets/LayoutSidebar';
import LayoutHeader from '../../shared/componets/LayoutHeader';
import MobilePhoneFooter from '../../shared/componets/MobilePhoneFooter';
import MobilePhoneHeader from '../../shared/componets/MobilePhoneHeader';
import MobilePhoneSidebar from '../../shared/componets/MobilePhoneSidebar';
import ChatLawyerMobileFooter from '../../shared/componets/ChatLawyerMobileFooter';
import ChatSupportMobileFooter from '../../shared/componets/ChatSupportMobileFooter';
import ChatLoanSelectionMobileFooter from '../../shared/componets/ChatLoanSelectionMobileFooter';

import { data } from '../../states/Auth/state/selectors';
import { pathname } from '../../states/Router/state/selectors';
import { data as userData } from '../../states/UserProfile/state/selectors';

import { getBrkPointsRules } from '../../shared/constants/LayoutBreakpoints/constants';
import {
  HELP_CHAT_REGISTERED_ROUTES,
  HELP_SUPPORT_URL,
  HELP_LOAN_SELECTION_URL,
  HELP_LAWYER_URL,
} from '../../shared/constants/Routes/constants';
import {
  LAYOUT_MINIMUM_ALLOWABLE_WIDTH,
  LAYOUT_MAXIMUM_ALLOWABLE_WIDTH,
} from '../../shared/constants/Defaults/constants';

import styles from './RootLayout.module.scss';
import { Intercom } from '../../shared/utils/intercom.helper';

// NB: Временно Отключено, т.к. меняется поставщик оплаты. Майчекаут как-то не взлетел
// import { environment } from '../../../environment';

const { useBreakpoint } = Grid;

/**
 * RootLayout -- Обертка адаптивного лейаута
 *
 * @returns
 */

export type RootLayoutProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement>

const RootLayout: React.FC<RootLayoutProps> = ({
  children,
  ...props
}) => {
  const screens = useBreakpoint();
  const { xxl, xl, lg, md, sm, xs } = screens;
  const brkPtsRules = getBrkPointsRules({ xxl, xl, lg, md, sm, xs });
  const { GREATER_THAN_LG, LESS_THAN_XL } = brkPtsRules;

  const authData = useSelector(data);
  const currentLocation = useSelector(pathname);
  const user = useSelector(userData);

  const { isAuthorized } = authData;

  const isHelpChatList = HELP_CHAT_REGISTERED_ROUTES.includes(currentLocation);

  const [isSidebarCollapsed, setSidebarCollapseStatus] = useState(true);
  const [isUserSettingsOpen, setUserSettingsStatus] = useState(false);

  function handleStatusChange(status: boolean) {
    setSidebarCollapseStatus(status);
  }

  function handleShowUserSettings(status: boolean) {
    setUserSettingsStatus(status);
  }

  React.useEffect(() => {
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  // IDEA: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  React.useLayoutEffect(() => {
    const setHeightForMobilePhone = () => {
      let vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', setHeightForMobilePhone);

    return () => {
      window.removeEventListener('resize', setHeightForMobilePhone);
    };
  }, []);

  useEffect(() => {
    if (user?.id) {
      Intercom.boot({
        name: user?.profile?.firstname,
        email: user?.email,
        user_id: user?.id,
        created_at: new Date(),
        click_type: user?.clickType,
        geo: user.profile.country_info.code.toUpperCase(),
        wmid: user.wmid,
        aff_id: user.affId,
      });
    }
  }, [user]);

  return (
    <>
      {isAuthorized ? (
        <Layout
          style={{
            minWidth: `${LAYOUT_MINIMUM_ALLOWABLE_WIDTH}px`,
            maxWidth: `${LAYOUT_MAXIMUM_ALLOWABLE_WIDTH}px`,
          }}
        >
          {/* Sidebar */}
          {GREATER_THAN_LG && <LayoutSidebar />}
          {LESS_THAN_XL && (
            <MobilePhoneSidebar
              isSidebarCollapsed={isSidebarCollapsed}
              handleStatusChange={handleStatusChange}
            />
          )}

          <Layout>
            {/* Header */}
            {GREATER_THAN_LG && (
              <LayoutHeader handleShowUserSettings={handleShowUserSettings} />
            )}
            {LESS_THAN_XL && (
              <div
                className={cx({ [styles.contentBlurred]: !isSidebarCollapsed })}
              >
                <MobilePhoneHeader
                  handleShowUserSettings={handleShowUserSettings}
                  isHelpChatList={isHelpChatList}
                />
              </div>
            )}

            {/* Content */}
            <div
              {...props}
              className={cx(styles.content, { [styles.contentBlurred]: !isSidebarCollapsed })}
            >
              {children}
            </div>

            {/* Footer */}
            {/* Footer for common routes */}
            {LESS_THAN_XL && (
              <>
                {/* NB: Когда в чате, то не нужно отображать футер с меню.
                    Там свои специфичные футеры */}
                {!isHelpChatList && (
                  <div
                    className={cx({
                      [styles.contentBlurred]: !isSidebarCollapsed,
                    })}
                  >
                    <MobilePhoneFooter
                      handleStatusChange={handleStatusChange}
                    />
                  </div>
                )}

                {/* Specific footers for personal routes */}
                {/* Support chat */}
                {currentLocation === HELP_SUPPORT_URL && (
                  <ChatSupportMobileFooter />
                )}
                {/* LoanSelection chat */}
                {currentLocation === HELP_LOAN_SELECTION_URL && (
                  <ChatLoanSelectionMobileFooter />
                )}
                {/* Lawyer chat */}
                {currentLocation === HELP_LAWYER_URL && (
                  <ChatLawyerMobileFooter />
                )}
              </>
            )}
          </Layout>

          {/* UserSettings */}
          <UserSettings
            isUserSettingsOpen={isUserSettingsOpen}
            handleShowUserSettings={handleShowUserSettings}
          />
        </Layout>
      ) : (
        <Layout
          style={{
            minWidth: `${LAYOUT_MINIMUM_ALLOWABLE_WIDTH}px`,
            maxWidth: `${LAYOUT_MAXIMUM_ALLOWABLE_WIDTH}px`,
            backgroundColor: 'transparent',
          }}
        >
          <Layout
            style={{
              backgroundColor: 'transparent',
            }}
          >
            {/* Content */}
            <div {...props}>
              {children}
            </div>
          </Layout>
        </Layout>
      )}

      {/* {!environment.PRODUCTION && (isAuthorized && <PaymentCardWrapper />)} */}
    </>
  );
};

export default RootLayout;
