import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { authSetAuthStatusSuccess } from '../../../Auth/state/duck';

// Actions ==========================

const EMAIL_CONFIRMATION = 'EMAIL_CONFIRMATION';

export const emailConfirmationCreateResult = createAction(
  `${EMAIL_CONFIRMATION}/CREATE_RESULT`,
);

export const emailConfirmationResult = createAction(
  `${EMAIL_CONFIRMATION}/RESULT`,
);

// Reducers ==========================

const rawResult = createReducer(
  {},
  {
    [authSetAuthStatusSuccess.toString()]: () => ({}),
    [emailConfirmationCreateResult.toString()]: (_state, action) =>
      action.payload,
  },
);

// Root Reducer
const reducer = combineReducers({
  rawResult,
});

export default reducer;
