import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const USER_SETTINGS_CHANGE_DATA = 'USER_SETTINGS_CHANGE_DATA';

export const userSettingsChangeDataRequest = createAction(
  `${USER_SETTINGS_CHANGE_DATA}/REQUEST`,
);
export const userSettingsChangeDataSuccess = createAction(
  `${USER_SETTINGS_CHANGE_DATA}/SUCCESS`,
);
export const userSettingsChangeDataFailure = createAction(
  `${USER_SETTINGS_CHANGE_DATA}/FAILURE`,
);

export const userSettingsChangeDataResult = createAction(
  `${USER_SETTINGS_CHANGE_DATA}/RESULT`,
);

export const userSettingsChangeDataPutRequest = createAction(
  `${USER_SETTINGS_CHANGE_DATA}/PUT_REQUEST`,
);
export const userSettingsChangeDataPutSuccess = createAction(
  `${USER_SETTINGS_CHANGE_DATA}/PUT_SUCCESS`,
);
export const userSettingsChangeDataPutFailure = createAction(
  `${USER_SETTINGS_CHANGE_DATA}/PUT_FAILURE`,
);

export const userSettingsChangeDataPutResult = createAction(
  `${USER_SETTINGS_CHANGE_DATA}/PUT_RESULT`,
);

export const userSettingsChangeDataClearForm = createAction(
  `${USER_SETTINGS_CHANGE_DATA}/CLEAR_FORM`,
);

// Reducers ==========================

const isFetching = createReducer(false, {
  [userSettingsChangeDataRequest.toString()]: () => true,
  [userSettingsChangeDataSuccess.toString()]: () => false,
  [userSettingsChangeDataFailure.toString()]: () => false,
});

const data = createReducer({}, {
  [userSettingsChangeDataRequest.toString()]: () => ({}),
  [userSettingsChangeDataSuccess.toString()]: (_state, action) => action.payload,
});

const result = createReducer({}, {
  [userSettingsChangeDataRequest.toString()]: () => ({}),
  [userSettingsChangeDataResult.toString()]: (_state, action) => action.payload,
});

const isFetchingPut = createReducer(false, {
  [userSettingsChangeDataPutRequest.toString()]: () => true,
  [userSettingsChangeDataPutSuccess.toString()]: () => false,
  [userSettingsChangeDataPutFailure.toString()]: () => false,
});

const dataPut = createReducer({}, {
  [userSettingsChangeDataClearForm.toString()]: () => ({}),
  [userSettingsChangeDataPutRequest.toString()]: () => ({}),
  [userSettingsChangeDataPutSuccess.toString()]: (_state, action) => action.payload,
  [userSettingsChangeDataPutFailure.toString()]: (_state, action) => action.payload,
});

const resultPut = createReducer({}, {
  [userSettingsChangeDataPutRequest.toString()]: () => ({}),
  [userSettingsChangeDataPutResult.toString()]: (_state, action) => action.payload,
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  result,
  isFetchingPut,
  dataPut,
  resultPut,
});

export default reducer;
