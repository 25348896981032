import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { registerGetJwtSuccess } from '../../../Register/state/duck';

// Actions ==========================

const LOGIN = 'LOGIN';

export const loginGetJwtRequest = createAction(`${LOGIN}/GET_JWT_REQUEST`);
export const loginGetJwtSuccess = createAction(`${LOGIN}/GET_JWT_SUCCESS`);
export const loginGetJwtFailure = createAction(`${LOGIN}/GET_JWT_FAILURE`);
export const loginGetJwtResult = createAction(`${LOGIN}/GET_JWT_RESULT`);

export const loginSetRecoveryPswdRequest = createAction(
  `${LOGIN}/SET_RECOVERY_PSWD_REQUEST`,
);
export const loginSetRecoveryPswdSuccess = createAction(
  `${LOGIN}/SET_RECOVERY_PSWD_SUCCESS`,
);
export const loginSetRecoveryPswdFailure = createAction(
  `${LOGIN}/SET_RECOVERY_PSWD_FAILURE`,
);

export const loginSocialRequest = createAction(`${LOGIN}/SOCIAL_REQUEST`);
export const loginSocialSuccess = createAction(`${LOGIN}/SOCIAL_SUCCESS`);
export const loginSocialFailure = createAction(`${LOGIN}/SOCIAL_FAILURE`);

export const loginRedirect = createAction(`${LOGIN}/REDIRECT`);

// Reducers ==========================

const isFetching = createReducer(false, {
  [loginGetJwtRequest.toString()]: () => true,
  [loginGetJwtSuccess.toString()]: () => false,
  [loginGetJwtFailure.toString()]: () => false,
});

const data = createReducer(
  {},
  {
    [loginGetJwtRequest.toString()]: () => ({}),
    [loginGetJwtSuccess.toString()]: (_state, action) => action.payload,
    [registerGetJwtSuccess.toString()]: (_state, action) => action.payload,
  },
);

const error = createReducer(
  {},
  {
    [loginGetJwtRequest.toString()]: () => ({}),
    [loginGetJwtFailure.toString()]: (_state, action) => action.payload,
  },
);

const result = createReducer(
  {},
  {
    [loginGetJwtRequest.toString()]: () => ({}),
    [loginGetJwtResult.toString()]: (_state, action) => action.payload,
  },
);

const isFetchingSocial = createReducer(false, {
  [loginSocialRequest.toString()]: () => true,
  [loginSocialSuccess.toString()]: () => false,
  [loginSocialFailure.toString()]: () => false,
});

const dataSocial = createReducer(
  {},
  {
    [loginSocialRequest.toString()]: () => ({}),
    [loginSocialSuccess.toString()]: (_state, action) => action.payload,
  },
);

const redirect = createReducer('', {
  [loginRedirect.toString()]: (_state, action) => action.payload,
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  error,
  result,
  isFetchingSocial,
  dataSocial,
  redirect,
});

export default reducer;
