import React from 'react';
import moment from 'moment';
import { DatePicker as DatePickerAntd } from 'antd';

interface IDatePicker {
  className?: string,
  ref?: React.RefObject<any>
  picker?: 'date' | 'time' | 'week' | 'month' | 'quarter' | 'year',
  showToday?: boolean,
  placeholder?: string,
  allowClear?: boolean,
  value: moment.Moment | Date | string | number | (number | string)[] | moment.MomentInputObject,
  onChange?: (value: moment.Moment | null, dateString: string) => void,
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void,
  name?: string,
  inputReadOnly?: boolean,
  format?: string,
}

const DatePicker: React.FC<IDatePicker> = ({
  className,
  ref,
  picker = 'date',
  showToday = false,
  placeholder,
  allowClear,
  value,
  onChange = () => null,
  onBlur = () => null,
  name,
  inputReadOnly = false,
  format = 'DD.MM.YYYY',
}) => {
  return (
    <DatePickerAntd
      value={value ? moment(value) : null}
      ref={ref}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      className={className}
      showToday={showToday}
      picker={picker}
      placeholder={placeholder}
      format={format}
      allowClear={allowClear}
      inputReadOnly={inputReadOnly}
      // localize https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json
    />
  );
};

export default DatePicker;
