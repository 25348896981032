import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const BALANCE = 'BALANCE';

export const balanceDepositRateRequest = createAction(`${BALANCE}/DEPOSIT_RATE_REQUEST`);
export const balanceDepositRateSuccess = createAction(`${BALANCE}/DEPOSIT_RATE_SUCCESS`);
export const balanceDepositRateFailure = createAction(`${BALANCE}/DEPOSIT_RATE_FAILURE`);

export const balanceDepositRequest = createAction(`${BALANCE}/DEPOSIT_REQUEST`);
export const balanceDepositSuccess = createAction(`${BALANCE}/DEPOSIT_SUCCESS`);
export const balanceDepositFailure = createAction(`${BALANCE}/DEPOSIT_FAILURE`);
export const balanceDepositClear = createAction(`${BALANCE}/DEPOSIT_CLEAR`);

// Reducers ==========================
// Разрешенный игнор
// @ts-ignore
const isFetching = createReducer(null, {
  [balanceDepositRequest.toString()]: () => true,
  [balanceDepositSuccess.toString()]: () => false,
  [balanceDepositFailure.toString()]: () => false,
  [balanceDepositClear.toString()]: () => null,
});

const deposit = createReducer({}, {
  [balanceDepositRequest.toString()]: () => ({}),
  [balanceDepositSuccess.toString()]: (_state, action) => action.payload,
  [balanceDepositFailure.toString()]: () => ({}),
  [balanceDepositClear.toString()]: () => ({}),
});

const isFetchingRate = createReducer(true, {
  [balanceDepositRateRequest.toString()]: () => true,
  [balanceDepositRateSuccess.toString()]: () => false,
  [balanceDepositRateFailure.toString()]: () => false,
});

const rate = createReducer([], {
  [balanceDepositRateRequest.toString()]: () => ([]),
  [balanceDepositRateSuccess.toString()]: (_state, action) => action.payload,
  [balanceDepositRateFailure.toString()]: () => ([]),
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  deposit,
  isFetchingRate,
  rate,
});

export default reducer;
