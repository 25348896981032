import styled from 'styled-components';
import { Drawer } from 'antd';

export const DrawerStyled = styled(Drawer)`
  height: 100%;
  width: 740px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.07), 0 1px 1px 0 rgba(0, 0, 0, 0.05);

  & .ant-drawer-content-wrapper {
    width: 740px !important;
    overflow-y: auto !important;
  }

  & .ant-drawer-close {
    /* z-index: -1 !important; */
  }

  & .ant-drawer-body {
    display: flex;
    padding: 0;
    overflow: unset !important;
  }

  & .ant-drawer-content {
    overflow: unset;
  }
`;

export const DrawerStyledMobile = styled(Drawer)`
  height: 100%;
  width: 100%;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.07), 0 1px 1px 0 rgba(0, 0, 0, 0.05);

  & .ant-drawer-content-wrapper {
    width: 100% !important;
  }

  & .ant-drawer-body {
    display: flex;
    padding: 0;
  }

  & .ant-drawer-content {
    overflow: unset;
  }
`;
