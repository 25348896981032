// NB: Разрешенный игнор
/* eslint-disable react/no-danger */

import React from 'react';
import { useSelector } from 'react-redux';

import { list as listSelector } from '../../../states/AdBlocks/state/selectors';

interface IProps {
  type: string;
}

/**
 * Рекламный блок. Кастомный
 *
 * @param {IProps} props
 * @returns
 */
const AdBlockLink: React.FC<IProps> = props => {
  const { type } = props;
  const list = useSelector(listSelector);
  const adData = list?.[type] ?? null;
  const text = adData?.item?.text ?? '';
  const size = adData?.size ?? '100x100';
  const [width, height] = size.split('x');

  function createMarkup() {
    return {
      __html: text,
    };
  }

  return (
    <>
      {adData?.item && <div
        style={{
          width: `${width}px`,
          height: `${height}px`,
          display: 'flex',
        }}
        dangerouslySetInnerHTML={createMarkup()}
      />
      }
    </>
  );
};

export default AdBlockLink;
