import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { Translate, withLocalize } from 'react-localize-redux';
import { Row } from 'antd';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';

import { sentryEnchancedMessage } from '../../shared/utils/runtimeReporters';

import AppRouter from '../AppRouter';

import Preloader from '../../shared/componets/Preloader';
import NotifyToast from '../../shared/containers/NotifyToast';
// NB: ErrorBoundary нужно импортировать последним из shared
// иначе возможны конфликты при сборке SCSS стилей
// IDEA:  https://github.com/facebook/create-react-app/issues/5372
import ErrorBoundary from '../../shared/componets/ErrorBoundary';

import { initRequest } from '../../states/Init/state/duck';
import { isFetching as isInitFetching } from '../../states/Init/state/selectors';
import {
  isLanguagesAlreadySet,
  allLanguages,
  activeLanguage,
  list,
} from '../../states/Languages/state/selectors';

interface IProps {
  // NB: Это пропсы из HOC withLocalize from 'react-localize-redux'
  initialize?: any
  setActiveLanguage: any
  addTranslationForLanguage?: any
}

export interface IAppInitStore {
  isInitFetching: boolean
  isLanguagesAlreadySet: boolean
  allLanguages: Record<string, any>
  activeLanguage: string
  languagesList: [Record<string, any>]
  languages: any
  // messagesPreloader: any
}

export interface IAppInitDispatch {
  initRequest: () => void
}

const mapStateToProps = (state: any) => ({
  isInitFetching: isInitFetching(state),
  isLanguagesAlreadySet: isLanguagesAlreadySet(state),
  allLanguages: allLanguages(state),
  activeLanguage: activeLanguage(state),
  languagesList: list(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      initRequest,
    },
    dispatch,
  );

const onMissingTranslation = (data: any) => {
  const user = {};
  sentryEnchancedMessage({
    level: Sentry.Severity.Error,
    tag: 'ERROR TRANSLATE',
    user,
    payload: { data },
    message: `[${data?.translationId}]`,
  });
  return data?.translationId;
};

type IAppInitTypes = IAppInitDispatch & IAppInitStore & IProps;

const AppInit: React.FC<IAppInitTypes> = (props) => {
  const {
    initRequest,
    isInitFetching,
    isLanguagesAlreadySet,
    initialize,
    allLanguages,
    addTranslationForLanguage,
    activeLanguage,
    languagesList,
    setActiveLanguage,
    languages,
  } = props;

  useEffect(() => {
    initRequest();
  }, []);

  useEffect(() => {
    setActiveLanguage(activeLanguage);
  }, [activeLanguage]);

  useEffect(() => {
    if (isLanguagesAlreadySet && languagesList.length) {
      const languages = languagesList.map(
        (item) => ({ name: item.title, code: item.code.toLowerCase(), flagImage: item.flagImage }),
      );

      initialize({
        languages,
        translation: allLanguages,
        // eslint-disable-next-line max-len
        options: { defaultLanguage: activeLanguage, renderToStaticMarkup, onMissingTranslation, renderInnerHtml: true },
      });
      languages.forEach(
        ({ code, flagImage }) => addTranslationForLanguage(allLanguages[code], code, flagImage),
      );
    }
  }, [isLanguagesAlreadySet]);

  return (
    <ErrorBoundary>
      <>
        {isInitFetching || !languages.length ? (
          <Row justify="center" align="middle" style={{ height: '100vh', marginTop: '-35px' }}>
            <Preloader size="large" />
            <div style={{
              fontSize: '20px',
              color: '#1373e5',
              marginTop: '115px',
              position: 'absolute',
              textAlign: 'center',
            }}
            >
              {
              (() => {
                let urlElements = window.location.href.split('/');
                switch (urlElements[3]) {
                  case 'ru': return 'Ваша персональная анкета готовится';
                  case 'ua': return 'Ваша персональна анкета готується';
                  case 'ua-ru': return 'Ваша персональная анкета готовится';
                  case 'kz': return 'Сіздің жеке сауалнамаңыз дайындалуда';
                  case 'kz-ru': return 'Ваша персональная анкета готовится';
                  case 'vn': return 'Cá nhân của câu hỏi là được chuẩn bị';
                  case 'ca': return 'Your personal questionnaire is being prepared';
                  case 'mx': return 'Su cuestionario personal se está preparando';
                  case 'ph': return 'Your personal application is being prepared';
                  case 'es': return 'Su solicitud personal está siendo preparada';
                  case 'us': return 'Your personal application is being prepared';
                  default: return ' ';
                }
              }).call(this)
              }
            </div>
          </Row>
        ) : (
          <>
            <Translate>
              {({ translate }) => (
                <Helmet>
                  <title>
                    {translate('meta_title_index_page').toString()}
                  </title>
                  <meta
                    name="description"
                    content={translate('meta_description_index_page').toString()}
                  />
                </Helmet>
              )}
            </Translate>

            <AppRouter />

            {/* Система уведомлений */}
            <NotifyToast />
          </>
        )}
      </>
    </ErrorBoundary>
  );
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(AppInit),
);
