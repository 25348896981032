import React from 'react';

import AppHeader from '../AppHeader';

import { HeaderStyled } from './theme';
import styles from './LayoutHeader.module.scss';

interface IProps {
  handleShowUserSettings: (status: boolean) => void;
}

/**
 * Обертка хеадера shared/components/AppHeader
 *
 * @returns
 */
const LayoutHeader: React.FC<IProps> = props => {
  const { handleShowUserSettings } = props;

  return (
    <HeaderStyled className={styles.header}>
      <AppHeader handleShowUserSettings={handleShowUserSettings} />
    </HeaderStyled>
  );
};

export default LayoutHeader;
