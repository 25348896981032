import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

export default {
  removeDataProfile(data: any) {
    const { token } = data;

    return AxiosInstance(token).put(
      `${apiPrefix}${versionOnePrefix}/user/delete`,
    );
  },
};
