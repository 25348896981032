import React from 'react';
import cx from 'classnames';

import styles from '../MultiloginCabinet/MultiloginCabinet.module.scss';

import { Loan } from '../../../../services/Multilogin/api';
import Application from '../Application';
import { Translate } from 'react-localize-redux';

const MultiLoginTable: React.FC<{loans: Loan[] | null}> = ({
  loans,
}) => {
  if (!loans || !loans.length) {
    return null;
  }
  return (
    <>
      <div className={styles.fields}>
        <div className={cx(styles.th, styles.status)}>
          <Translate id="multilogin.loan.status" />
        </div>
        <div className={cx(styles.th, styles.sum)}>
          <Translate id="multilogin.loan.count" />
        </div>
        <div className={cx(styles.th, styles.percent)}>
          <Translate id="multilogin.loan.percent" />
        </div>
        <div className={cx(styles.th, styles.returnSum)}>
          <Translate id="multilogin.loan.return" />
        </div>
        <div className={cx(styles.th, styles.applyDate)}>
          <Translate id="multilogin.loan.date.create" />
        </div>
        <div className={cx(styles.th, styles.returnDate)}>
          <Translate id="multilogin.loan.date.return" />
        </div>
        <div className={cx(styles.th, styles.way)}>
          <Translate id="multilogin.loan.method" />
        </div>
        <div className={cx(styles.th, styles.buttons)} />
      </div>

      <div className={styles.wrapper}>
        {loans.map((loan) => <Application {...loan} key={loan.id} />)}
      </div>
    </>
  );
};

export default MultiLoginTable;
