import React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Avatar } from 'antd';
import { Translate } from 'react-localize-redux';
import get from 'lodash/get';

import Preloader from '../Preloader';

import AvatarWrapper from '../AvatarWrapper';

import defaultUserAvatar from '../../../../assets/images/defaultUserAvatar.svg';

import {
  isFetching as isFetchingSelector,
  data as dataSelector,
} from '../../../states/UserProfile/state/selectors';
import { userProfileRequest } from '../../../states/UserProfile/state/duck';

import { ButtonStyled } from './theme';

import styles from '../BalanceBadge/BalanceBadge.module.scss';

import { useHistory } from 'react-router-dom';

import CrownOutlined from '@ant-design/icons/CrownOutlined';

interface IProps {
  handleShowUserSettings: (status: boolean) => void;
}

const UserAvatar: React.FC<IProps> = props => {
  const { handleShowUserSettings } = props;
  const dispatch = useDispatch();
  const isFetching = useSelector(isFetchingSelector);
  const data = useSelector(dataSelector);
  const cssTarif = 'currentTarif';

  const { profile, avatar, userSubscribe } = data;
  const { avatar: avatarStr } = avatar;
  const firstName = get(profile, 'firstname', '');
  // const userSubscribe = get(profile, 'userSubscribe', '');
  // const midleName = get(profile, 'midlename', '');
  const lastName = get(profile, 'lastname', '');
  // const fullName = `${lastName} ${firstName[0] ? `${firstName[0]}.` : ''} ${
  //   midleName[0] ? `${midleName[0]}.` : ''
  // }`.trim();
  const fullName = `${firstName} ${lastName ? `${lastName}.` : ''}`.trim();

  const isFree = userSubscribe === 'Free' ? true : false;

  const history = useHistory();

  React.useEffect(() => {
    dispatch({ type: userProfileRequest.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Translate>
      {({ translate }) => (
        <ButtonStyled onClick={() => handleShowUserSettings(true)}>
          {avatarStr !== '' ? (
            <Avatar
              style={{ marginRight: '10px' }}
              size={38}
              icon={
                <AvatarWrapper
                  style={{
                    height: 38,
                    width: 38,
                  }}
                  image={avatarStr}
                />
              }
            />
          ) : (
            <Avatar
              style={{ marginRight: '10px' }}
              size={38}
              icon={<img src={defaultUserAvatar} alt="" />}
            />
          )}
          {isFetching ? (
            <Preloader size="small" />
          ) : (
            <span
              style={{
                position: 'relative',
                top: '-10px',
              }}
            >
              {fullName ||
                `${translate('shared.componets.useravatar.user').toString()}`}
            </span>
          )}
          <Button
            onClick={() => history.push('?tariff=1')}
            style={{
              left: '68px',
              position: 'absolute',
              top: '30px',
              fontSize: '11px',
              fontWeight: '900',
              padding: '0px 5px',
              height: '18px',
            }}
            className={cx(styles.badge + cssTarif, {
              [styles.success]: !isFree,
              [styles.danger]: isFree,
            })}
          ><CrownOutlined />{userSubscribe}</Button>
        </ButtonStyled>
      )}
    </Translate>
  );
};

export default UserAvatar;
