// NB: Разрешенный игнор
/* eslint-disable indent */

import { createSelector } from 'reselect';
import get from 'lodash/get';
import truncate from 'lodash/truncate';
import moment from 'moment';

export const isFetching = (store: any) =>
  store.chatListLawyerReducer.isFetching;

export const isFetchingPreFetch = (store: any) =>
  store.chatListLawyerReducer.isFetchingPreFetch;

export const isFetchingFirstPreFetch = (store: any) =>
  store.chatListLawyerReducer.isFetchingFirstPreFetch;

export const isFetchingChatSendMsg = (store: any) =>
  store.chatListLawyerReducer.isFetchingChatSendMsg;

export const isUpdatingDataAfterChatSendMsg = (store: any) =>
  store.chatListLawyerReducer.isUpdatingDataAfterChatSendMsg;

export const isChatInputNeedClear = (store: any) =>
  store.chatListLawyerReducer.isChatInputNeedClear;

export const isResponseFromSocketServer = (store: any) =>
  store.chatListLawyerReducer.isResponseFromSocketServer;

export const isAllowedAcceptFromSocketServer = (store: any) =>
  store.chatListLawyerReducer.isAllowedAcceptFromSocketServer;

// Список всех сообщений
export const messagesList = createSelector(
  (store: any) => store.chatListLawyerReducer.data.messages,
  messages => messages,
);

// Количество найденных сообщений
export const messagesTotal = createSelector(
  (store: any) => store.chatListLawyerReducer.data.total,
  total => total,
);

export const query = createSelector(
  (store: any) => store.chatListLawyerReducer.data.query,
  query => query || '',
);

export const callFrom = createSelector(
  (store: any) => store.chatListLawyerReducer.data.callFrom,
  callFrom => callFrom,
);

// PRE FETCH Селектор для конкатенации данных из нескольких запросов
export const preFetchedData = createSelector(
  (store: any) => store.chatListLawyerReducer.preFetchedData,
  preFetchedData => preFetchedData,
);

// PRE FETCH Список всех сообщений
export const preFetchedMessagesList = createSelector(
  (store: any) => store.chatListLawyerReducer.preFetchedData.messages,
  messages => messages,
);

// Список всех сообщений без посчитанной высоты
export const messagesWithoutRenderHeight = createSelector(
  (store: any) => store.chatListLawyerReducer.preFetchedData.messages,
  messages => {
    const tempMessages = [...(messages || [])];
    const result = tempMessages.filter((item: any) => {
      const messageRenderHeight =
        get(item, 'messageRenderHeight', null) || null;

      return !messageRenderHeight;
    });

    return result;
  },
);

// Список всех сообщений с посчитанной высотой
export const messagesWithRenderHeight = createSelector(
  (store: any) => store.chatListLawyerReducer.preFetchedData.messages,
  messages => {
    const tempMessages = [...(messages || [])];
    const result = tempMessages.filter((item: any) => {
      const messageRenderHeight =
        get(item, 'messageRenderHeight', null) || null;

      return messageRenderHeight;
    });

    return result;
  },
);

export const activeServerMessageID = createSelector(
  messagesWithRenderHeight,
  (messagesWithRenderHeight = []) => {
    const result = get(messagesWithRenderHeight, '[0].id', -1);

    return result;
  },
);

export const activeServerMessage = createSelector(
  messagesWithRenderHeight,
  (messagesWithRenderHeight = []) => {
    const result = get(messagesWithRenderHeight, '[0]', {});

    return result;
  },
);

// PRE FETCH Максимально возможное количество запросов
export const preFetchedCountOfFetches = createSelector(
  (store: any) => store.chatListLawyerReducer.preFetchedData.countOfFetches,
  countOfFetches => countOfFetches,
);

// PRE FETCH Количество выполненных запросов
export const preFetchedIteration = createSelector(
  (store: any) => store.chatListLawyerReducer.preFetchedData.iteration,
  iteration => iteration,
);

export const preFetchedMessagesTotal = createSelector(
  (store: any) => store.chatListLawyerReducer.preFetchedData.total,
  total => total,
);

export const preFetchedCallFrom = createSelector(
  (store: any) => store.chatListLawyerReducer.preFetchedData.callFrom,
  callFrom => callFrom,
);

export const dataActiveButton = createSelector(
  (store: any) => store.chatListLawyerReducer.dataActiveButton,
  dataActiveButton => dataActiveButton,
);

// NB: Последнее сообщение в чате
export const isFetchingLastMsg = (store: any) =>
  store.chatListLawyerReducer.isFetchingLastMsg;

export const dataLastMsg = createSelector(
  (store: any) => store.chatListLawyerReducer.dataLastMsg,
  dataLastMsg => {
    const msg = get(dataLastMsg, 'msg', '') || '';
    const date = get(dataLastMsg, 'date', '') || '';

    const result = {
      time: date !== '' ? `${moment(date).format('HH:mm')}` : date,
      message:
        msg !== ''
          ? `${truncate(msg, {
              length: 20,
              separator: /,? +/,
            })}`
          : msg,
    };

    return result;
  },
);

export const errorLastMsg = createSelector(
  (store: any) => store.chatListLawyerReducer.errorLastMsg,
  errorLastMsg => errorLastMsg,
);
