import React from 'react';

const WalletImage = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2939 3.25H12.5439V2.5C12.5439 1.90326 12.3069 1.33097 11.8849 0.90901C11.463 0.487053 10.8907 0.25 10.2939 0.25H2.79395C2.19721 0.25 1.62491 0.487053 1.20296 0.90901C0.780998 1.33097 0.543945 1.90326 0.543945 2.5V11.5C0.543945 12.0967 0.780998 12.669 1.20296 13.091C1.62491 13.5129 2.19721 13.75 2.79395 13.75H13.2939C13.8907 13.75 14.463 13.5129 14.8849 13.091C15.3069 12.669 15.5439 12.0967 15.5439 11.5V5.5C15.5439 4.90326 15.3069 4.33097 14.8849 3.90901C14.463 3.48705 13.8907 3.25 13.2939 3.25ZM2.79395 1.75H10.2939C10.4929 1.75 10.6836 1.82902 10.8243 1.96967C10.9649 2.11032 11.0439 2.30109 11.0439 2.5V3.25H2.79395C2.59503 3.25 2.40427 3.17098 2.26362 3.03033C2.12296 2.88968 2.04395 2.69891 2.04395 2.5C2.04395 2.30109 2.12296 2.11032 2.26362 1.96967C2.40427 1.82902 2.59503 1.75 2.79395 1.75ZM14.0439 9.25H13.2939C13.095 9.25 12.9043 9.17098 12.7636 9.03033C12.623 8.88968 12.5439 8.69891 12.5439 8.5C12.5439 8.30109 12.623 8.11032 12.7636 7.96967C12.9043 7.82902 13.095 7.75 13.2939 7.75H14.0439V9.25ZM14.0439 6.25H13.2939C12.6972 6.25 12.1249 6.48705 11.703 6.90901C11.281 7.33097 11.0439 7.90326 11.0439 8.5C11.0439 9.09674 11.281 9.66903 11.703 10.091C12.1249 10.5129 12.6972 10.75 13.2939 10.75H14.0439V11.5C14.0439 11.6989 13.9649 11.8897 13.8243 12.0303C13.6836 12.171 13.4929 12.25 13.2939 12.25H2.79395C2.59503 12.25 2.40427 12.171 2.26362 12.0303C2.12296 11.8897 2.04395 11.6989 2.04395 11.5V4.6225C2.2849 4.70726 2.53852 4.75038 2.79395 4.75H13.2939C13.4929 4.75 13.6836 4.82902 13.8243 4.96967C13.9649 5.11032 14.0439 5.30109 14.0439 5.5V6.25Z"
      fill="#1373E5"
    />
  </svg>

);

export default WalletImage;
