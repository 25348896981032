import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * Recovery Password API
 */
export default {
  /**
   * Отправить данные логина для получения временного токена на смену пароля
   *
   * @returns
   */
  postDataLogin(data: any) {
    const { token, login } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/user/recovery-request`,
      {
        login,
      },
    );
  },

  /**
   * Отправить данные формы для изменения пароля.
   * `token` -- JWT берется из URL параметра `access-recovery-confirmation`
   * получаемого по ссылке из письма или СМС сообщения
   *
   * @returns
   */
  putDataForm(data: any) {
    const { token, password, confirm } = data;

    return AxiosInstance('').put(
      `${apiPrefix}${versionOnePrefix}/user/recovery-confirm`,
      {
        password,
        confirm,
        token,
      },
    );
  },
};
