import AxiosInstance from '../../../core/api/axiosinstance';
import paramsSerializer from '../../../shared/utils/paramsSerializer';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * User Settings Activity
 */
export default {
  /**
   * Получить данные об активности пользователя
   *
   * @returns
   */
  getData(data: any) {
    const { token, page = 1 } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/action-log?${paramsSerializer({
        page,
      })}`,
    );
  },
};
