import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * User Settings Change Data API
 */
export default {
  /**
   * Получить данные формы
   *
   * @returns
   */
  getData(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/profile`,
    );
  },

  /**
   * Отправить данные формы для изменения пароля
   *
   * @returns
   */
  putData(data: any) {
    const { token, ...body } = data;

    return AxiosInstance(token).put(
      `${apiPrefix}${versionOnePrefix}/user/profile`,
      body,
    );
  },
};
