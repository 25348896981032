import React, { SVGAttributes } from 'react';

const InfoFill: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="#1373E5" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="8.42839" cy="7.99998" r="6.66667" fillOpacity="0.1" />
    <path
      d="M7.95699 11.1381C8.08202 11.2631 8.25159 11.3333 8.4284 11.3333C8.60521 11.3333 8.77478 11.2631 8.8998 11.1381C9.02483 11.013 9.09506 10.8435 9.09506 10.6666V7.99998C9.09506 7.82317 9.02483 7.6536 8.8998 7.52858C8.77478 7.40355 8.60521 7.33331 8.4284 7.33331C8.25159 7.33331 8.08202 7.40355 7.95699 7.52858C7.83197 7.6536 7.76173 7.82317 7.76173 7.99998V10.6666C7.76173 10.8435 7.83197 11.013 7.95699 11.1381Z"
    />
    <path
      d="M8.98173 5.70665C9.00696 5.66948 9.02714 5.62913 9.04173 5.58665C9.06559 5.54964 9.08358 5.50916 9.09506 5.46665C9.09834 5.42226 9.09834 5.3777 9.09506 5.33331C9.09401 5.2462 9.07589 5.16013 9.04173 5.07998C9.01179 4.99725 8.96402 4.92212 8.90181 4.8599C8.83959 4.79769 8.76446 4.74992 8.68173 4.71998C8.60193 4.68471 8.51564 4.66649 8.4284 4.66649C8.34115 4.66649 8.25486 4.68471 8.17506 4.71998C8.09233 4.74992 8.0172 4.79769 7.95499 4.8599C7.89277 4.92212 7.845 4.99725 7.81506 5.07998C7.7809 5.16013 7.76278 5.2462 7.76173 5.33331C7.76122 5.42105 7.77804 5.50803 7.81122 5.58925C7.84439 5.67048 7.89328 5.74435 7.95506 5.80665C8.01986 5.86553 8.09427 5.91289 8.17506 5.94665C8.29647 5.9977 8.43028 6.01165 8.55961 5.98673C8.68893 5.96182 8.80798 5.89915 8.90173 5.80665L8.98173 5.70665Z"
    />
  </svg>
);

export default InfoFill;
