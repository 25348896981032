import { createSelector } from 'reselect';

const initData = {
  form: {
    name: '',
    is_valid: undefined,
    fields: {
      current: {},
      new: {},
      confirm: {},
    },
  },
};

export const isFetchingPut = (store: any) =>
  store.userSettingsChangePhoneReducer.isFetchingPut;

export const dataPut = createSelector(
  (store: any) => store.userSettingsChangePhoneReducer.dataPut,
  dataPut => {
    const result = dataPut?.data ?? initData;

    return result;
  },
);

export const isFetchingPost = (store: any) =>
  store.userSettingsChangePhoneReducer.isFetchingPost;

export const dataPost = createSelector(
  (store: any) => store.userSettingsChangePhoneReducer.dataPost,
  dataPost => {
    const result = dataPost?.data ?? initData;

    return result;
  },
);

export const isFormValidPut = createSelector(
  (store: any) => store.userSettingsChangePhoneReducer.dataPut,
  dataPut => {
    const result = dataPut?.data?.form?.is_valid ?? false;

    return result;
  },
);

export const isFormValidPost = createSelector(
  (store: any) => store.userSettingsChangePhoneReducer.dataPost,
  dataPost => {
    const result = dataPost?.data?.form?.is_valid ?? false;

    return result;
  },
);
