import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from '../../../../views/MultiLogin/MultiLogin.module.scss';

import Card from '../Card';
import MultiloginSlider from '../MultiloginSlider';

import { $getMfoAll } from '../../../../states/Multilogin/event';
import { Mfo } from '../../../../services/Multilogin/api';

const mfo: Mfo = {
  id: 0,
  name: '',
  url: '',
  inn: '',
  ogrn: '',
  logoImage: '',
  backgroundImage: '',
  isMain: 0,
  description: '',
  legalName: '',
  createNewLoanUrl: '',
};

export const mockMfoAll = [
  { ...mfo, id: 1, isMain: 1 },
  { ...mfo, id: 2, isMain: 1 },
  { ...mfo, id: 3, isMain: 1 },
  { ...mfo, id: 4 },
  { ...mfo, id: 5 },
  { ...mfo, id: 6 },
  { ...mfo, id: 7 },
];

const MultiLoginMfoSkeleton = () => {
  const mfoAll = mockMfoAll;

  useEffect(() => {
    $getMfoAll(null);
  }, []);

  return (
    <div>

      <div className={styles.search}>
        <Skeleton width={'100%'} />
      </div>

      <div className={styles.title}>
        <Skeleton width={170} />
      </div>
      <div className={styles.subtitle}>
        <Skeleton width={220} />
      </div>

      <MultiloginSlider
        mfoAll={mfoAll.filter((mfo) => mfo.isMain === 1)}
        isLoading
      />

      <div className={styles.subtitle}>
        <Skeleton width={120} />
      </div>

      <div className={styles.wrapper}>
        {mfoAll.map((item) => {
          return (
            <Card
              {...item}
              key={item.id}
              isLoading
            />
          );
        })}
      </div>

    </div>
  );
};

export default MultiLoginMfoSkeleton;
