import React, { SVGAttributes } from 'react';

const Fincoin: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="#1373E5" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.75199 1H3.06399C2.36546 1 1.83325 1.52349 1.83325 2.21056V7.87077C2.83115 4.59897 5.42567 1.98154 8.75199 1Z"
    />
    <path
      d="M11.7798 2.47232C7.12299 2.47232 3.36426 6.20217 3.36426 10.75V16.7373H6.05857V10.75C6.05857 7.64176 8.61983 5.12248 11.7798 5.12248H15.6716C15.6716 6.30032 14.707 7.24914 13.5095 7.24914H11.8131C9.85057 7.24914 8.25394 8.8196 8.25394 10.75H13.6426C13.3765 11.9932 12.2788 12.8766 10.9483 12.8766H8.22068V16.7373H16.603C17.2683 16.7373 17.8337 16.2139 17.8337 15.5268V2.43961H11.7798V2.47232Z"
    />
  </svg>

);

export default Fincoin;
