import React, { SVGAttributes } from 'react';

const CheckCircul: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="#10B97B" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.666016 8.00008C0.666016 12.0502 3.94926 15.3334 7.99935 15.3334C12.0494 15.3334 15.3327 12.0502 15.3327 8.00008C15.3327 3.94999 12.0494 0.666748 7.99935 0.666748C3.94926 0.666748 0.666016 3.94999 0.666016 8.00008ZM13.9993 8.00008C13.9993 11.3138 11.3131 14.0001 7.99935 14.0001C4.68564 14.0001 1.99935 11.3138 1.99935 8.00008C1.99935 4.68637 4.68564 2.00008 7.99935 2.00008C11.3131 2.00008 13.9993 4.68637 13.9993 8.00008Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0928 5.48779C11.3757 5.7235 11.4139 6.14387 11.1782 6.42673L7.84483 10.4267C7.72492 10.5706 7.55001 10.6574 7.36289 10.6659C7.17578 10.6744 6.99372 10.6038 6.86128 10.4713L4.86128 8.47134C4.60093 8.21099 4.60093 7.78888 4.86128 7.52853C5.12163 7.26818 5.54374 7.26818 5.80409 7.52853L7.28788 9.01233L10.1539 5.57315C10.3896 5.29029 10.81 5.25208 11.0928 5.48779Z"
    />
  </svg>

);

export default CheckCircul;
