// import * as E from 'fp-ts/lib/Either';
// import * as Sentry from '@sentry/browser';
import get from 'lodash/get';

import { soketServerMsgContract } from '../../../../contracts/Socket/ChatListSupport';

import { DIRECTION } from '../../../../core/state/utils/checkContract/saga';

// import { sentryEnchancedMessage } from '../../../../shared/utils/runtimeReporters';

import {
  chatListSupportResMsgFromSocket,
  socketSupportClearBeforeRes,
} from '../duck';

interface IParams {
  message: any;
  emit: (input: any) => void;
}

/**
 * Проверка контракта и отправка уведомления в сентри,
 * в случае несоответсвия контракта
 *
 * @param {({
 *   contract: any;
 *   data: any;
 *   direction: DIRECTION.B_F | DIRECTION.F_B;
 * })} {
 *   contract,
 *   data,
 *   direction,
 * }
 * @returns
 */
const checkContract = ({
  contract,
  // data,
  // direction,
}: {
  contract: any;
  data: any;
  direction: DIRECTION.B_F | DIRECTION.F_B;
}) => {
  const isContract = get(contract, 'decode', false) || false;
  if (!isContract) {
    // NB: Разрешенный игнор
    // eslint-disable-next-line
    console.log('socketCallback Contract error');
    return null;
  }

  // const contractStatus = contract.decode(data);
  // const contractName =
  //  get(contract, 'name', 'NOT_A_CONTRACT') || 'NOT_A_CONTRACT';

  // if (E.isLeft(contractStatus)) {
  //  const dir =
  //    direction === DIRECTION.B_F
  //      ? 'VIOLATION FROM SOCKET SRV BACKEND'
  //      : 'VIOLATION FROM FRONTEND';

  // sentryEnchancedMessage({
  //  level: Sentry.Severity.Error,
  //  tag: `CONTRACT: ${dir}`,
  //  user: {},
  //  payload: { data },
  //  message: `Contract error: ${dir} -- Name: ${contractName}`,
  // });
  // }
};

/**
 * Коллбек, который помещается в event канал с сокетами,
 * где при событии от сокет сервера `publish`
 * "вытягивает" emit (эмиттер) в замыкание.
 * Эмиттер (emit) это аргумент коллбека из функции `eventChannel` пакета redux-saga:
 *
 * `eventChannel((emit: any) => {...}`
 *
 * Эмиттер нужен, чтобы потом "дернуть" экшен и передать в его `payload`
 * данные полученные от сокет сервера
 *
 * @param {IParams} params
 * @param message any;
 * @param emit (input: any) => void;
 */
export const socketCallback = (params: IParams) => {
  const { message } = params;
  const { data } = message;

  checkContract({
    contract: soketServerMsgContract,
    data,
    direction: DIRECTION.B_F,
  });

  params.emit({
    type: socketSupportClearBeforeRes.toString(),
  });

  params.emit({
    type: chatListSupportResMsgFromSocket.toString(),
    payload: data,
  });
};
