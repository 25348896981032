/* eslint-disable no-restricted-syntax */

import { createSelector } from 'reselect';
import get from 'lodash/get';

import { language } from '../../../Languages/state/selectors';

import { ID_FOR_I_DONT_KNOW } from '../../../../shared/constants/SelectionLoanProduct/constants';

export const isFetching = (store: any) =>
  store.selectionLoanProductReducer.isFetching;

export const data = createSelector(
  (store: any) => store.selectionLoanProductReducer.data,
  data => data,
);

export const error = createSelector(
  (store: any) => store.selectionLoanProductReducer.error,
  error => error,
);

export const isFetchingFilteredList = (store: any) =>
  store.selectionLoanProductReducer.isFetchingFilteredList;

export const isFilterSettingUp = (store: any) =>
  store.selectionLoanProductReducer.isFilterSettingUp;

export const dataFilteredList = createSelector(
  (store: any) => store.selectionLoanProductReducer.dataFilteredList,
  dataFilteredList => dataFilteredList,
);

export const errorFilteredList = createSelector(
  (store: any) => store.selectionLoanProductReducer.errorFilteredList,
  errorFilteredList => errorFilteredList,
);

export const offersTabsData = createSelector(
  (store: any) => store.selectionLoanProductReducer.data.offersTabsData,
  offersTabsData => offersTabsData,
);

export const categoryTypesData = createSelector(
  (store: any) => store.selectionLoanProductReducer.data.categoryTypesData,
  language,
  (categoryTypesData = [], language) => {
    const iDontKnowSynteticData = {
      id: ID_FOR_I_DONT_KNOW,
      sort: null,
      title: language.views_selection_loan_product_controller_i_dont_know_title,
      h1: language.views_selection_loan_product_controller_i_dont_know_title,
      country_id: null,
      image: null,
    };

    let result = [...categoryTypesData, iDontKnowSynteticData];
    if (categoryTypesData.length === 0) {
      result = [...categoryTypesData];
    }

    return result;
  },
);

export const offersList = createSelector(
  (store: any) => store.selectionLoanProductReducer.data.offersList,
  offersList => offersList,
);

export const offersListFiltered = createSelector(
  (store: any) => store.selectionLoanProductReducer.dataFilteredList.offersList,
  offersList => offersList,
);

export const filtersByCategories = createSelector(
  categoryTypesData,
  offersTabsData,
  (categoryTypesData = [], offersTabsData = []) => {
    let result: Record<number, any> = {};

    for (const item of categoryTypesData) {
      const { id = -1 } = item;

      if (id !== -1) {
        const tempData = offersTabsData.filter((i: any) => {
          const { category = {} } = i;
          const catID = get(category, 'id', null);

          return catID === id;
        });

        result[id] = tempData;
      }
    }

    return result;
  },
);

export const allTagsData = createSelector(
  (store: any) => store.selectionLoanProductReducer.data.allTagsData,
  (allTagsData = []) => {
    const result = [
      ...allTagsData.map((item: string, index: number) => {
        return {
          id: `${item}__${index + 1}`,
          value: item,
        };
      }),
    ];

    return result;
  },
);

export const isFetchingNestedTags = (store: any) =>
  store.selectionLoanProductReducer.isFetchingNestedTags;

export const dataNestedTags = createSelector(
  (store: any) => store.selectionLoanProductReducer.dataNestedTags,
  (dataNestedTags = []) => {
    let result: any[] = [];

    if (dataNestedTags.length > 0) {
      result = [
        ...dataNestedTags.map((item: any = {}, index: number) => {
          return { ...item, id: index + 1 };
        }),
      ];
    }

    return result;
  },
);

export const errorNestedTags = createSelector(
  (store: any) => store.selectionLoanProductReducer.errorNestedTags,
  errorNestedTags => errorNestedTags,
);

export const allOffersListforIdontKnowData = createSelector(
  (store: any) =>
    store.selectionLoanProductReducer.data.allOffersListforIdontKnowData,
  (allOffersListforIdontKnowData = []) => allOffersListforIdontKnowData,
);

export const isCached = createSelector(
  (store: any) => store.selectionLoanProductReducer.isCached,
  isCached => isCached,
);
