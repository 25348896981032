import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * User Settings Change Avatar API
 */
export default {
  /**
   * Получить данные формы
   *
   * @returns
   */
  getData(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/avatar`,
    );
  },

  /**
   * Отправить данные формы для изменения аватара
   *
   * @returns
   */
  postFormData(data: any) {
    const { token, formData } = data;
    const type = 'FORM_DATA';

    return AxiosInstance(token, type).post(
      `${apiPrefix}${versionOnePrefix}/user/avatar`,
      formData,
    );
  },
};
