import {
  PHONE_INPUT,
  SELECT_PHONE_VERIFICATION,
} from '../../../../shared/constants/Register/constants';

// Сценарий по шагам
export const SCRIPT = [
  {
    id: Date.now() + 0,
    name: 'welcome',
    footer: {},
  },
  {
    id: Date.now() + 1,
    name: 'phone',
    footer: {
      type: PHONE_INPUT,
      isRequired: true,
      back: { type: 'link', value: '/signup' },
    },
  },
  {
    id: Date.now() + 2,
    name: 'phone_verify',
    footer: {
      type: SELECT_PHONE_VERIFICATION,
      isRequired: true,
      back: { type: 'link', value: '/signup/1' },
    },
  },
];

// Количество шагов в скрипте, нулевой не считается
export const SCRIPT_LENGTH = SCRIPT.length - 1;

// Вопросы от "робота"
export const PHONE_NUM_REQ = [
  {
    id: Date.now() + 100,
    type: 'bot',
    value: 'dlya.podtverzhdenia.bezopasnosti.nam.nuzhen.nomer.vashego.telefona',
    buttons: [],
    stepNumForFilter: 1,
  },
  {
    id: Date.now() + 101,
    type: 'bot',
    value: 'tolko.dlya.bezopasnosti',
    buttons: [],
    stepNumForFilter: 1,
  },
];

export const PHONE_NUM_VERIFY_REQ = [
  {
    id: Date.now() + 200,
    type: 'bot',
    value:
      'kakim.sposobom.vy.hotite.poluchit.kod.dlya.podtverzhdeniya.telefona',
    buttons: [
      { id: 0, value: 'sms', image: 'sms', active: false, stepNum: 2 },
      { id: 1, value: 'zvonok', image: 'zvonok', active: false, stepNum: 2 },
    ],
    stepNumForFilter: 2,
  },
];
