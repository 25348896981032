import { createSelector } from 'reselect';

export const modalData = createSelector(
  (store: any) => store.modalSystemReducer.modalData,
  modalData => modalData,
);

export const modalActive = createSelector(
  (store: any) => store.modalSystemReducer.modalActive,
  modalActive => modalActive,
);

export const modalQueue = createSelector(
  (store: any) => store.modalSystemReducer.modalQueue,
  modalQueue => modalQueue,
);

export const isFetchingFields = createSelector(
  (store: any) => store.modalSystemReducer.isFetchingFields,
  isFetchingFields => isFetchingFields,
);
