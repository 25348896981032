import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';

import { userSettingsChangeEmailAPI } from '../../../../services/UserSettingsChangeEmail/api';

import {
  userSettingsChangeEmailRequest,
  userSettingsChangeEmailResult,
  userSettingsChangeEmailSuccess,
  userSettingsChangeEmailFailure,
  userSettingsChangeEmailPutRequest,
  userSettingsChangeEmailPutSuccess,
  userSettingsChangeEmailPutFailure,
  userSettingsChangeEmailPutResult,
} from '../duck';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';

export function* getData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const srvRes = yield call(userSettingsChangeEmailAPI.getData, {
    ...completePayload,
  });

  yield put({
    type: userSettingsChangeEmailResult.toString(),
    payload: createResultV2(srvRes),
  });
} // getData =========

// TODO:  Реализовать проверку контракта! Формы
export function* checkResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: userSettingsChangeEmailSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: userSettingsChangeEmailFailure.toString(),
      payload: error,
    });
  }
} // checkResult =========

export function* putData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const srvRes = yield call(userSettingsChangeEmailAPI.putData, {
    ...completePayload,
  });

  yield put({
    type: userSettingsChangeEmailPutResult.toString(),
    payload: createResultV2(srvRes),
  });
} // putData =========

// TODO: Добавить контракт
export function* checkResultPut(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: userSettingsChangeEmailPutSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: userSettingsChangeEmailPutFailure.toString(),
    });
  }
} // checkResultPut =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    userSettingsChangeEmailRequest,
    safe(onError, getData, {
      terminator: userSettingsChangeEmailFailure,
    }),
  );

  yield takeEvery(userSettingsChangeEmailResult, safe(onError, checkResult));

  yield takeLatest(
    userSettingsChangeEmailPutRequest,
    safe(onError, putData, {
      terminator: userSettingsChangeEmailPutFailure,
    }),
  );

  yield takeEvery(
    userSettingsChangeEmailPutResult,
    safe(onError, checkResultPut),
  );
}
