import { components } from 'react-select';
import React from 'react';

export const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <></>
    </components.NoOptionsMessage>
  );
};

export const customStyles = {
  control: (provided: any, state: { isFocused: any; }) => ({
    ...provided,
    border: state.isFocused ? '1px' : '1px solid rgba(214,220,227,0.5)',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 13,
    color: '#73797f',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    width: 'calc(100% - 10px)',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused && 'rgba(214,220,227,0.5)',
    ':active': {
      backgroundColor: 'rgba(214,220,227,0.5)',
    },
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 13,
    color: '#73797f',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 13,
    color: '#73797f',
  }),
  input: (provided: any) => ({
    ...provided,
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 13,
    color: '#73797f',
  }),
};
