import styled from 'styled-components';
import { Button } from 'antd';

export const ButtonStyled = styled(Button)`
  height: 100%;
  border: 0;
  border-right: 1px solid rgba(214, 220, 227, 0.4);
  border-bottom: 0;
  border-left: 0;
  box-shadow: none;
  padding: 0 20px;
  color: #9CA5B0 !important;

  p {
    margin-bottom: 3px;
    font-size: 11px;
    line-height: 13px;
    font-weight: normal;
  }

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    border-bottom: 0 !important;
    border-left: 0 !important;
    box-shadow: none !important;
    color: #9CA5B0 !important;
    border-color: rgba(214, 220, 227, 0.4) !important;
  }

  line-height: 1.5715 !important;
  // FIN-1063 disabled
  //padding-top: 9px !important;
`;
