import { createSelector } from 'reselect';
import get from 'lodash/get';

export const isLanguagesAlreadySet = createSelector(
  (store: any) => store.languagesReducer.isLanguagesAlreadySet,
  isLanguagesAlreadySet => isLanguagesAlreadySet,
);

export const languages = createSelector(
  (store: any) => store.languagesReducer,
  languagesReducer => languagesReducer,
);

export const activeLanguage = createSelector(
  languages,
  languages => languages.activeLanguage,
);

export const activeRegion = createSelector(
  languages,
  languages => languages.activeRegion,
);

export const activeRegionNoSms = createSelector(
  activeRegion,
  (store: any) => store.countriesReducer.list,
  (activeRegion) => {
    return activeRegion;
  },
);

export const activeLanguageUPPER = createSelector(
  (store: any) => store.languagesReducer.activeLanguage,
  activeLanguage => activeLanguage.toUpperCase(),
);

export const allLanguages = createSelector(
  languages,
  languages => languages.language,
);

export const language = createSelector(
  languages,
  activeLanguage,
  (languages, activeLanguage) => {
    const result = get(languages.language, `${activeLanguage}`);

    return result;
  },
);

export const list = createSelector(
  languages,
  (languages) => {
    return languages.list.map((item: Record<string, any>) => {
      const id = get(item, 'id');
      const code = get(item, 'code');
      const name = get(item, 'name');
      const title = get(item, 'title');
      const flagImage = get(item, 'flag_image');
      return { id, code, name, title, flagImage };
    });
  },
);
