import React from 'react';
import { TariffModal } from '../TariffModal';
import { ConfirmEmailModal } from '../ConfirmEmailModal';
import { Instructions } from '../../../enum/Instructions.enum';
import { useSelector } from 'react-redux';
import { instructions as instructionsSelector } from '../../../../states/UserTask/state/selectors';
import { useLocation } from 'react-router-dom';

export const InstructionModal = () => {
  const instructions = useSelector(instructionsSelector);

  const instruction = instructions[0] || {};

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const urlTariff = query.get('tariff') || false;

  return (
    <>
      {urlTariff &&
        <TariffModal />
      }
      {instruction?.handler?.alias === Instructions.TARIFF &&
        <TariffModal />
      }
      {instruction?.handler?.alias === Instructions.EMAIL_CONFIRM &&
        <ConfirmEmailModal />
      }
    </>
  );
};
