import React from 'react';

const ArrowImage = () => (
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.862254 0.528514C1.1226 0.268165 1.54471 0.268165 1.80506 0.528514L6.00033 4.72378L10.1956 0.528514C10.4559 0.268165 10.878 0.268165 11.1384 0.528514C11.3987 0.788864 11.3987 1.21097 11.1384 1.47132L6.47173 6.13799C6.21138 6.39834 5.78927 6.39834 5.52892 6.13799L0.862254 1.47132C0.601905 1.21097 0.601905 0.788864 0.862254 0.528514Z"
      fill="#CED7E0"
    />
  </svg>
);

export default ArrowImage;
