import AxiosInstance from '../../../core/api/axiosinstance';
import paramsSerializer from '../../../shared/utils/paramsSerializer';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * Register API
 */
export default {
  /**
   * Отправка полей формы регистрации на бекенд валидацию
   *
   * @returns
   */
  postDataValidation(data: any) {
    return AxiosInstance('').post(
      `${apiPrefix}${versionOnePrefix}/registration/validate`,
      {
        ...data,
      },
    );
  },

  /**
   * Отправка запроса СМС кода
   *
   * @returns
   */
  postDataSMSCode(data: any) {
    return AxiosInstance('').post(
      `${apiPrefix}${versionOnePrefix}/registration/send-sms-code`,
      {
        ...data,
      },
    );
  },

  /**
   * Отправка запроса голосового кода
   *
   * @returns
   */
  postDataCallCode(data: any) {
    return AxiosInstance('').post(
      `${apiPrefix}${versionOnePrefix}/registration/send-call-code`,
      {
        ...data,
      },
    );
  },

  /**
   * Отправка запроса Email кода
   *
   * @returns
   */
  postDataEmailCode(data: any) {
    return AxiosInstance('').post(
      `${apiPrefix}${versionOnePrefix}/registration/send-email-code`,
      {
        ...data,
      },
    );
  },

  /**
   * Отправка запроса Email кода - проверка статуса
   *
   * @returns
   */
  postDataEmailCodeStatus(data: any) {
    return AxiosInstance('').post(
      `${apiPrefix}${versionOnePrefix}/registration/send-email-code-status`,
      {
        ...data,
      },
    );
  },

  /**
   * Получить данные о телефоне
   *
   * @returns
   */
  getDataPhoneInfo(data: any) {
    const { phone } = data;
    return AxiosInstance('').get(
      `${apiPrefix}${versionOnePrefix}/validate-phone?${paramsSerializer({
        phone,
      })}`,
    );
  },

  /**
   * Отправка всех полей анкеты для регистрации
   *
   * @returns
   */
  postDataRegistration(data: any) {
    return AxiosInstance('').post(
      `${apiPrefix}${versionOnePrefix}/registration/register`,
      {
        ...data,
      },
    );
  },

  /**
   * Отправка всех полей анкеты для регистрации
   *
   * @returns
   */
  postDataRegistrationNew(data: any) {
    return AxiosInstance('').post(
      `${apiPrefix}${versionOnePrefix}/reg/register`,
      {
        ...data,
      },
    );
  },

  postDataRegistrationSocial(data: any) {
    return AxiosInstance('').post(
      `${apiPrefix}${versionOnePrefix}/auth/third-party-v2/register`,
      {
        ...data,
      },
    );
  },
};
