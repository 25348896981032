import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * Init API
 */
export default {
  /**
   * Получить данные о версии
   *
   * @returns
   */
  getVersion(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(`${apiPrefix}version`);
  },

  /**
   * Получить данные о настройках сайта
   *
   * @returns
   */
  getSiteSettings(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/site-settings`,
    );
  },
};
