export const THINSP = '\u0020';

export const DFLT_PLACEHOLDER = '—';
export const BROWSER_SAFARI = 'Safari';

export const DEVICE_MOBILE_WIDTH_370 = 370;
export const DEVICE_MOBILE_WIDTH_420 = 420;
export const DEVICE_MOBILE_WIDTH_480 = 480;
export const DEVICE_MOBILE_WIDTH_600 = 600;
export const DEVICE_MOBILE_WIDTH_768 = 768;
export const DEVICE_MOBILE_WIDTH = 768;
export const DEVICE_TABLET_WIDTH = 992;
export const DEVICE_NETBOOKS_WIDTH = 1200;

export const LEFT_QUOTE = '«';
export const RIGHT_QUOTE = '»';

export const EXCHANGE_RATE = 'USD;74,2506;−0,0023';

export const LOCAL_STORAGE_AUTH_KEY = 'auth_token';
export const LOCAL_STORAGE_CLICK_ID = 'clickID';
export const LOCAL_STORAGE_CLICK_ID_VALID = 'clickIDValid';
export const LOCAL_STORAGE_CLICK_TYPE = 'clickType';
export const LOCAL_STORAGE_CLICK_TYPE_VALID = 'clickTypeValid';
export const LOCAL_STORAGE_CLICK_PRODUCT_TYPE = 'clickProductType';
export const LOCAL_STORAGE_CLICK_PRODUCT_TYPE_VALID = 'clickProductTypeValid';
export const LOCAL_STORAGE_CLICK_SUM = 'clickSum';
export const LOCAL_STORAGE_CLICK_SUM_VALID = 'clickSumValid';
export const LOCAL_STORAGE_CLICK_TERM = 'clickTerm';
export const LOCAL_STORAGE_CLICK_TERM_VALID = 'clickTermValid';
export const LOCAL_STORAGE_CLICK_CITY = 'clickCity';
export const LOCAL_STORAGE_CLICK_CITY_VALID = 'clickCityValid';
export const LOCAL_STORAGE_WMID = 'wmid';
export const LOCAL_STORAGE_WMID_VALID = 'wmidValid';
export const LOCAL_STORAGE_AFF_ID = 'affID';
export const LOCAL_STORAGE_AFF_ID_VALID = 'affIDValid';
export const LOCAL_STORAGE_REFERER = 'referer';
export const LOCAL_STORAGE_REFERER_VALID = 'refererValid';
export const LOCAL_STORAGE_YA_CLIENT_ID = 'yaClientID';
export const LOCAL_STORAGE_YA_CLIENT_ID_VALID = 'yaClientIDValid';
export const LOCAL_STORAGE_GOOGLE_CLIENT_ID = 'googleClientID';
export const LOCAL_STORAGE_GOOGLE_CLIENT_ID_VALID = 'googleClientIDValid';
export const LOCAL_STORAGE_EMAIL_CONFIRMATION = 'emailConfirmation';
export const LOCAL_STORAGE_ACCESS_RECOVERY_CONFIRMATION =
  'accessRecoveryConfirmation';

// NB: Старые минимальные значения, под которые был дизайн макет = 375
export const LAYOUT_MINIMUM_ALLOWABLE_WIDTH = 320;
export const LAYOUT_MAXIMUM_ALLOWABLE_WIDTH = 1580;

export const CAROUCEL_MOVE_THRESHOLD = 0.07;

// NB: Захардкоженные в админке типы масок
// ISF-179: Подробности в таске https://www.wrike.com/open.htm?id=604730578
export const PASSPORT_MASK_TYPE = 1;
export const PHONE_MASK_TYPE = 2;
export const PHONE_CUSTOM_MASK_TYPE = 3;

export const DFLT_DATE_PLUG = '1900-01-01T00:00:01.000Z';

export const COME_FROM_COMMON = 'COME_FROM_COMMON';
export const COME_FROM_LAWYER = 'COME_FROM_LAWYER';
