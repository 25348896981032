import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const MASKS = 'MASKS';

export const masksRequest = createAction(`${MASKS}/REQUEST`);
export const masksSuccess = createAction(`${MASKS}/SUCCESS`);
export const masksFailure = createAction(`${MASKS}/FAILURE`);

export const masksResult = createAction(`${MASKS}/RESULT`);

// Reducers ==========================

const isMasksFetching = createReducer(false, {
  [masksRequest.toString()]: () => true,
  [masksSuccess.toString()]: () => false,
  [masksFailure.toString()]: () => false,
});

const result = createReducer(
  {},
  {
    [masksResult.toString()]: (_state, action) => action.payload,
  },
);

const isMasksAlreadySet = createReducer(false, {
  [masksResult.toString()]: () => false,
  [masksSuccess.toString()]: () => true,
});

const list = createReducer(
  {},
  {
    [masksSuccess.toString()]: (_state, action) => action.payload,
    [masksFailure.toString()]: () => ({}),
  },
);

// Root Reducer
const reducer = combineReducers({
  isMasksFetching,
  result,
  isMasksAlreadySet,
  list,
});

export default reducer;
