import AxiosInstance from '../../../core/api/axiosinstance';
import { IData } from '../../../states/Products/state/IProducts';
import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

export interface IResponseFetchFields {
  status: number;
  data: IData,
  init?: string;
}

/**
 * Products API
 */
export default {
  /**
   * Получить данные формы для формирования заявки
   * на продукт, через "API шлюзов"
   *
   * @returns
   */
  getData(data: any) {
    const { token, offerID } = data;

    return AxiosInstance(token).get<IResponseFetchFields>(
      `${apiPrefix}${versionOnePrefix}/credit-products/request-offer/${offerID}`,
    );
  },

  /**
   * Отправить данные формы для формирования заявки
   * на продукт, через "API шлюзов"
   *
   * @param {*} data
   * @returns
   */
  postData(data: any) {
    const { token, offerID } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/credit-products/request-offer/${offerID}`,
      data,
    );
  },

  /**
   * Получить данные организации
   *
   * @returns
   */
  getDataOrganization(data: any) {
    const { token, ID } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/credit-products/banks/${ID}`,
    );
  },
};
