/* eslint-disable no-restricted-syntax */
export const toCamelCase = (s: string) =>
  s.replace(/[_ -](.)/g, i => i.slice(1).toUpperCase());

export const objectToCamelCase = (object: Record<string, any>) => {
  const result: Record<string, any> = {};
  for (const key in object) {
    if (Array.isArray(object[key])) {
      result[toCamelCase(key)] =
        object[key].map((item: Record<string, any> | string | number) =>
          typeof item === 'object'
            ? objectToCamelCase(item)
            : item,
        );
    } else if (typeof object[key] === 'object') {
      result[toCamelCase(key)] = objectToCamelCase(object[key]);
    } else {
      result[toCamelCase(key)] = object[key];
    }
  }
  return result as any;
};

export const arrayWithObjectToCamelCase = (array: Record<string, any>[]) =>
  array.map((obj) => objectToCamelCase(obj));

export const toSnakeCase = (s: string) =>
  s.replace(/[A-Z]/g, i => `_${i.toLowerCase()}`);

export const objectToSnakeCase = (object: Record<string, any>) => {
  const result: Record<string, any> = {};
  for (const key in object) {
    if (object[key] instanceof Object) {
      result[toSnakeCase(key)] = objectToSnakeCase(object[key]);
    } else {
      result[toSnakeCase(key)] = object[key];
    }
  }
  return result;
};
