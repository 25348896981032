import { createStore } from 'effector';
import { arrayWithObjectToCamelCase } from '@finanso/react-uikit';

import { $getSubscribe } from './event';
import { APIError } from '../../core/api/Result';
import { GetSubscribeRes } from '../../services/UserSubscription/api/api';

export const $subscribe = createStore<GetSubscribeRes>([])
  .on($getSubscribe.done, (_, { result }) => arrayWithObjectToCamelCase(result.data));
export const $subscribeError = createStore<Partial<APIError>>({})
  .on($getSubscribe.fail, (_, { error }) => error.errors);
export const $subscribeLoading = createStore(true)
  .on($getSubscribe.pending, (_, pending) => pending);
