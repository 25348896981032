import { lazy } from 'react';

import withSuspense from '../../../hooks/withSuspense';
import MultiLoginMfoSkeleton from './MultiLoginMfo.skeleton';

const MultiLoginMfo = lazy(() => import('./MultiLoginMfo'));

const MultiLoginMfoLoadable = withSuspense(MultiLoginMfo, MultiLoginMfoSkeleton);

export default MultiLoginMfoLoadable;
