import React from 'react';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { Button, Row, Col } from 'antd';
import { mapKeys } from 'lodash';

import Input from '../../componets/Input';
import FormWrapper from '../../componets/FormWrapper';

import {
  isFetchingPut as isFetchingPutSelector,
  dataPut as dataPutSelector,
} from '../../../states/UserSettingsChangePswd/state/selectors';
import {
  userSettingsChangePswdPutRequest,
  userSettingsChangePswdClearForm,
} from '../../../states/UserSettingsChangePswd/state/duck';

import styles from './UserSettingsChangePswdForm.module.scss';

interface IFormInputs {
  current: string;
  new: string;
  confirm: string;
}

/**
 * Форма сменить пароль
 *
 * @returns
 */
const UserSettingsChangePswdForm: React.FC = () => {
  const dataPut = useSelector(dataPutSelector);
  const isFetchingPut = useSelector(isFetchingPutSelector);
  const dispatch = useDispatch();

  // cdM
  React.useEffect(() => {
    dispatch({
      type: userSettingsChangePswdClearForm.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm<IFormInputs>({ mode: 'all' });
  const {
    handleSubmit,
    control,
    getValues,
    formState,
    setError,
    reset,
  } = methods;
  const { errors } = formState;
  const { isValid } = useFormState({ control });

  const onSubmit = (data: IFormInputs) =>
    dispatch({
      type: userSettingsChangePswdPutRequest.toString(),
      payload: data,
    });

  // eslint-disable-next-line camelcase
  const isValidationUndefined = typeof dataPut?.form?.is_valid === 'undefined';

  // cdU
  React.useEffect(() => {
    if (!isValidationUndefined) {
      const { form } = dataPut;
      const { is_valid: isFormValid, fields } = form;

      if (isFormValid) {
        reset({
          current: '',
          new: '',
          confirm: '',
        });

        return undefined;
      }

      mapKeys(fields, (val: any, key: any) => {
        const { is_valid: isFieldValid, error_messages: errMsgs } = val;

        if (!isFieldValid) {
          setError(key, {
            type: 'manual',
            message: errMsgs[0],
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPut]);

  return (
    <>
      <Translate>
        {({ translate }) => (
          <FormWrapper
            name={`${translate(
              'shared_containers_usersettingsprofile_yourprofile_edit_password',
            ).toString()}`}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row gutter={15}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className={styles.fieldWrapper}
                >
                  <Controller
                    name="current"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'error_field_required' }}
                    render={props => (
                      <Input
                        type="password"
                        isError={errors?.current ? true : false}
                        isGood={
                          !errors?.current && getValues('current') !== ''
                            ? true
                            : false
                        }
                        label={`${translate(
                          'shared_containers_usersettingsprofile_yourprofile_edit_password_current',
                        ).toString()}`}
                        placeholder={`${translate(
                          'shared_containers_usersettingsprofile_yourprofile_edit_password_current_placeholder',
                        ).toString()}`}
                        isSuffix={false}
                        suffix=""
                        value={props.field.value}
                        onChange={(e: any) =>
                          props.field.onChange(e.currentTarget.value)
                        }
                      />
                    )}
                  />

                  <div className={styles.errorMessage}>
                    {errors?.current?.message && (
                      <Translate id={`${errors?.current?.message}`} />
                    )}
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className={styles.fieldWrapper}
                >
                  <Controller
                    name="new"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'error_field_required',
                      minLength: {
                        value: 6,
                        message: 'error_field_min_length_is_6',
                      },
                      maxLength: {
                        value: 50,
                        message: 'error_field_max_length_is_50',
                      },
                      pattern: {
                        value: /^(?=.*[A-Z])(?=.*[!_@#$%^&()+-=;*])(?=.*[0-9])(?=.*[a-z]).[A-Za-z0-9!_@#$%^&()+-=;*]+$/,
                        message: 'rhf_error_message_password_pattern',
                      },
                    }}
                    render={props => (
                      <Input
                        type="password"
                        isError={errors?.new ? true : false}
                        isGood={
                          !errors?.new && getValues('new') !== '' ? true : false
                        }
                        label={`${translate(
                          'shared_containers_usersettingsprofile_yourprofile_edit_password_new',
                        ).toString()}`}
                        placeholder={`${translate(
                          'shared_containers_usersettingsprofile_yourprofile_edit_password_new_placeholder',
                        ).toString()}`}
                        isSuffix={false}
                        suffix=""
                        value={props.field.value}
                        onChange={(e: any) =>
                          props.field.onChange(e.currentTarget.value)
                        }
                      />
                    )}
                  />

                  <div className={styles.errorMessage}>
                    {errors?.new?.message && (
                      <Translate id={`${errors?.new?.message}`} />
                    )}
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className={styles.fieldWrapper}
                >
                  <Controller
                    name="confirm"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'error_field_required',
                      minLength: {
                        value: 6,
                        message: 'error_field_min_length_is_6',
                      },
                      maxLength: {
                        value: 50,
                        message: 'error_field_max_length_is_50',
                      },
                      validate: (value: string) =>
                        value === getValues('new') ||
                        'error_field_doesnt_match_for_another',
                      pattern: {
                        value: /^(?=.*[A-Z])(?=.*[!_@#$%^&()+-=;*])(?=.*[0-9])(?=.*[a-z]).[A-Za-z0-9!_@#$%^&()+-=;*]+$/,
                        message: 'rhf_error_message_password_pattern',
                      },
                    }}
                    render={props => (
                      <Input
                        type="password"
                        isError={errors?.confirm ? true : false}
                        isGood={
                          !errors?.confirm && getValues('confirm') !== ''
                            ? true
                            : false
                        }
                        label={`${translate(
                          'shared_containers_usersettingsprofile_yourprofile_edit_password_retype',
                        ).toString()}`}
                        placeholder={`${translate(
                          'shared_containers_usersettingsprofile_yourprofile_edit_password_retype_placeholder',
                        ).toString()}`}
                        isSuffix={false}
                        suffix=""
                        value={props.field.value}
                        onChange={(e: any) =>
                          props.field.onChange(e.currentTarget.value)
                        }
                      />
                    )}
                  />

                  <div className={styles.errorMessage}>
                    {errors?.confirm?.message && (
                      <Translate id={`${errors?.confirm?.message}`} />
                    )}
                  </div>
                </Col>
              </Row>

              <div className={styles.formFooter}>
                <Button
                  type="primary"
                  shape="round"
                  style={{
                    height: '48px',
                  }}
                  disabled={!isValid}
                  onClick={handleSubmit(onSubmit)}
                  loading={isFetchingPut}
                >
                  <Translate id="shared_containers_usersettingsprofile_yourprofile_edit_save" />
                </Button>
              </div>
            </form>
          </FormWrapper>
        )}
      </Translate>
    </>
  );
};

export default UserSettingsChangePswdForm;
