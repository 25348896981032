import { put, takeLatest, call, takeEvery, select } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';
import { get, uniqBy } from 'lodash';

import { userSettingsLogsFormAPI } from '../../../../services/UserSettingsLogsForm/api';

import {
  userSettingsLogsFormRequest,
  userSettingsLogsFormResult,
  userSettingsLogsFormSuccess,
  userSettingsLogsFormFailure,
  userSettingsLogsFormScrollingRequest,
  userSettingsLogsFormScrollingResult,
} from '../duck';
import { logList } from '../selectors';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';

export function* getData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const srvRes = yield call(userSettingsLogsFormAPI.getDataLog, {
    ...completePayload,
  });

  yield put({
    type: userSettingsLogsFormResult.toString(),
    payload: createResultV2(srvRes),
  });
} // getData =========

// TODO:  Реализовать проверку контракта! Формы
export function* checkResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { headers } = successData;

    const dataRes = get(successData, 'data', []) || [];
    const data = Array.isArray(dataRes) ? dataRes : [];
    const totalPages = Number(headers['x-pagination-page-count']) || 1;
    const currentPage = Number(headers['x-pagination-current-page']) || 1;

    const payloadTmp = {
      data: [...data],
      totalPages,
      currentPage,
    };

    yield put({
      type: userSettingsLogsFormSuccess.toString(),
      payload: payloadTmp,
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: userSettingsLogsFormFailure.toString(),
    });
  }
} // checkResult =========

export function* getDataScrolling(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const srvRes = yield call(userSettingsLogsFormAPI.getDataLog, {
    ...completePayload,
  });

  yield put({
    type: userSettingsLogsFormScrollingResult.toString(),
    payload: createResultV2(srvRes),
  });
} // getDataScrolling =========

// TODO:  Реализовать проверку контракта! Формы
export function* checkResultScrolling(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const resivedLogs: any = yield select(logList);
    const { right: successData } = payload;
    const { headers, data } = successData;
    const totalPages = Number(headers['x-pagination-page-count']) || 1;
    const currentPage = Number(headers['x-pagination-current-page']) || 1;
    const payloadTmp = {
      data: uniqBy([...resivedLogs, ...data], 'id'),
      totalPages,
      currentPage,
    };

    yield put({
      type: userSettingsLogsFormSuccess.toString(),
      payload: payloadTmp,
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: userSettingsLogsFormFailure.toString(),
      payload: error,
    });
  }
} // checkResultScrolling =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    userSettingsLogsFormRequest,
    safe(onError, getData, {
      terminator: userSettingsLogsFormFailure,
    }),
  );

  yield takeEvery(userSettingsLogsFormResult, safe(onError, checkResult));

  yield takeLatest(
    userSettingsLogsFormScrollingRequest,
    safe(onError, getDataScrolling, {
      terminator: userSettingsLogsFormFailure,
    }),
  );

  yield takeEvery(
    userSettingsLogsFormScrollingResult,
    safe(onError, checkResultScrolling),
  );
}
