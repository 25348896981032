import AxiosInstance from '../../../core/api/axiosinstance';
import { Form } from '../../../core/api/Result';
import promiseToResultHelper from '../../../shared/utils/promiseToResult.helper';
import { objectToSnakeCase } from '../../../shared/utils/object.helper';

export enum MfoSortType {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  COMPANY = '',
}
export enum MfoStatus {
  PROCESS = 'IN_PROCESS',
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR',
  NONE = 'NONE',
}
export enum LoanType {
  INTERNAL = 'INTERNAL',
  REFERRAL = 'REFERRAL',
}
export enum LoanStatus {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  DECLINED = 'DECLINED',
  COMPLETED = 'COMPLETED',
}

export interface Mfo {
  id: number
  name: string
  url: string
  inn: string
  ogrn: string
  logoImage: string
  backgroundImage: string
  isMain: number
  description: string
  legalName: string
  createNewLoanUrl: string
}
export interface Loan {
  id: number
  status: LoanStatus
  sum: number
  percent: number
  fromDate: string
  deadlineDate: string
  sumToReturnToday: string
  loanDays: string
  type: LoanType
  closeLoanUrl: string
  cancelLoanUrl: string
}
export interface CabinetMfo {
  id: number
  mfo: Mfo
  status: MfoStatus
  loans: Loan[] | null
}

export type GetMfoAllRes = Mfo[]
const getMfoAll = () => {
  return promiseToResultHelper(AxiosInstance().get<GetMfoAllRes>(
    '/v1/mfo/mfos',
  ));
};

export interface GetMfoReq {
  id: string | number
}
export type GetMfoRes = Mfo
const getMfo = (data: GetMfoReq) => {
  const { id } = data;
  return promiseToResultHelper(AxiosInstance().get<GetMfoRes>(
    `/v1/mfo/mfos/${id}`,
  ));
};

export interface PostAddMfoReq {
  cabinetId: number
  login: string
  password: string
}
export interface PostAddMfoRes {
  form: Form
}
const postAddMfo = (data: PostAddMfoReq) => {
  const body = objectToSnakeCase(data);
  return promiseToResultHelper(AxiosInstance().post<PostAddMfoRes>(
    '/v1/mfo/cabinets',
    body,
  ));
};

export interface GetCabinetsMfoReq {
  filter?: string
}
export type GetCabinetsMfoRes = CabinetMfo[]
const getCabinetsMfo = (data: GetCabinetsMfoReq) => {
  const { filter } = data;
  return promiseToResultHelper(AxiosInstance().get<GetCabinetsMfoRes>(
    `/v1/mfo/cabinets?${!filter ? '' : `filter[loans][status]=${filter}`}`,
  ));
};

export interface DeleteMfoReq {
  id?: string | number
}
export interface DeleteMfoRes {
  status: boolean
  message: string
}
const deleteMfo = (data: DeleteMfoReq) => {
  const { id } = objectToSnakeCase(data);
  return promiseToResultHelper(AxiosInstance().delete<DeleteMfoRes>(
    `/v1/mfo/cabinets/${id}`,
  ));
};

export interface GetTotalMfoRes {
  count: number
  active: number
  inProcess: number
}
const getTotalMfo = () => {
  return promiseToResultHelper(AxiosInstance().get<GetTotalMfoRes>(
    '/v1/mfo/cabinets/total',
  ));
};

export const MultiloginAPI = {
  getMfoAll,
  getMfo,
  postAddMfo,
  getCabinetsMfo,
  deleteMfo,
  getTotalMfo,
};
