/* eslint-disable @typescript-eslint/no-unused-vars */
import { takeEvery, put, cancel, select, call } from 'redux-saga/effects';
import has from 'lodash/has';
import tail from 'lodash/tail';
import take from 'lodash/take';
import get from 'lodash/get';
import { isLeft, isRight } from 'fp-ts/lib/Either';

import {
  modalClearInfo,
  modalPutErrorInfo,
  modalPutActiveInfo,
  modalPutQueueInfo,
  modalUpdateQueueInfo,
  modalRemoveActiveInfo,
  modalPutErrorFromQueueInfo,
  modalFields,
} from '../duck';
import { logoutRequest } from '../../../Logout/state/duck';
import { modalActive, modalQueue } from '../selectors';

// NB: Для этих экшенов устанавливаются отдельные правила оповещения
import { userSettingsChangePswdPutResult } from '../../../UserSettingsChangePswd/state/duck';

import { isFormValid as isFormValidSelector } from '../../../UserSettingsChangePswd/state/selectors';

// Объект экшенов, которые нужно фильтровать, как полезные
import { resultsActions } from './constants';

import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';

import { removeData } from '../../../../shared/utils/commonLocalStorage.helper';
import { LOCAL_STORAGE_EMAIL_CONFIRMATION } from '../../../../shared/constants/Defaults/constants';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';
import { chatListLawyerSendMsgFailure } from '../../../ChatListLawyer/state/duck';
import { paymentRatingUpdateRequests } from '../../../Payment/state/duck';
import PayModal from '../../../../shared/componets/Modal/PayModal';
import { creditRatingUpdate } from '../../../CreditRating/state/duck';
import { userDebtsUpdateRequest, userDebtsUpdateResult } from '../../../UserDebts/state/duck';
import { userTaskInstructionsApiOffer } from '../../../UserTask/state/duck';
import {
  userLimitsRating,
  userLimitsRequest,
  userLimitsDebt,
  userLimitsLawyer,
} from '../../../UserLimits/state/duck';

const MAX_SHOWED_TOASTERS = 3;

interface IErrorContract {
  error?: any;
  msg?: string;
  title?: string;
  supportInfo?: string;
  msgType?: string;
  ID?: number;
  duration?: number;
  type?: string;
}

/**
 * Вспомогательная функция
 * Удаляет дубли из очереди сообщений с ошибками
 *
 * @param {IErrorContract[]} arr
 * @returns
 */
const removeDoubles = (arr: IErrorContract[]) => {
  if (arr.length === 0) {
    return [];
  }

  const firstArrItem = arr[0];
  const { duration, msg, msgType, supportInfo, title } = firstArrItem;

  const result = [
    { ...firstArrItem },
    ...arr.filter((item: IErrorContract) => {
      const {
        duration: durationFltr,
        msg: msgFltr,
        msgType: msgTypeFltr,
        supportInfo: supportInfoFltr,
        title: titleFltr,
      } = item;

      if (
        duration === durationFltr &&
        msg === msgFltr &&
        msgType === msgTypeFltr &&
        supportInfo === supportInfoFltr &&
        title === titleFltr
      ) {
        return false;
      }

      return true;
    }),
  ];

  return result;
};

/**
 * Сага modalObserver пропускает через себя все экшены приложения
 * фильтрует только экшены в которых записывается результат возвращаемый из АПИ
 * Проверяет результат на наличие ошибок, логирует этот результат
 * и отображает уведомление пользователю
 *
 * NB: Отсюда ничего отправлять в сентри не нужно!
 *
 * @export
 * @param {*} action
 * @returns
 */
export function* modalObserver(action: IResultEiterAction) {
  const { type, payload } = action;

  const commonData: IErrorContract = {
    ID: Date.now(),
  };
  switch (type) {
    case userTaskInstructionsApiOffer.toString():
      yield put({
        type: modalPutErrorInfo.toString(),
        payload: {
          ...commonData,
          type: payload,
          mod: 'apiOffer',
        },
      });
      return;
    case modalFields.toString():
      yield put({
        type: modalPutErrorInfo.toString(),
        payload: {
          ...commonData,
          mod: 'fields',
          payload,
        },
      });
      return;
    case userLimitsRating.toString():
      yield put({
        type: modalPutErrorInfo.toString(),
        payload: {
          ...commonData,
          mod: 'payRequest',
          type: 'kreditnyj_skoring',
          tag: 'rating',
          callbackType: userLimitsRequest.toString(),
        },
      });
      return;
    case userLimitsDebt.toString():
      yield put({
        type: modalPutErrorInfo.toString(),
        payload: {
          ...commonData,
          mod: 'payRequest',
          type: 'zapros_dolgov',
          tag: 'debt',
          callbackType: userLimitsRequest.toString(),
        },
      });
      return;
    case userLimitsLawyer.toString():
      yield put({
        type: modalPutErrorInfo.toString(),
        payload: {
          ...commonData,
          mod: 'payRequest',
          type: 'obrasenie_k_uristu',
          tag: 'lawyer',
          callbackType: userLimitsRequest.toString(),
        },
      });
      return;
    default:
      break;
  }

  if (has(resultsActions, type)) {
    // NB: Любой экшен c результатами чистит сообшение об ошибке
    yield put({ type: modalClearInfo.toString() });

    // NB: И только экшен с ошибкой формирует сообшение об ошибке
    if (isLeft(payload)) {
      const { left: info } = payload;
      const { status, data } = info;
      const { form, message: errorData } = data;
      const { error: errorFrom } = form || {};

      // Поля `msg` и `title` это метки для переводов
      const commonData: IErrorContract = {
        ID: Date.now(),
      };

      if (status === 401) {
        yield put({ type: logoutRequest.toString() });
        yield cancel();
      }

      const error = errorData ? errorData : errorFrom;
      const isUserRequiredField = error === 'error_user_has_not_required_field';

      // NB: правила оповещения для эешенов
      switch (type) {
        case paymentRatingUpdateRequests.toString():
          yield put({
            type: modalPutErrorInfo.toString(),
            payload: {
              ...commonData,
              mod: isUserRequiredField ? 'userRequiredField' : 'payRequest',
              type: 'kreditnyj_skoring',
              tag: 'rating',
              callbackType: creditRatingUpdate.toString(),
            },
          });
          break;
        case userDebtsUpdateResult.toString():
          yield put({
            type: modalPutErrorInfo.toString(),
            payload: {
              ...commonData,
              mod: isUserRequiredField ? 'userRequiredField' : 'payRequest',
              type: 'zapros_dolgov',
              tag: 'debt',
              callbackType: userDebtsUpdateRequest.toString(),
            },
          });
          break;

        default:
          yield put({
            type: modalPutErrorInfo.toString(),
            payload: {
              ...commonData,
            },
          });
          break;
      }
    }
  }
}

/**
 * Создать очередь из сообщений
 *
 * @export
 * @param {*} action
 */
export function* createQueue(action: any) {
  const { payload: errorData } = action;
  const queue = yield select(modalQueue);
  const queueTmp = removeDoubles([...queue, { ...errorData }]);

  yield put({ type: modalPutQueueInfo.toString(), payload: queueTmp });
}

/**
 * Вывести на экран сообщение из очереди сообщений
 *
 * @export
 */
export function* showFromQueue() {
  const active = yield select(modalActive);
  const queue = yield select(modalQueue);

  let activeTmp = [];
  let queueHead = take(queue);
  let queueTail = tail(queue);

  if (active.length < MAX_SHOWED_TOASTERS) {
    activeTmp = removeDoubles([...active, ...queueHead]);

    yield put({ type: modalPutActiveInfo.toString(), payload: activeTmp });
    yield put({
      type: modalUpdateQueueInfo.toString(),
      payload: [...queueTail],
    });
  }
}

/**
 * Удалить сообщение из очереди и убрать с экрана
 *
 * @export
 * @param {*} action
 */
export function* removeFromActive(action: any) {
  const { payload } = action;
  const { modalID } = payload;
  const active = yield select(modalActive);
  const queue = yield select(modalQueue);

  let queueHead = take(queue);
  let queueTail = tail(queue);
  let activeTmp = [...active].filter((item: any) => item.ID !== modalID);

  if (activeTmp.length < MAX_SHOWED_TOASTERS) {
    activeTmp = [...activeTmp, ...queueHead];
  }

  yield put({ type: modalPutActiveInfo.toString(), payload: activeTmp });
  yield put({
    type: modalUpdateQueueInfo.toString(),
    payload: [...queueTail],
  });
  yield put({
    type: modalPutErrorFromQueueInfo.toString(),
    payload: get(queueHead, '[0]', {}) || {},
  });
}

// Root Saga
export default function* rootSaga() {
  yield takeEvery('*', modalObserver);
  yield takeEvery(modalPutErrorInfo, safe(onError, createQueue));
  yield takeEvery(modalPutQueueInfo, safe(onError, showFromQueue));
  yield takeEvery(modalRemoveActiveInfo, safe(onError, removeFromActive));
}
