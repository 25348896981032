/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, NavLink, useLocation } from 'react-router-dom';

import stylesModal from './OfferModal.module.scss';
import stylesAuth from '../../../views/Auth/AuthController.module.scss';
import Header from '../../../views/Auth/components/Header';
import Description from '../../../views/Auth/components/Description/Description';
import { offerFormClearForm, offerFormPostRequest, offerFormRequest } from '../../../states/OfferForm/state/duck';
import {
  isFetching as isFetchingDataSelector,
  isFetchingPost as isFetchingPostSelector,
  formFieldsSteps as formFieldsStepsSelector,
  formFieldsStepsPost as formFieldsStepsPostSelector,
  formFieldsPost as formFieldsPostSelector,
  formGet as formGetSelector,
  dataError as dataErrorSelector,
  dataPostError as dataPostErrorSelector,
} from '../../../states/OfferForm/state/selectors';
import Preloader from '../Preloader';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import InputUniversal from './components/InputUniversal';
import { Button } from 'antd';

import arrowBackImg from '../../../../assets/images/arrowBack.svg';
import protectionImg from '../../../../assets/images/protection.svg';

import { mapKeys } from 'lodash';
import { Translate } from 'react-localize-redux';
import { parsRulesForm } from '../../utils/parsRulesForm.helper';

const OfferModal = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const isFetchingData = useSelector(isFetchingDataSelector);
  const isFetchingPost = useSelector(isFetchingPostSelector);
  const formFieldsSteps = useSelector(formFieldsStepsSelector);
  const formFieldsStepsPost = useSelector(formFieldsStepsPostSelector);
  const formFieldsPost = useSelector(formFieldsPostSelector);
  const formGet = useSelector(formGetSelector);
  const dataError = useSelector(dataErrorSelector);
  const dataPostError = useSelector(dataPostErrorSelector);

  const refBody = useRef() as any;

  const [offerId, setOfferId] = useState<string | number | null>(0);

  const methods = useForm<Record<string, string | number>>({
    mode: 'all',
    defaultValues: {
      step: 1,
    },
  });
  const {
    watch,
    control,
    getValues,
    setValue,
    setError,
  } = methods;

  const step = Number(watch('step'));

  useEffect(() => {
    if (offerId) {
      dispatch({ type: offerFormRequest.toString(), payload: { offerId } });
      document.body.style.overflow = 'hidden';
    } else {
      dispatch({ type: offerFormClearForm.toString() });
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
      dispatch({ type: offerFormClearForm.toString() });
      history.replace({
        pathname: history.location.pathname,
        search: history.location.search,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const offerId = query.get('id');
    setOfferId(offerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, location.search]);

  const scrollTop = () => {
    setTimeout(() => refBody.current?.scrollTo(0, 0), 0);
  };

  const handlerNext = () => {
    setValue('step', step + 1);
    scrollTop();
  };

  const handlePrev = () => {
    setValue('step', step - 1);
    scrollTop();
  };

  const submit = () => {
    dispatch({
      type: offerFormPostRequest.toString(),
      payload: { ...getValues(), offerId },
    });
  };

  // cdU
  useEffect(() => {
    const fields = formFieldsStepsPost[step - 1];

    if (fields) {
      let isValidStep = true;

      mapKeys(fields, (val: any) => {
        const { is_valid: isFieldValid, error_messages: errMsgs, name } = val;

        if (!isFieldValid) {
          setError(name, {
            type: 'manual',
            message: errMsgs[0],
          });
          isValidStep = false;
        }
      });

      if (isValidStep) {
        handlerNext();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldsStepsPost]);

  useEffect(() => {
    if ((!dataPostError.status && dataPostError.message) || formFieldsPost.isValid) {
      setValue('step', 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldsPost, dataPostError]);

  const handlerBlur = (e: React.FocusEvent<HTMLInputElement, Element>): void => {
    const { name } = e.target;
    const value = getValues(name);
    const field = formGet.fields[name];

    parsRulesForm({ field, value, name, setError });
  };

  const isStart = formFieldsSteps.length >= step;
  const isFinish = formFieldsPost.isValid;
  const isFailed = !!(!dataPostError.status && dataPostError.message);
  const isError = (!!(!dataError.status && dataError.message) || formFieldsPost.error) && !isStart;

  return (
    <div
      className={cx(
        stylesModal.offerModal,
        offerId && stylesModal.offerModalOpen,
      )}
      ref={refBody}
    >

      <Header />

      <div className={stylesAuth.wrapper}>
        <div className={stylesAuth.wrapperColWhite} />
        <div className={stylesAuth.wrapperColGrey} />

        <div className={stylesAuth.main}>
          <div className={stylesAuth.mainInner}>

            {isFetchingData ?
              <Preloader size="large" />
              :
              <div className={cx(stylesAuth.mainRegistration, stylesAuth.registration)}>
                <div className={stylesModal.title}>
                  {/* Отображение стрелки назад, при том условии,
                  что шаг находится в приделах формы и шаг не первый */}
                  {step > 1 && formFieldsSteps.length >= step &&
                    <img
                      tabIndex={0}
                      role="button"
                      src={arrowBackImg}
                      alt="<"
                      onClick={handlePrev}
                      onKeyPress={handlePrev}
                    />
                  }
                  {/* Отображение заголовка */}
                  {isStart &&
                  <Translate id={formGet.titleMark} />
                  }
                  {isFinish &&
                  <Translate id={formGet.finishTitleMark} />
                  }
                  {isFailed &&
                    <Translate id={dataPostError.message} />
                  }
                  {isError &&
                  <Translate id={formFieldsPost.error} />
                  }
                </div>

                <div className={stylesModal.description}>
                  {isStart &&
                  <Translate id={formGet.descriptionMark} />
                  }
                  {isFinish &&
                  <Translate id={formGet.finishDescriptionMark} />
                  }
                  {isError &&
                  <Translate id={dataError.message} />
                  }
                </div>

                {isStart &&
                  <div className={stylesModal.category}>
                    <Translate id={formFieldsSteps[step - 1][0]?.category} />
                  </div>
                }
                <FormProvider {...methods}>
                  {!!(formFieldsSteps.length && isStart) &&
                  formFieldsSteps[step - 1].map((fields: Record<string, any>) =>
                    <Controller
                      key={fields?.name}
                      name={fields?.name}
                      control={control}
                      rules={{
                        required: fields?.rules?.required,
                      }}
                      defaultValue={fields?.value}
                      render={({ field, formState }) => {
                        const type: string = fields.mask && fields?.type !== 'date' ? 'mask' : fields?.handler.name || fields?.type;
                        // eslint-disable-next-line no-restricted-syntax
                        for (const el of fields.visible_conditions) {
                          const value = watch(el.target_field_name);
                          if (value !== el.target_field_value) {
                            return <></>;
                          }
                        }
                        return <InputUniversal
                          {...field}
                          placeholder={fields?.placeholder}
                          type={type}
                          label={fields?.title}
                          error={formState?.errors[field.name]?.message}
                          values={fields?.values}
                          mask={fields?.mask}
                          value={field?.value}
                          onChange={field?.onChange}
                          rules={fields?.rules}
                          onBlur={handlerBlur}
                        />;
                      }}
                    />,
                  )}
                </FormProvider>

                {isStart ?
                  <div className={stylesModal.footerButton}>
                    <Button
                      className={stylesModal.button}
                      onClick={submit}
                      loading={isFetchingPost}
                    >
                      <Translate id={step !== formFieldsSteps.length ? 'offer.btn.next' : 'offer.btn.send'} />
                    </Button>
                    <div className={stylesModal.protected}>
                      <img src={protectionImg} alt="" />
                      <Translate id="deposit.stripe.protection" />
                    </div>
                  </div>
                  :
                  <NavLink to="/cabinet/credit-rating">
                    <Button className={stylesModal.button}>
                      <Translate id="offer.btn.cabinet" />
                    </Button>
                  </NavLink>
                }
              </div>
            }

            <Description />

          </div>
        </div>
      </div>

    </div>
  );
};

export default OfferModal;
