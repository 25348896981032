import { createSelector } from 'reselect';

// Auth
export const isFetching = (store: any) => store.authReducer.isFetching;

export const data = createSelector(
  (store: any) => store.authReducer.data,
  data => data,
);

export const error = createSelector(
  (store: any) => store.authReducer.error,
  error => error,
);

export const isAuthorized = createSelector(
  (store: any) => store.authReducer.data.isAuthorized,
  isAuthorized => isAuthorized,
);

export const comeFrom = createSelector(
  (store: any) => store.authReducer.comeFrom,
  comeFrom => comeFrom,
);
