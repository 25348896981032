import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const SHOP = 'SHOP';

export const shopRequest = createAction(`${SHOP}/REQUEST`);
export const shopSuccess = createAction(`${SHOP}/SUCCESS`);
export const shopFailure = createAction(`${SHOP}/FAILURE`);

export const shopResult = createAction(`${SHOP}/RESULT`);

export const shopCancelResult = createAction(`${SHOP}/CANCEL_RESULT`);

export const shopGetPriceRequest = createAction(`${SHOP}/GET_PRICE_REQUEST`);
export const shopGetPriceSuccess = createAction(`${SHOP}/GET_PRICE_SUCCESS`);
export const shopGetPriceFailure = createAction(`${SHOP}/GET_PRICE_FAILURE`);
export const shopGetPriceResult = createAction(`${SHOP}/GET_PRICE_RESULT`);

export const shopPurchaseRequest = createAction(`${SHOP}/PURCHASE_REQUEST`);
export const shopPurchaseSuccess = createAction(`${SHOP}/PURCHASE_SUCCESS`);
export const shopPurchaseFailure = createAction(`${SHOP}/PURCHASE_FAILURE`);
export const shopPurchaseResult = createAction(`${SHOP}/PURCHASE_RESULT`);

export const shopPermissionsRequest = createAction(`${SHOP}/PERMISSIONS_REQUEST`);
export const shopPermissionsSuccess = createAction(`${SHOP}/PERMISSIONS_SUCCESS`);
export const shopPermissionsFailure = createAction(`${SHOP}/PERMISSIONS_FAILURE`);

export const shopRestrictionsRequest = createAction(`${SHOP}/RESTRICTIONS_REQUEST`);
export const shopRestrictionsSuccess = createAction(`${SHOP}/RESTRICTIONS_SUCCESS`);
export const shopRestrictionsFailure = createAction(`${SHOP}/RESTRICTIONS_FAILURE`);

export const shopRestrictionsValidationRequest = createAction(`${SHOP}/RESTRICTIONS_VALIDATION_REQUEST`);

// Reducers ==========================

const isFetching = createReducer(true, {
  [shopRequest.toString()]: () => true,
  [shopSuccess.toString()]: () => false,
  [shopFailure.toString()]: () => false,
});

const data = createReducer({}, {
  [shopRequest.toString()]: () => ({}),
  [shopSuccess.toString()]: (_state, action) => action.payload,
},
);

const isFetchingPermissions = createReducer(false, {
  [shopPermissionsRequest.toString()]: () => true,
  [shopPermissionsSuccess.toString()]: () => false,
  [shopPermissionsFailure.toString()]: () => false,
});

const permissions = createReducer([], {
  [shopPermissionsRequest.toString()]: () => ([]),
  [shopPermissionsSuccess.toString()]: (_state, action) => action.payload,
},
);

const isFetchingRestrictions = createReducer(false, {
  [shopRestrictionsRequest.toString()]: () => true,
  [shopRestrictionsSuccess.toString()]: () => false,
  [shopRestrictionsSuccess.toString()]: () => false,
});

const restrictions = createReducer({}, {
  [shopRestrictionsRequest.toString()]: () => ({}),
  [shopRestrictionsSuccess.toString()]: (_state, action) => action.payload,
},
);

const isFetchingCurrentPrice = createReducer(true, {
  [shopGetPriceRequest.toString()]: () => true,
  [shopGetPriceSuccess.toString()]: () => false,
  [shopGetPriceFailure.toString()]: () => false,
});

const currentPrice = createReducer({}, {
  [shopGetPriceRequest.toString()]: () => ({}),
  [shopGetPriceSuccess.toString()]: (_state, action) => action.payload,
  [shopPurchaseSuccess.toString()]: () => ({}),
  [shopPurchaseFailure.toString()]: () => ({}),
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  isFetchingCurrentPrice,
  currentPrice,
  isFetchingPermissions,
  permissions,
  isFetchingRestrictions,
  restrictions,
});

export default reducer;
