import React from 'react';
import cx from 'classnames';

import styles from './Balance.module.scss';
import Preloader from '../../../../Preloader';
import { Translate } from 'react-localize-redux';

interface IProps {
  title: string;
  number: number;
  type?: string;
  isFetching?: boolean;
}

const Balance: React.FC<IProps> = props => {
  const { number, type, title, isFetching } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <Translate id={title} />
      </div>
      <div className={cx(
        styles.number,
        type === 'success' && styles.success,
        type === 'danger' && styles.danger,
        !type && styles.normal,
      )}
      >
        {isFetching ? <Preloader size="small" /> : number}
      </div>
    </div>
  );
};

export default Balance;
