import React from 'react';

import styles from './NotifyDescription.module.scss';

import { HELP_SUPPORT_URL } from '../../constants/Routes/constants';

interface IProps {
  description: string;
  isAuthorized: boolean;
  supportInfo: string;
}

/**
 * Компонент с контентом (информацией) о возникшей ошибке
 *
 * @param {*} props
 * @returns
 */
const NotifyDescription: React.FC<IProps> = props => {
  const { description, isAuthorized, supportInfo } = props;

  const handleClick = React.useCallback(() => {
    return null;
  }, []);

  const handleKeyPress = React.useCallback(() => {
    return null;
  }, []);

  return (
    <>
      <span className={styles.desc}>{description}</span>{' '}
      {supportInfo !== '' && (
        <>
          {!isAuthorized ? (
            <span
              onClick={handleClick}
              onKeyPress={handleKeyPress}
              role="link"
              tabIndex={0}
              className={styles.sprtInfo}
            >
              {supportInfo}
            </span>
          ) : (
            <a href={HELP_SUPPORT_URL} className={styles.sprtInfo}>
              {supportInfo}
            </a>
          )}
        </>
      )}
    </>
  );
};

export default NotifyDescription;
