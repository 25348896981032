import { IEnv } from './env.type';

export const environment: IEnv = {
  PRODUCTION: false,
  API_PREFIX: 'https://sandbox.api.finanso.com/',
  STATIC_PREFIX: 'https://sandbox.api.finanso.com',
  API_VERSION_ONE_PREFIX: 'v1',
  SITE_PREFIX: 'https://sandbox.my.finanso.com/',
  MAIN_LANDING_URL: 'https://sandbox.my.finanso.com/',
  MAIN_LANDING_STATIC_URL: 'https://sandbox.api.finanso.com',
  WEB_SOCKET_SUFFIX: '/connection/websocket',
  RECAPTCHA_SITE_KEY: '6LfNFH4aAAAAABlUOFjyDT7Gc1CTniCV4YP3ZwOt',
  EXCHANGE_RECOMPUTE: false,
};
