import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const INIT = 'INIT';

// Получение списка
export const initRequest = createAction(`${INIT}/REQUEST`);
export const initSuccess = createAction(`${INIT}/SUCCESS`);
export const initFailure = createAction(`${INIT}/FAILURE`);

export const initVersionSuccess = createAction(`${INIT}/VERSION_SUCCESS`);

export const initPrivateRequest = createAction(`${INIT}/PRIVATE_REQUEST`);
export const initPrivateSuccess = createAction(`${INIT}/PRIVATE_SUCCESS`);
export const initPrivateFailure = createAction(`${INIT}/PRIVATE_FAILURE`);

export const initPrivateSiteSettingsRequest = createAction(
  `${INIT}/PRIVATE_SITE_SETTINGS_REQUEST`,
);
export const initPrivateSiteSettingsSuccess = createAction(
  `${INIT}/PRIVATE_SITE_SETTINGS_SUCCESS`,
);
export const initPrivateSiteSettingsFailure = createAction(
  `${INIT}/PRIVATE_SITE_SETTINGS_FAILURE`,
);

// IDEA: Пока не используется, потенциально можно отслеживать обновление версии в АПИ
// по сокетам, а потом делать скрытый от пользователя запрос за новыми данными.
// Скрытное получение списка, нужно после апдейта в админке
export const initShadowRequest = createAction(`${INIT}/SHADOW_REQUEST`);

export const initResult = createAction(`${INIT}/RESULT`);
export const initPrivateResult = createAction(`${INIT}/PRIVATE_RESULT`);

// Reducers ==========================

// Статус запроса списка
const isFetching = createReducer(false, {
  [initRequest.toString()]: () => true,
  [initSuccess.toString()]: () => false,
  [initFailure.toString()]: () => false,
});

// Статус запроса shadow списка
const isFetchingShadow = createReducer(false, {
  [initShadowRequest.toString()]: () => true,
  [initSuccess.toString()]: () => false,
  [initFailure.toString()]: () => false,
});

// Успешно получен список
const list = createReducer([], {
  [initRequest.toString()]: () => [],
  [initSuccess.toString()]: (_state, action) => action.payload,
  [initFailure.toString()]: () => [],
});

const version = createReducer(
  {},
  {
    [initRequest.toString()]: () => ({}),
    [initVersionSuccess.toString()]: (_state, action) => action.payload,
    [initFailure.toString()]: () => ({}),
  },
);

// Ошибка при получении списка
const error = createReducer([], {
  [initRequest.toString()]: () => [],
  [initSuccess.toString()]: () => [],
  [initFailure.toString()]: (_state, action) => action.payload,
});

const siteSettings = createReducer(
  {},
  {
    [initPrivateSiteSettingsSuccess.toString()]: (_state, action) =>
      action.payload,
    [initPrivateSiteSettingsFailure.toString()]: () => ({}),
  },
);

const initSuccessStatus = createReducer(false, {
  [initRequest.toString()]: () => false,
  [initSuccess.toString()]: () => true,
  [initFailure.toString()]: () => false,
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  list,
  error,
  isFetchingShadow,
  siteSettings,
  initSuccessStatus,
  version,
});

export default reducer;
