import React, { SVGAttributes } from 'react';

const Clock: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="#1373E5" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0007 30.6668C7.90047 30.6668 1.33398 24.1003 1.33398 16.0002C1.33398 7.89999 7.90047 1.3335 16.0007 1.3335C24.1008 1.3335 30.6673 7.89999 30.6673 16.0002C30.6673 24.1003 24.1008 30.6668 16.0007 30.6668ZM16.0007 28.0002C22.6281 28.0002 28.0007 22.6276 28.0007 16.0002C28.0007 9.37275 22.6281 4.00016 16.0007 4.00016C9.37323 4.00016 4.00065 9.37275 4.00065 16.0002C4.00065 22.6276 9.37323 28.0002 16.0007 28.0002ZM16.0007 6.66683C16.737 6.66683 17.334 7.26378 17.334 8.00016V15.4479L20.9435 19.0574C21.4642 19.5781 21.4642 20.4223 20.9435 20.943C20.4228 21.4637 19.5785 21.4637 19.0578 20.943L15.0578 16.943C14.8078 16.6929 14.6673 16.3538 14.6673 16.0002V8.00016C14.6673 7.26378 15.2643 6.66683 16.0007 6.66683Z"
    />
  </svg>
);

export default Clock;
