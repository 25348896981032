import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';

import { userSettingsChangeAvatarAPI } from '../../../../services/UserSettingsChangeAvatar/api';

import {
  userSettingsChangeAvatarRequest,
  userSettingsChangeAvatarResult,
  userSettingsChangeAvatarSuccess,
  userSettingsChangeAvatarFailure,
  userSettingsChangeAvatarPostRequest,
  userSettingsChangeAvatarPostSuccess,
  userSettingsChangeAvatarPostFailure,
  userSettingsChangeAvatarPostResult,
} from '../duck';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';

export function* getData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const srvRes = yield call(userSettingsChangeAvatarAPI.getData, {
    ...completePayload,
  });

  yield put({
    type: userSettingsChangeAvatarResult.toString(),
    payload: createResultV2(srvRes),
  });
} // getData =========

// TODO:  Реализовать проверку контракта! Формы
export function* checkResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: userSettingsChangeAvatarSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: userSettingsChangeAvatarFailure.toString(),
      payload: error,
    });
  }
} // checkResult =========

export function* postData(action: any) {
  const { avatar } = action.payload;
  const tempPayload: any = yield call(checkToken, {});

  const body = new FormData();
  body.append('avatar', avatar);

  const srvRes = yield call(userSettingsChangeAvatarAPI.postFormData, {
    formData: body,
    ...tempPayload,
  });

  yield put({
    type: userSettingsChangeAvatarPostResult.toString(),
    payload: createResultV2(srvRes),
  });
} // postData =========

// TODO: Добавить контракт
export function* checkResultPost(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: userSettingsChangeAvatarPostSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: userSettingsChangeAvatarPostFailure.toString(),
    });
  }
} // checkResultPost =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    userSettingsChangeAvatarRequest,
    safe(onError, getData, {
      terminator: userSettingsChangeAvatarFailure,
    }),
  );

  yield takeEvery(userSettingsChangeAvatarResult, safe(onError, checkResult));

  yield takeLatest(
    userSettingsChangeAvatarPostRequest,
    safe(onError, postData, {
      terminator: userSettingsChangeAvatarPostFailure,
    }),
  );

  yield takeEvery(
    userSettingsChangeAvatarPostResult,
    safe(onError, checkResultPost),
  );
}
