/* eslint-disable react/jsx-wrap-multilines */

import React from 'react';
import { useDispatch } from 'react-redux';
import { /* CloseOutlined */ LeftOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';
import { /* Row, Progress, Col, */ Button } from 'antd';

import { userSubscriptionRequest } from '../../../states/UserSubscription/state/duck';
import { paymentShowFormInc } from '../../../states/Payment/state/duck';
import { logoutRequest } from '../../../states/Logout/state/duck';

import { useWindowSize } from '../../hooks/useWindowSize';

import ModalWrapper from '../../componets/ModalWrapper';

import RemovingModal from '../RemovingModal';
import UserSettingsProfile from '../UserSettingsProfile';
import UserSettingsSubscription from '../UserSettingsSubscription';
import UserSettingsSettings from '../UserSettingsSettings';
import UserSettingsActivity from '../UserSettingsActivity';
import UserSettingsShopping from '../UserSettingsShopping';

import { DEVICE_MOBILE_WIDTH_480, DEVICE_NETBOOKS_WIDTH } from '../../constants/Defaults/constants';

import logoutImage from './assets/images/logout.svg';
import userImage from './assets/images/user.svg';
import closeImage from './assets/images/close_icon.svg';
import closeModalImage from './assets/images/close.svg';
import notificationImage from './assets/images/notification.svg';
import clockImage from './assets/images/clock.svg';
import bagImage from './assets/images/bag.svg';
import settingsImage from './assets/images/settings.svg';

import styles from './UserSettings.module.scss';
import { DrawerStyled, DrawerStyledMobile } from './theme';

import ErrorBoundary from '../../componets/ErrorBoundary/ErrorBoundary';

interface IProps {
  isUserSettingsOpen: boolean;
  handleShowUserSettings: (status: boolean) => void;
}

const PROFILE = 'PROFILE';
// const EDITPROFILE = 'EDITPROFILE';
const SUBSCRIPTION = 'SUBSCRIPTION';
const SETTINGS = 'SETTINGS';
const ACTIVITY = 'ACTIVITY';
const SHOPPING = 'SHOPPING';

const initState = {
  activeTab: PROFILE,
  isModalVisible: false,
  inputWorld: '',
};

/**
 * Drawer настройки пользователя
 *
 * @returns
 */

const UserSettings: React.FC<IProps> = props => {
  const { isUserSettingsOpen, handleShowUserSettings } = props;

  const [state, setState] = React.useState(initState);
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const { width = 0 } = windowSize;

  const { activeTab, isModalVisible } = state;

  const [statusProfile, setStatusProfile] = React.useState({ editHeader: false });
  const { editHeader } = statusProfile;

  const changeHeader = (value: boolean) => {
    setStatusProfile({ ...statusProfile, editHeader: value });
  };

  // По умолчанию считаем что запущено на десктопе, мобила = false
  let isMobile = false;

  if (width < DEVICE_NETBOOKS_WIDTH) {
    isMobile = true;
  }

  function handleChangeState(name: string, value: string | number | boolean) {
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  const handleClickTab = React.useCallback(event => {
    const { currentTarget } = event;
    const tabKey = currentTarget.getAttribute('data-key');
    handleChangeState('activeTab', tabKey);
  }, []);

  const handleKeyPressTab = React.useCallback(event => {
    if (event.key === 'Enter') {
      const { currentTarget } = event;
      const tabKey = currentTarget.getAttribute('data-key');

      handleChangeState('activeTab', tabKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyPressLogout = React.useCallback(event => {
    if (event.key === 'Enter') {
      dispatch({ type: logoutRequest.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalStatusChange = React.useCallback((status: boolean) => {
    if (!status) {
      dispatch({
        type: paymentShowFormInc.toString(),
      });
    }

    handleChangeState('isModalVisible', status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    dispatch({ type: userSubscriptionRequest.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let isLessThan480 = false;

  if (width < DEVICE_MOBILE_WIDTH_480) {
    isLessThan480 = true;
  }

  return (
    <>
      <ModalWrapper
        isModalVisible={isModalVisible}
        handleModalStatusChange={() => handleModalStatusChange(false)}
        width={isLessThan480 ? 313 : 450}
      >
        <ErrorBoundary>
          <RemovingModal />
        </ErrorBoundary>
      </ModalWrapper>
      {isMobile ? (
        <DrawerStyledMobile
          destroyOnClose
          closable={false}
          visible={isUserSettingsOpen}
          maskClosable={false}
          maskStyle={{
            backdropFilter: 'blur(5px)',
            backgroundColor: 'rgba(55, 64, 75, 0.85)',
          }}
          style={{ display: !isUserSettingsOpen ? 'none' : 'block' }}
        >
          <div className={styles.mobileDrawer}>
            {/* Header */}
            <div className={styles.mobileDrawerHeader}>
              {activeTab === PROFILE ? (
                ''
              ) : (
                <LeftOutlined
                  data-key={PROFILE}
                  style={{ color: '#0F1011', fontSize: '16px' }}
                  onClick={handleClickTab}
                  onKeyPress={handleKeyPressTab}
                  className={styles.mobileHeaderBackward}
                />
              )}

              <span className={styles.mobileHeaderLabel}>
                {activeTab === PROFILE && !editHeader && <Translate id="shared_containers_usersettings_myprofile" />}
                {activeTab === PROFILE && editHeader && <span>
                  <LeftOutlined
                    data-key={PROFILE}
                    style={{ color: '#0F1011', fontSize: '16px' }}
                    onClick={() => changeHeader(false)}
                    className={styles.mobileHeaderBackward}
                  />
                  <Translate id="shared_containers_usersettings_edit_myprofile" />
                </span>}
                {activeTab === SUBSCRIPTION && <span> <Translate id="shared_containers_usersettings_subscribe" /> </span>}
                {activeTab === ACTIVITY && <span> <Translate id="shared_containers_usersettings_activity" /> </span>}
                {activeTab === SHOPPING && <span> <Translate id="shared_containers_usersettings_shopping" /> </span>}
                {activeTab === SETTINGS && <span> <Translate id="shared.containers.usersettings.settings" /> </span>}
              </span>

              {activeTab === PROFILE && <Button
                onClick={() => handleShowUserSettings(false)}
                className={styles.mobileHeaderClose}
              >
                <img src={closeImage} alt="close" />
              </Button>}
            </div>

            {/* Content */}
            <div className={styles.content}>
              {activeTab === PROFILE && <UserSettingsProfile
                handleClickTab={handleClickTab}
                handleKeyPressTab={handleKeyPressTab}
                changeHeader={changeHeader}
                editHeader={editHeader}
              />}
              {activeTab === SUBSCRIPTION && <UserSettingsSubscription />}
              {activeTab === SETTINGS && <UserSettingsSettings
                handleModalStatusChange={handleModalStatusChange}
              />}
              {activeTab === ACTIVITY && <UserSettingsActivity isProfileSettings />}
              {activeTab === SHOPPING && <UserSettingsShopping />}
            </div>

          </div>
        </DrawerStyledMobile>
      ) : (
        <DrawerStyled
          onClose={() => handleShowUserSettings(false)}
          visible={isUserSettingsOpen}
          maskClosable={false}
          maskStyle={{
            backdropFilter: 'blur(5px)',
            backgroundColor: 'rgba(55, 64, 75, 0.85)',
          }}
          style={{ display: !isUserSettingsOpen ? 'none' : 'block' }}
          closable
          destroyOnClose
          className={styles.modal}
          closeIcon={
            <div
              style={{
                position: 'fixed',
                zIndex: 200,
                top: '45px',
                right: 'calc(740px + 30px)',
                width: '38px',
                height: '38px',
                borderRadius: '19px',
                // paddingTop: '7px',
                // paddingLeft: '16px',
              }}
              className={styles.closeButton}
            >
              {/* <CloseOutlined style={{ color: '#FFFFFF', fontSize: '14px' }} /> */}
              <img src={closeModalImage} alt="close" />
            </div>
          }
        >
          {/* Settings aside */}
          <div className={styles.drawerSidebar}>
            <div className={styles.drawerSidebar__inner}>
              {/* My profile tab */}
              <div
                data-key={PROFILE}
                className={cx(styles.tab, {
                  [styles.tabActive]: activeTab === PROFILE,
                })}
                onClick={handleClickTab}
                onKeyPress={handleKeyPressTab}
                role="menuitem"
                tabIndex={0}
              >
                <img src={userImage} alt="profile" />
                <span>
                  <Translate id="shared_containers_usersettings_myprofile" />
                </span>
              </div>

              {/* Subscription tab */}
              <div
                data-key={SUBSCRIPTION}
                className={cx(styles.tab, {
                  [styles.tabActive]: activeTab === SUBSCRIPTION,
                })}
                onClick={handleClickTab}
                onKeyPress={handleKeyPressTab}
                role="menuitem"
                tabIndex={0}
              >
                <img src={notificationImage} alt="notification" />
                <span>
                  <Translate id="shared_containers_usersettings_subscription" />
                </span>
              </div>

              {/* Activity */}
              <div
                data-key={ACTIVITY}
                className={cx(styles.tab, {
                  [styles.tabActive]: activeTab === ACTIVITY,
                })}
                onClick={handleClickTab}
                onKeyPress={handleKeyPressTab}
                role="menuitem"
                tabIndex={0}
              >
                <img src={clockImage} alt="activity" />
                <span>
                  <Translate id="shared_containers_usersettings_activity" />
                </span>
              </div>

              {/* Shopping */}
              <div
                data-key={SHOPPING}
                className={cx(styles.tab, {
                  [styles.tabActive]: activeTab === SHOPPING,
                })}
                onClick={handleClickTab}
                onKeyPress={handleKeyPressTab}
                role="menuitem"
                tabIndex={0}
              >
                <img src={bagImage} alt="shopping" />
                <span>
                  <Translate id="shared_containers_usersettings_shopping" />
                </span>
              </div>

              {/* Settings */}
              <div
                data-key={SETTINGS}
                className={cx(styles.tab, {
                  [styles.tabActive]: activeTab === SETTINGS,
                })}
                onClick={handleClickTab}
                onKeyPress={handleKeyPressTab}
                role="menuitem"
                tabIndex={0}
              >
                <img src={settingsImage} alt="settings" />
                <span>
                  <Translate id="shared_containers_usersettings_settings" />
                </span>
              </div>
            </div>

            {/* Logout */}
            <Button
              className={styles.sidebarLogout}
              onClick={() => dispatch({ type: logoutRequest.toString() })}
              onKeyPress={handleKeyPressLogout}
              type="text"
            >
              <img src={logoutImage} alt="logout" width={16} height={16} />
              <span>
                <Translate id="shared.containers.usersettings.logout" />
              </span>
            </Button>
          </div>

          {/* Settings content */}
          <div
            className={styles.drawerContent}
            style={{
              padding: '30px',
            }}
          >
            {activeTab === PROFILE && <UserSettingsProfile
              handleClickTab={handleClickTab}
              handleKeyPressTab={handleKeyPressTab}
              changeHeader={changeHeader}
              editHeader={editHeader}
            />}
            {activeTab === SUBSCRIPTION && <UserSettingsSubscription />}
            {activeTab === SETTINGS && <UserSettingsSettings
              handleModalStatusChange={handleModalStatusChange}
            />}
            {activeTab === ACTIVITY && <UserSettingsActivity isProfileSettings />}
            {activeTab === SHOPPING && <UserSettingsShopping />}
          </div>
        </DrawerStyled>
      )}
    </>
  );
};

export default UserSettings;
