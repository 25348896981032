import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const USER_SETTINGS_LOGS_FORM = 'USER_SETTINGS_LOGS_FORM';

export const userSettingsLogsFormRequest = createAction(
  `${USER_SETTINGS_LOGS_FORM}/REQUEST`,
);
export const userSettingsLogsFormSuccess = createAction(
  `${USER_SETTINGS_LOGS_FORM}/SUCCESS`,
);
export const userSettingsLogsFormFailure = createAction(
  `${USER_SETTINGS_LOGS_FORM}/FAILURE`,
);

export const userSettingsLogsFormResult = createAction(
  `${USER_SETTINGS_LOGS_FORM}/RESULT`,
);

export const userSettingsLogsFormScrollingRequest = createAction(
  `${USER_SETTINGS_LOGS_FORM}/SCROLLING_REQUEST`,
);

export const userSettingsLogsFormScrollingResult = createAction(
  `${USER_SETTINGS_LOGS_FORM}/SCROLLING_RESULT`,
);

// Reducers ==========================

const isFetching = createReducer(false, {
  [userSettingsLogsFormRequest.toString()]: () => true,
  [userSettingsLogsFormSuccess.toString()]: () => false,
  [userSettingsLogsFormFailure.toString()]: () => false,
});

const data = createReducer(
  {},
  {
    [userSettingsLogsFormRequest.toString()]: () => ({}),
    [userSettingsLogsFormSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

const result = createReducer(
  {},
  {
    [userSettingsLogsFormRequest.toString()]: () => ({}),
    [userSettingsLogsFormResult.toString()]: (_state, action) => action.payload,
  },
);

const isFetchingScrolling = createReducer(false, {
  [userSettingsLogsFormScrollingRequest.toString()]: () => true,
  [userSettingsLogsFormSuccess.toString()]: () => false,
  [userSettingsLogsFormFailure.toString()]: () => false,
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  result,
  isFetchingScrolling,
});

export default reducer;
