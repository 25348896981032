import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * UserLimits API
 */
export default {
  /**
   * Получить данные по лимитам текущего пользователя
   *
   * @param {*} data
   * @returns
   */
  getData(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/all-limits`,
    );
  },
};
