import React, { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import cx from 'classnames';
import { withLocalize } from 'react-localize-redux';
import ReactCountryFlag from 'react-country-flag';
import { connect, useSelector } from 'react-redux';
import { Select } from 'antd';

import styles from './LanguageSelect.module.scss';
import { SelectStyled } from './SelectStyled';

import { languagesSetAppActiveLangRequest } from '../../../../states/Languages/state/duck';
import {
  list as languagesSelector,
} from '../../../../states/Languages/state/selectors';

import ArrowDown from './ArrowDown';

const { Option } = Select;

const LanguageSelect: React.FC<any> = (props) => {
  const {
    activeLanguage,
    languagesSetAppActiveLangRequest,
  } = props;
  const languages = useSelector(languagesSelector);

  const [defaultValue, setDefaultValue] = useState(activeLanguage?.code);

  const handleChangeAppLang = (lang: any) => {
    languagesSetAppActiveLangRequest({ lang });
  };

  useEffect(() => {
    setDefaultValue(activeLanguage?.code);
  }, [activeLanguage]);

  if (Array.isArray(languages) && languages.length === 1) {
    return null;
  }
  return (
    <div className={styles.wrapper}>
      <SelectStyled
        onChange={handleChangeAppLang}
        className={cx(styles.languageSelector, styles.languageSelectorWidth)}
        suffixIcon={
          <ArrowDown />
        }
        value={defaultValue}
      >
        {languages.map(({ name, title, code, flagImage }: Record<string, string>) => (
          <Option value={code} key={code}>
            {
              flagImage ?
                <img
                  src={flagImage}
                  alt=""
                  style={{
                    marginRight: 10,
                    width: 20,
                    height: 20,
                  }}
                />
                :
                <ReactCountryFlag
                  countryCode={code}
                  svg
                  style={{
                    marginRight: 10,
                    width: 20,
                    height: 20,
                  }}
                />
            }
            {name || title}
          </Option>
        ))}
      </SelectStyled>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = (state: any) => ({
  // activeLanguage: activeLanguage(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ languagesSetAppActiveLangRequest }, dispatch);

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(LanguageSelect),
);
