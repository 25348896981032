import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Layout, Button } from 'antd';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import * as E from 'fp-ts/lib/Either';
import get from 'lodash/get';
import * as Sentry from '@sentry/react';

import { sentryEnchancedMessage } from '../../utils/runtimeReporters';

import { result as userProfileEither } from '../../../states/UserProfile/state/selectors';

import styles from './ErrorBoundary.module.scss';

import { HELP_SUPPORT_URL } from '../../constants/Routes/constants';
import { DEVICE_NETBOOKS_WIDTH } from '../../constants/Defaults/constants';
import { STYLE_THEME_ERROR_COLOR } from '../../constants/Styles/constants';

const { Content } = Layout;

interface IProps {
  children: JSX.Element;
}

// Store interface
export interface IErrorBoundaryStore {
  userProfileEither: E.Either<any, any>;
}

const mapStateToProps = (state: any) => ({
  userProfileEither: userProfileEither(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

type IErrorBoundaryProps = IErrorBoundaryStore & IProps;

/**
 * Обертка для отлавливания ошибок в модулях,
 * отправки информации об ошибке в Sentry,
 * отображения "тостера" с информацией для пользователя
 *
 * IDEA: https://ru.reactjs.org/docs/error-boundaries.html
 *
 * @export
 * @class ErrorBoundary
 * @extends {Component}
 */
class ErrorBoundary extends Component<IErrorBoundaryProps> {
  state = {
    // hasError: false,
    isMobile: false,
    isCDM: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.checkForMobileDevice);

    this.setState(() => ({ isCDM: true }));
    this.checkForMobileDevice();
  }

  componentDidCatch(error: any, info: any) {
    const { userProfileEither } = this.props;

    let user = {};

    if (E.isRight(userProfileEither)) {
      const { right: successData } = userProfileEither;
      const data = get(successData, 'data', {});

      user = { ...data };
    }

    if (E.isLeft(userProfileEither)) {
      const { left: error } = userProfileEither;

      user = { ...error };
    }

    // NB: Отправка в сентри
    sentryEnchancedMessage({
      level: Sentry.Severity.Error,
      tag: 'ERROR BOUNDARY',
      user,
      payload: { error, info },
      message: 'ErrorBoundary component',
    });

    // NB: Уведомление в консоль
    // NB: Разрешенный игнор
    // eslint-disable-next-line
    console.log('error:', error);
    // NB: Разрешенный игнор
    // eslint-disable-next-line
    console.log('info:', this.props.children.type, info);

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkForMobileDevice);
  }

  static getDerivedStateFromError() {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { hasError: true };
  }

  checkForMobileDevice = () => {
    const width = document.documentElement.clientWidth;

    if (width < DEVICE_NETBOOKS_WIDTH) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  render() {
    const { isMobile, isCDM } = this.state;
    const { children } = this.props;

    // eslint-disable-next-line no-constant-condition
    if (false) {
      return (
        <Content style={{ backgroundColor: '#f9fafc' }}>
          {/* Mobile Header Helper */}
          <div className={styles.HeaderForExtraSmall} />

          {/* Header */}
          <div className={styles.header}>
            <span>
              <CloseCircleOutlined
                style={{ color: STYLE_THEME_ERROR_COLOR, fontSize: '28px' }}
              />{' '}
              <Translate id="error_boundary_header" />
            </span>
            {/* Mobile */}
            {isCDM && isMobile && (
              <div className={styles.subHeaderMobile}>
                <span>
                  <Translate id="error_boundary_subheader" />
                </span>
              </div>
            )}

            <Button
              type="default"
              shape="round"
              style={{
                height: '38px',
                backgroundColor: 'transparent',
                minWidth: isCDM && isMobile ? '100%' : '180px',
              }}
            >
              <Link to={HELP_SUPPORT_URL}>
                <Translate id="error_boundary_tell_to_support" />
              </Link>
            </Button>

            {/* Mobile */}
            {isCDM && isMobile && <div style={{ marginBottom: '15px' }} />}
          </div>

          {/* Not mobile */}
          {!(isCDM && isMobile) && (
            <div className={styles.subHeader}>
              <span>
                <Translate id="error_boundary_subheader" />
              </span>
            </div>
          )}
        </Content>
      );
    }

    return children;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
