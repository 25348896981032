import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';

import { shopAPI } from '../../../../services/Shop/api';

import {
  shopRequest,
  shopSuccess,
  shopFailure,
  shopResult,
  shopGetPriceRequest,
  shopGetPriceResult,
  shopGetPriceSuccess,
  shopGetPriceFailure,
  shopPurchaseRequest,
  shopPurchaseFailure,
  shopPurchaseSuccess,
  shopPurchaseResult,
  shopPermissionsRequest,
  shopPermissionsFailure,
  shopPermissionsSuccess,
  shopRestrictionsRequest,
  shopRestrictionsSuccess,
  shopRestrictionsFailure,
  shopRestrictionsValidationRequest,
} from '../duck';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';
import { userProfileRequest } from '../../../UserProfile/state/duck';

export function* getData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const productsRes = yield call(shopAPI.getList, completePayload);
  yield put({
    type: shopResult.toString(),
    payload: createResultV2(productsRes),
  });
}

export function* checkResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const data = successData?.data ?? {};

    yield put({
      type: shopSuccess.toString(),
      payload: { listPericeData: data },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: shopFailure.toString(),
      payload: error,
    });
  }
}

// получение данных по конкретной услуге
export function* getPrice(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const res = yield call(shopAPI.getPerice, completePayload);

  yield put({
    type: shopGetPriceResult.toString(),
    payload: createResultV2(res),
  });
}

export function* checkResultGetPrice(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const data = successData?.data ?? {};

    yield put({
      type: shopGetPriceSuccess.toString(),
      payload: data[0],
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: shopGetPriceFailure.toString(),
      payload: error,
    });
  }
}

// Покупка услуги
export function* createPurchase(action: IResultEiterAction) {
  const completePayload: any = yield call(checkToken, action);
  const res = yield call(shopAPI.postPurchase, completePayload);

  yield put({
    type: shopPurchaseResult.toString(),
    payload: { ...createResultV2(res), ...action.payload },
  });
}

export function* checkResultPurchase(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData, callbackType }: any = payload;
    const data = successData?.data ?? {};

    yield put({
      type: shopPurchaseSuccess.toString(),
      payload: data,
    });

    yield put({
      type: userProfileRequest.toString(),
      payload: {
        nonCached: true,
      },
    });

    if (callbackType) {
      yield put({
        type: callbackType.toString(),
      });
    }
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: shopPurchaseFailure.toString(),
      payload: error,
    });
  }
}

// Проверка доступности услуги
export function* checkPermissions(action: IResultEiterAction) {
  const completePayload: any = yield call(checkToken, action);
  const res = yield call(shopAPI.getServicesHandlers, completePayload);

  const result = createResultV2(res);

  if (E.isRight(result)) {
    const { right: successData }: any = result;
    const data = successData?.data ?? {};
    yield put({
      type: shopPermissionsSuccess.toString(),
      payload: data,
    });
  }
}

// Проверка доступности услуги
export function* handlerRestrictions(action: IResultEiterAction) {
  const completePayload: any = yield call(checkToken, action);
  const res = yield call(
    shopAPI.getRestrictions,
    { ...completePayload },
  );

  const result = createResultV2(res);

  if (E.isRight(result)) {
    const { right: successData }: any = result;
    const data = successData?.data ?? {};
    yield put({
      type: shopRestrictionsSuccess.toString(),
      payload: data.form,
    });
  }
  if (E.isLeft(result)) {
    const { left: successData }: any = result;
    const data = successData?.data ?? {};
    yield put({
      type: shopRestrictionsSuccess.toString(),
      payload: data.form,
    });
  }
}

// Проверка доступности услуги
export function* postRestrictions(action: IResultEiterAction) {
  const { payload: payloadData }: any = action;
  const completePayload: any = yield call(checkToken, action);
  const res = yield call(
    shopAPI.postRestrictions,
    { ...completePayload },
  );

  const result = createResultV2(res);

  if (E.isRight(result)) {
    const { right: successData }: any = result;
    const data = successData?.data ?? {};
    yield put({
      type: shopRestrictionsSuccess.toString(),
      payload: data.form,
    });
    yield put({
      type: payloadData.callbackType.toString(),
    });
  }
  if (E.isLeft(result)) {
    const { left: successData }: any = result;
    const data = successData?.data ?? {};
    yield put({
      type: shopRestrictionsSuccess.toString(),
      payload: data.form,
    });
  }
}

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    shopRequest,
    safe(onError, getData, {
      terminator: shopFailure,
    }),
  );
  yield takeEvery(shopResult, safe(onError, checkResult));

  yield takeLatest(
    shopGetPriceRequest,
    safe(onError, getPrice, {
      terminator: shopGetPriceFailure,
    }),
  );
  yield takeEvery(shopGetPriceResult, safe(onError, checkResultGetPrice));

  yield takeLatest(
    shopPurchaseRequest,
    safe(onError, createPurchase, {
      terminator: shopPurchaseFailure,
    }),
  );
  yield takeEvery(shopPurchaseResult, safe(onError, checkResultPurchase));

  yield takeLatest(
    shopPermissionsRequest,
    safe(onError, checkPermissions, {
      terminator: shopPermissionsFailure,
    }),
  );

  yield takeLatest(
    shopRestrictionsRequest,
    safe(onError, handlerRestrictions, {
      terminator: shopRestrictionsFailure,
    }),
  );

  yield takeLatest(
    shopRestrictionsValidationRequest,
    safe(onError, postRestrictions, {
      terminator: shopRestrictionsFailure,
    }),
  );
}
