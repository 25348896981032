import {
  put,
  takeLatest,
  call,
  select,
  all,
  takeEvery,
  cancel,
} from 'redux-saga/effects';
import get from 'lodash/get';
import * as A from 'fp-ts/lib/Array';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';

import { feedbacks } from '../../../../services/Feedbacks/api';

import {
  feedbacksRequest,
  feedbacksSuccess,
  feedbacksFailure,
  feedbacksResult,
  feedbacksCreateReviewRequest,
  feedbacksCreateReviewSuccess,
  feedbacksCreateReviewFailure,
  feedbacksCreateReviewResult,
  feedbacksSecondRequest,
  feedbacksSecondResult,
  feedbacksOrgRequest,
  feedbacksOrgSuccess,
  feedbacksOrgFailure,
  feedbacksOrgResult,
  feedbacksCitiesRequest,
  feedbacksCitiesSuccess,
  feedbacksCitiesFailure,
  feedbacksCitiesResult,
  feedbacksInitRequest,
  feedbacksInitSuccess,
  feedbacksInitFailure,
  feedbacksInitDrawerRequest,
  feedbacksInitDrawerSuccess,
  feedbacksInitDrawerFailure,
  feedbacksSecondDrawerRequest,
  feedbacksSecondDrawerResult,
  feedbacksDrawerSuccess,
  feedbacksDrawerFailure,
  feedbacksPatchCommentsDrawerSuccess,
  feedbacksPatchLikesDrawerSuccess,
  feedbacksCredProdsSuccess,
  // TODO: Использовать в обработчике ошибок
  // feedbacksCredProdsFailure,
  feedbacksCreateCommentRequest,
  feedbacksCreateCommentSuccess,
  feedbacksCreateCommentFailure,
  feedbacksPatchCommentsRequest,
  feedbacksPatchCommentsSuccess,
  feedbacksCreateCommentResult,
  feedbacksAddLikeRequest,
  feedbacksAddLikeSuccess,
  feedbacksAddLikeFailure,
  feedbacksAddLikeResult,
  feedbacksAllUserLikesSuccess,
  // TODO: Использовать в обработчике ошибок
  // feedbacksAllUserLikesFailure,
  feedbacksRemoveLikeRequest,
  feedbacksRemoveLikeSuccess,
  feedbacksRemoveLikeFailure,
  feedbacksRemoveLikeResult,
  feedbacksPatchLikesSuccess,
  feedbacksInitResult,
  feedbacksInitDrawerResult,
} from '../duck';
import {
  reviewsList,
  data,
  allUserLikesData,
  reviewsListDrawer,
  dataDrawer,
} from '../selectors';
import { activeCountryID } from '../../../Countries/state/selectors';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import {
  FEEDBACKS__REWIEWS_RES,
  FEEDBACKS__ORGANIZATIONS_RES,
  FEEDBACKS__CITIES_RES,
  FEEDBACKS__PRODUCTS_RES,
  FEEDBACKS__ALL_USER_LIKES_RES,
  FEEDBACKS__DATA_FILTERED_RES,
  FEEDBACKS__DATA_RES,
  FEEDBACKS__CREATE_REWIEW_RES,
  FEEDBACKS__GET_ORG_DATA_RES,
  FEEDBACKS__GET_CITIES_DATA_RES,
  FEEDBACKS__CREATE_COMMENT_RES,
  FEEDBACKS__ADD_LIKE_RES,
  FEEDBACKS__REMOVE_LIKE_RES,
} from './constants';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';

export function* getDataFiltered(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const feedbacksRes = yield call(feedbacks.getData, completePayload);

  yield put({
    type: feedbacksResult.toString(),
    payload: createResultV2({
      ...feedbacksRes,
      init: FEEDBACKS__DATA_FILTERED_RES,
    }),
  });
} // getDataFiltered =========

export function* checkDataFilteredResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { headers } = successData;

    const dataRes = get(successData, 'data', []) || [];
    const data = Array.isArray(dataRes) ? dataRes : [];
    const totalPages = Number(headers['x-pagination-page-count']) || 1;
    const currentPage = Number(headers['x-pagination-current-page']) || 1;

    const payloadTmp = {
      data: [...data],
      totalPages,
      currentPage,
      comments: [...data].reduce((acc, item) => {
        const { id, comments } = item;
        acc = { ...acc, [id]: comments };
        return acc;
      }, {}),
      likes: [...data].reduce((acc, item) => {
        const { id, likes_count: likes, dislikes_count: disLikes } = item;
        acc = { ...acc, [id]: { likes: likes - disLikes } };
        return acc;
      }, {}),
    };

    yield put({
      type: feedbacksSuccess.toString(),
      payload: payloadTmp,
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: feedbacksFailure.toString(),
      payload: error,
    });
  }
} // checkDataFilteredResult =========

export function* getData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const feedbacksRes = yield call(feedbacks.getData, completePayload);

  yield put({
    type: feedbacksSecondResult.toString(),
    payload: createResultV2({
      ...feedbacksRes,
      init: FEEDBACKS__DATA_RES,
    }),
  });
} // getData =========

export function* checkDataResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const resivedReviews: any = yield select(reviewsList);
    const { right: successData } = payload;
    const { headers, data } = successData;
    const totalPages = Number(headers['x-pagination-page-count']) || 1;
    const currentPage = Number(headers['x-pagination-current-page']) || 1;
    const payloadTmp = {
      data: [...resivedReviews, ...data],
      totalPages,
      currentPage,
      comments: [...resivedReviews, ...data].reduce((acc, item) => {
        const { id, comments } = item;
        acc = { ...acc, [id]: comments };
        return acc;
      }, {}),
      likes: [...resivedReviews, ...data].reduce((acc, item) => {
        const { id, likes_count: likes, dislikes_count: disLikes } = item;
        acc = { ...acc, [id]: { likes: likes - disLikes } };
        return acc;
      }, {}),
    };

    yield put({
      type: feedbacksSuccess.toString(),
      payload: payloadTmp,
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: feedbacksFailure.toString(),
      payload: error,
    });
  }
} // checkDataResult =========

export function* getDataDrawer(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const feedbacksRes = yield call(feedbacks.getData, completePayload);

  yield put({
    type: feedbacksSecondDrawerResult.toString(),
    payload: createResultV2({
      ...feedbacksRes,
      init: FEEDBACKS__DATA_RES,
    }),
  });
} // getDataDrawer =========

export function* checkDataDrawerResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const resivedReviews: any = yield select(reviewsListDrawer);
    const { right: successData } = payload;
    const { headers, data } = successData;
    const totalPages = Number(headers['x-pagination-page-count']) || 1;
    const currentPage = Number(headers['x-pagination-current-page']) || 1;
    const payloadTmp = {
      data: [...resivedReviews, ...data],
      totalPages,
      currentPage,
      comments: [...resivedReviews, ...data].reduce((acc, item) => {
        const { id, comments } = item;
        acc = { ...acc, [id]: comments };
        return acc;
      }, {}),
      likes: [...resivedReviews, ...data].reduce((acc, item) => {
        const { id, likes_count: likes, dislikes_count: disLikes } = item;
        acc = { ...acc, [id]: { likes: likes - disLikes } };
        return acc;
      }, {}),
    };

    yield put({
      type: feedbacksDrawerSuccess.toString(),
      payload: payloadTmp,
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: feedbacksDrawerFailure.toString(),
      payload: error,
    });
  }
} // checkDataDrawerResult =========

export function* getInitData(action: any) {
  const country: number = yield select(activeCountryID);
  const completePayload: any = yield call(checkToken, action);
  const [
    feedbacksRewiewsRes,
    feedbacksOrgsRes,
    feedbacksCitiesRes,
    creditProductsRes,
    feedbacksAllUserLikesRes,
  ]: any = yield all([
    call(feedbacks.getData, completePayload),
    call(feedbacks.getDataOrgs, completePayload),
    call(feedbacks.getDataCities, completePayload),
    call(feedbacks.postDataCredProdsList, {
      ...completePayload,
      country,
      limit: -1,
    }),
    call(feedbacks.getDataAllUserLikes, completePayload),
  ]);

  const input = [
    { ...feedbacksRewiewsRes, init: FEEDBACKS__REWIEWS_RES },
    { ...feedbacksOrgsRes, init: FEEDBACKS__ORGANIZATIONS_RES },
    { ...feedbacksCitiesRes, init: FEEDBACKS__CITIES_RES },
    { ...creditProductsRes, init: FEEDBACKS__PRODUCTS_RES },
    { ...feedbacksAllUserLikesRes, init: FEEDBACKS__ALL_USER_LIKES_RES },
  ];

  const result = pipe(input, A.map(createResultV2), A.separate);
  const { left: errors, right: success } = result;

  if (errors.length > 0) {
    // NB: Если есть хоть одна 401, то разлогингить пользователя
    // и закончить работу саги
    for (let index = 0; index < errors.length; index++) {
      const element = errors[index];
      const { status } = element;

      if (status === 401) {
        yield put({
          type: feedbacksInitResult.toString(),
          payload: createResultV2(element),
        });
        yield cancel();
      }
    }

    // TODO: Ошибки не связанные с аутентификацией отдать в очередь для отображения.

    yield put({ type: feedbacksInitFailure.toString() });
  }

  if (success.length > 0) {
    for (let index = 0; index < success.length; index++) {
      const element = success[index];
      const { init } = element;

      switch (init) {
        case FEEDBACKS__REWIEWS_RES: {
          const { headers } = feedbacksRewiewsRes;
          const feedbacksRewiewsData =
            get(feedbacksRewiewsRes, 'data', []) || [];
          const feedbacksRewiewsDataIterable = Array.isArray(
            feedbacksRewiewsData,
          )
            ? feedbacksRewiewsData
            : [];
          const totalPages = Number(headers['x-pagination-page-count']) || 1;
          const currentPage = Number(headers['x-pagination-current-page']) || 1;
          const payloadRewiews = {
            data: [...feedbacksRewiewsDataIterable],
            totalPages,
            currentPage,
            comments: [...feedbacksRewiewsDataIterable].reduce((acc, item) => {
              const { id, comments } = item;
              acc = { ...acc, [id]: comments };
              return acc;
            }, {}),
            likes: [...feedbacksRewiewsDataIterable].reduce((acc, item) => {
              const { id, likes_count: likes, dislikes_count: disLikes } = item;
              acc = { ...acc, [id]: { likes: likes - disLikes } };
              return acc;
            }, {}),
          };
          yield put({
            type: feedbacksSuccess.toString(),
            payload: payloadRewiews,
          });
          break;
        }
        case FEEDBACKS__ORGANIZATIONS_RES: {
          const { data: feedbacksOrgsData } = feedbacksOrgsRes;
          yield put({
            type: feedbacksOrgSuccess.toString(),
            payload: { data: [...feedbacksOrgsData] },
          });
          break;
        }
        case FEEDBACKS__CITIES_RES: {
          const { data: feedbacksCitiesData } = feedbacksCitiesRes;
          yield put({
            type: feedbacksCitiesSuccess.toString(),
            payload: { data: [...feedbacksCitiesData] },
          });
          break;
        }
        case FEEDBACKS__PRODUCTS_RES: {
          const { data: creditProductsData } = creditProductsRes;
          yield put({
            type: feedbacksCredProdsSuccess.toString(),
            payload: { data: [...creditProductsData] },
          });
          break;
        }
        case FEEDBACKS__ALL_USER_LIKES_RES: {
          const { data: feedbacksAllUserLikesData } = feedbacksAllUserLikesRes;
          yield put({
            type: feedbacksAllUserLikesSuccess.toString(),
            payload: { data: [...feedbacksAllUserLikesData] },
          });
          break;
        }

        default:
          break;
      }
    }

    yield put({
      type: feedbacksInitSuccess.toString(),
    });
  }
} // getInitData =========

export function* getInitDataDrawer(action: any) {
  const country: number = yield select(activeCountryID);
  const completePayload: any = yield call(checkToken, action);
  const [
    feedbacksRewiewsRes,
    feedbacksOrgsRes,
    feedbacksCitiesRes,
    creditProductsRes,
    feedbacksAllUserLikesRes,
  ]: any = yield all([
    call(feedbacks.getData, completePayload),
    call(feedbacks.getDataOrgs, completePayload),
    call(feedbacks.getDataCities, completePayload),
    call(feedbacks.postDataCredProdsList, {
      ...completePayload,
      country,
      limit: -1,
    }),
    call(feedbacks.getDataAllUserLikes, completePayload),
  ]);

  const input = [
    { ...feedbacksRewiewsRes, init: FEEDBACKS__REWIEWS_RES },
    { ...feedbacksOrgsRes, init: FEEDBACKS__ORGANIZATIONS_RES },
    { ...feedbacksCitiesRes, init: FEEDBACKS__CITIES_RES },
    { ...creditProductsRes, init: FEEDBACKS__PRODUCTS_RES },
    { ...feedbacksAllUserLikesRes, init: FEEDBACKS__ALL_USER_LIKES_RES },
  ];

  const result = pipe(input, A.map(createResultV2), A.separate);
  const { left: errors, right: success } = result;

  if (errors.length > 0) {
    // NB: Если есть хоть одна 401, то разлогингить пользователя
    // и закончить работу саги
    for (let index = 0; index < errors.length; index++) {
      const element = errors[index];
      const { status } = element;

      if (status === 401) {
        yield put({
          type: feedbacksInitDrawerResult.toString(),
          payload: createResultV2(element),
        });
        yield cancel();
      }
    }

    // TODO: Ошибки не связанные с аутентификацией отдать в очередь для отображения.

    yield put({ type: feedbacksInitDrawerFailure.toString() });
  }

  if (success.length > 0) {
    for (let index = 0; index < success.length; index++) {
      const element = success[index];
      const { init } = element;

      switch (init) {
        case FEEDBACKS__REWIEWS_RES: {
          const { headers } = feedbacksRewiewsRes;
          const feedbacksRewiewsData =
            get(feedbacksRewiewsRes, 'data', []) || [];
          const feedbacksRewiewsDataIterable = Array.isArray(
            feedbacksRewiewsData,
          )
            ? feedbacksRewiewsData
            : [];
          const totalPages = Number(headers['x-pagination-page-count']) || 1;
          const currentPage = Number(headers['x-pagination-current-page']) || 1;
          const payloadRewiews = {
            data: [...feedbacksRewiewsDataIterable],
            totalPages,
            currentPage,
            comments: [...feedbacksRewiewsDataIterable].reduce((acc, item) => {
              const { id, comments } = item;
              acc = { ...acc, [id]: comments };
              return acc;
            }, {}),
            likes: [...feedbacksRewiewsDataIterable].reduce((acc, item) => {
              const { id, likes_count: likes, dislikes_count: disLikes } = item;
              acc = { ...acc, [id]: { likes: likes - disLikes } };
              return acc;
            }, {}),
          };
          yield put({
            type: feedbacksDrawerSuccess.toString(),
            payload: payloadRewiews,
          });
          break;
        }
        case FEEDBACKS__ORGANIZATIONS_RES: {
          const { data: feedbacksOrgsData } = feedbacksOrgsRes;
          yield put({
            type: feedbacksOrgSuccess.toString(),
            payload: { data: [...feedbacksOrgsData] },
          });
          break;
        }
        case FEEDBACKS__CITIES_RES: {
          const { data: feedbacksCitiesData } = feedbacksCitiesRes;
          yield put({
            type: feedbacksCitiesSuccess.toString(),
            payload: { data: [...feedbacksCitiesData] },
          });
          break;
        }
        case FEEDBACKS__PRODUCTS_RES: {
          const { data: creditProductsData } = creditProductsRes;
          yield put({
            type: feedbacksCredProdsSuccess.toString(),
            payload: { data: [...creditProductsData] },
          });
          break;
        }
        case FEEDBACKS__ALL_USER_LIKES_RES: {
          const { data: feedbacksAllUserLikesData } = feedbacksAllUserLikesRes;
          yield put({
            type: feedbacksAllUserLikesSuccess.toString(),
            payload: { data: [...feedbacksAllUserLikesData] },
          });
          break;
        }

        default:
          break;
      }
    }

    yield put({
      type: feedbacksInitDrawerSuccess.toString(),
    });
  }
} // getInitDataDrawer =========

export function* createReview(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const { areUClient, rate, cityID, orgID, credProdID } = completePayload;
  const createReviewRes = yield call(feedbacks.postDataReview, {
    ...completePayload,
    rating: rate,
    is_need_lawyer_help: 0,
    is_client_of_product: areUClient,
    credit_product_id: credProdID,
    city_id: cityID,
    bank_id: orgID,
  });

  yield put({
    type: feedbacksCreateReviewResult.toString(),
    payload: createResultV2({
      ...createReviewRes,
      init: FEEDBACKS__CREATE_REWIEW_RES,
    }),
  });
} // createReview =========

export function* checkCreatedRewiewResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: feedbacksCreateReviewSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: feedbacksCreateReviewFailure.toString(),
      payload: error,
    });
  }
} // checkCreatedRewiewResult =========

export function* getOrgData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const feedbacksRes = yield call(feedbacks.getDataOrgs, completePayload);

  yield put({
    type: feedbacksOrgResult.toString(),
    payload: createResultV2({
      ...feedbacksRes,
      init: FEEDBACKS__GET_ORG_DATA_RES,
    }),
  });
} // getOrgData =========

export function* checkOrgDataResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: feedbacksOrgSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: feedbacksOrgFailure.toString(),
      payload: error,
    });
  }
} // checkOrgDataResult =========

export function* getCitiesData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const feedbacksRes = yield call(feedbacks.getDataCities, completePayload);

  yield put({
    type: feedbacksCitiesResult.toString(),
    payload: createResultV2({
      ...feedbacksRes,
      init: FEEDBACKS__GET_CITIES_DATA_RES,
    }),
  });
} // getCitiesData =========

export function* checkCitiesDataResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: feedbacksCitiesSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: feedbacksCitiesFailure.toString(),
      payload: error,
    });
  }
} // checkCitiesDataResult =========

export function* createComment(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const { ID, commentText } = completePayload;
  const serverRes = yield call(feedbacks.postDataComment, {
    ...completePayload,
    text: commentText,
    credit_product_review_id: ID,
  });

  yield put({
    type: feedbacksCreateCommentResult.toString(),
    payload: createResultV2({
      ...serverRes,
      init: FEEDBACKS__CREATE_COMMENT_RES,
    }),
  });
} // createComment =========

export function* checkCreateCommentResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: feedbacksCreateCommentSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: feedbacksCreateCommentFailure.toString(),
      payload: error,
    });
  }
} // checkCreateCommentResult =========

export function* patchComments(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const { ID, newComments } = completePayload;

  // NB: Отзывы в списке раздела "Отзывы"
  const dataReviews: any = yield select(data);
  const payload = {
    ...dataReviews,
    comments: { ...dataReviews.comments, [ID]: [...newComments] },
  };

  yield put({
    type: feedbacksPatchCommentsSuccess.toString(),
    payload,
  });

  // NB: Отзывы в боковом меню "Информации о продукте"
  const dataReviewsDrawer: any = yield select(dataDrawer);
  const payloadDrawer = {
    ...dataReviewsDrawer,
    comments: { ...dataReviewsDrawer.comments, [ID]: [...newComments] },
  };
  yield put({
    type: feedbacksPatchCommentsDrawerSuccess.toString(),
    payload: payloadDrawer,
  });
} // patchComments =========

export function* patchLikesAdd(action: any) {
  const allUserLikes: any = yield select(allUserLikesData);
  const { payload } = action;
  const { ID } = payload;

  // NB: Отзывы в списке раздела "Отзывы"
  const dataReviews: any = yield select(data);
  const likesID = get(dataReviews, `likes[${ID}]`, 'NO_DATA');

  let newPayload = {
    ...dataReviews,
    likes: {
      ...dataReviews.likes,
    },
  };

  if (likesID !== 'NO_DATA') {
    newPayload = {
      ...dataReviews,
      likes: {
        ...dataReviews.likes,
        [ID]: { likes: dataReviews.likes[ID].likes + 1 },
      },
    };
  }

  yield put({
    type: feedbacksPatchLikesSuccess.toString(),
    payload: newPayload,
  });

  // NB: Отзывы в боковом меню "Информации о продукте"
  const dataReviewsDrawer: any = yield select(dataDrawer);
  const likesIDDrawer = get(dataReviewsDrawer, `likes[${ID}]`, 'NO_DATA');

  let newPayloadDrawer = {
    ...dataReviewsDrawer,
    likes: {
      ...dataReviewsDrawer.likes,
    },
  };

  if (likesIDDrawer !== 'NO_DATA') {
    newPayloadDrawer = {
      ...dataReviewsDrawer,
      likes: {
        ...dataReviewsDrawer.likes,
        [ID]: { likes: dataReviewsDrawer.likes[ID].likes + 1 },
      },
    };
  }

  yield put({
    type: feedbacksPatchLikesDrawerSuccess.toString(),
    payload: newPayloadDrawer,
  });

  // NB: Лайки пользователя
  const allUserLikesPayload = { data: [...allUserLikes, ID] };

  yield put({
    type: feedbacksAllUserLikesSuccess.toString(),
    payload: allUserLikesPayload,
  });
} // patchLikesAdd =========

export function* patchLikesRemove(action: any) {
  const allUserLikes: any = yield select(allUserLikesData);
  const { payload } = action;
  const { ID } = payload;

  // NB: Отзывы в списке раздела "Отзывы"
  const dataReviews: any = yield select(data);
  const likesID = get(dataReviews, `likes[${ID}]`, 'NO_DATA');

  let newPayload = {
    ...dataReviews,
    likes: {
      ...dataReviews.likes,
    },
  };

  if (likesID !== 'NO_DATA') {
    newPayload = {
      ...dataReviews,
      likes: {
        ...dataReviews.likes,
        [ID]: { likes: dataReviews.likes[ID].likes - 1 },
      },
    };
  }

  yield put({
    type: feedbacksPatchLikesSuccess.toString(),
    payload: newPayload,
  });

  // NB: Отзывы в боковом меню "Информации о продукте"
  const dataReviewsDrawer: any = yield select(dataDrawer);
  const likesIDDrawer = get(dataReviewsDrawer, `likes[${ID}]`, 'NO_DATA');

  let newPayloadDrawer = {
    ...dataReviewsDrawer,
    likes: {
      ...dataReviewsDrawer.likes,
    },
  };

  if (likesIDDrawer !== 'NO_DATA') {
    newPayloadDrawer = {
      ...dataReviewsDrawer,
      likes: {
        ...dataReviewsDrawer.likes,
        [ID]: { likes: dataReviewsDrawer.likes[ID].likes - 1 },
      },
    };
  }

  yield put({
    type: feedbacksPatchLikesDrawerSuccess.toString(),
    payload: newPayloadDrawer,
  });

  // NB: Лайки пользователя
  const allUserLikesPayload = {
    data: [...allUserLikes].filter((x: number) => x !== ID),
  };

  yield put({
    type: feedbacksAllUserLikesSuccess.toString(),
    payload: allUserLikesPayload,
  });
} // patchLikesRemove =========

export function* addLike(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const { ID } = completePayload;
  const serverRes = yield call(feedbacks.postDataAddLike, {
    ...completePayload,
    ID,
  });

  yield put({
    type: feedbacksAddLikeResult.toString(),
    payload: createResultV2({
      ...serverRes,
      init: FEEDBACKS__ADD_LIKE_RES,
      ID,
    }),
  });
} // addLike =========

export function* checkAddLikeResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data, ID } = successData;

    yield put({
      type: feedbacksAddLikeSuccess.toString(),
      payload: { data, ID },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: feedbacksAddLikeFailure.toString(),
      payload: error,
    });
  }
} // checkAddLikeResult =========

export function* removeLike(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const { ID } = completePayload;
  const serverRes = yield call(feedbacks.deleteDataRemoveLike, {
    ...completePayload,
    ID,
  });

  yield put({
    type: feedbacksRemoveLikeResult.toString(),
    payload: createResultV2({
      ...serverRes,
      init: FEEDBACKS__REMOVE_LIKE_RES,
      ID,
    }),
  });
} // removeLike =========

export function* checkRemoveLikeResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data, ID } = successData;

    yield put({
      type: feedbacksRemoveLikeSuccess.toString(),
      payload: { data, ID },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: feedbacksRemoveLikeFailure.toString(),
      payload: error,
    });
  }
} // checkRemoveLikeResult =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    feedbacksRequest,
    safe(onError, getDataFiltered, {
      terminator: feedbacksFailure,
    }),
  );

  yield takeLatest(feedbacksSecondRequest, safe(onError, getData));

  yield takeLatest(feedbacksSecondDrawerRequest, safe(onError, getDataDrawer));

  yield takeLatest(
    feedbacksCreateReviewRequest,
    safe(onError, createReview, {
      terminator: feedbacksCreateReviewFailure,
    }),
  );

  yield takeLatest(
    feedbacksOrgRequest,
    safe(onError, getOrgData, {
      terminator: feedbacksOrgFailure,
    }),
  );

  yield takeLatest(
    feedbacksCitiesRequest,
    safe(onError, getCitiesData, {
      terminator: feedbacksCitiesFailure,
    }),
  );

  yield takeLatest(
    feedbacksInitRequest,
    safe(onError, getInitData, {
      terminator: feedbacksInitFailure,
    }),
  );

  yield takeLatest(
    feedbacksInitDrawerRequest,
    safe(onError, getInitDataDrawer, {
      terminator: feedbacksInitDrawerFailure,
    }),
  );

  yield takeLatest(
    feedbacksCreateCommentRequest,
    safe(onError, createComment, {
      terminator: feedbacksCreateCommentFailure,
    }),
  );

  yield takeEvery(feedbacksPatchCommentsRequest, safe(onError, patchComments));

  yield takeLatest(
    feedbacksAddLikeRequest,
    safe(onError, addLike, {
      terminator: feedbacksAddLikeFailure,
    }),
  );

  yield takeLatest(
    feedbacksRemoveLikeRequest,
    safe(onError, removeLike, {
      terminator: feedbacksRemoveLikeFailure,
    }),
  );

  yield takeEvery(feedbacksAddLikeSuccess, safe(onError, patchLikesAdd));

  yield takeEvery(feedbacksRemoveLikeSuccess, safe(onError, patchLikesRemove));

  // NB: Either Результаты запросов в АПИ
  yield takeEvery(feedbacksResult, safe(onError, checkDataFilteredResult));

  yield takeEvery(feedbacksSecondResult, safe(onError, checkDataResult));

  yield takeEvery(
    feedbacksSecondDrawerResult,
    safe(onError, checkDataDrawerResult),
  );

  yield takeEvery(
    feedbacksCreateReviewResult,
    safe(onError, checkCreatedRewiewResult),
  );

  yield takeEvery(feedbacksOrgResult, safe(onError, checkOrgDataResult));

  yield takeEvery(feedbacksCitiesResult, safe(onError, checkCitiesDataResult));

  yield takeEvery(
    feedbacksCreateCommentResult,
    safe(onError, checkCreateCommentResult),
  );

  yield takeEvery(feedbacksAddLikeResult, safe(onError, checkAddLikeResult));

  yield takeEvery(
    feedbacksRemoveLikeResult,
    safe(onError, checkRemoveLikeResult),
  );
}
