import AxiosInstance from '../../../core/api/axiosinstance';
import paramsSerializer from '../../../shared/utils/paramsSerializer';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * CreditRating API
 */
export default {
  /**
   * Получить данные с рейтингом текущего пользователя
   *
   * @param {*} data
   * @returns
   */
  getRatingScoring(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/rating/scorings`,
    );
  },

  /**
   * Получить дату следующего запланированного обновления рейтинга
   *
   * @param {*} data
   * @returns
   */
  getRatingScoringNxtDate(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/rating/scorings/next-date-of-update`,
    );
  },

  /**
   * Средние значения рейтинга всех пользователей системы
   *
   * @param {*} data
   * @returns
   */
  getRatingAverageScoring(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/rating/average-scoring`,
    );
  },

  /**
   * Получить табы с предложениями
   *
   * @param {*} data
   * @returns
   */
  postDataFilter(data: any) {
    const { token } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/credit-products/filters`,
      data,
    );
  },

  /**
   * Получить табы с "Категориями типов"
   *
   * @param {*} data
   * @returns
   */
  getDataCategoryTypes(data: any) {
    const { token, country } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/credit-products/type-categories?filter[country_id]=${country}`,
    );
  },

  /**
   * Получить данные с предложениями по всем табам системы
   *
   * @param {*} data
   * @returns
   */
  postDataList(data: any) {
    const { token } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/credit-products/list`,
      data,
    );
  },

  /**
   * Получить данные обо всех тегах для формы `IDontKnowForm`
   *
   * @param {*} data
   * @returns
   */
  getTags(data: any) {
    const { token, country } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/credit-products/tags?${paramsSerializer({
        country_id: country,
      })}`,
    );
  },

  /**
   * Получить данные о вложенных тегах, по конкретному тегу, для формы `IDontKnowForm`
   *
   * @param {*} data
   * @returns
   */
  getTagsNested(data: any) {
    const { token, country, tag } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/credit-products/tags/nested?${paramsSerializer(
        {
          tag,
          country_id: country,
        },
      )}`,
    );
  },
};
