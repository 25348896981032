import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';
import { FrownTwoTone, SmileTwoTone, MehTwoTone } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import Preloader from '../Preloader';

import {
  isFetching as isFetchingSelector,
  userRatingActual,
  detailsData,
} from '../../../states/CreditRating/state/selectors';

import { ButtonStyled } from './theme';
import styles from './RatingBadge.module.scss';
import { creditRatingRequest } from '../../../states/CreditRating/state/duck';

const RatingBadge: React.FC<any> = () => {
  const [status, setStatus] = useState('');

  const dispatch = useDispatch();
  const isFetching = useSelector(isFetchingSelector);
  const rating = useSelector(userRatingActual);
  const { ratingScale, hide } = useSelector(detailsData);
  const history = useHistory();

  useEffect(() => {
    dispatch({
      type: creditRatingRequest.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ratingScale.forEach((item: any) => {
      if (
        (rating >= item.from && rating <= item.to) ||
        rating > item.to
      ) {
        setStatus(item.zone_status);
      }
    });
  }, [rating, ratingScale]);

  function btnClick() {
    history.push('/cabinet/credit-rating');
  }

  if (hide) {
    return null;
  }
  return (
    <ButtonStyled onClick={btnClick}>
      <p>
        <Translate id="shared.componets.ratingbadge.myrating" />
      </p>

      {isFetching ? (
        <Preloader size="small" />
      ) : (
        <div
          className={cx(
            styles.badge,
            status === 'danger' && styles.danger,
            status === 'warning' && styles.warning,
            status === 'success' && styles.success,
          )}
        >
          {status === 'success' && (
            <SmileTwoTone
              className={styles.iconPadding}
              twoToneColor="#10B97B"
            />
          )}
          {status === 'warning' && (
            <MehTwoTone
              className={styles.iconPadding}
              twoToneColor="#FABD48"
            />
          )}
          {status === 'danger' && (
            <FrownTwoTone
              className={styles.iconPadding}
              twoToneColor="#E3405E"
            />
          )}
          {rating}
        </div>
      )}
    </ButtonStyled>
  );
};

export default RatingBadge;
