import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';
// import { Row, Col } from 'antd';
import { get } from 'lodash';

import CheckBox from '../../componets/CheckBox';
import Preloader from '../../componets/Preloader';

import { useWindowSize } from '../../hooks/useWindowSize';
import { DEVICE_NETBOOKS_WIDTH } from '../../constants/Defaults/constants';

// NB: ErrorBoundary нужно импортировать последним из shared
// иначе возможны конфликты при сборке SCSS стилей
// IDEA:  https://github.com/facebook/create-react-app/issues/5372
import ErrorBoundary from '../../componets/ErrorBoundary';

import {
  data as userSubscriptionSelector,
  dataState as dataStateSelector,
  isFetching as isFetchingSelector,
} from '../../../states/UserSubscription/state/selectors';
import { userSubscriptionPutRequest } from '../../../states/UserSubscription/state/duck';

import styles from './UserSettingsSubscription.module.scss';

interface ISettings {
  name: string;
  value: string;
  translate: string;
}

/**
 * Окно настройки: Мой профиль
 *
 * @returns
 */
const UserSettingsSubscription: React.FC = () => {
  const userSubscription = useSelector(userSubscriptionSelector);
  const dataState = useSelector(dataStateSelector);
  const isFetching = useSelector(isFetchingSelector);
  // eslint-disable-next-line
  const { subscribe, notice, notice_channel_sms } = userSubscription;
  const dispatch = useDispatch();

  const windowSize = useWindowSize();
  const { width = 0 } = windowSize;

  // По умолчанию считаем что запущено на десктопе, мобила = false
  let isMobile = false;

  if (width < DEVICE_NETBOOKS_WIDTH) {
    isMobile = true;
  }

  const handleChangeSubscription = React.useCallback(
    (name, value) =>
      dispatch({
        type: userSubscriptionPutRequest.toString(),
        payload: { name, value },
      }),
    [dispatch],
  );

  const handleChangeCheckBox = React.useCallback(
    event => {
      const { name, checked } = event.target;
      const value = checked ? '1' : '0';

      handleChangeSubscription(name, value);
    },
    [handleChangeSubscription],
  );

  return (
    <ErrorBoundary>
      <>
        {isMobile ? (
          ''
        ) : (
          <div className={cx(styles.title)}>
            <Translate id="shared_containers_usersettings_subscribe" />
          </div>
        )}
        <div className={cx(styles.subtitle)}>
          <Translate id="shared_containers_usersettingssubscription_subheader" />
        </div>
        <div className={cx(styles.header)}>
          <div className={cx(styles.headerText)}>
            <Translate id="shared_containers_usersettingssubscription_header" />
          </div>
        </div>
        <div className={cx(styles.list)}>
          {isFetching ? (
            <Preloader size="small" />
          ) : (
            <div className={styles.listCheckbox}>
              {subscribe.map((item: ISettings) => {
                const { name } = item;
                const innerTranslate = get(item, 'translate', '') || '';

                return (
                  <div className={cx(styles.itemCheckbox)} key={name}>
                    {innerTranslate !== '' && (
                      <Translate>
                        {({ translate }) => (
                          <CheckBox
                            name={name}
                            onChange={handleChangeCheckBox}
                            checked={dataState[name] === '1' ? true : false}
                            label={`${translate(innerTranslate).toString()}`}
                          />
                        )}
                      </Translate>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className={cx(styles.header)}>
          <div className={cx(styles.headerText)}>
            <Translate id="shared_containers_usersettingssubscription_header_notice" />
          </div>
        </div>
        <div className={cx(styles.list)}>
          {isFetching ? (
            <Preloader size="small" />
          ) : (
            <div className={cx(styles.listCheckbox, styles.listCheckbox_notify)}>
              {notice.map((item: ISettings) => {
                const { name } = item;
                const innerTranslate = get(item, 'translate', '') || '';

                return (
                  <div className={cx(styles.itemCheckbox)} key={name}>
                    {innerTranslate !== '' && (
                      <Translate>
                        {({ translate }) => (
                          <CheckBox
                            name={name}
                            onChange={handleChangeCheckBox}
                            checked={dataState[name] === '1' ? true : false}
                            label={`${translate(innerTranslate).toString()}`}
                          />
                        )}
                      </Translate>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className={cx(styles.header)}>
          <div className={cx(styles.headerText)}>
            <Translate id="shared_containers_usersettingssubscription_header_sms" />
          </div>
        </div>
        <div className={cx(styles.list)}>
          {isFetching ? (
            <Preloader size="small" />
          ) : (
            <div className={cx(styles.listCheckbox)}>
              {notice_channel_sms.map((item: ISettings) => {
                const { name } = item;
                const innerTranslate = get(item, 'translate', '') || '';

                return (
                  <div className={cx(styles.itemCheckbox)} key={name}>
                    {innerTranslate !== '' && (
                      <Translate>
                        {({ translate }) => (
                          <CheckBox
                            name={name}
                            onChange={handleChangeCheckBox}
                            checked={dataState[name] === '1' ? true : false}
                            label={`${translate(innerTranslate).toString()}`}
                          />
                        )}
                      </Translate>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </>
    </ErrorBoundary>
  );
};

export default UserSettingsSubscription;
