import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { logoutSuccess } from '../../../Logout/state/duck';

import {
  COME_FROM_COMMON,
  COME_FROM_LAWYER,
} from '../../../../shared/constants/Defaults/constants';

const initialState = {
  isAuthorized: false,
  authToken: '',
};

// Actions ==========================

const AUTH = 'AUTH';

// Установка статуса аутентификации пользователя
export const authSetAuthStatusRequest = createAction(
  `${AUTH}/SET_AUTH_STATUS_REQUEST`,
);
export const authSetAuthStatusSuccess = createAction(
  `${AUTH}/SET_AUTH_STATUS_SUCCESS`,
);
export const authSetAuthStatusFailure = createAction(
  `${AUTH}/SET_AUTH_STATUS_FAILURE`,
);

export const authSetComeFromLawyer = createAction(
  `${AUTH}/SET_COME_FROM_LAWYER`,
);

// Reducers ==========================

// Статус установки аутентификации
// IDEA: Всегда по умолчанию `true`, чтобы рендер не начал отрабатывать
// и не передал данные роутеру раньше чем будет точно установлено
// авторизован пользователь или нет
const isFetching = createReducer(true, {
  [authSetAuthStatusRequest.toString()]: () => true,
  [authSetAuthStatusSuccess.toString()]: () => false,
  [authSetAuthStatusFailure.toString()]: () => false,
});

// Успешно установлен статус
const data = createReducer(initialState, {
  [authSetAuthStatusRequest.toString()]: () => initialState,
  [authSetAuthStatusSuccess.toString()]: (_state, action) => action.payload,
  [authSetAuthStatusFailure.toString()]: () => initialState,
  [logoutSuccess.toString()]: () => initialState,
});

// Ошибка при установке статуса
const error = createReducer([], {
  [authSetAuthStatusRequest.toString()]: () => [],
  [authSetAuthStatusSuccess.toString()]: () => [],
  [authSetAuthStatusFailure.toString()]: (_state, action) => action.payload,
});

const comeFrom = createReducer(COME_FROM_COMMON, {
  [authSetComeFromLawyer.toString()]: () => COME_FROM_LAWYER,
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  error,
  comeFrom,
});

export default reducer;
