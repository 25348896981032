import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './MultiloginCabinet.module.scss';

const MultiloginCabinetSkeleton: React.FC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.bank}>

        <div className={styles.head}>
          <Skeleton className={styles.logo} height={32} width={32} />
          <Skeleton className={styles.brand} width={100} />
        </div>

        {/* <MultiLoginTable loans={loans} /> */}

        <div className={styles.buttons}>
          <div className={styles.plug}>
            <Skeleton height={48} width={160} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiloginCabinetSkeleton;
