import { createSelector } from 'reselect';
import { get } from 'lodash';
import { userProfile } from '../../../UserProfile/state/selectors';

export const languages = createSelector(
  (store: any) => store.languagesReducer,
  languagesReducer => languagesReducer,
);

export const activeLanguage = createSelector(
  languages,
  languages => languages.activeLanguage,
);

export const activeLanguageUPPER = createSelector(
  (store: any) => store.languagesReducer.activeLanguage,
  activeLanguage => activeLanguage.toUpperCase(),
);

// Countries
const currentCountry = createSelector(
  userProfile,
  (userProfile) => userProfile?.country,
);

export const activeCountryID = createSelector(
  currentCountry,
  (store: any) => store.countriesReducer.list,
  (currentCountry, list) => {
    let result = 0;
    list.forEach((item: any) => {
      const { name, id } = item;
      if (name === currentCountry) {
        result = id;
      }
    });

    return result;
  },
);

export const activeCountryCode = createSelector(
  currentCountry,
  (store: any) => store.countriesReducer.list,
  (currentCountry, list) => {
    let result = 0;
    list.forEach((item: any) => {
      const { name, code } = item;
      if (name === currentCountry) {
        result = code;
      }
    });

    return result;
  },
);

export const activeCountryNoSms = createSelector(
  activeCountryCode,
  (store: any) => store.countriesReducer.list,
  (activeCountryCode, list) => {
    // eslint-disable-next-line
    // console.debug(list);
    let result = 1;
    list.forEach((item: any) => {
      // eslint-disable-next-line
      const { code, no_sms } = item;
      // eslint-disable-next-line
      // console.debug(item);
      if (code === activeCountryCode) {
        // eslint-disable-next-line
        result = no_sms;
        // eslint-disable-next-line
        // console.debug(code);
        // eslint-disable-next-line
        // console.debug(activeLanguageUPPER);
      }
    });
    return result;
    // return 1;
  },
);

export const countries = createSelector(
  (store: any) => store.countriesReducer.list,
  (list) => {
    const result = Array.isArray(list) && list.map((item: Record<string, any>) => {
      const id = get(item, 'id');
      const code = get(item, 'code');
      const name = get(item, 'name');
      const title = get(item, 'title');
      const flagImage = get(item, 'flag_image');
      const languages = get(item, 'languages') || [];
      const noSms = get(item, 'no_sms');
      return { id, code, name, title, flagImage, languages, noSms };
    });

    return result || [];
  },
);
