import React from 'react';
import { useSelector } from 'react-redux';
// import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';
// import { Empty } from 'antd';
// import moment from 'moment';
// import InfiniteScroll from 'react-infinite-scroll-component';

// import InfinitePreloader from '../../componets/InfinitePreloader';
import Preloader from '../../componets/Preloader';
import FormWrapper from '../../componets/FormWrapper';

import {
  // logList as logListSelector,
  isFetching as isFetchingSelector,
  // totalPages as totalPagesSelector,
  // currentPage as currentPageSelector,
} from '../../../states/UserSettingsLogsForm/state/selectors';
// import {
//   userSettingsLogsFormRequest,
//   userSettingsLogsFormScrollingRequest,
// } from '../../../states/UserSettingsLogsForm/state/duck';

import styles from './UserSettingsRemoveAccauntForm.module.scss';

import trashImg from './assets/images/trash.svg';

/**
 * Форма со списком логов доступа пользователя
 *
 * @returns
 */

interface IProps {
  handleModalStatusChange: any;
}

const UserSettingsLogsForm: React.FC<IProps> = (props) => {
  // const logList = useSelector(logListSelector);
  const isFetching = useSelector(isFetchingSelector);
  // const totalPages = useSelector(totalPagesSelector);
  // const currentPage = useSelector(currentPageSelector);
  // const dispatch = useDispatch();

  const { handleModalStatusChange } = props;

  // // cdM
  // React.useEffect(
  //   () => {
  //     dispatch({
  //       type: userSettingsLogsFormRequest.toString(),
  //     });
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [],
  // );

  // const handleFetchNewData = React.useCallback(() => {
  //   if (currentPage - totalPages !== 0) {
  //     dispatch({
  //       type: userSettingsLogsFormScrollingRequest.toString(),
  //       payload: { page: currentPage + 1 },
  //     });
  //   }
  // }, [dispatch, currentPage, totalPages]);

  return (
    <>
      <Translate>
        {({ translate }) => (
          <FormWrapper
            name={`${translate(
              'shared_containers_usersettingsprofile_security_remove_accaunt',
            ).toString()}`}
          >
            <div>
              <>
                {isFetching ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Preloader size="large" />
                  </div>
                ) : (
                  <>
                    <div className={cx(styles.removeAccaunt)}>
                      <div className={cx(styles.removeAccaunt__text)}>
                        <Translate id="shared_containers_usersettingsprofile_security_remove_accaunt_text" />
                      </div>
                      <div
                        className={cx(styles.removeAccaunt__remove)}
                        onClick={() => handleModalStatusChange(true)}
                        role="button"
                        onKeyPress={() => handleModalStatusChange(true)}
                        tabIndex={0}
                      >
                        <img src={trashImg} alt="removeAccaunt" />
                        <span><Translate id="shared_containers_usersettingsprofile_security_remove_accaunt_btn" /></span>
                      </div>
                    </div>
                  </>
                )}
              </>
            </div>
          </FormWrapper>
        )}
      </Translate>
    </>
  );
};

export default UserSettingsLogsForm;
