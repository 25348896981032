import {
  takeEvery,
  put,
  all,
  call,
  cancel,
  putResolve,
  select,
  takeLeading,
} from 'redux-saga/effects';
import * as A from 'fp-ts/lib/Array';
import { pipe } from 'fp-ts/lib/function';
import { map } from 'lodash';

import { initAPI } from '../../../../services/Init/api';
import { i18nAPI } from '../../../../services/I18n/api';
import { countriesAPI } from '../../../../services/Countries/api';
import { masksAPI } from '../../../../services/Masks/api';
import { adBlocksAPI } from '../../../../services/AdBlocks/api';
import { languagesAPI } from '../../../../services/Languages/api';

import {
  initRequest,
  initSuccess,
  initFailure,
  initResult,
  initPrivateSiteSettingsSuccess,
  initVersionSuccess,
} from '../duck';
import { authSetAuthStatusSuccess } from '../../../Auth/state/duck';
import {
  languagesSuccess,
  languagesSetActiveLangRequest,
  languagesInitAlreadySucess,
  languagesListSuccess,
} from '../../../Languages/state/duck';
import { isLanguagesAlreadySet as isLanguagesAlreadySetSelector } from '../../../Languages/state/selectors';
import { countriesSuccess } from '../../../Countries/state/duck';
import { masksSuccess } from '../../../Masks/state/duck';
import { adBlocksSuccess } from '../../../AdBlocks/state/duck';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import {
// checkContract,
// DIRECTION,
} from '../../../../core/state/utils/checkContract/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

// import { okSiteSettingsContract } from '../../../../contracts/Init';

import {
  INIT_PRIVATE__SITE_SETTINGS,
  INIT__VERSION_RES,
  INIT__LANGUAGES_RES,
  INIT__COUNTRIES_RES,
  INIT__MASKS_RES,
  INIT__AD_BLOCKS_RES,
  INIT__LANGUAGES_LIST_RES,
} from './constants';

/**
 * Получить все публичные инициализационные данные для системы
 *
 * @export
 * @returns {IterableIterator<any>}
 */
export function* getRecords(): IterableIterator<any> {
  const tempPayload: any = yield call(checkToken, {});
  const [
    versionRes,
    languagesListRes,
    langsRes,
    countriesRes,
    masksRes,
    adBlocksRes,
  ]: any = yield all([
    call(initAPI.getVersion, { ...tempPayload }),
    call(languagesAPI.getList),
    call(i18nAPI.getData),
    call(countriesAPI.getData),
    call(masksAPI.getData),
    call(adBlocksAPI.getData),
  ]);

  const input = [
    { ...versionRes, init: INIT__VERSION_RES },
    { ...languagesListRes, init: INIT__LANGUAGES_LIST_RES },
    { ...langsRes, init: INIT__LANGUAGES_RES },
    { ...countriesRes, init: INIT__COUNTRIES_RES },
    { ...masksRes, init: INIT__MASKS_RES },
    { ...adBlocksRes, init: INIT__AD_BLOCKS_RES },
  ];

  const result = pipe(input, A.map(createResultV2), A.separate);
  const { left: errors, right: success } = result;

  if (errors.length > 0) {
    // NB: Если есть хоть одна 401, то разлогингить пользователя
    // и закончить работу саги
    for (let index = 0; index < errors.length; index++) {
      const element = errors[index];
      const { status } = element;

      if (status === 401) {
        yield put({
          type: initResult.toString(),
          payload: createResultV2(element),
        });
        yield cancel();
      }
    }

    // TODO: Ошибки не связанные с аутентификацией отдать в очередь для отображения.

    yield put({ type: initFailure.toString() });
  }

  if (success.length > 0) {
    for (let index = 0; index < success.length; index++) {
      const element = success[index];
      const { init } = element;

      switch (init) {
        case INIT__VERSION_RES: {
          const { data: dataVersion } = element;
          yield put({
            type: initVersionSuccess.toString(),
            payload: { version: dataVersion },
          });
          break;
        }
        case INIT__LANGUAGES_LIST_RES: {
          const { data } = element;
          yield putResolve({
            type: languagesListSuccess.toString(),
            payload: data,
          });
          break;
        }
        case INIT__LANGUAGES_RES: {
          const { data } = element;
          yield putResolve({
            type: languagesSuccess.toString(),
            payload: data,
          });
          yield put({
            type: languagesSetActiveLangRequest.toString(),
          });
          break;
        }
        case INIT__COUNTRIES_RES: {
          const { data } = element;
          yield put({
            type: countriesSuccess.toString(),
            payload: data,
          });
          break;
        }
        case INIT__AD_BLOCKS_RES: {
          const { data } = element;
          yield put({
            type: adBlocksSuccess.toString(),
            payload: data,
          });
          break;
        }
        case INIT__MASKS_RES: {
          const { data } = element;
          const adaptedData = map(data, (mask: any) => {
            const { country_id: countryId, id, value, type } = mask;

            return {
              id,
              value,
              countryId,
              type,
            };
          });

          yield put({
            type: masksSuccess.toString(),
            payload: adaptedData,
          });
          break;
        }

        default:
          break;
      }
    }
  }
} // getRecords =========

/**
 * Получить все приватные инициализационные данные для системы
 *
 * @export
 * @returns {IterableIterator<any>}
 */
export function* getRecordsPrivate(): IterableIterator<any> {
  const tempPayload: any = yield call(checkToken, {});
  const [siteSettingsRes]: any = yield all([
    call(initAPI.getSiteSettings, { ...tempPayload }),
  ]);

  const input = [{ ...siteSettingsRes, init: INIT_PRIVATE__SITE_SETTINGS }];

  const result = pipe(input, A.map(createResultV2), A.separate);
  const { left: errors, right: success } = result;

  if (errors.length > 0) {
    // NB: Если есть хоть одна 401, то разлогингить пользователя
    // и закончить работу саги
    for (let index = 0; index < errors.length; index++) {
      const element = errors[index];
      const { status } = element;

      if (status === 401) {
        yield put({
          type: initResult.toString(),
          payload: createResultV2(element),
        });
        yield cancel();
      }
    }
  }

  if (success.length > 0) {
    for (let index = 0; index < success.length; index++) {
      const element = success[index];
      const { init } = element;

      switch (init) {
        case INIT_PRIVATE__SITE_SETTINGS: {
          const { data } = siteSettingsRes;

          yield put({
            type: initPrivateSiteSettingsSuccess.toString(),
            payload: { data },
          });
          break;
        }

        default:
          break;
      }
    }
  }
} // getRecordsPrivate =========

export function* fynallyInit(): IterableIterator<any> {
  const isLanguagesAlreadySet = yield select(isLanguagesAlreadySetSelector);

  if (isLanguagesAlreadySet) {
    yield put({
      type: initSuccess.toString(),
    });
  }
} // getRecords =========

// Root Saga
export default function* rootSaga() {
  yield takeLeading(
    initRequest,
    safe(onError, getRecords, { terminator: initFailure }),
  );

  yield takeEvery(authSetAuthStatusSuccess, safe(onError, getRecordsPrivate));

  yield takeEvery(
    languagesInitAlreadySucess,
    safe(onError, fynallyInit, { terminator: initFailure }),
  );
}
