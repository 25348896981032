import { lazy } from 'react';

import withSuspense from '../../shared/hooks/withSuspense';

const AuthController = lazy(() => import('./Auth.controller'));

// Main Render
const AuthLoadable = withSuspense(AuthController);

export default AuthLoadable;
