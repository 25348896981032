import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const RECOVERY_PASSWORD = 'RECOVERY_PASSWORD';

export const recoveryPasswordPutRequest = createAction(
  `${RECOVERY_PASSWORD}/PUT_REQUEST`,
);
export const recoveryPasswordPutSuccess = createAction(
  `${RECOVERY_PASSWORD}/PUT_SUCCESS`,
);
export const recoveryPasswordPutFailure = createAction(
  `${RECOVERY_PASSWORD}/PUT_FAILURE`,
);

export const recoveryPasswordPutResult = createAction(
  `${RECOVERY_PASSWORD}/PUT_RESULT`,
);

export const recoveryPasswordClearForm = createAction(
  `${RECOVERY_PASSWORD}/CLEAR_FORM`,
);

export const recoveryPasswordPostRequest = createAction(
  `${RECOVERY_PASSWORD}/POST_REQUEST`,
);
export const recoveryPasswordPostSuccess = createAction(
  `${RECOVERY_PASSWORD}/POST_SUCCESS`,
);
export const recoveryPasswordPostFailure = createAction(
  `${RECOVERY_PASSWORD}/POST_FAILURE`,
);

export const recoveryPasswordPostResult = createAction(
  `${RECOVERY_PASSWORD}/POST_RESULT`,
);

// Reducers ==========================

const isFetchingPut = createReducer(false, {
  [recoveryPasswordPutRequest.toString()]: () => true,
  [recoveryPasswordPutSuccess.toString()]: () => false,
  [recoveryPasswordPutFailure.toString()]: () => false,
});

const dataPut = createReducer(
  {},
  {
    [recoveryPasswordClearForm.toString()]: () => ({}),
    [recoveryPasswordPutRequest.toString()]: () => ({}),
    [recoveryPasswordPutSuccess.toString()]: (_state, action) => action.payload,
  },
);

const resultPut = createReducer(
  {},
  {
    [recoveryPasswordPutRequest.toString()]: () => ({}),
    [recoveryPasswordPutResult.toString()]: (_state, action) => action.payload,
  },
);

const isFetchingPost = createReducer(false, {
  [recoveryPasswordPostRequest.toString()]: () => true,
  [recoveryPasswordPostSuccess.toString()]: () => false,
  [recoveryPasswordPostFailure.toString()]: () => false,
});

const dataPost = createReducer(
  {},
  {
    [recoveryPasswordClearForm.toString()]: () => ({}),
    [recoveryPasswordPostRequest.toString()]: () => ({}),
    [recoveryPasswordPostSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

const resultPost = createReducer(
  {},
  {
    [recoveryPasswordPostRequest.toString()]: () => ({}),
    [recoveryPasswordPostResult.toString()]: (_state, action) => action.payload,
  },
);

// Root Reducer
const reducer = combineReducers({
  isFetchingPut,
  dataPut,
  resultPut,
  isFetchingPost,
  dataPost,
  resultPost,
});

export default reducer;
