import { createSelector } from 'reselect';
import { groupBy, get } from 'lodash';

const initData = {
  form: {
    name: '',
    is_valid: undefined,
    fields: {
      current: {},
      new: {},
      confirm: {},
    },
  },
};

const offerForm = (store: any) => store.offerFormReducer;

export const isFetching = createSelector(
  offerForm,
  (offerForm: any) => offerForm.isFetching,
);

export const isFetchingPost = createSelector(
  offerForm,
  (offerForm: any) => offerForm.isFetchingPost,
);

export const data = createSelector(
  offerForm,
  (offerForm: any) => offerForm.data,
);

export const dataError = createSelector(
  data,
  (data: any) => {
    const status = get(data, 'status', '');
    const message = get(data, 'message', '');
    return { message, status };
  },
);

export const dataPost = createSelector(
  offerForm,
  (offerForm: any) => offerForm.dataPost,
);

export const dataPostError = createSelector(
  dataPost,
  (dataPost: any) => {
    const status = get(dataPost, 'status', '');
    const message = get(dataPost, 'message', '');
    return { message, status };
  },
);

export const formFieldsPost = createSelector(
  dataPost,
  ({ form }: any) => {
    const isValid = get(form, 'is_valid', false) || false;
    const error = get(form, 'error', '') || '';

    return { isValid, error };
  },
);

export const form = createSelector(
  data,
  (data = initData) => data?.form,
);

export const formGet = createSelector(
  form,
  (form) => {
    const isValid = get(form, 'is_valid', false) || false;
    const error = get(form, 'error', '') || '';
    const finishDescriptionMark = get(form, 'finish_description_mark', '') || 'offer.description.finish';
    const finishTitleMark = get(form, 'finish_title_mark', '') || 'offer.title.finish';
    const descriptionMark = get(form, 'description_mark', '') || 'shared_components_offerelement_form_subinfo';
    const titleMark = get(form, 'title_mark', '') || 'shared_components_offerelement_form_info';
    const fields = get(form, 'fields', {}) || {};

    return {
      isValid,
      error,
      finishDescriptionMark,
      finishTitleMark,
      descriptionMark,
      titleMark,
      fields,
    };
  },
);

export const formFields = createSelector(
  form,
  (form: any) => form?.fields,
);

export const formFieldsSteps = createSelector(
  formFields,
  (formFields: Record<string, any>) =>
    Object.values(groupBy(Object.values(formFields || {}), v => v.step)),
);

export const formFieldsStepsPost = createSelector(
  (store: any) => store?.offerFormReducer?.dataPost?.form?.fields,
  (formFields: Record<string, any>) =>
    Object.values(groupBy(Object.values(formFields || {}), v => v.step)),
);
