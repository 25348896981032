import React, { Suspense } from 'react';
import RootLayout from '../../views/RootLayout';
import { Layout } from 'antd';
import Preloader from '../componets/Preloader';

const { Content } = Layout;

// Preloader for Suspense
const PreLoader = () => {
  return (
    <RootLayout>
      <Content>
        <Content
          style={{
            padding: 0,
            margin: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'transparent',
          }}
        >
          <Preloader size="large" />
        </Content>
      </Content>
    </RootLayout>
  );
};

const withSuspense = (Component: React.FC, Loader: React.FC = PreLoader) => {
  return (props: any) => {
    return (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );
  };
};

export default withSuspense;
