/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Col, Row } from 'antd';
import { createTextMask } from 'redux-form-input-masks';
import styles from '../OfferElementCard/OfferElementCard.module.scss';
import { IField } from '../../../states/Products/state/IProducts';
import OfferAllFields from '../OfferAllFields';
import { Translate } from 'react-localize-redux';
import cx from 'classnames';
import { useFormContext } from 'react-hook-form';
import { parsRulesForm } from '../../utils/parsRulesForm.helper';

interface IProps {
  formFields: [string, IField][],
  id: number | string,
  control: any,
  translate: any,
  errors: any,
  handleSubmit: any,
  offerCompendiums?: any,
  errorsList?: any,
  key?:any,
  getValues?: any,
  title?: any,
  placeholder?: any,
  value?: any,
}

const OfferForm: React.FC<IProps> = props => {
  const [address, setAddress] = React.useState(
    {
      region: '',
      сity: '',
      street: '',
      house: '',
      region_fias_id: '',
      city_fias_id: '',
      street_fias_id: '',
    },
  );
  const {
    watch,
    setError,
  } = useFormContext();

  const {
    formFields,
    control,
    translate,
    getValues,
    errors,
  } = props;

  const changeAddress = (value: any, label: any, field: any) => {
    const name = `${value}_fias_id`;
    setAddress({ ...address, [value]: label, [name]: field });
  };

  const initialValues:any = {};
  formFields.forEach((form: any) => {
    // eslint-disable-next-line prefer-destructuring
    initialValues[form[0]] = form[1].value;
  });
  const categoryFields = Object.entries(formFields.reduce((curr, acc) => {
    curr[acc[1].category] = curr[acc[1].category] || [];
    curr[acc[1].category].push(acc);
    return curr;
  }, Object.create(null)));

  const handlerBlur = (e: React.FocusEvent<HTMLInputElement, Element>): void => {
    const { name } = e.target;
    const value = getValues(name);
    const fields = Object.fromEntries(formFields);
    const field = fields[name];

    parsRulesForm({ field, value, name, setError });
  };

  return (
    <div>
      {categoryFields.map((category: Array<any>, keyIdx: number) => {
        const categoryName = category[0];

        return (
          <div key={`category_${keyIdx + 10}`}>
            <div className={cx(styles.infoOfferHeader, styles.infoOfferHeaderCategory)}>
              <Translate id={categoryName.toLowerCase()} />
            </div>
            <Row gutter={15}>
              {category[1].map((item: [any, any]) => {
                const [key, field] = item;
                const name = field?.name;
                const isRequired = field?.rules?.required ?? false;
                const placeholder = field?.placeholder || `eg.${name}.placeholder`;
                const title = field?.title || `eg.${name}.title`;
                const patternField = field?.mask;
                const offerCompendiums =
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      Object.entries(field?.values).map(([id, value]: any) => (
                        { id, value }),
                      );
                const value = field?.value ?? undefined;

                const mask: any = patternField ? createTextMask({
                  pattern: patternField.replace(/[0]/g, '9'),
                  guide: false,
                  stripMask: false,
                }) : null;

                // eslint-disable-next-line no-restricted-syntax
                for (const el of field.visible_conditions) {
                  const value = watch(el.target_field_name);
                  if (value !== el.target_field_value) {
                    return null;
                  }
                }

                return (
                  <Col
                    key={key}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={12}
                    className={styles.fieldWrapper}
                  >
                    <OfferAllFields
                      name={name}
                      field={field}
                      errors={errors}
                      address={address}
                      label={
                          title !== ''
                            ? `${translate(title.toLowerCase()).toString()}`
                            : ''
                        }
                      changeAddress={changeAddress}
                      mask={mask}
                      patternField={patternField}
                      placeholder={
                          placeholder !== ''
                            ? `${translate(placeholder).toString()}`
                            : ''
                        }
                      getValues={getValues}
                      offerCompendiums={offerCompendiums}
                      isRequired={isRequired}
                      control={control}
                      keyValue={key}
                      defaultValue={value}
                      onBlur={handlerBlur}
                    />
                  </Col>
                );
              })
                }
            </Row>
          </div>);
      })}
    </div>
  );
};
export default OfferForm;
