import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * UserDebts API
 */
export default {
  /**
   * Получить данные по задолженностям текущего пользователя
   *
   * @param {*} data
   * @returns
   */
  getData(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/debt`,
    );
  },

  /**
   * Получить список задолженностей текущего пользователя
   *
   * @param {*} data
   * @returns
   */
  getDetails(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/debts/details`,
    );
  },

  /**
   * Получить статус запроса в сервисе ФССП по задолженностям текущего пользователя
   *
   * @param {*} data
   * @returns
   */
  getStatus(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/rating/debts/status`,
    );
  },

  /**
   * Получить данные по лимитам на запросы пользователя о его задолженностях
   *
   * @param {*} data
   * @returns
   */
  getLimitsData(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/limits/debt_user_forward_request_limit`,
    );
  },

  /**
   * Запросить обновление долгов из сервиса ФССП
   *
   * @param {*} data
   * @returns
   */
  postDebtsRequest(data: any) {
    const { token } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/debts/request`,
      {},
    );
  },
};
