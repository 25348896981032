import { createSelector } from 'reselect';
import { get } from 'lodash';

export const isFetching = (store: any) => store.userProfileReducer.isFetching;

// Инициализационные данные на основе документации
// https://api.finanso.com/#/User/api%5Cmodules%5Cv1%5Cmodules%5Cuser%5Ccontrollers%5CProfileController%3A%3AactionIndex
const initEmail = '';
const initBalance = 0;
const initBalanceHold = 0;
const initClickType = '';
const initProfile = {
  firstname: '',
  midlename: '',
  lastname: '',
  birthday: '1900-01-01',
  gender: '',
  country: '',
  language: { id: 0, code: '' },
  country_info: { id: 0, code: '', name: '' },
};
const initLastPhone = {
  phone: '0000000',
  prefix: '0',
  code: '000',
  number: '',
};
const initAvatar = {
  avatar: '',
  status: -1,
  moderation_status: -1,
};

export const data = createSelector(
  (store: any) => {
    return (store.userProfileReducer.data);
  },
  data => {
    const email = get(data, 'email', initEmail) || initEmail;
    const id = get(data, 'id', 0) || 0;
    const profile = get(data, 'profile', initProfile) || initProfile;
    const lastPhone = get(data, 'lastPhone', initLastPhone) || initLastPhone;
    const avatar = get(data, 'avatar', initAvatar) || initAvatar;
    const balance = get(data, 'balance', initBalance) || initBalance;
    const balanceHold = get(data, 'balanceHold', initBalanceHold) || initBalanceHold;
    const clickType = get(data, 'click_type', initClickType) || initClickType;
    const hasMfoCabinets = get(data, 'has_mfo_cabinets', false) || false;
    const wmid = get(data, 'wmid', '') || '';
    const affId = get(data, 'aff_id', '') || '';
    const userSubscribe = get(data, 'user_subscribe', '') || 'Free';
    const isSubscribed = get(data, 'is_subscribed', '') || '';
    const userTarif = get(data, 'user_tarif', '') || '';

    return {
      email,
      profile,
      lastPhone,
      avatar,
      balance,
      balanceHold,
      clickType,
      hasMfoCabinets,
      id,
      wmid,
      affId,
      userSubscribe,
      isSubscribed,
      userTarif,
    };
  },
);

export const userProfile = createSelector(
  data,
  data => {
    const profile = get(data, 'profile', initProfile) || initProfile;

    return profile;
  },
);

export const error = createSelector(
  (store: any) => store.userProfileReducer.error,
  error => error,
);

export const result = createSelector(
  (store: any) => store.userProfileReducer.result,
  result => result,
);

export const isCached = createSelector(
  (store: any) => store.userProfileReducer.isCached,
  isCached => isCached,
);

export const isRemoveProfile = (store: any) =>
  store.userProfileReducer.isRemoveProfile;

export const removeUser = createSelector(
  (store: any) => store.feedbacksReducer.removeUser,
  removeUser => removeUser,
);

export const errorRemoveUser = createSelector(
  (store: any) => store.feedbacksReducer.errorRemoveUser,
  errorRemoveUser => errorRemoveUser,
);

export const userCountry = createSelector(
  userProfile,
  data => {
    const countryInfo = get(data, 'country_info', {}) || {};
    const id = get(countryInfo, 'id', 1) || 1;
    const code = get(countryInfo, 'code', 'ru').toLowerCase() || 'ru';
    const name = get(countryInfo, 'name', 'Russia') || 'Russia';

    return { id, code, name };
  },
);

export const userLanguage = createSelector(
  userProfile,
  data => {
    const languageInfo = get(data, 'language', {}) || {};

    return languageInfo;
  },
);
