import { createSelector } from 'reselect';
import * as E from 'fp-ts/lib/Either';
import get from 'lodash/get';

export const isFetching = (store: any) => store.articlesReducer.isFetching;

export const isFetchingSecond = (store: any) =>
  store.articlesReducer.isFetchingSecond;

export const resultArticle = createSelector(
  (store: any) => store.articlesReducer.resultArticle,
  resultArticle => resultArticle,
);

export const result = createSelector(
  (store: any) => store.articlesReducer.result,
  result => result,
);

export const headers = createSelector(
  (store: any) => store.articlesReducer.result,
  result => {
    let tempResult = {};

    if (E.isRight(result)) {
      const { right: successData } = result;
      const dataRes = get(successData, 'headers', {}) || {};

      tempResult = { ...dataRes };
    }

    return tempResult;
  },
);

export const totalPages = createSelector(headers, headers => {
  const res = Number(get(headers, 'x-pagination-page-count', 1)) || 1;

  return res;
});

export const currentPage = createSelector(headers, headers => {
  const res = Number(get(headers, 'x-pagination-current-page', 1)) || 1;

  return res;
});

export const data = createSelector(
  (store: any) => store.articlesReducer.data,
  data => data,
);

export const articlesList = createSelector(data, (data = {}) => {
  const result = get(data, 'data', []) || [];

  return result;
});

export const isFetchingArticle = (store: any) =>
  store.articlesReducer.isFetchingArticle;

export const dataArticle = createSelector(
  (store: any) => store.articlesReducer.dataArticle,
  dataArticle => dataArticle,
);

export const isAlreadyChashed = (store: any) =>
  store.articlesReducer.isAlreadyChashed;

export const isFetchingCategories = (store: any) =>
  store.articlesReducer.isFetchingCategories;

export const resultCategories = createSelector(
  (store: any) => store.articlesReducer.resultCategories,
  resultCategories => resultCategories,
);
