export const makeRequest = async (
  query: string | number,
  url: string,
  type: string,
  location: any,
  isRestrict: boolean,
  field: string,
) => {
  const token = '664d4f07dbb6ad9fa0fef7be3159639125568656';

  const addressBody = {
    query,
    locations: location,
    restrict_value: isRestrict,
    from_bound: { value: field },
    to_bound: { value: field },
  };

  const options: any = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    },
    body: type === 'address' ? JSON.stringify(addressBody) : null,
  };

  const suggestions = await fetch(url, options)
    .then(response => response.text())
    .then(result => result)
    /* eslint-disable-next-line no-console */
    .catch(error => console.log('error', error));
  return suggestions;
};
