import { createSelector } from 'reselect';

export const isFetching = (store: any) => store.userTaskReducer.isFetching;

export const data = createSelector(
  (store: any) => store.userTaskReducer.data,
  data => data,
);

export const bonusActionsData = createSelector(
  (store: any) => store.userTaskReducer.data.bonusActionsData,
  (bonusActionsData = []) => {
    const result: Array<any> = [];

    const groupTaskSocialSharing: any = {
      options: [],
      total: 0,
    };

    bonusActionsData.forEach((task: any) => {
      let options: any = {
      };

      switch (task.category_alias) {
        case 'social_sharing':
          groupTaskSocialSharing.options.push({
            next: task.next_accrual_date_at,
            last: task.last_accrual_date_at,
            social: task.alias,
            id: task.id,
            isActive: task.is_available,
            total: task.total_bonuses,
          });
          groupTaskSocialSharing.id = task.id;
          groupTaskSocialSharing.price = task.price;
          groupTaskSocialSharing.title = task.category_alias;
          groupTaskSocialSharing.description = task.category_description_mark;
          groupTaskSocialSharing.isActive = true;
          groupTaskSocialSharing.isPermanent = true;
          groupTaskSocialSharing.total += task.total_bonuses;
          break;
        default:
          options = {
            id: task.id,
            price: task.price,
            title: task.category_alias,
            description: task.category_description_mark,
            isActive: task.is_available,
            isPermanent: task.is_permanent,
            total: task.total_bonuses,
          };
          result.push(options);
      }
    });

    if (groupTaskSocialSharing.price) {
      result.push(groupTaskSocialSharing);
    }

    return result;
  },
);

export const isCached = createSelector(
  (store: any) => store.userTaskReducer.isCached,
  isCached => isCached,
);

export const creditRatingTasks = createSelector(
  (store: any) => store.userTaskReducer.data.bonusActionsData,
  (bonusActionsData = []) => {
    let referralLink = {};
    const referralShare: any = {
      title: '',
      subTitle: '',
      price: 0,
      data: [],
    };

    // eslint-disable-next-line no-unused-expressions
    bonusActionsData.forEach((task: any) => {
      if (task.category_alias === 'referral_link') {
        referralLink = {
          title: task.category_alias,
          description: task.category_description_mark,
          price: task.price,
          isActive: true,
          total: task.total_bonuses,
        };
      }

      if (task.category_alias === 'social_sharing') {
        referralShare.data.push({
          next: task.next_accrual_date_at,
          last: task.last_accrual_date_at,
          social: task.alias,
          id: task.id,
          isActive: task.is_available,
        });
        referralShare.price = task.price;
        referralShare.title = task.category_alias;
        referralShare.description = task.category_description_mark;
        referralShare.isActive = true;
      }
    });

    const result = {
      referralLink,
      referralShare,
    };

    return result;
  },
);

export const dataRefUrl = createSelector(
  (store: any) => store.userTaskReducer.dataRefUrl,
  dataRefUrl => dataRefUrl,
);

export const isFetchingRefURl = (store: any) => store.userTaskReducer.isFetching;

export const instructions = createSelector(
  (store: any) => store.userTaskReducer.instructions,
  instructions => instructions || [],
);

export const isFetchingInstructions = (store: any) => store.userTaskReducer.isFetchingInstructions;
