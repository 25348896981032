/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { Button, Row, Col } from 'antd';
import { mapKeys } from 'lodash';
import { isValidNumber } from 'libphonenumber-js';

import { useWindowSize } from '../../hooks/useWindowSize';

import Input from '../../componets/Input';
import FormWrapper from '../../componets/FormWrapper';

import {
  isFetchingPut as isFetchingPutSelector,
  dataPut as dataPutSelector,
  isFetchingPost as isFetchingPostSelector,
  dataPost as dataPostSelector,
} from '../../../states/UserSettingsChangePhone/state/selectors';
import {
  userCountry as userCountrySelector,
} from '../../../states/UserProfile/state/selectors';
import {
  userSettingsChangePhonePutRequest,
  userSettingsChangePhonePostRequest,
  userSettingsChangePhoneClearForm,
} from '../../../states/UserSettingsChangePhone/state/duck';

import styles from './UserSettingsChangePhoneForm.module.scss';

import { DEVICE_NETBOOKS_WIDTH } from '../../constants/Defaults/constants';

// import normalizePhoneNumber from '../../utils/normalizePhoneNumber.helper';
import cx from 'classnames';
import MaskedInput from 'react-input-mask';
import { masks as masksSelector } from '../../../states/Masks/state/selectors';

interface IFormInputs {
  phone: string;
  code: string;
}

/**
 * Форма сменить пароль
 *
 * @returns
 */
const UserSettingsChangePhoneForm: React.FC = () => {
  const windowSize = useWindowSize();
  const { width = 0 } = windowSize;

  const masksSelect = useSelector(masksSelector);

  let isMobile = false;

  if (width < DEVICE_NETBOOKS_WIDTH) {
    isMobile = true;
  }

  const dataPut = useSelector(dataPutSelector);
  const isFetchingPut = useSelector(isFetchingPutSelector);
  const isFetchingPost = useSelector(isFetchingPostSelector);
  const dataPost = useSelector(dataPostSelector);
  const userCountry = useSelector(userCountrySelector);
  const dispatch = useDispatch();

  // cdM
  React.useEffect(() => {
    dispatch({
      type: userSettingsChangePhoneClearForm.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm<IFormInputs>({ mode: 'all' });
  const {
    handleSubmit,
    control,
    getValues,
    formState,
    watch,
    setError,
    reset,
  } = methods;
  const { errors } = formState;
  const { isValid } = useFormState({ control });
  const onSubmit = (data: IFormInputs) =>
    dispatch({
      type: userSettingsChangePhonePutRequest.toString(),
      payload: { ...data, phone: data.phone.replace(/[^0-9.]+/g, '') },
    });

  const handleRequestCode = React.useCallback(
    () =>
      dispatch({
        type: userSettingsChangePhonePostRequest.toString(),
        payload: { phone: getValues('phone').replace(/[^0-9.]+/g, '') },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const isValidationUndefined = typeof dataPut?.form?.is_valid === 'undefined';

  // cdU
  React.useEffect(() => {
    if (!isValidationUndefined) {
      const { form } = dataPut;
      const { is_valid: isFormValid, fields } = form;

      if (isFormValid) {
        mapKeys(fields, (_: any, key: any) => {
          reset({
            [key]: '',
          });
        });

        return undefined;
      }

      mapKeys(fields, (val: any, key: any) => {
        const { is_valid: isFieldValid, error_messages: errMsgs } = val;

        if (!isFieldValid) {
          setError(key, {
            type: 'manual',
            message: errMsgs[0],
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPut]);

  const isValidationPostUndefined =
    typeof dataPost?.form?.is_valid === 'undefined';

  React.useEffect(() => {
    if (!isValidationPostUndefined) {
      const { form } = dataPost;
      const { fields } = form;

      mapKeys(fields, (val: any, key: any) => {
        const { is_valid: isFieldValid, error_messages: errMsgs } = val;

        if (!isFieldValid) {
          setError(key, {
            type: 'manual',
            message: errMsgs[0],
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPost]);

  const mask = masksSelect
    .find(({ type, countryId }: Record<string, any>) => type === 2 && countryId === userCountry.id);

  const phoneIsValid = isValidNumber(watch('phone') || '79', userCountry.code.toUpperCase());
  const disablePhone = errors?.phone || !phoneIsValid;

  return (
    <>
      <Translate>
        {({ translate }) => (
          <FormWrapper
            name={`${translate(
              'shared_containers_usersettingsprofile_yourprofile_edit_phone',
            ).toString()}`}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row gutter={15}>
                {/* Номер телефона */}
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className={styles.fieldWrapper}
                >
                  <div className={styles.label}>
                    <Translate id="shared_containers_usersettingsprofile_yourprofile_edit_phone_number" />
                  </div>

                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'error_field_required',
                    }}
                    render={({ field }) => (
                      <MaskedInput
                        {...field}
                        mask={mask?.value}
                        alwaysShowMask
                        // @ts-ignore
                        maskChar=""
                        inputMode="tel"
                        type="tel"
                      >
                        {(props: any) =>
                          <input
                            className={cx(
                              styles.input,
                              errors?.phone && styles.errorInput,
                            )}
                            {...props}
                          />
                        }
                      </MaskedInput>
                    )}
                  />

                  <div className={styles.errorMessage}>
                    {errors?.phone?.message && (
                      <Translate id={`${errors?.phone?.message}`} />
                    )}
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className={styles.fieldWrapper}
                  style={{
                    paddingBottom: '2px',
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    marginBottom: isMobile ? '0px' : '11px',
                  }}
                >
                  <Button
                    type="primary"
                    shape="round"
                    style={{
                      height: '38px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                    }}
                    disabled={!!disablePhone}
                    onClick={handleRequestCode}
                    loading={isFetchingPost || isFetchingPut}
                    className={styles.mobileButton}
                  >
                    <Translate id="shared_containers_usersettingsprofile_yourprofile_edit_send_sms" />
                  </Button>

                  {/* Невидимый текст, чтобы кнопка подровнялась по высоте */}
                  <div
                    className={styles.errorMessage}
                    style={{
                      color: 'transparent',
                    }}
                  >
                    {errors?.phone?.message && (
                      <Translate id={`${errors?.phone?.message}`} />
                    )}
                  </div>
                </Col>

                {/* Код подтверждения из SMS */}
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className={styles.fieldWrapper}
                >
                  <Controller
                    name="code"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'error_field_required',
                      maxLength: {
                        value: 10,
                        message: 'error_field_max_length_unreached',
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        isError={errors?.code ? true : false}
                        isGood={
                          !errors?.code && getValues('code') !== ''
                            ? true
                            : false
                        }
                        label={`${translate(
                          'shared_containers_usersettingsprofile_yourprofile_edit_sms_code',
                        ).toString()}`}
                        placeholder={`${translate(
                          'shared_containers_usersettingsprofile_yourprofile_edit_sms_code',
                        ).toString()}`}
                        isSuffix={false}
                        suffix=""
                      />
                    )}
                  />

                  <div className={styles.errorMessage}>
                    {errors?.code?.message && (
                      <Translate id={`${errors?.code?.message}`} />
                    )}
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={12}
                  className={styles.fieldWrapper}
                  style={{
                    paddingBottom: '2px',
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    type="primary"
                    shape="round"
                    style={{
                      height: '38px',
                    }}
                    disabled={!isValid}
                    onClick={handleSubmit(onSubmit)}
                    loading={isFetchingPut}
                    className={styles.mobileButton}
                  >
                    <Translate id="shared_containers_usersettingsprofile_yourprofile_edit_phone_confirm" />
                  </Button>

                  {/* Невидимый текст, чтобы кнопка подровнялась по высоте */}
                  <div
                    className={styles.errorMessage}
                    style={{
                      color: 'transparent',
                    }}
                  >
                    {errors?.code?.message && (
                      <Translate id={`${errors?.code?.message}`} />
                    )}
                  </div>
                </Col>
              </Row>
            </form>
          </FormWrapper>
        )}
      </Translate>
    </>
  );
};

export default UserSettingsChangePhoneForm;
