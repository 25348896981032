import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const MODAL = 'MODAL';

// Получение списка
export const modalClearInfo = createAction(`${MODAL}/CLEAR_INFO`);
export const modalPutErrorInfo = createAction(`${MODAL}/PUT_ERROR_INFO`);

export const modalPutActiveInfo = createAction(`${MODAL}/PUT_ACTIVE_INFO`);
export const modalPutQueueInfo = createAction(`${MODAL}/PUT_QUEUE_INFO`);
export const modalUpdateQueueInfo = createAction(
  `${MODAL}/UPDATE_QUEUE_INFO`,
);
export const modalRemoveActiveInfo = createAction(
  `${MODAL}/REMOVE_ACTIVE_INFO`,
);
export const modalPutErrorFromQueueInfo = createAction(
  `${MODAL}/PUT_ERROR_FROM_QUEUE_INFO`,
);

export const modalFields = createAction(`${MODAL}/FIELDS`);

export const modalFieldsSetLoading = createAction(`${MODAL}/FIELDS_SET_LOADING`);
export const modalFieldsUnSetLoading = createAction(`${MODAL}/FIELDS_UNSET_LOADING`);

// Reducers ==========================

// Информация об уведомлении
const modalData = createReducer({}, {
  [modalClearInfo.toString()]: () => ({}),
  [modalPutErrorInfo.toString()]: (_state, action) => action.payload,
  [modalPutErrorFromQueueInfo.toString()]: (_state, action) =>
    action.payload,
});

// Активные уведомления
const modalActive = createReducer([], {
  [modalPutActiveInfo.toString()]: (_state, action) => action.payload,
});

// Очередь уведомлений
const modalQueue = createReducer([], {
  [modalPutQueueInfo.toString()]: (_state, action) => action.payload,
  [modalUpdateQueueInfo.toString()]: (_state, action) => action.payload,
});

// Загрузка Fields
const isFetchingFields = createReducer(false, {
  [modalFieldsSetLoading.toString()]: () => true,
  [modalFieldsUnSetLoading.toString()]: () => false,
});

// Root Reducer
const reducer = combineReducers({
  modalData,
  modalActive,
  modalQueue,
  isFetchingFields,
});

export default reducer;
