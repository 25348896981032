import { put, takeLatest, call, takeEvery, select } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';

import { productsAPI, IResponseFetchFields } from '../../../../services/Products/api';

import {
  productsRequest,
  productsResult,
  productsSuccess,
  productsFailure,
  productsChecklistRequest,
  productsChecklistSuccess,
  productsChecklistFailure,
  productsChecklistResult,
  productsIDSendSuccess,
  productsOrgResult,
  productsOrgFailure,
  productsOrgSuccess,
  productsOrgRequest,
} from '../duck';
import {
  activeOfferID as activeOfferIDSelector,
  alreadySuccessSendID as alreadySuccessSendIDSelector,
  organization,
} from '../selectors';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';
import { modalClearInfo } from '../../../ModalSystem/state/duck';

export function* getData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const productsRes: IResponseFetchFields = yield call(productsAPI.getData, { ...completePayload });
  yield put({
    type: productsResult.toString(),
    payload: createResultV2(productsRes),
  });
}

export function* checkResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const data = successData?.data ?? {};
    const formFields = data?.form?.fields ?? {};
    const formFieldsCondition = typeof formFields === 'object'
      ? formFields.length
      : -1;

    // NB: Если форма уже заполнена,
    // то не требовать от пользователя ее заполнения,
    // а сразу передавать запрос в АПИ шлюза
    if (formFieldsCondition === 0) {
      const offerID = yield select(activeOfferIDSelector);

      yield put({
        type: productsChecklistRequest.toString(),
        payload: { offerID },
      });
      yield put({
        type: modalClearInfo.toString(),
      });
    } else {
      yield put({
        type: productsSuccess.toString(),
        payload: { ...data },
      });
    }
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: productsFailure.toString(),
      payload: error,
    });
  }
}

export function* postData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const res = yield call(productsAPI.postData, { ...completePayload });

  yield put({
    type: productsChecklistResult.toString(),
    payload: createResultV2(res),
  });
}

export function* checkChecklistResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const data = successData?.data ?? {};

    const alreadySuccessSendID: any = yield select(
      alreadySuccessSendIDSelector,
    );
    const offerID = yield select(activeOfferIDSelector);
    const idsData = [...alreadySuccessSendID, offerID];

    yield put({
      type: productsIDSendSuccess.toString(),
      payload: idsData,
    });
    yield put({
      type: modalClearInfo.toString(),
    });

    yield put({
      type: productsChecklistSuccess.toString(),
      payload: { ...data },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: productsChecklistFailure.toString(),
      payload: error,
    });
  }
}

export function* getOrganization(action: any) {
  const completePayload: any = yield call(checkToken, action);

  const orgRes = yield call(productsAPI.getDataOrganization, {
    ...completePayload,
  });

  let org = yield select(organization);
  if (Object.keys(org).length !== 0) {
    orgRes.data = [...org, orgRes.data];
  } else {
    orgRes.data = [orgRes.data];
  }

  yield put({
    type: productsOrgResult.toString(),
    payload: createResultV2(orgRes),
  });
}

export function* checkResultOrg(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: productsOrgSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: productsOrgFailure.toString(),
    });
  }
}

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    productsRequest,
    safe(onError, getData, {
      terminator: productsFailure,
    }),
  );

  yield takeEvery(productsResult, safe(onError, checkResult));

  yield takeLatest(
    productsChecklistRequest,
    safe(onError, postData, {
      terminator: productsChecklistFailure,
    }),
  );

  yield takeEvery(productsChecklistResult, safe(onError, checkChecklistResult));

  yield takeEvery(
    productsOrgRequest,
    safe(onError, getOrganization, {
      terminator: productsOrgFailure,
    }),
  );

  yield takeEvery(productsOrgResult, safe(onError, checkResultOrg));
}
