import { put, takeLatest, takeEvery, call, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { chatListLawyerAPI } from '../../../../services/ChatListLawyer/api';

import {
  chatListLawyerFetchChatRequest,
  chatListLawyerFetchChatRequestFirstTime,
  chatListLawyerFetchChatSuccess,
  chatListLawyerFetchChatFailure,
  chatListLawyerPreFetchChatRequest,
  chatListLawyerPreFetchChatSuccess,
  chatListLawyerPreFetchChatFailure,
  chatListLawyerPreFetchChatClear,
  chatListLawyerSendMsgRequest,
  chatListLawyerSendMsgSuccess,
  chatListLawyerSendMsgFailure,
  chatListLawyerFirstPreFetchChatRequest,
  chatListLawyerFirstPreFetchChatSuccess,
  chatListLawyerUpdateDataRequest,
  chatListLawyerUpdateDataSuccess,
  // ISF-32: В чате саппорта это походу не нужно
  // chatListLawyerChatInputSuccess,
  chatListLawyerActiveButtonRequest,
  chatListLawyerActiveButtonSuccess,
  chatListLawyerLastMessageRequest,
  chatListLawyerLastMessageSuccess,
  chatListLawyerLastMessageFailure,
  chatLawyerSendAttachRequest,
  // ISF-139:
  chatListLawyerResMsgFromSocket,
} from '../duck';
import { logoutRequest } from '../../../Logout/state/duck';
import {
  preFetchedData,
  preFetchedCountOfFetches,
  preFetchedIteration,
  isFetchingFirstPreFetch as isFetchingFirstPreFetchSelector,
  isFetching as isFetchingSelector,
  isAllowedAcceptFromSocketServer as isAllowedAcceptFromSocketServerSelector,
  // ISF-29: NB: Отключено в рамках тестирования, ver. 0.8.4
  // preFetchedMessagesTotal,
} from '../selectors';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';

import { CHAT_FETCH_LIMIT } from '../../../../views/HelpChat/constants';

/**
 * getPreFetchedData helper for getData
 *
 * @export
 * @param {*} action
 */
export function* getPreFetchedData(action: any) {
  const {
    callFrom = 'pre-fetch',
    page = 1,
    countOfFetches,
    tempPayload,
  } = action.payload;

  try {
    const beforeFetchedData = yield select(preFetchedData);
    const chatListLawyerData = yield call(chatListLawyerAPI.getData, {
      page,
      perPage: CHAT_FETCH_LIMIT,
      ...tempPayload,
    });

    const { data, headers, status } = chatListLawyerData;
    const total = Number(headers['x-pagination-total-count']) || 0;

    try {
      if (status === 200) {
        const stateMessages = get(beforeFetchedData, 'messages', []);

        yield put({
          type: chatListLawyerPreFetchChatSuccess.toString(),
          payload: {
            total,
            callFrom,
            countOfFetches,
            iteration: page,
            messages: [...stateMessages, ...data],
          },
        });
      } else {
        yield put({
          type: chatListLawyerPreFetchChatFailure.toString(),
          payload: {
            error: data,
            status,
          },
        });

        if (status === 401) {
          yield put({ type: logoutRequest.toString() });
        }
      }
    } catch (err) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log(
        '[SAGA chatListLawyer chat]:',
        yield put({
          type: chatListLawyerPreFetchChatFailure.toString(),
          payload: {
            error: err,
          },
        }),
      );
    }
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR Lawyer chat]:', error);
    }
  }
} // getPreFetchedData =========

/**
 * Сага getData через себя вызывает генератор getPreFetchedData
 * для запроса pre fetched данных о товарах
 *
 * @export
 * @param {*} action
 * @param {sring} action.callFrom
 * cdM -- иницирующий запрос
 * cdU -- запрос только при поиске
 * scroll -- запрос при скроллинге
 * @returns
 */
export function* getData(action: any) {
  const { callFrom } = action.payload;

  try {
    const beforeFetchedIteration = yield select(preFetchedIteration);
    const beforeFetchedCountOfFetches = yield select(preFetchedCountOfFetches);
    // ISF-29: NB: Отключено в рамках тестирования, ver. 0.8.4
    // const beforeFetchedMessagesTotal = yield select(preFetchedMessagesTotal);

    const maximumPrefetchedPages = beforeFetchedCountOfFetches;

    // ISF-13: token
    const tempPayload: any = yield call(checkToken, {});

    let maxIteration = 5;
    let page = 1;

    // Запрос при скролле
    if (callFrom === 'scroll' && beforeFetchedIteration > 0) {
      page = beforeFetchedIteration + page;

      if (page > maximumPrefetchedPages) {
        return null;
      }

      yield put({ type: chatListLawyerPreFetchChatRequest.toString() });
      yield call(getPreFetchedData, {
        type: chatListLawyerPreFetchChatRequest.toString(),
        payload: {
          page,
          callFrom,
          countOfFetches: beforeFetchedCountOfFetches,
          tempPayload,
        },
      });
      return null;
    }
    // ==========================================================

    yield put({ type: chatListLawyerFetchChatRequestFirstTime.toString() });

    const chatListLawyerData = yield call(chatListLawyerAPI.getData, {
      page,
      perPage: CHAT_FETCH_LIMIT,
      ...tempPayload,
    });

    const { data, headers, status } = chatListLawyerData;
    const total = Number(headers['x-pagination-total-count']) || 0;
    const countOfFetches = Number(headers['x-pagination-page-count']) || 0;

    try {
      if (status === 200) {
        // ISF-114: NB: В чате "Поддержка" не нужно отправлять приветсвенное сообщение
        // // ISF-61: FIX: При первом входе в чат, сразу загрузить первое приветвенное сообщение
        // if (data.length === 0) {
        //   yield put({
        //     type: chatListLawyerSendMsgRequest.toString(),
        //     payload: { text: 'pick_me_a_loan' },
        //   });
        // }

        yield put({
          type: chatListLawyerFetchChatSuccess.toString(),
          payload: {
            total,
            callFrom,
            messages: data,
          },
        });

        // Если уже есть предзагруженные данные соответсвующие данному запросу, то работать с ними
        // ISF-29: NB: Отключено в рамках тестирования, ver. 0.8.4
        // if (
        //   countOfFetches === beforeFetchedCountOfFetches &&
        //   total === beforeFetchedMessagesTotal &&
        //   callFrom === 'cdM'
        // ) {
        //   return null;
        // }

        // Иницирующий запрос
        if (callFrom === 'cdM') {
          // Очистить ранее предзагруженные данные
          yield put({ type: chatListLawyerPreFetchChatClear.toString() });

          // Посчитать количество необходимых запросов
          maxIteration = 5;
          if (maxIteration > countOfFetches) {
            maxIteration = countOfFetches;
          }

          // Набить данными на 5 страниц вперед или на оставшееся количество
          yield put({
            type: chatListLawyerFirstPreFetchChatRequest.toString(),
          });
          for (let index = 0; index < maxIteration; index++) {
            yield call(getPreFetchedData, {
              type: chatListLawyerPreFetchChatRequest.toString(),
              payload: {
                countOfFetches,
                callFrom,
                page: index + 1,
                tempPayload,
              },
            });
          }
          yield put({
            type: chatListLawyerFirstPreFetchChatSuccess.toString(),
          });
        }
        // ==========================================================
      } else {
        yield put({
          type: chatListLawyerFetchChatFailure.toString(),
          payload: {
            error: data,
            status,
          },
        });

        if (status === 401) {
          yield put({ type: logoutRequest.toString() });
        }
      }
    } catch (err) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[SAGA ERROR Lawyer chat]:', err);
    }
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR Lawyer chat]:', error);
    }
  }
} // getData =========

export function* createNewChatMessage(action: any): IterableIterator<any> {
  const { text } = action.payload;
  const tempPayload: any = yield call(checkToken, {});
  const preFetchedDataTemp: any = yield select(preFetchedData);
  const { messages = [], total } = preFetchedDataTemp;

  const body = new FormData();

  body.append('text', text);

  try {
    const chatListLawyerData: any = yield call(chatListLawyerAPI.postFormData, {
      formData: body,
      ...tempPayload,
    });

    const { data, status } = chatListLawyerData;

    if (status === 200) {
      const updatedMessages = [...data.reverse(), ...messages];

      yield put({
        type: chatListLawyerSendMsgSuccess.toString(),
        payload: {
          messages: updatedMessages,
          total: total + data.length,
        },
      });
    } else {
      yield put({
        type: chatListLawyerSendMsgFailure.toString(),
        payload: {
          _tag: 'Left',
          left: { data, status },
        },
      });

      if (status === 401) {
        yield put({ type: logoutRequest.toString() });
      }
    }
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR Lawyer createNewChatMessage]:', error);
      yield put({
        type: chatListLawyerSendMsgFailure.toString(),
        payload: {
          error,
        },
      });
    }
  }
} // createNewChatMessage =========

// DUPLICATE:  возможно стоит объеденить с createNewChatMessage
// Отличие только в методе формирования отправляемых на сервер данных
export function* createNewMessageWithAttach(
  action: any,
): IterableIterator<any> {
  const { files } = action.payload;
  const tempPayload: any = yield call(checkToken, {});
  const preFetchedDataTemp: any = yield select(preFetchedData);
  const { messages = [], total } = preFetchedDataTemp;

  const body = new FormData();

  body.append('text', 'support_user_attachments');
  files.forEach((item: any, index: number) => {
    body.append(`attachment_file_${index}`, item);
  });

  try {
    const chatListLawyerData: any = yield call(chatListLawyerAPI.postFormData, {
      formData: body,
      ...tempPayload,
    });

    const { data, status } = chatListLawyerData;

    if (status === 200) {
      const updatedMessages = [...data.reverse(), ...messages];

      yield put({
        type: chatListLawyerSendMsgSuccess.toString(),
        payload: {
          messages: updatedMessages,
          total: total + data.length,
        },
      });
    } else {
      yield put({
        type: chatListLawyerSendMsgFailure.toString(),
        payload: {
          error: data,
          status,
        },
      });

      if (status === 401) {
        yield put({ type: logoutRequest.toString() });
      }
    }
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR Lawyer createNewMessageWithAttach]:', error);
      yield put({
        type: chatListLawyerSendMsgFailure.toString(),
        payload: {
          error,
        },
      });
    }
  }
} // createNewMessageWithAttach =========

/**
 * Сага updateData обновляет редьюсер внося информацию
 * о высоте отрендеренных сообщений
 *
 * @export
 * @param {*} action
 * @returns
 */
export function* updateData(action: any) {
  const { pseudoRenderedData } = action.payload;

  try {
    const preFetchedDataTemp = yield select(preFetchedData);
    const { messages, callFrom, total } = preFetchedDataTemp;

    const messagesWithoutRenderHeight = messages.filter((item: any) => {
      const messageRenderHeight =
        get(item, 'messageRenderHeight', null) || null;

      return !messageRenderHeight;
    });

    const messagesWithRenderHeight = messages.filter((item: any) => {
      const messageRenderHeight =
        get(item, 'messageRenderHeight', null) || null;

      return messageRenderHeight;
    });

    const updatedMessages = [
      ...messagesWithoutRenderHeight.map((item: any) => {
        const { id }: { id: string } = item;
        const messageRenderHeight: any = pseudoRenderedData![id];
        const result = { ...item, messageRenderHeight };

        return result;
      }),
    ];

    let newMessages = [...messagesWithRenderHeight, ...updatedMessages];
    let newTotalMsgs = total;

    if (callFrom === 'createNewMessage') {
      // NB: naming style `value.value` %)
      // const retroValueType = get(
      //   messagesWithRenderHeight,
      //   '[0].value.type',
      //   'NO_TYPE',
      // );

      // if (retroValueType !== 'products') {
      //   const currentValue = get(
      //     updatedMessages,
      //     '[1].value.value',
      //     'NO_VALUE',
      //   );
      //   const retroObj = {
      //     ...messagesWithRenderHeight[0],
      //     value: { ...messagesWithRenderHeight[0].value, value: currentValue },
      //   };

      //   newMessages = [
      //     ...updatedMessages,
      //     retroObj,
      //     ...messagesWithRenderHeight.slice(1),
      //   ];
      // } else {
      //   newMessages = [...updatedMessages, ...messagesWithRenderHeight];
      // }
      newMessages = [...updatedMessages, ...messagesWithRenderHeight];

      newTotalMsgs = newMessages.length;
    }

    // ISF-32: В чате саппорта это походу не нужно
    // const dataChatInput = get(newMessages, '[0]', {});

    yield put({
      type: chatListLawyerUpdateDataSuccess.toString(),
      payload: {
        messages: newMessages,
        total: newTotalMsgs,
      },
    });
    // ISF-32: В чате саппорта это походу не нужно
    // yield put({
    //   type: chatListLawyerChatInputSuccess.toString(),
    //   payload: dataChatInput,
    // });
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR Lawyer chat updateData]:', error);
    }
  }
} // updateData =========

export function* setActiveButton(action: any): IterableIterator<any> {
  try {
    const { text } = action.payload;

    yield put({
      type: chatListLawyerActiveButtonSuccess.toString(),
      payload: text,
    });
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR Lawyer setActiveButton]:', error);
    }
  }
} // setActiveButton =========

export function* getDataLastMsg(): IterableIterator<any> {
  try {
    const tempPayload: any = yield call(checkToken, {});

    const srvRes: any = yield call(chatListLawyerAPI.getDataLastMsg, {
      ...tempPayload,
    });

    const { data, status } = srvRes;

    if (status === 200) {
      yield put({
        type: chatListLawyerLastMessageSuccess.toString(),
        payload: data,
      });
    } else {
      yield put({
        type: chatListLawyerLastMessageFailure.toString(),
        payload: {
          error: data,
          status,
        },
      });

      if (status === 401) {
        yield put({ type: logoutRequest.toString() });
      }
    }
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR Lawyer getDataLastMsg]:', error);
      yield put({
        type: chatListLawyerLastMessageFailure.toString(),
        payload: {
          error,
        },
      });
    }
  }
} // getDataLastMsg =========

export function* createNewChatMessageFromSocket(
  action: any,
): IterableIterator<any> {
  // Guards
  const isAllowedAcceptFromSocketServer: any = yield select(
    isAllowedAcceptFromSocketServerSelector,
  );
  if (!isAllowedAcceptFromSocketServer) {
    return null;
  }
  const isFetching: any = yield select(isFetchingSelector);
  if (isFetching) {
    return null;
  }
  const isFetchingFirstPreFetch: any = yield select(
    isFetchingFirstPreFetchSelector,
  );
  if (isFetchingFirstPreFetch) {
    return null;
  }

  // Бизнес логика
  const { messages: scktMsgs } = action.payload;
  const preFetchedDataTemp: any = yield select(preFetchedData);
  const { messages = [], total } = preFetchedDataTemp;
  const updatedMessages = [...scktMsgs.reverse(), ...messages];

  yield put({
    type: chatListLawyerSendMsgSuccess.toString(),
    payload: {
      messages: updatedMessages,
      total: total + scktMsgs.length,
    },
  });
} // createNewChatMessageFromSocket =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(chatListLawyerFetchChatRequest, getData);
  yield takeLatest(chatListLawyerLastMessageRequest, getDataLastMsg);
  yield takeEvery(chatListLawyerSendMsgRequest, createNewChatMessage);
  yield takeEvery(chatListLawyerUpdateDataRequest, updateData);
  yield takeEvery(chatListLawyerActiveButtonRequest, setActiveButton);
  yield takeEvery(chatLawyerSendAttachRequest, createNewMessageWithAttach);

  yield takeEvery(
    chatListLawyerResMsgFromSocket,
    safe(onError, createNewChatMessageFromSocket, {
      terminator: chatListLawyerSendMsgFailure,
    }),
  );
}
