import React, { SVGAttributes } from 'react';

const ArrowLeft: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="#0F1011" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0894 3.57745C13.4149 3.90289 13.4149 4.43053 13.0894 4.75596L7.84534 10L13.0894 15.2441C13.4149 15.5696 13.4149 16.0972 13.0894 16.4226C12.764 16.7481 12.2363 16.7481 11.9109 16.4226L6.07757 10.5893C5.75214 10.2639 5.75214 9.73622 6.07757 9.41078L11.9109 3.57745C12.2363 3.25201 12.764 3.25201 13.0894 3.57745Z"
    />
  </svg>
);

export default ArrowLeft;
