import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const SOCKET = 'SOCKET';

export const socketSaveSocket = createAction(`${SOCKET}/SAVE_SOCKET`);

// Reducers ==========================

const socket = createReducer(
  {},
  {
    [socketSaveSocket.toString()]: (_state, action) => action.payload,
  },
);

// Root Reducer
const reducer = combineReducers({
  socket,
});

export default reducer;
