import { call } from 'redux-saga/effects';
import { getData } from '../../../../shared/utils/commonLocalStorage.helper';
import {
  LOCAL_STORAGE_AFF_ID, LOCAL_STORAGE_CLICK_CITY,
  LOCAL_STORAGE_CLICK_ID,
  LOCAL_STORAGE_CLICK_PRODUCT_TYPE,
  LOCAL_STORAGE_CLICK_SUM,
  LOCAL_STORAGE_CLICK_TERM,
  LOCAL_STORAGE_CLICK_TYPE,
  LOCAL_STORAGE_GOOGLE_CLIENT_ID,
  LOCAL_STORAGE_REFERER,
  LOCAL_STORAGE_WMID,
  LOCAL_STORAGE_YA_CLIENT_ID,
} from '../../../../shared/constants/Defaults/constants';

export function* getStorageMetaDataRegister(): Record<string, any> {
  const storedClickID = yield call(getData, LOCAL_STORAGE_CLICK_ID);
  const storedClickType = yield call(getData, LOCAL_STORAGE_CLICK_TYPE);
  const storedClickProductType = yield call(getData, LOCAL_STORAGE_CLICK_PRODUCT_TYPE);
  const storedClickSum = yield call(getData, LOCAL_STORAGE_CLICK_SUM);
  const storedClickTerm = yield call(getData, LOCAL_STORAGE_CLICK_TERM);
  const storedClickCity = yield call(getData, LOCAL_STORAGE_CLICK_CITY);
  const storedWmid = yield call(getData, LOCAL_STORAGE_WMID);
  const storedAffID = yield call(getData, LOCAL_STORAGE_AFF_ID);
  const storedReferer = yield call(getData, LOCAL_STORAGE_REFERER);
  const storedyaClientID = yield call(getData, LOCAL_STORAGE_YA_CLIENT_ID);
  const storedgoogleClientID = yield call(getData, LOCAL_STORAGE_GOOGLE_CLIENT_ID);

  return {
    click_id: storedClickID ? storedClickID : '',
    click_type: storedClickType ? storedClickType : '',
    click_product_type: storedClickProductType ? storedClickProductType : '',
    click_sum: storedClickSum ? storedClickSum : '',
    click_term: storedClickTerm ? storedClickTerm : '',
    click_city: storedClickCity ? storedClickCity : '',
    wmid: storedWmid ? storedWmid : '',
    aff_id: storedAffID ? storedAffID : '',
    referer: storedReferer ? storedReferer : '',
    ya_client_id: storedyaClientID ? storedyaClientID : '',
    google_client_id: storedgoogleClientID ? storedgoogleClientID : '',
  };
}
