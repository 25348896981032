import React, { useEffect, useState } from 'react';
import { BottomSheet } from '@finanso/react-uikit';
import { Drawer } from 'antd';
import { useStore } from 'effector-react';
import { useHistory } from 'react-router-dom';

import { useWindowSize } from '../../../hooks/useWindowSize';
import { useQuery } from '../../../hooks/useQuery';

import MultiloginAddMfoForm from '../MultiloginAddMfoForm';
import { ModalType } from '../../../enum/ModalType.enum';
import { $getMfo } from '../../../../states/Multilogin/event';
import { $mfoAdd } from '../../../../states/Multilogin/store';
import { CABINET__MULTTILOGIN_MY_URL } from '../../../constants/Routes/constants';

const MultiloginAddMfoModal: React.FC = () => {
  const mfoAdd = useStore($mfoAdd);

  const [visible, setVisible] = useState(true);
  const { width } = useWindowSize();
  const history = useHistory();

  const { deleteQuery } = useQuery({
    name: ModalType.ADDMFO,
    observer: (query) => {
      setVisible(!!query);
      if (query) {
        $getMfo({ id: query });
      }
    },
  });

  const onClose = () => {
    deleteQuery(ModalType.ADDMFO);
  };

  useEffect(() => {
    if (mfoAdd?.form.isValid) {
      history.replace(CABINET__MULTTILOGIN_MY_URL);
    }
  }, [mfoAdd]);

  if (width < 1200) {
    return (
      <BottomSheet
        open={visible}
        onDismiss={onClose}
        snapPoints={({ maxHeight }) => [maxHeight]}
      >
        <MultiloginAddMfoForm />
      </BottomSheet>
    );
  }
  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={visible}
    >
      <MultiloginAddMfoForm />
    </Drawer>
  );
};

export default MultiloginAddMfoModal;
