import Cookies from 'universal-cookie';
import { environment } from '../../../../environment';

const cookies = new Cookies();

const domain = environment.PRODUCTION ? '.finanso.com' : 'localhost';

/**
 * Запись Данных
 *
 * @param {*} cookiesKey - имя ключа в куках
 * @param {*} data - данные присвоенные данному ключу
 */
export const setData = (cookiesKey: string, data: any) => {
  cookies.set(cookiesKey, data, {
    domain,
    secure: true,
  });
};

/**
 * Получение Данных
 *
 * @param {*} cookiesKey
 * @returns data - данные токена
 */
export const getData = (cookiesKey: string) => {
  const stringData: any = cookies.get(cookiesKey);

  if (stringData) {
    return stringData;
  }

  return false;
};

/**
 * Удаление Данных
 *
 * @param {*} cookiesKey - имя ключа в куках
 */
export const removeData = (cookiesKey: string) => {
  const stringData = cookies.get(cookiesKey);

  if (stringData) {
    cookies.remove(cookiesKey, {
      domain,
      path: '/',
    });
  }
};
