import React from 'react';
import cx from 'classnames';

import Trash from '../../../../assets/images/Trash';
// import Maestro from './assets/images/maestro.svg';
// import Pen from './assets/images/pen.svg';

import styles from './UserSettingsActiveSubscribe.module.scss';
import { useStore } from 'effector-react';
import { $subscribe } from '../../../states/UserSubscription/store';
import moment from 'moment';
import { Translate } from 'react-localize-redux';
import { $deleteSubscribe } from '../../../states/UserSubscription/event';

const UserSettingsActiveSubscribe: React.FC = () => {
  const subscribe = useStore($subscribe);

  if (!subscribe.length) {
    return null;
  }
  return (
    <>
      <div className={styles.root}>
        <div className={styles.title}>
          <Translate id="subscribe.title" />
        </div>
        {subscribe.map(({
          userSubscribesId,
          name,
          card,
          endDate,
          sum,
          expMonth,
          expYear,
          period,
        }) => (
          <div
            className={styles.wrapper}
            key={userSubscribesId}
          >
            <div className={cx(styles.name, styles.firstElem)}>
              <Translate id="subscribe.card" />
            </div>
            <div className={cx(styles.value, styles.firstElem)}>
              <div className={styles.card}>
                {/* <img src={Maestro} alt="maestro" /> */}
                <span>{card}</span>
              </div>
              <div className={styles.valid}>
                <Translate id="subscribe.card.existed" /> {expMonth}/{expYear}
              </div>
            </div>
            <div className={styles.name}>
              <Translate id="subscribe.tariff" />
            </div>
            <div className={styles.value}>{name}</div>
            <div className={styles.name}>
              <Translate id="subscribe.period" />
            </div>
            <div className={styles.value}>
              <Translate id={`subscribe.period.${period}`} />
            </div>
            <div className={styles.name}>
              <Translate id="subscribe.price" />
            </div>
            <div className={styles.value}>{sum}</div>
            <div className={styles.name}>
              <Translate id="subscribe.pay" />
            </div>
            <div className={styles.value}>{moment(endDate).format('DD.MM.YYYY mm:hh')}</div>
            <div className={styles.name}>
              <Translate id="subscribe.action" />
            </div>
            <div className={cx(styles.value, styles.action)}>
              {/* <img src={Pen} alt="edit" /> */}
              <Trash
                onClick={() => $deleteSubscribe({ id: userSubscribesId })}
                fill="#E3405E"
                width="20px"
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default UserSettingsActiveSubscribe;
