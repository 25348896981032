/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { call, takeEvery, put, select } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';

import { registerAPI } from '../../../../services/Register/api';

import {
  registerValidationRequest,
  registerValidationSuccess,
  registerValidationFailure,
  registerValidationResult,
  registerSMSCodeRequest,
  registerSMSCodeSuccess,
  registerSMSCodeFailure,
  registerSMSCodeResult,
  registerCallCodeRequest,
  registerCallCodeSuccess,
  registerCallCodeFailure,
  registerCallCodeResult,
  registerEmailCodeRequest,
  registerEmailCodeSuccess,
  registerEmailCodeFailure,
  registerEmailCodeResult,
  registerPhoneInfoRequest,
  registerPhoneInfoSuccess,
  registerPhoneInfoFailure,
  registerPhoneInfoResult,
  registerRequest,
  registerSuccess,
  registerFailure,
  registerResult,
  registerGetJwtSuccess,
  registerSetErrorMessage,
  registerAddQuestionsToDialogue,
  registerAddQuestionsToDialogueResult,
  registerAddAnswerToDialogue,
  registerAddAnswerToDialogueResult,
  registerAddErrorToDialogueResult,
  registerSetActiveButton,
  registerSetActiveButtonResult,
  registerSetHistoryOfActiveButtonsResult,
  registerSetListOfActiveButtonsResult,
  registerSetCurrentStep,
  registerResultNew, registerRequestNew,
} from '../duck';
import { authSetAuthStatusSuccess } from '../../../Auth/state/duck';
import { activeLanguage } from '../../../Languages/state/selectors';
import {
  dialogue as dialogueSelector,
  questionsStepOne as questionsStepOneSelector,
  questionsStepTwo as questionsStepTwoSelector,
  currentStep as currentStepSelector,
  historyOfActiveButtons as historyOfActiveButtonsSelector,
} from '../selectors';

import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';
import preparePhoneForAPI from '../../../../core/state/utils/preparePhoneForAPI/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';
// import { setData as setToken } from '../../../../shared/utils/commonLocalStorage.helper';

import {
  setData as setToken,
} from '../../../../shared/utils/commonLocalStorage.helper';
import {
  LOCAL_STORAGE_AUTH_KEY,
} from '../../../../shared/constants/Defaults/constants';
import { STEP_ONE_STR, STEP_TWO_STR } from './constants';
import {
  getStorageMetaDataRegister,
} from './helper';

export function* postValidation(action: any): IterableIterator<any> {
  const { payload } = action;

  const metaData: Record<string, string> = (yield call(getStorageMetaDataRegister)) || {};

  const serverResponse: any = yield call(
    registerAPI.postDataValidation,
    {
      ...payload,
      ...metaData,
    },
  );

  yield put({
    type: registerValidationResult.toString(),
    payload: createResultV2(serverResponse),
  });
}

export function* checkValidationResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: registerValidationSuccess.toString(),
      payload: data,
    });
  }

  if (E.isLeft(payload)) {
    const { left: errorData } = payload;
    const { data } = errorData;

    yield put({
      type: registerValidationFailure.toString(),
      payload: data,
    });
  }
}

export function* postSMSCode(action: any): IterableIterator<any> {
  const { payload } = action;

  const serverResponse: any = yield call(registerAPI.postDataSMSCode, payload);

  yield put({
    type: registerSMSCodeResult.toString(),
    payload: createResultV2(serverResponse),
  });
}

export function* checkSMSCodeResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: registerSMSCodeSuccess.toString(),
      payload: data,
    });
  }

  if (E.isLeft(payload)) {
    // const { left: errorData } = payload;
    // const { data } = errorData;

    yield put({
      type: registerSMSCodeFailure.toString(),
      payload,
    });
  }
}

export function* postCallCode(action: any): IterableIterator<any> {
  const { payload } = action;

  const serverResponse: any = yield call(registerAPI.postDataCallCode, payload);

  yield put({
    type: registerCallCodeResult.toString(),
    payload: createResultV2(serverResponse),
  });
}

export function* checkCallCodeResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: registerCallCodeSuccess.toString(),
      payload: data,
    });
  }

  if (E.isLeft(payload)) {
    const { left: errorData } = payload;
    const { data } = errorData;

    yield put({
      type: registerCallCodeFailure.toString(),
      payload: data,
    });
  }
}

export function* postEmailCode(action: any): IterableIterator<any> {
  const { payload } = action;

  const serverResponse: any = yield call(
    registerAPI.postDataEmailCode,
    payload,
  );

  yield put({
    type: registerEmailCodeResult.toString(),
    payload: createResultV2(serverResponse),
  });
  // eslint-disable-next-line
  // console.log(serverResponse.data);
  // if (serverResponse.data.message === 'send_error') {
  // eslint-disable-next-line
  // console.log('skipEmail');
  // }
}

export function* checkEmailCodeResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: registerEmailCodeSuccess.toString(),
      payload: data,
    });
  }

  if (E.isLeft(payload)) {
    // const { left: errorData } = payload;
    // const { data } = errorData;

    yield put({
      type: registerEmailCodeFailure.toString(),
      payload,
    });
  }
}

export function* getPhoneInfo(action: any): IterableIterator<any> {
  const { payload } = action;

  const serverResponse: any = yield call(registerAPI.getDataPhoneInfo, payload);

  yield put({
    type: registerPhoneInfoResult.toString(),
    payload: createResultV2(serverResponse),
  });
}

export function* checkPhoneInfoResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: registerPhoneInfoSuccess.toString(),
      payload: data,
    });
  }

  if (E.isLeft(payload)) {
    const { left: errorData } = payload;
    const { data } = errorData;

    yield put({
      type: registerPhoneInfoFailure.toString(),
      payload: data,
    });
  }
}

export function* postRegistration(action: any): IterableIterator<any> {
  const { payload } = action;
  const readyDataForSend = payload;
  const { phone, isNew = false } = readyDataForSend;
  const activeLang: any = yield select(activeLanguage);

  if (phone) {
    readyDataForSend.phone = yield call(preparePhoneForAPI, {
      phone,
      activeLang,
    });
  }

  const metaData: Record<string, string> = (yield call(getStorageMetaDataRegister)) || {};

  const readyDataForSendFull = {
    ...readyDataForSend,
    ...metaData,
  };

  const serverResponse: any = yield call(
    isNew ? registerAPI.postDataRegistrationNew : registerAPI.postDataRegistration,
    readyDataForSendFull,
  );

  yield put({
    type: registerResult.toString(),
    payload: createResultV2(serverResponse),
  });
}

export function* checkRegistrationResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;
    const result = data?.result ?? 'error';
    const token = data?.token ?? '';
    const message = data?.message ?? 'registration_common_error_occured';

    if (result === 'success') {
      yield call(setToken, LOCAL_STORAGE_AUTH_KEY, token);

      const isAuthorized = true;

      yield put({
        type: registerGetJwtSuccess.toString(),
        payload: data,
      });
      yield put({
        type: authSetAuthStatusSuccess.toString(),
        payload: {
          isAuthorized,
        },
      });

      yield put({
        type: registerSuccess.toString(),
        payload: data,
      });
    }

    if (result === 'error') {
      let msg = '';
      for (let item in message) {
        msg = message?.[item]?.[0] ?? 'registration_common_error_occured';
      }

      yield put({
        type: registerSetErrorMessage.toString(),
        payload: { message: msg },
      });
    }
  }

  if (E.isLeft(payload)) {
    const message = 'registration_common_error_occured';

    yield put({
      type: registerFailure.toString(),
      payload: { message },
    });
  }
}

export function* addQuestionsToDialogue(action: any): IterableIterator<any> {
  const dialogue: any = yield select(dialogueSelector);
  const questionsStepOne: any = yield select(questionsStepOneSelector);
  const questionsStepTwo: any = yield select(questionsStepTwoSelector);
  const { payload } = action;
  const { step } = payload;

  let newDialogue = [...dialogue].filter(
    x => x.stepNumForFilter < Number(step),
  );
  switch (true) {
    case step === STEP_ONE_STR:
      newDialogue = [...newDialogue, ...questionsStepOne];
      break;
    case step === STEP_TWO_STR:
      newDialogue = [...newDialogue, ...questionsStepTwo];
      break;

    default:
      break;
  }

  yield put({
    type: registerAddQuestionsToDialogueResult.toString(),
    payload: newDialogue,
  });
}

export function* addAnswerToDialogue(action: any): IterableIterator<any> {
  const dialogue: any = yield select(dialogueSelector);
  const { payload } = action;
  const { answer, step } = payload;

  let newDialogue = [
    ...dialogue,
    {
      id: Date.now(),
      type: 'user',
      value: answer,
      buttons: [],
      stepNumForFilter: Number(step),
    },
  ];

  yield put({
    type: registerAddAnswerToDialogueResult.toString(),
    payload: newDialogue,
  });
}

export function* addErrorsToDialogue(action: any): IterableIterator<any> {
  const dialogue: any = yield select(dialogueSelector);
  const currentStep: any = yield select(currentStepSelector);
  const { payload } = action;
  const { message } = payload;

  let newDialogue = [...dialogue];

  switch (typeof message) {
    case 'object':
      newDialogue = [
        ...newDialogue,
        ...Object.keys(message).map((key, idx) => ({
          id: Date.now() + idx,
          type: 'error',
          value: message[key][0],
          buttons: [],
          stepNumForFilter: Number(currentStep.step),
        })),
      ];
      break;

    case 'string':
      newDialogue = [
        ...newDialogue,
        {
          id: Date.now(),
          type: 'error',
          value: message,
          buttons: [],
          stepNumForFilter: Number(currentStep),
        },
      ];
      break;

    default:
      break;
  }

  yield put({
    type: registerAddErrorToDialogueResult.toString(),
    payload: newDialogue,
  });
}

export function* addActiveButtons(action: any): IterableIterator<any> {
  const historyOfActiveButtons: any = yield select(
    historyOfActiveButtonsSelector,
  );
  const currentStep: any = yield select(currentStepSelector);
  const { payload } = action;
  const { buttonName, buttonStep } = payload;
  const curStep = Number(currentStep.step) || 0;

  let newHistoryList = [...historyOfActiveButtons];
  let newButtonsList = [
    ...historyOfActiveButtons.map((item: any) => item.buttonName),
  ];

  if (curStep === Number(buttonStep)) {
    newButtonsList = [...newButtonsList.slice(0, -1), buttonName];
    newHistoryList = [
      ...newButtonsList.slice(0, -1),
      { buttonName, buttonStep },
    ];
  }

  yield put({
    type: registerSetActiveButtonResult.toString(),
    payload: buttonName,
  });
  yield put({
    type: registerSetListOfActiveButtonsResult.toString(),
    payload: newButtonsList,
  });
  yield put({
    type: registerSetHistoryOfActiveButtonsResult.toString(),
    payload: newHistoryList,
  });
}

export function* filterActiveButtons(): IterableIterator<any> {
  const historyOfActiveButtons: any = yield select(
    historyOfActiveButtonsSelector,
  );
  const currentStep: any = yield select(currentStepSelector);
  const curStep = Number(currentStep.step) || 0;

  const newHistoryList = [...historyOfActiveButtons].filter(
    x => Number(x.buttonStep) < Number(curStep),
  );
  const newButtonsList = [
    ...newHistoryList.map((item: any) => item.buttonName),
  ];

  yield put({
    type: registerSetListOfActiveButtonsResult.toString(),
    payload: newButtonsList,
  });
  yield put({
    type: registerSetHistoryOfActiveButtonsResult.toString(),
    payload: newHistoryList,
  });
}

export function* postRegistrationNew(action: any): IterableIterator<any> {
  const { payload } = action;
  const readyDataForSend = payload;
  const { phone, isSocial } = readyDataForSend;
  const activeLang: any = yield select(activeLanguage);

  if (phone) {
    readyDataForSend.phone = yield call(preparePhoneForAPI, {
      phone,
      activeLang,
    });
  }

  const metaData: Record<string, string> = (yield call(getStorageMetaDataRegister)) || {};

  const readyDataForSendFull = {
    ...readyDataForSend,
    ...metaData,
  };

  const serverResponse: any = yield call(
    isSocial ? registerAPI.postDataRegistrationSocial : registerAPI.postDataRegistrationNew,
    readyDataForSendFull,
  );

  yield put({
    type: registerResultNew.toString(),
    payload: createResultV2(serverResponse),
  });
}

export function* checkRegistrationResultNew(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;
    const form = data?.form;
    if (!form) {
      const message = 'registration_common_error_occured';
      yield put({
        type: registerFailure.toString(),
        payload: { message },
      });
    }
    const result = form?.result ?? {};
    const token = result?.token ?? '';

    if (token) {
      yield call(setToken, LOCAL_STORAGE_AUTH_KEY, token);

      const isAuthorized = true;

      yield put({
        type: registerGetJwtSuccess.toString(),
        payload: { token },
      });
      yield put({
        type: authSetAuthStatusSuccess.toString(),
        payload: {
          isAuthorized,
        },
      });

      yield put({
        type: registerSuccess.toString(),
        payload: form,
      });
    }

    if (!token) {
      const message = 'registration_common_error_occured';

      yield put({
        type: registerSetErrorMessage.toString(),
        payload: { message },
      });
    }
  }

  if (E.isLeft(payload)) {
    const message = 'registration_common_error_occured';

    yield put({
      type: registerFailure.toString(),
      payload: { message },
    });
  }
}

// Root Saga
export default function* rootSaga() {
  yield takeEvery(
    registerValidationRequest,
    safe(onError, postValidation, { terminator: registerValidationFailure }),
  );

  yield takeEvery(
    registerValidationResult,
    safe(onError, checkValidationResult),
  );

  yield takeEvery(
    registerSMSCodeRequest,
    safe(onError, postSMSCode, { terminator: registerSMSCodeFailure }),
  );

  yield takeEvery(registerSMSCodeResult, safe(onError, checkSMSCodeResult));

  yield takeEvery(
    registerCallCodeRequest,
    safe(onError, postCallCode, { terminator: registerCallCodeFailure }),
  );

  yield takeEvery(registerCallCodeResult, safe(onError, checkCallCodeResult));

  yield takeEvery(
    registerEmailCodeRequest,
    safe(onError, postEmailCode, { terminator: registerEmailCodeFailure }),
  );

  yield takeEvery(registerEmailCodeResult, safe(onError, checkEmailCodeResult));

  yield takeEvery(
    registerPhoneInfoRequest,
    safe(onError, getPhoneInfo, { terminator: registerPhoneInfoFailure }),
  );

  yield takeEvery(registerPhoneInfoResult, safe(onError, checkPhoneInfoResult));

  yield takeEvery(
    registerRequest,
    safe(onError, postRegistration, { terminator: registerFailure }),
  );

  yield takeEvery(registerResult, safe(onError, checkRegistrationResult));

  yield takeEvery(
    registerRequestNew,
    safe(onError, postRegistrationNew, { terminator: registerFailure }),
  );

  yield takeEvery(registerResultNew, safe(onError, checkRegistrationResultNew));

  yield takeEvery(
    registerAddQuestionsToDialogue,
    safe(onError, addQuestionsToDialogue),
  );

  yield takeEvery(
    registerAddAnswerToDialogue,
    safe(onError, addAnswerToDialogue),
  );

  yield takeEvery(
    registerValidationFailure,
    safe(onError, addErrorsToDialogue),
  );

  yield takeEvery(registerSetActiveButton, safe(onError, addActiveButtons));

  yield takeEvery(registerSetCurrentStep, safe(onError, filterActiveButtons));
}
