import { select, fork, call, put } from 'redux-saga/effects';
import { get } from 'lodash';

import {
  getData,
  setData,
  removeData,
} from '../../../../shared/utils/commonLocalStorage.helper';
import {
  setData as setToken,
} from '../../../../shared/utils/commonCookies.helper';

import {
  query as querySelector,
  pathname as pathnameSelector,
} from '../selectors';
import { authSetComeFromLawyer } from '../../../Auth/state/duck';

import {
  LOCAL_STORAGE_CLICK_ID,
  LOCAL_STORAGE_CLICK_ID_VALID,
  LOCAL_STORAGE_CLICK_TYPE,
  LOCAL_STORAGE_CLICK_TYPE_VALID,
  LOCAL_STORAGE_CLICK_PRODUCT_TYPE,
  LOCAL_STORAGE_CLICK_PRODUCT_TYPE_VALID,
  LOCAL_STORAGE_CLICK_SUM,
  LOCAL_STORAGE_CLICK_SUM_VALID,
  LOCAL_STORAGE_CLICK_TERM,
  LOCAL_STORAGE_CLICK_TERM_VALID,
  LOCAL_STORAGE_WMID,
  LOCAL_STORAGE_WMID_VALID,
  LOCAL_STORAGE_AFF_ID,
  LOCAL_STORAGE_AFF_ID_VALID,
  LOCAL_STORAGE_EMAIL_CONFIRMATION,
  LOCAL_STORAGE_AUTH_KEY,
  LOCAL_STORAGE_REFERER,
  LOCAL_STORAGE_REFERER_VALID,
  LOCAL_STORAGE_YA_CLIENT_ID,
  LOCAL_STORAGE_YA_CLIENT_ID_VALID,
  LOCAL_STORAGE_GOOGLE_CLIENT_ID,
  LOCAL_STORAGE_GOOGLE_CLIENT_ID_VALID, LOCAL_STORAGE_CLICK_CITY, LOCAL_STORAGE_CLICK_CITY_VALID,
} from '../../../../shared/constants/Defaults/constants';
import {
  SET_NEW_CREDENTIAL_URL,
  SET_LAWYER_CREDENTIAL_URL,
} from '../../../../shared/constants/Routes/constants';
import { loginRedirect } from '../../../Login/state/duck';

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;
const THIRTY_DAYS = 30 * ONE_DAY;

// Sagas

export function* localStorageOps(params: any): IterableIterator<any> {
  const {
    storedID,
    storedIDValid,
    KEY_ID,
    KEY_VALID,
    currentID,
    currentTime,
  } = params;

  // Если нет в хранилище, то установить
  if (!storedID) {
    yield call(setData, KEY_ID, currentID);
    yield call(setData, KEY_VALID, currentTime + THIRTY_DAYS);
  }

  // Если есть в хранилище
  if (storedID) {
    // Проверить срок действия и удалить в случае просрочки
    if (storedID === currentID) {
      if (storedIDValid && currentTime > storedIDValid) {
        yield call(removeData, KEY_ID);
        yield call(removeData, KEY_VALID);
      }
    }

    // Если токены разные, то заменить в сторадже новым из параметров
    if (storedID !== currentID) {
      yield call(setData, KEY_ID, currentID);
      yield call(setData, KEY_VALID, currentTime + THIRTY_DAYS);
    }
  }
}

export function* processingURLParams(): IterableIterator<any> {
  const currentTime = Date.now();
  const query = yield select(querySelector);

  const clickID = get(query, 'click_id', null) || null;
  if (clickID) {
    const storedClickID = yield call(getData, LOCAL_STORAGE_CLICK_ID);
    const storedClickIDValid = yield call(
      getData,
      LOCAL_STORAGE_CLICK_ID_VALID,
    );

    yield call(localStorageOps, {
      storedID: storedClickID,
      storedIDValid: storedClickIDValid,
      KEY_ID: LOCAL_STORAGE_CLICK_ID,
      KEY_VALID: LOCAL_STORAGE_CLICK_ID_VALID,
      currentID: clickID,
      currentTime,
    });
  }

  const clickType = get(query, 'click_type', null) || null;
  if (clickType) {
    const storedClickType = yield call(getData, LOCAL_STORAGE_CLICK_TYPE);
    const storedClickTypeValid = yield call(
      getData,
      LOCAL_STORAGE_CLICK_TYPE_VALID,
    );

    yield call(localStorageOps, {
      storedID: storedClickType,
      storedIDValid: storedClickTypeValid,
      KEY_ID: LOCAL_STORAGE_CLICK_TYPE,
      KEY_VALID: LOCAL_STORAGE_CLICK_TYPE_VALID,
      currentID: clickType,
      currentTime,
    });
  }

  const clickProductType = get(query, 'click_product_type', null) || null;
  if (clickProductType) {
    const storedClickProductType = yield call(getData, LOCAL_STORAGE_CLICK_PRODUCT_TYPE);
    const storedClickProductTypeValid = yield call(
      getData,
      LOCAL_STORAGE_CLICK_PRODUCT_TYPE_VALID,
    );

    yield call(localStorageOps, {
      storedID: storedClickProductType,
      storedIDValid: storedClickProductTypeValid,
      KEY_ID: LOCAL_STORAGE_CLICK_PRODUCT_TYPE,
      KEY_VALID: LOCAL_STORAGE_CLICK_PRODUCT_TYPE_VALID,
      currentID: clickProductType,
      currentTime,
    });
  }

  const clickSum = get(query, 'click_sum', null) || null;
  if (clickSum) {
    const storedClickSum = yield call(getData, LOCAL_STORAGE_CLICK_SUM);
    const storedClickSumValid = yield call(
      getData,
      LOCAL_STORAGE_CLICK_SUM_VALID,
    );

    yield call(localStorageOps, {
      storedID: storedClickSum,
      storedIDValid: storedClickSumValid,
      KEY_ID: LOCAL_STORAGE_CLICK_SUM,
      KEY_VALID: LOCAL_STORAGE_CLICK_SUM_VALID,
      currentID: clickSum,
      currentTime,
    });
  }

  const clickTerm = get(query, 'click_term', null) || null;
  if (clickTerm) {
    const storedClickTerm = yield call(getData, LOCAL_STORAGE_CLICK_TERM);
    const storedClickTermValid = yield call(
      getData,
      LOCAL_STORAGE_CLICK_TERM_VALID,
    );

    yield call(localStorageOps, {
      storedID: storedClickTerm,
      storedIDValid: storedClickTermValid,
      KEY_ID: LOCAL_STORAGE_CLICK_TERM,
      KEY_VALID: LOCAL_STORAGE_CLICK_TERM_VALID,
      currentID: clickTerm,
      currentTime,
    });
  }

  const clickTypeCity = get(query, 'click_city', null) || null;
  if (clickTypeCity) {
    const storedClickTerm = yield call(getData, LOCAL_STORAGE_CLICK_CITY);
    const storedClickTermValid = yield call(
      getData,
      LOCAL_STORAGE_CLICK_CITY_VALID,
    );

    yield call(localStorageOps, {
      storedID: storedClickTerm,
      storedIDValid: storedClickTermValid,
      KEY_ID: LOCAL_STORAGE_CLICK_CITY,
      KEY_VALID: LOCAL_STORAGE_CLICK_CITY_VALID,
      currentID: decodeURI(clickTypeCity),
      currentTime,
    });
  }

  const wmid = get(query, 'wmid', null) || null;
  if (wmid) {
    const storedWmid = yield call(getData, LOCAL_STORAGE_WMID);
    const storedWmidValid = yield call(getData, LOCAL_STORAGE_WMID_VALID);

    yield call(localStorageOps, {
      storedID: storedWmid,
      storedIDValid: storedWmidValid,
      KEY_ID: LOCAL_STORAGE_WMID,
      KEY_VALID: LOCAL_STORAGE_WMID_VALID,
      currentID: wmid,
      currentTime,
    });
  }

  const affID = get(query, 'aff_id', null) || null;
  if (affID) {
    const storedAffID = yield call(getData, LOCAL_STORAGE_AFF_ID);
    const storedAffIDValid = yield call(getData, LOCAL_STORAGE_AFF_ID_VALID);

    yield call(localStorageOps, {
      storedID: storedAffID,
      storedIDValid: storedAffIDValid,
      KEY_ID: LOCAL_STORAGE_AFF_ID,
      KEY_VALID: LOCAL_STORAGE_AFF_ID_VALID,
      currentID: affID,
      currentTime,
    });
  }

  const referer = get(query, 'referer', null) || null;
  if (referer) {
    const storedReferer = yield call(getData, LOCAL_STORAGE_REFERER);
    const storedRefererValid = yield call(getData, LOCAL_STORAGE_REFERER_VALID);

    yield call(localStorageOps, {
      storedID: storedReferer,
      storedIDValid: storedRefererValid,
      KEY_ID: LOCAL_STORAGE_REFERER,
      KEY_VALID: LOCAL_STORAGE_REFERER_VALID,
      currentID: referer,
      currentTime,
    });
  }

  const yaClientID = get(query, 'ya_client_id', null) || null;
  if (yaClientID) {
    const storedYaClientID = yield call(getData, LOCAL_STORAGE_YA_CLIENT_ID);
    const storedYaClientIDValid = yield call(
      getData,
      LOCAL_STORAGE_YA_CLIENT_ID_VALID,
    );

    yield call(localStorageOps, {
      storedID: storedYaClientID,
      storedIDValid: storedYaClientIDValid,
      KEY_ID: LOCAL_STORAGE_YA_CLIENT_ID,
      KEY_VALID: LOCAL_STORAGE_YA_CLIENT_ID_VALID,
      currentID: yaClientID,
      currentTime,
    });
  }

  const googleClientID = get(query, 'google_client_id', null) || null;
  if (googleClientID) {
    const storedGoogleClientID = yield call(getData, LOCAL_STORAGE_GOOGLE_CLIENT_ID);
    const storedGoogleClientIDValid = yield call(
      getData,
      LOCAL_STORAGE_GOOGLE_CLIENT_ID_VALID,
    );

    yield call(localStorageOps, {
      storedID: storedGoogleClientID,
      storedIDValid: storedGoogleClientIDValid,
      KEY_ID: LOCAL_STORAGE_GOOGLE_CLIENT_ID,
      KEY_VALID: LOCAL_STORAGE_GOOGLE_CLIENT_ID_VALID,
      currentID: googleClientID,
      currentTime,
    });
  }

  const redirect = get(query, 'redirect', null) || null;
  if (redirect) {
    yield put({
      type: loginRedirect.toString(),
      payload: redirect,
    });
  }
} // processingURLParams =========

export function* localStorageSettingsURLConfirm(
  params: any,
): IterableIterator<any> {
  const { storedID, KEY_ID, currentID } = params;

  // Если нет в хранилище, то установить
  if (!storedID) {
    yield call(setData, KEY_ID, currentID);
  }

  // Если есть в хранилище
  if (storedID) {
    // Проверить равенство и удалить, если такой токен уже был записан
    if (storedID === currentID) {
      yield call(removeData, KEY_ID);
    }

    // Если токены разные, то заменить в сторадже новым из параметров
    if (storedID !== currentID) {
      yield call(setData, KEY_ID, currentID);
    }
  }
}

export function* processingConfirmEmailURLParams(): IterableIterator<any> {
  const query = yield select(querySelector);
  const pathname = yield select(pathnameSelector);

  if (pathname === '/settings') {
    const emailConfirmation =
      get(query, 'email-confirmation', null) || null;
    if (emailConfirmation) {
      const storedEmailConfirmation = yield call(
        getData,
        LOCAL_STORAGE_EMAIL_CONFIRMATION,
      );

      yield call(localStorageSettingsURLConfirm, {
        storedID: storedEmailConfirmation,
        KEY_ID: LOCAL_STORAGE_EMAIL_CONFIRMATION,
        currentID: emailConfirmation,
      });
    }
  }
} // processingConfirmEmailURLParams =========

export function* processingNewCredentialsURLParams(): IterableIterator<any> {
  const query = yield select(querySelector);
  const pathname = yield select(pathnameSelector);

  if (pathname === SET_NEW_CREDENTIAL_URL) {
    const token = get(query, 'token', null) || null;
    if (token) {
      yield call(setData, LOCAL_STORAGE_AUTH_KEY, token);
    }
  }
} // processingNewCredentialsURLParams =========

export function* processingLoginLawyerURLParams(): IterableIterator<any> {
  const query = yield select(querySelector);
  const pathname = yield select(pathnameSelector);

  if (pathname === SET_LAWYER_CREDENTIAL_URL) {
    const token = get(query, 'token', null) || null;
    if (token) {
      yield call(setToken, LOCAL_STORAGE_AUTH_KEY, token);
      yield put({
        type: authSetComeFromLawyer.toString(),
      });
    }
  }
} // processingLoginLawyerURLParams =========

// Root Saga
// IDEA: Здесь реализован запуск саги при инициализации
// DOCS: https://stackoverflow.com/questions/44625944/is-possible-to-listen-on-redux-init-action-in-middleware
export default function* rootSaga() {
  yield fork(processingURLParams);
  yield fork(processingConfirmEmailURLParams);
  yield fork(processingNewCredentialsURLParams);
  yield fork(processingLoginLawyerURLParams);
}
