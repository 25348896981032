/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import cx from 'classnames';
import styles from './StepWrapper.module.scss';
import ArrowLeft from '../../../../../../../assets/images/ArrowLeft';
import { Translate } from 'react-localize-redux';

export interface StepWrapperProps {
  title?: string | JSX.Element
  description?: string | JSX.Element
  className?: string
  onBack?: () => void
}

export const StepWrapper: React.FC<StepWrapperProps> = ({
  title,
  description,
  className,
  onBack,
  children,
}) => {
  const isHandlerBack = typeof onBack === 'function';

  return (
    <div className={cx(className, styles.root)}>
      <div
        className={cx(
          styles.title,
          isHandlerBack && styles.arrowTitle,
        )}
      >
        {isHandlerBack &&
        <div className={styles.arrow} onClick={onBack}>
          <ArrowLeft />
        </div>
        }
        {typeof title === 'string'
          ? <Translate id={title} />
          : title
        }
      </div>

      <div className={styles.description}>
        {typeof description === 'string'
          ? <Translate id={description} />
          : description
        }
      </div>
      {children}
    </div>
  );
};
