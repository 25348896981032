import React from 'react';

import styles from './Card.module.scss';

import { Mfo } from '../../../../services/Multilogin/api';
import { ModalType } from '../../../enum/ModalType.enum';

import { useQuery } from '../../../hooks/useQuery';

import ImageWrapper from '../../ImageWrapper';
import CardSkeleton from './Card.skeleton';

export interface CardProps extends Mfo {
  isLoading?: boolean
}

const Card: React.FC<CardProps> = ({
  logoImage,
  url,
  name,
  id,
  isLoading,
  legalName,
}) => {
  const { setQuery } = useQuery();

  if (isLoading) {
    return <CardSkeleton />;
  }
  return (
    <div
      className={styles.root}
      onClick={() => setQuery(id, ModalType.ADDMFO)}
      onKeyDown={() => setQuery(id, ModalType.ADDMFO)}
      role="button"
      tabIndex={0}
    >
      <ImageWrapper image={logoImage} alt="" className={styles.logo} />
      <div className={styles.col}>
        <div className={styles.brand}>{name}</div>
        <div className={styles.site}>{url}</div>
        <div className={styles.law}>{legalName}</div>
      </div>
    </div>
  );
};

export default Card;
