import React, { SVGAttributes } from 'react';

const Close: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="#0F1011" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1.41406" width="20" height="2" transform="rotate(45 1.41406 0)" />
    <rect y="14.1421" width="20" height="2" transform="rotate(-45 0 14.1421)" />
  </svg>
);

export default Close;
