import { createSelector } from 'reselect';

export const isFetchingIFrame = (store: any) =>
  store.paymentReducer.isFetchingIFrame;

export const dataIFrame = createSelector(
  (store: any) => store.paymentReducer.dataIFrame,
  dataIFrame => dataIFrame,
);

export const transactionID = createSelector(
  (store: any) => store.paymentReducer.dataIFrame,
  dataIFrame => dataIFrame?.transaction_id ?? '',
);

export const isStillWaiting = (store: any) =>
  store.paymentReducer.isStillWaiting;

export const transactionStatus = createSelector(
  (store: any) => store.paymentReducer.dataTransaction,
  dataTransaction => dataTransaction?.status ?? -1,
);

export const isFetchingBindedCards = (store: any) =>
  store.paymentReducer.isFetchingBindedCards;

export const dataBindedCards = createSelector(
  (store: any) => store.paymentReducer.dataBindedCards,
  (dataBindedCards = []) => dataBindedCards,
);

export const showFormCounter = createSelector(
  (store: any) => store.paymentReducer.showFormCounter,
  showFormCounter => showFormCounter,
);
