import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * User Settings Change Email API
 */
export default {
  /**
   * Получить данные формы
   *
   * @returns
   */
  getData(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/email`,
    );
  },

  /**
   * Отправить данные формы для изменения пароля
   *
   * @returns
   */
  putData(data: any) {
    const { token, email } = data;

    return AxiosInstance(token).put(
      `${apiPrefix}${versionOnePrefix}/user/email`,
      {
        email,
      },
    );
  },
};
