import React from 'react';

import userAvatarPlugImage from './assets/images/userAvatarPlug.svg';

import styles from './AvatarWrapper.module.scss';

interface IProps {
  image: string;
  style?: Record<string, any>;
}

/**
 * Обертка для создания аватарки из картинки
 *
 * Использовать:
 *
 * <AvatarWrapper
 *   style={{ marginRight: '10px', height: 38, width: 38 }}
 *   image={avatar}
 * />
 *
 *
 * @param {*} props
 * @returns
 */
const AvatarWrapper: React.FC<IProps> = props => {
  const { image = '', style = {} } = props;

  return (
    <div
      className={styles.avatarWrapper}
      style={{
        backgroundImage: `url(${image !== '' ? image : userAvatarPlugImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: image === '' ? '50%' : '100%',
        ...style,
      }}
    />
  );
};

export default AvatarWrapper;
