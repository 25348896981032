import React from 'react';
import cx from 'classnames';

import Info from '../../../../../assets/images/Info';

import styles from './Alert.module.scss';
import { Translate } from 'react-localize-redux';

const Alert: React.FC = () => {
  return (
    <div className={styles.root}>
      <Info className={cx(styles.info, styles.img)} />
      <Translate
        id="multilogin.my.bonus"
      />
    </div>
  );
};

export default Alert;
