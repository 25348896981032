import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';
import { Empty } from 'antd';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';

import { DEVICE_NETBOOKS_WIDTH } from '../../constants/Defaults/constants';
import { useWindowSize } from '../../hooks/useWindowSize';

import InfinitePreloader from '../../componets/InfinitePreloader';
import Preloader from '../../componets/Preloader';

import {
  logList as logListSelector,
  isFetching as isFetchingSelector,
  totalPages as totalPagesSelector,
  currentPage as currentPageSelector,
} from '../../../states/UserSettingsActivity/state/selectors';
import {
  userSettingsActivityRequest,
  userSettingsActivityScrollingRequest,
} from '../../../states/UserSettingsActivity/state/duck';

import siteImage from './assets/images/fincoin.svg';

import styles from './UserSettingsActivity.module.scss';
// import { isMobile } from 'react-device-detect';

/**
 * Окно настройки: Мой профиль
 *
 * @returns
 */

interface IProps {
  isProfileSettings: boolean
}

const UserSettingsActivity: React.FC<IProps> = props => {
  const logList = useSelector(logListSelector);
  const isFetching = useSelector(isFetchingSelector);
  const totalPages = useSelector(totalPagesSelector);
  const currentPage = useSelector(currentPageSelector);
  const dispatch = useDispatch();

  const { isProfileSettings } = props;

  const windowSize = useWindowSize();
  const { width = 0 } = windowSize;

  // По умолчанию считаем что запущено на десктопе, мобила = false
  let isMobile = false;

  if (width < DEVICE_NETBOOKS_WIDTH) {
    isMobile = true;
  }

  // cdM
  React.useEffect(
    () => {
      dispatch({
        type: userSettingsActivityRequest.toString(),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleFetchNewData = React.useCallback(() => {
    if (currentPage - totalPages !== 0) {
      dispatch({
        type: userSettingsActivityScrollingRequest.toString(),
        payload: { page: currentPage + 1 },
      });
    }
  }, [dispatch, currentPage, totalPages]);

  return (
    <>
      {isMobile ? (
        ''
      ) : (
        <div className={cx(styles.header)}>
          <div className={cx(styles.headerText)}>
            <Translate id="shared_containers_usersettingsactivity_header" />
          </div>
        </div>
      )}

      <div className={cx(styles.subHeader)}>
        <div className={cx(styles.subHeaderText)}>
          <Translate id="shared_containers_usersettingsactivity_subheader" />
        </div>
      </div>
      <div className={cx(styles.listDesktop)}>
        <div className={cx(styles.listDesktopElHeader, styles.listDesktopEl)}>
          <div className={styles.flexWidth1}>
            <Translate id="shared_containers_usersettingsactivity_actions" />
          </div>
          <div className={styles.flexWidth2}>
            <Translate id="shared_common_date" />
          </div>
          <div className={styles.flexWidth3}>
            <Translate id="shared_containers_usersettingsactivity_scores" />
          </div>
        </div>

        <>
          {isFetching ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Preloader size="large" />
            </div>
          ) : (
            <>
              <InfiniteScroll
                dataLength={logList.length}
                next={handleFetchNewData}
                hasMore={totalPages - currentPage !== 0}
                loader={<InfinitePreloader />}
                height={isProfileSettings ? 496 : 'auto'}
              >
                {logList.length === 0 ? (
                  <Empty />
                ) : (
                  <>
                    {logList.map((item: any) => {
                      const {
                        id,
                        title,
                        created_at: dateTime,
                        balance_movement: balanceMovement,
                      } = item;

                      return (
                        <div key={id} className={cx(styles.listDesktopEl)}>
                          <div className={styles.flexWidth1}>
                            <img
                              src={siteImage}
                              alt="site"
                              width={32}
                              height={32}
                              style={{ marginRight: '10px' }}
                            />
                            <Translate id={`${title}`} />
                          </div>
                          <div className={styles.flexWidth2}>
                            {moment(dateTime).format('DD.MM.YYYY')}
                          </div>
                          {
                            balanceMovement > 0
                              ? <div style={{ color: '#1373e5' }} className={styles.flexWidth3}>
                                {balanceMovement}
                              </div>
                              : <div style={{ color: '#E3405E' }} className={styles.flexWidth3}>
                                {balanceMovement}
                              </div>
                          }
                        </div>
                      );
                    })}
                  </>
                )}
              </InfiniteScroll>
            </>
          )}
        </>
      </div>
    </>
  );
};

export default UserSettingsActivity;
