import { put, takeLatest, all, call, select } from 'redux-saga/effects';

import { selectionLoanProduct } from '../../../../services/SelectionLoanProduct/api';

import {
  selectionLoanProductRequest,
  selectionLoanProductSuccess,
  selectionLoanProductFailure,
  selectionLoanProductFilteredListRequest,
  selectionLoanProductFilteredListSuccess,
  selectionLoanProductFilteredListFailure,
  selectionLoanProductTagsNestedRequest,
  selectionLoanProductTagsNestedSuccess,
  selectionLoanProductTagsNestedFailure,
  selectionLoanProductCancelRequest,
} from '../duck';
import { logoutRequest } from '../../../Logout/state/duck';
import { activeCountryID } from '../../../Countries/state/selectors';
import {
  offersList,
  data as storedData,
  isCached as isCachedSelector,
} from '../selectors';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';

import { ID_FOR_I_DONT_KNOW } from '../../../../shared/constants/SelectionLoanProduct/constants';

export function* getData(action: any) {
  const isCached: boolean = yield select(isCachedSelector);
  if (isCached) {
    yield put({
      type: selectionLoanProductCancelRequest.toString(),
    });

    return;
  }

  const country: number = yield select(activeCountryID);

  try {
    const completePayload: any = yield call(checkToken, action);

    const [
      userRatingScoring,
      userRatingScoringNxtDate,
      allUsersRatingAverageScoring,
      offersTabs,
      categoryTypes,
      allTags,
      allOffersListforIdontKnow,
    ]: any = yield all([
      call(selectionLoanProduct.getRatingScoring, completePayload),
      call(selectionLoanProduct.getRatingScoringNxtDate, completePayload),
      call(selectionLoanProduct.getRatingAverageScoring, completePayload),
      call(selectionLoanProduct.postDataFilter, {
        ...completePayload,
        country,
      }),
      call(selectionLoanProduct.getDataCategoryTypes, {
        ...completePayload,
        country,
      }),
      call(selectionLoanProduct.getTags, {
        ...completePayload,
        country,
      }),
      call(selectionLoanProduct.postDataList, {
        ...completePayload,
        country,
        limit: -1,
      }),
    ]);

    // userRatingScoring
    const {
      status: userRatingScoringStatus,
      data: userRatingScoringData,
    } = userRatingScoring;
    // userRatingScoringNxtDate
    const {
      status: userRatingScoringNxtDateStatus,
      data: userRatingScoringNxtDateData,
    } = userRatingScoringNxtDate;
    // allUsersRatingAverageScoring
    const {
      status: allUsersRatingAverageScoringStatus,
      data: allUsersRatingAverageScoringData,
    } = allUsersRatingAverageScoring;
    // offersTabs
    const { status: offersTabsStatus, data: offersTabsData } = offersTabs;
    // categoryTypes
    const {
      status: categoryTypesStatus,
      data: categoryTypesData,
    } = categoryTypes;
    // allTags
    const { status: allTagsStatus, data: allTagsData } = allTags;
    // allOffersListforIdontKnow
    const {
      status: allOffersListforIdontKnowStatus,
      data: allOffersListforIdontKnowData,
    } = allOffersListforIdontKnow;

    let tempOffersList = {};
    if (offersTabsStatus === 200) {
      for (let i = 0; i < offersTabsData.length; i++) {
        const tempOffer = offersTabsData[i];
        const { id = 0 } = tempOffer;

        try {
          const selectionLoanProductData = yield call(
            selectionLoanProduct.postDataList,
            {
              ...completePayload,
              country,
              type: id,
              limit: -1,
            },
          );

          const { data, status } = selectionLoanProductData;

          if (status === 200) {
            tempOffersList = { ...tempOffersList, [id]: { data } };
          }

          if (status === 401) {
            yield put({ type: logoutRequest.toString() });
          }
        } catch (error) {
          if (error) {
            // NB: Разрешенный игнор
            // eslint-disable-next-line
            console.log('[ERROR offersList]:', error);
          }
        }
      }
    }

    const payload = {
      userRatingScoringData,
      userRatingScoringNxtDateData,
      allUsersRatingAverageScoringData,
      offersTabsData,
      offersList: {
        ...tempOffersList,
        [ID_FOR_I_DONT_KNOW]: { data: allOffersListforIdontKnowData },
      },
      categoryTypesData,
      allTagsData,
      allOffersListforIdontKnowData,
    };

    const allStatuses =
      userRatingScoringStatus === 200 &&
      userRatingScoringNxtDateStatus === 200 &&
      allUsersRatingAverageScoringStatus === 200 &&
      offersTabsStatus === 200 &&
      categoryTypesStatus === 200 &&
      allOffersListforIdontKnowStatus === 200 &&
      allTagsStatus === 200;
    const someStatuses401 =
      userRatingScoringStatus === 401 ||
      userRatingScoringNxtDateStatus === 401 ||
      allUsersRatingAverageScoringStatus === 401 ||
      offersTabsStatus === 401 ||
      categoryTypesStatus === 401 ||
      allOffersListforIdontKnowStatus === 401 ||
      allTagsStatus === 401;

    if (allStatuses) {
      yield put({
        type: selectionLoanProductSuccess.toString(),
        payload,
      });
    }

    if (someStatuses401) {
      yield put({
        type: selectionLoanProductFailure.toString(),
        payload,
      });
      yield put({ type: logoutRequest.toString() });
    }
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR selectionLoanProduct initial]:', error);
    }
  }
} // getData =========

export function* getFilteredListData(action: any) {
  const country: number = yield select(activeCountryID);
  const storedOffersList = yield select(offersList);
  const data = yield select(storedData);
  const { payload: actionPayload } = action;
  const { type: id, products } = actionPayload;

  const completePayload: any = yield call(checkToken, action);

  let tempOffersList = { ...storedOffersList };

  try {
    let selectionLoanProductData: any = null;
    if (id === ID_FOR_I_DONT_KNOW) {
      selectionLoanProductData = yield call(selectionLoanProduct.postDataList, {
        ...completePayload,
        products,
        country,
        limit: -1,
      });
    } else {
      selectionLoanProductData = yield call(selectionLoanProduct.postDataList, {
        ...completePayload,
        country,
        limit: -1,
      });
    }

    const { data: resData, status } = selectionLoanProductData;

    if (status === 200) {
      tempOffersList = { ...tempOffersList, [id]: { data: resData } };

      const payload = {
        ...data,
        offersList: tempOffersList,
      };

      yield put({
        type: selectionLoanProductFilteredListSuccess.toString(),
        payload,
      });
    }

    if (status === 401) {
      yield put({
        type: selectionLoanProductFilteredListFailure.toString(),
        payload: { data: resData },
      });
      yield put({ type: logoutRequest.toString() });
    }
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR getFilteredListData offersList]:', error);
    }
  }
} // getFilteredListData =========

export function* getNestedTagsData(action: any) {
  const country: number = yield select(activeCountryID);

  const completePayload: any = yield call(checkToken, action);

  try {
    const tagsNestedData = yield call(selectionLoanProduct.getTagsNested, {
      ...completePayload,
      country,
    });

    const { data, status } = tagsNestedData;

    if (status === 200) {
      yield put({
        type: selectionLoanProductTagsNestedSuccess.toString(),
        payload: data,
      });
    }

    if (status === 401) {
      yield put({
        type: selectionLoanProductTagsNestedFailure.toString(),
        payload: { data, status },
      });
      yield put({ type: logoutRequest.toString() });
    }
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR getNestedTagsData offersList]:', error);
    }
  }
} // getNestedTagsData =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(selectionLoanProductRequest, getData);

  yield takeLatest(
    selectionLoanProductFilteredListRequest,
    getFilteredListData,
  );

  yield takeLatest(selectionLoanProductTagsNestedRequest, getNestedTagsData);
}
