import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const PAYMENT = 'PAYMENT';

export const paymentPrepareRequest = createAction(`${PAYMENT}/PREPARE_REQUEST`);
export const paymentPrepareSuccess = createAction(`${PAYMENT}/PREPARE_SUCCESS`);
export const paymentPrepareFailure = createAction(`${PAYMENT}/PREPARE_FAILURE`);

export const paymentPrepareResult = createAction(`${PAYMENT}/PREPARE_RESULT`);

export const paymentCheckTransactionRequest = createAction(
  `${PAYMENT}/CHECK_TRANSACTION_REQUEST`,
);
export const paymentCheckTransactionSuccess = createAction(
  `${PAYMENT}/CHECK_TRANSACTION_SUCCESS`,
);
export const paymentCheckTransactionFailure = createAction(
  `${PAYMENT}/CHECK_TRANSACTION_FAILURE`,
);

export const paymentCheckTransactionResult = createAction(
  `${PAYMENT}/CHECK_TRANSACTION_RESULT`,
);

export const paymentTransactionClear = createAction(
  `${PAYMENT}/TRANSACTION_CLEAR`,
);

export const paymentWaitOn = createAction(`${PAYMENT}/WAIT_ON`);
export const paymentWaitOff = createAction(`${PAYMENT}/WAIT_OFF`);

export const paymentCheckBindedCardsRequest = createAction(
  `${PAYMENT}/CHECK_BINDED_CARDS_REQUEST`,
);
export const paymentCheckBindedCardsSuccess = createAction(
  `${PAYMENT}/CHECK_BINDED_CARDS_SUCCESS`,
);
export const paymentCheckBindedCardsFailure = createAction(
  `${PAYMENT}/CHECK_BINDED_CARDS_FAILURE`,
);

export const paymentCheckBindedCardsResult = createAction(
  `${PAYMENT}/CHECK_BINDED_CARDS_RESULT`,
);

export const paymentShowFormInc = createAction(`${PAYMENT}/SHOW_FORM_INC`);

export const paymentRatingUpdateRequests = createAction(`${PAYMENT}/RATING_UPDATE_REQUEST`);

// Reducers ==========================

const isFetchingIFrame = createReducer(false, {
  [paymentPrepareRequest.toString()]: () => true,
  [paymentPrepareSuccess.toString()]: () => false,
  [paymentPrepareFailure.toString()]: () => false,
});

const dataIFrame = createReducer(
  {},
  {
    [paymentPrepareRequest.toString()]: () => ({}),
    [paymentPrepareFailure.toString()]: () => ({
      responseStatus: 'failure',
      checkoutUrl: null,
      paymentId: null,
    }),
    [paymentPrepareSuccess.toString()]: (_state, action) => action.payload,
  },
);

const isFetchingTransaction = createReducer(false, {
  [paymentCheckTransactionRequest.toString()]: () => true,
  [paymentCheckTransactionSuccess.toString()]: () => false,
  [paymentCheckTransactionFailure.toString()]: () => false,
});

const dataTransaction = createReducer(
  {},
  {
    [paymentPrepareRequest.toString()]: () => ({}),
    [paymentTransactionClear.toString()]: () => ({}),
    [paymentCheckTransactionRequest.toString()]: () => ({}),
    [paymentCheckTransactionFailure.toString()]: () => ({}),
    [paymentCheckTransactionSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

const isStillWaiting = createReducer(false, {
  [paymentWaitOn.toString()]: () => true,
  [paymentWaitOff.toString()]: () => false,
});

const isFetchingBindedCards = createReducer(false, {
  [paymentCheckBindedCardsRequest.toString()]: () => true,
  [paymentCheckBindedCardsSuccess.toString()]: () => false,
  [paymentCheckBindedCardsFailure.toString()]: () => false,
});

const dataBindedCards = createReducer([], {
  [paymentCheckBindedCardsRequest.toString()]: () => [],
  [paymentCheckBindedCardsFailure.toString()]: () => [],
  [paymentCheckBindedCardsSuccess.toString()]: (_state, action) =>
    action.payload,
});

const showFormCounter = createReducer(0, {
  [paymentShowFormInc.toString()]: state => state + 1,
});

// Root Reducer
const reducer = combineReducers({
  isFetchingIFrame,
  dataIFrame,
  isFetchingTransaction,
  dataTransaction,
  isStillWaiting,
  isFetchingBindedCards,
  dataBindedCards,
  showFormCounter,
});

export default reducer;
