import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';
// Actions ==========================

const PRODUCTS = 'PRODUCTS';

export const productsRequest = createAction(`${PRODUCTS}/REQUEST`);
export const productsSuccess = createAction(`${PRODUCTS}/SUCCESS`);
export const productsFailure = createAction(`${PRODUCTS}/FAILURE`);

export const productsOrgRequest = createAction(`${PRODUCTS}/ORG_REQUEST`);
export const productsOrgSuccess = createAction(`${PRODUCTS}/ORG_SUCCESS`);
export const productsOrgFailure = createAction(`${PRODUCTS}/ORG_FAILURE`);

export const productsResult = createAction(`${PRODUCTS}/RESULT`);

export const productsOrgResult = createAction(`${PRODUCTS}/ORG_RESULT`);

export const productsChecklistRequest = createAction(
  `${PRODUCTS}/CHECKLIST_REQUEST`,
);
export const productsChecklistSuccess = createAction(
  `${PRODUCTS}/CHECKLIST_SUCCESS`,
);
export const productsChecklistFailure = createAction(
  `${PRODUCTS}/CHECKLIST_FAILURE`,
);

export const productsChecklistResult = createAction(
  `${PRODUCTS}/CHECKLIST_RESULT`,
);

export const productsCancelResult = createAction(`${PRODUCTS}/CANCEL_RESULT`);

export const productsIDSendSuccess = createAction(
  `${PRODUCTS}/ID_SEND_SUCCESS`,
);

// Reducers ==========================

const isFetching = createReducer(false, {
  [productsRequest.toString()]: () => true,
  [productsSuccess.toString()]: () => false,
  [productsFailure.toString()]: () => false,
  [productsChecklistSuccess.toString()]: () => false,
  [productsChecklistFailure.toString()]: () => false,
});

const isFetchOrganization = createReducer(false, {
  [productsOrgRequest.toString()]: () => true,
  [productsOrgSuccess.toString()]: () => false,
  [productsOrgFailure.toString()]: () => false,
});

const fetchOrganization = createReducer(
  {},
  {
    [productsOrgRequest.toString()]: () => ({}),
    [productsOrgResult.toString()]: (_state, action) =>
      action.payload,
  },
);

const errorFetchOrganization = createReducer([], {
  [productsOrgRequest.toString()]: () => [],
  [productsOrgSuccess.toString()]: () => [],
  [productsOrgFailure.toString()]: (_state, action) => action.payload,
});

const data = createReducer({}, {
  [productsRequest.toString()]: () => ({}),
  [productsSuccess.toString()]: (_state, action) => action.payload,
  [productsCancelResult.toString()]: () => ({}),
},
);

const result = createReducer({}, {
  [productsRequest.toString()]: () => ({}),
  [productsResult.toString()]: (_state, action) => action.payload,
},
);

const activeOfferID = createReducer(-1, {
  [productsRequest.toString()]: (_state, action) =>
    action.payload?.offerID ?? -1,
  [productsCancelResult.toString()]: () => -1,
});

const formContractSuccessLoaded = createReducer(false, {
  [productsRequest.toString()]: () => false,
  [productsSuccess.toString()]: () => true,
});

const isFetchingChecklist = createReducer(false, {
  [productsChecklistRequest.toString()]: () => true,
  [productsChecklistSuccess.toString()]: () => false,
  [productsChecklistFailure.toString()]: () => false,
});

const dataChecklist = createReducer(
  {},
  {
    [productsChecklistRequest.toString()]: () => ({}),
    [productsChecklistSuccess.toString()]: (_state, action) => action.payload,
  },
);

const formChecklistSuccessSend = createReducer(false, {
  [productsChecklistRequest.toString()]: () => false,
  [productsChecklistSuccess.toString()]: () => true,
});

const alreadySuccessSendID = createReducer([], {
  [productsIDSendSuccess.toString()]: (_state, action) => action.payload,
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  result,
  activeOfferID,
  formContractSuccessLoaded,
  isFetchingChecklist,
  dataChecklist,
  formChecklistSuccessSend,
  alreadySuccessSendID,
  isFetchOrganization,
  fetchOrganization,
  errorFetchOrganization,
});

export default reducer;
