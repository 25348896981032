// import { push } from 'connected-react-router';

import { put, takeLatest, takeEvery, call, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { loanSelectionChat } from '../../../../services/LoanSelection/api';

import {
  loanSelectionFetchChatRequest,
  loanSelectionFetchChatRequestFirstTime,
  loanSelectionFetchChatSuccess,
  loanSelectionFetchChatFailure,
  loanSelectionPreFetchChatRequest,
  loanSelectionPreFetchChatSuccess,
  loanSelectionPreFetchChatFailure,
  loanSelectionPreFetchChatClear,
  chatSendMsgRequest,
  chatSendMsgSuccess,
  chatSendMsgFailure,
  loanSelectionFirstPreFetchChatRequest,
  loanSelectionFirstPreFetchChatSuccess,
  loanSelectionUpdateDataRequest,
  loanSelectionUpdateDataSuccess,
  loanSelectionChatInputSuccess,
  loanSelectionActiveButtonRequest,
  loanSelectionActiveButtonSuccess,
  loanSelectionLastMessageRequest,
  loanSelectionLastMessageSuccess,
  loanSelectionLastMessageFailure,
} from '../duck';
import { logoutRequest } from '../../../Logout/state/duck';
import {
  preFetchedData,
  preFetchedCountOfFetches,
  preFetchedIteration,
  // ISF-29: NB: Отключено в рамках тестирования, ver. 0.8.4
  // preFetchedMessagesTotal,
} from '../selectors';

// import { data as authData } from '../../../Auth/state/selectors';
import { checkToken } from '../../../../core/state/utils/checkToken/saga';

import { CHAT_FETCH_LIMIT } from '../../../../views/HelpChat/constants';

/**
 * getPreFetchedData helper for getData
 *
 * @export
 * @param {*} action
 */
export function* getPreFetchedData(action: any) {
  const {
    callFrom = 'pre-fetch',
    page = 1,
    countOfFetches,
    tempPayload,
  } = action.payload;

  try {
    const beforeFetchedData = yield select(preFetchedData);
    const loanSelectionChatData = yield call(loanSelectionChat.getData, {
      page,
      perPage: CHAT_FETCH_LIMIT,
      ...tempPayload,
    });

    const { data, headers, status } = loanSelectionChatData;
    const total = Number(headers['x-pagination-total-count']) || 0;

    try {
      if (status === 200) {
        const stateMessages = get(beforeFetchedData, 'messages', []);

        yield put({
          type: loanSelectionPreFetchChatSuccess.toString(),
          payload: {
            total,
            callFrom,
            countOfFetches,
            iteration: page,
            messages: [...stateMessages, ...data],
          },
        });
      } else {
        yield put({
          type: loanSelectionPreFetchChatFailure.toString(),
          payload: {
            error: data,
            status,
          },
        });

        if (status === 401) {
          yield put({ type: logoutRequest.toString() });
        }
      }
    } catch (err) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log(
        '[SAGA loanSelection chat]:',
        yield put({
          type: loanSelectionPreFetchChatFailure.toString(),
          payload: {
            error: err,
          },
        }),
      );
    }
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR loanSelection chat]:', error);
    }
  }
} // getPreFetchedData =========

/**
 * Сага getData через себя вызывает генератор getPreFetchedData
 * для запроса pre fetched данных о товарах
 *
 * @export
 * @param {*} action
 * @param {sring} action.callFrom
 * cdM -- иницирующий запрос
 * cdU -- запрос только при поиске
 * scroll -- запрос при скроллинге
 * @returns
 */
export function* getData(action: any) {
  const { callFrom } = action.payload;

  try {
    const beforeFetchedIteration = yield select(preFetchedIteration);
    const beforeFetchedCountOfFetches = yield select(preFetchedCountOfFetches);
    // ISF-29: NB: Отключено в рамках тестирования, ver. 0.8.4
    // const beforeFetchedMessagesTotal = yield select(preFetchedMessagesTotal);

    const maximumPrefetchedPages = beforeFetchedCountOfFetches;

    // ISF-13: token
    const tempPayload: any = yield call(checkToken, {});

    let maxIteration = 5;
    let page = 1;

    // Запрос при скролле
    if (callFrom === 'scroll' && beforeFetchedIteration > 0) {
      page = beforeFetchedIteration + page;

      if (page > maximumPrefetchedPages) {
        return null;
      }

      yield put({ type: loanSelectionPreFetchChatRequest.toString() });
      yield call(getPreFetchedData, {
        type: loanSelectionPreFetchChatRequest.toString(),
        payload: {
          page,
          callFrom,
          countOfFetches: beforeFetchedCountOfFetches,
          tempPayload,
        },
      });
      return null;
    }
    // ==========================================================

    yield put({ type: loanSelectionFetchChatRequestFirstTime.toString() });

    const loanSelectionChatData = yield call(loanSelectionChat.getData, {
      page,
      perPage: CHAT_FETCH_LIMIT,
      ...tempPayload,
    });

    const { data, headers, status } = loanSelectionChatData;
    const total = Number(headers['x-pagination-total-count']) || 0;
    const countOfFetches = Number(headers['x-pagination-page-count']) || 0;

    try {
      if (status === 200) {
        // ISF-61: FIX: При первом входе в чат, сразу загрузить первое приветвенное сообщение
        if (data.length === 0) {
          yield put({
            type: chatSendMsgRequest.toString(),
            payload: { text: 'pick_me_a_loan' },
          });
        }

        yield put({
          type: loanSelectionFetchChatSuccess.toString(),
          payload: {
            total,
            callFrom,
            messages: data,
          },
        });

        // Если уже есть предзагруженные данные соответсвующие данному запросу, то работать с ними
        // ISF-29: NB: Отключено в рамках тестирования, ver. 0.8.4
        // if (
        //   countOfFetches === beforeFetchedCountOfFetches &&
        //   total === beforeFetchedMessagesTotal &&
        //   callFrom === 'cdM'
        // ) {
        //   return null;
        // }

        // Иницирующий запрос
        if (callFrom === 'cdM') {
          // Очистить ранее предзагруженные данные
          yield put({ type: loanSelectionPreFetchChatClear.toString() });

          // Посчитать количество необходимых запросов
          maxIteration = 5;
          if (maxIteration > countOfFetches) {
            maxIteration = countOfFetches;
          }

          // Набить данными на 5 страниц вперед или на оставшееся количество
          yield put({
            type: loanSelectionFirstPreFetchChatRequest.toString(),
          });
          for (let index = 0; index < maxIteration; index++) {
            yield call(getPreFetchedData, {
              type: loanSelectionPreFetchChatRequest.toString(),
              payload: {
                countOfFetches,
                callFrom,
                page: index + 1,
                tempPayload,
              },
            });
          }
          yield put({
            type: loanSelectionFirstPreFetchChatSuccess.toString(),
          });
        }
        // ==========================================================
      } else {
        yield put({
          type: loanSelectionFetchChatFailure.toString(),
          payload: {
            error: data,
            status,
          },
        });

        if (status === 401) {
          yield put({ type: logoutRequest.toString() });
        }
      }
    } catch (err) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[SAGA loanSelection chat]:', err);
    }
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR loanSelection chat]:', error);
    }
  }
} // getData =========

export function* createNewChatMessage(action: any): IterableIterator<any> {
  const { text } = action.payload;
  const tempPayload: any = yield call(checkToken, {});
  const preFetchedDataTemp: any = yield select(preFetchedData);
  const { messages = [], total } = preFetchedDataTemp;

  try {
    const loanSelectionChatData: any = yield call(loanSelectionChat.postData, {
      text,
      ...tempPayload,
    });

    const { data, status } = loanSelectionChatData;

    if (status === 200) {
      const updatedMessages = [...data.reverse(), ...messages];

      yield put({
        type: chatSendMsgSuccess.toString(),
        payload: {
          messages: updatedMessages,
          total: total + data.length,
        },
      });
    } else {
      yield put({
        type: chatSendMsgFailure.toString(),
        payload: {
          error: data,
          status,
        },
      });

      if (status === 401) {
        yield put({ type: logoutRequest.toString() });
      }
    }
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR createNewChatMessage]:', error);
      yield put({
        type: chatSendMsgFailure.toString(),
        payload: {
          error,
        },
      });
    }
  }
} // createNewChatMessage =========

/**
 * Сага updateData обновляет редьюсер внося информацию
 * о высоте отрендеренных сообщений
 *
 * @export
 * @param {*} action
 * @returns
 */
export function* updateData(action: any) {
  const { pseudoRenderedData } = action.payload;

  try {
    const preFetchedDataTemp = yield select(preFetchedData);
    const { messages, callFrom, total } = preFetchedDataTemp;

    const messagesWithoutRenderHeight = messages.filter((item: any) => {
      const messageRenderHeight =
        get(item, 'messageRenderHeight', null) || null;

      return !messageRenderHeight;
    });

    const messagesWithRenderHeight = messages.filter((item: any) => {
      const messageRenderHeight =
        get(item, 'messageRenderHeight', null) || null;

      return messageRenderHeight;
    });

    const updatedMessages = [
      ...messagesWithoutRenderHeight.map((item: any) => {
        const { id }: { id: string } = item;
        const messageRenderHeight: any = pseudoRenderedData![id];
        const result = { ...item, messageRenderHeight };

        return result;
      }),
    ];

    let newMessages = [...messagesWithRenderHeight, ...updatedMessages];
    let newTotalMsgs = total;

    // NB: Если вызывается при создании сообщения и сообщение
    // messagesWithRenderHeight при этом не должно быть пустым массивом
    if (
      callFrom === 'createNewMessage' &&
      messagesWithRenderHeight.length !== 0
    ) {
      // NB: Antosha's naming style `value.value` %)
      const retroValueType = get(
        messagesWithRenderHeight,
        '[0].value.type',
        'NO_TYPE',
      );

      if (retroValueType !== 'products') {
        const currentValue = get(
          updatedMessages,
          '[1].value.value',
          'NO_VALUE',
        );

        const retroObj = {
          ...messagesWithRenderHeight[0],
          value: { ...messagesWithRenderHeight[0].value, value: currentValue },
        };

        newMessages = [
          ...updatedMessages,
          retroObj,
          ...messagesWithRenderHeight.slice(1),
        ];
      } else {
        newMessages = [...updatedMessages, ...messagesWithRenderHeight];
      }

      newTotalMsgs = newMessages.length;
    }

    const dataChatInput = get(newMessages, '[0]', {});

    yield put({
      type: loanSelectionUpdateDataSuccess.toString(),
      payload: {
        messages: newMessages,
        total: newTotalMsgs,
      },
    });
    yield put({
      type: loanSelectionChatInputSuccess.toString(),
      payload: dataChatInput,
    });
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR loanSelection chat updateData]:', error);
    }
  }
} // updateData =========

export function* setActiveButton(action: any): IterableIterator<any> {
  try {
    const { text } = action.payload;

    yield put({
      type: loanSelectionActiveButtonSuccess.toString(),
      payload: text,
    });
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR setActiveButton]:', error);
    }
  }
} // setActiveButton =========

export function* getDataLastMsg(): IterableIterator<any> {
  try {
    const tempPayload: any = yield call(checkToken, {});

    const srvRes: any = yield call(loanSelectionChat.getDataLastMsg, {
      ...tempPayload,
    });

    const { data, status } = srvRes;

    if (status === 200) {
      yield put({
        type: loanSelectionLastMessageSuccess.toString(),
        payload: data,
      });
    } else {
      yield put({
        type: loanSelectionLastMessageFailure.toString(),
        payload: {
          error: data,
          status,
        },
      });

      if (status === 401) {
        yield put({ type: logoutRequest.toString() });
      }
    }
  } catch (error) {
    if (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('[ERROR getDataLastMsg]:', error);
      yield put({
        type: loanSelectionLastMessageFailure.toString(),
        payload: {
          error,
        },
      });
    }
  }
} // getDataLastMsg =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(loanSelectionFetchChatRequest, getData);
  yield takeLatest(loanSelectionLastMessageRequest, getDataLastMsg);
  yield takeEvery(chatSendMsgRequest, createNewChatMessage);
  yield takeEvery(loanSelectionUpdateDataRequest, updateData);
  yield takeEvery(loanSelectionActiveButtonRequest, setActiveButton);
}
