import React, { SVGAttributes } from 'react';

const ThreeDots: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="#1373E5" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.0001 7.4351C11.2822 7.4351 11.5579 7.35147 11.7923 7.19479C12.0268 7.03811 12.2096 6.81541 12.3175 6.55486C12.4255 6.2943 12.4537 6.0076 12.3987 5.73099C12.3437 5.45439 12.2078 5.20032 12.0084 5.0009C11.809 4.80148 11.5549 4.66567 11.2783 4.61065C11.0017 4.55563 10.715 4.58387 10.4545 4.69179C10.1939 4.79972 9.97121 4.98248 9.81453 5.21698C9.65785 5.45147 9.57422 5.72716 9.57422 6.00918C9.57422 6.38736 9.72445 6.75005 9.99186 7.01746C10.2593 7.28487 10.622 7.4351 11.0001 7.4351ZM11.0001 14.5647C10.7181 14.5647 10.4424 14.6484 10.2079 14.805C9.97345 14.9617 9.79069 15.1844 9.68276 15.445C9.57484 15.7055 9.5466 15.9922 9.60162 16.2688C9.65664 16.5454 9.79244 16.7995 9.99186 16.9989C10.1913 17.1984 10.4454 17.3342 10.722 17.3892C10.9986 17.4442 11.2853 17.416 11.5458 17.308C11.8064 17.2001 12.0291 17.0174 12.1858 16.7829C12.3424 16.5484 12.4261 16.2727 12.4261 15.9907C12.4261 15.6125 12.2758 15.2498 12.0084 14.9824C11.741 14.715 11.3783 14.5647 11.0001 14.5647ZM11.0001 9.57399C10.7181 9.57399 10.4424 9.65762 10.2079 9.8143C9.97345 9.97099 9.79069 10.1937 9.68276 10.4542C9.57484 10.7148 9.5466 11.0015 9.60162 11.2781C9.65664 11.5547 9.79244 11.8088 9.99186 12.0082C10.1913 12.2076 10.4454 12.3434 10.722 12.3984C10.9986 12.4535 11.2853 12.4252 11.5458 12.3173C11.8064 12.2094 12.0291 12.0266 12.1858 11.7921C12.3424 11.5576 12.4261 11.2819 12.4261 10.9999C12.4261 10.6217 12.2758 10.259 12.0084 9.99164C11.741 9.72422 11.3783 9.57399 11.0001 9.57399Z"
    />
  </svg>
);

export default ThreeDots;
