import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const AD_BLOCKS = 'AD_BLOCKS';

export const adBlocksRequest = createAction(`${AD_BLOCKS}/REQUEST`);
export const adBlocksSuccess = createAction(`${AD_BLOCKS}/SUCCESS`);
export const adBlocksFailure = createAction(`${AD_BLOCKS}/FAILURE`);

export const adBlocksResult = createAction(`${AD_BLOCKS}/RESULT`);

// Reducers ==========================

const isFetching = createReducer(false, {
  [adBlocksRequest.toString()]: () => true,
  [adBlocksSuccess.toString()]: () => false,
  [adBlocksFailure.toString()]: () => false,
});

const isAdBlocksAlreadySet = createReducer(false, {
  [adBlocksRequest.toString()]: () => false,
  [adBlocksSuccess.toString()]: () => true,
});

const result = createReducer(
  {},
  {
    [adBlocksResult.toString()]: (_state, action) => action.payload,
  },
);

const list = createReducer([], {
  [adBlocksSuccess.toString()]: (_state, action) => action.payload,
  [adBlocksFailure.toString()]: () => [],
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  isAdBlocksAlreadySet,
  result,
  list,
});

export default reducer;
