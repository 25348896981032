import React from 'react';

const ArrowDown = () => (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.811503 0.678203C0.915643 0.574063 1.08449 0.574063 1.18863 0.678203L5.00006 4.48964L8.8115 0.678203C8.91564 0.574063 9.08449 0.574063 9.18863 0.678203C9.29277 0.782342 9.29277 0.951186 9.18863 1.05533L5.00006 5.24389L0.811503 1.05533C0.707363 0.951186 0.707363 0.782342 0.811503 0.678203Z"
      fill="#0F1011"
    />
  </svg>

);

export default ArrowDown;
