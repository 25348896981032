import React from 'react';
import cx from 'classnames';

import Clock from '../../../../../assets/images/Clock';

import styles from './Dashboard.module.scss';
import { useStore } from 'effector-react';
import { $mfoTotal, $isLoadingMfoTotal } from '../../../../states/Multilogin/store';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { userCountry as userCountrySelector } from '../../../../states/UserProfile/state/selectors';

const Dashboard: React.FC = () => {
  const mfoTotal = useStore($mfoTotal);
  const isLoadingMfoTotal = useStore($isLoadingMfoTotal);
  const { code: userCountryCode } = useSelector(userCountrySelector);

  return (
    <div className={styles.root}>
      <div className={styles.dashboard}>
        <div className={styles.count}>
          {isLoadingMfoTotal
            ? <Skeleton width={40} height="100%" />
            : mfoTotal.count
          }
        </div>
        <div className={styles.subtitle}>
          <Translate id="multilogin.my.mfo.all" />
        </div>
      </div>
      <div className={styles.dashboard}>
        <div className={styles.count}>
          {isLoadingMfoTotal
            ? <Skeleton width={120} height="100%" />
            : <>{mfoTotal.active} <Translate id={`currency.symbol.${userCountryCode}`} /></>
          }
        </div>
        <div className={styles.subtitle}>
          <Translate id="multilogin.my.active.all" />
        </div>
      </div>
      <div className={styles.dashboard}>
        <div className={cx(styles.count, styles.gray)}>
          <Clock fill="#CED7E0" width="20px" height="20px" />
          {isLoadingMfoTotal
            ? <Skeleton width={120} height="100%" />
            : <>{mfoTotal.active} <Translate id={`currency.symbol.${userCountryCode}`} /></>
          }
        </div>
        <div className={styles.subtitle}>
          <Translate id="multilogin.my.await.all" />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
