/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';
import { /* Row, */ Col, Button, Progress } from 'antd';
// @ts-ignore
import StringMask from 'string-mask';

// import UserSettingsChangePswdForm from '../UserSettingsChangePswdForm';
// import UserSettingsChangeEmailForm from '../UserSettingsChangeEmailForm';
// import UserSettingsChangeAvatarForm from '../UserSettingsChangeAvatarForm';
import { logoutRequest } from '../../../states/Logout/state/duck';

import {
  data as userProfileSelector,
  userCountry as userCountrySelector,
} from '../../../states/UserProfile/state/selectors';
import { masks as maskSelector } from '../../../states/Masks/state/selectors';
import { countries as countriesSelector } from '../../../states/Countries/state/selectors';
// FIXME: когда добавятся кабинеты под разные страны,
// то нужно брать не язык интерфейса а именно страну(!) из урла

import { useWindowSize } from '../../hooks/useWindowSize';

import { DEVICE_NETBOOKS_WIDTH } from '../../constants/Defaults/constants';

import styles from './UserSettingsProfile.module.scss';

// import editSettingsImage from './assets/images/editSettings.svg';
import userAvatarPlugImage from './assets/images/userAvatarPlug.svg';
// import approvedImage from './assets/images/approved.svg';
// import approvedTransparentImage from './assets/images/approvedTransparent.svg';
// import backArrowImage from './assets/images/backArrow.svg';
import penIcon from './assets/images/pen_icon.svg';
import cameraIcon from './assets/images/camera.svg';

import fincoinImage from './assets/images/fincoin.svg';
import smileImage from './assets/images/smile.svg';
import statusImage from './assets/images/status.svg';
import logoutImage from './assets/images/logout.svg';
import arrowLeftImage from './assets/images/arrow_left.svg';

import arrowRightImage from './assets/images/arrowRight.svg';
import settingsImage from './assets/images/settings.svg';
import bagImage from './assets/images/bag.svg';
import notificationImage from './assets/images/notification.svg';
import clockImage from './assets/images/clock.svg';

import { environment } from '../../../../environment';

// import { setPhoneSecureForHuman } from '../../utils/normalizePhoneSecure.helper';
import { formatEmailSecure } from '../../utils/formatEmailSecure.helper';
import { detailsData as detailsDataSelector } from '../../../states/CreditRating/state/selectors';
import { userSettingsChangeAvatarPostRequest } from '../../../states/UserSettingsChangeAvatar/state/duck';
import UserSettingsEditProfile from '../UserSettingsEditProfile';
import { replaceAll } from '../../utils/replaceAll.helper';

import stylesTarif from '../../componets/BalanceBadge/BalanceBadge.module.scss';

import { useHistory } from 'react-router-dom';

import CrownOutlined from '@ant-design/icons/CrownOutlined';

// const PROFILE = 'PROFILE';
const SUBSCRIPTION = 'SUBSCRIPTION';
const SETTINGS = 'SETTINGS';
const ACTIVITY = 'ACTIVITY';
const SHOPPING = 'SHOPPING';

const defaultLevel = 1;

interface IProps {
  handleClickTab: any;
  handleKeyPressTab: any;
  changeHeader: any;
  editHeader: boolean;
}

/**
 * Окно настройки: Мой профиль
 *
 * @returns
 */
const UserSettingsProfile: React.FC<IProps> = props => {
  const userProfile = useSelector(userProfileSelector);
  const detailsData = useSelector(detailsDataSelector);
  const { code: userCountryCode } = useSelector(userCountrySelector);
  const dispatch = useDispatch();

  const { handleClickTab, handleKeyPressTab, changeHeader, editHeader } = props;

  const { email, profile, lastPhone, avatar: avatarData, balance, userSubscribe } = userProfile;
  const { country } = profile;
  const {
    firstname: firstName,
    midlename: middleName,
    lastname: lastName,
    user_id: userId,
  } = profile;

  const isFree = userSubscribe === 'Free' ? true : false;
  const cssTarif = 'currentTarif';

  const history = useHistory();

  const levelPercent = (defaultLevel / 10) * 100;

  const { phone } = lastPhone;

  const countries = useSelector(countriesSelector);
  const { id: countryId } = countries.find((item: any) => item.name === country);
  const masks = useSelector(maskSelector);
  const { value: mask } = masks.find((item: any) =>
    item.countryId === countryId && item.type === 2,
  );
  const formatter = new StringMask(replaceAll(mask.toString(), '\\', ''), { reverse: true });

  const securePhone = formatter.apply(phone);
  const emailSecure = formatEmailSecure(email);

  const { avatar } = avatarData;
  const defaultAvatarImage =
    avatar !== ''
      ? avatar
      : userAvatarPlugImage;

  const windowSize = useWindowSize();
  const { width = 0 } = windowSize;

  // По умолчанию считаем что запущено на десктопе, мобила = false
  let isMobile = false;

  if (width < DEVICE_NETBOOKS_WIDTH) {
    isMobile = true;
  }

  // const [state, setState] = React.useState({ editStatus: false });
  // const { editStatus } = state;
  // console.log(editStatus);

  // function handleChangeState(name: string, value: boolean) {
  //   setState(prevState => ({ ...prevState, [name]: value }));
  // }

  // const handleClickTrue = React.useCallback(() => {
  //   handleChangeState('editStatus', true);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const handleClickFalse = React.useCallback(() => {
  //   handleChangeState('editStatus', false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const handleKeyPressTrue = React.useCallback(event => {
  //   if (event.key === 'Enter') {
  //     handleChangeState('editStatus', true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const handleKeyPressFalse = React.useCallback(event => {
  //   if (event.key === 'Enter') {
  //     handleChangeState('editStatus', false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const toggleHeader = () => {
    // handleClickTrue();
    changeHeader(true);
  };

  const toggleHeaderDesktop = () => {
    // handleClickFalse();
    changeHeader(false);
  };

  const handleKeyPressLogout = React.useCallback(event => {
    if (event.key === 'Enter') {
      dispatch({ type: logoutRequest.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleClickTab = React.useCallback(event => {
  //   const { currentTarget } = event;
  //   const tabKey = currentTarget.getAttribute('data-key');

  //   handleChangeState('activeTab', tabKey);
  // }, []);

  // const handleKeyPressTab = React.useCallback(event => {
  //   if (event.key === 'Enter') {
  //     const { currentTarget } = event;
  //     const tabKey = currentTarget.getAttribute('data-key');

  //     handleChangeState('activeTab', tabKey);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const handleClickFalse = React.useCallback(() => {
  //   handleChangeState('editStatus', false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const handleKeyPressFalse = React.useCallback(event => {
  //   if (event.key === 'Enter') {
  //     handleChangeState('editStatus', false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const changeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    dispatch({
      type: userSettingsChangeAvatarPostRequest.toString(),
      payload: { avatar: files && files.length && files[0] },
    });
  };

  return (
    <>
      {/* <div className={cx(styles.header)}>
        {editStatus && (
          <div
            className={cx(styles.headerIcon)}
            onClick={handleClickFalse}
            onKeyPress={handleKeyPressFalse}
            role="button"
            tabIndex={0}
            style={{
              marginRight: '11px',
            }}
          >
            <img src={backArrowImage} alt="Edit" width={26} height={26} />
          </div>
        )}
        <div className={cx(styles.headerText)}>
          {editStatus ? (
            <Translate id="shared_containers_usersettingsprofile_yourprofile_edit" />
          ) : (
            <Translate id="shared_containers_usersettingsprofile_yourprofile" />
          )}
        </div>
        {!editStatus && (
          <div
            className={cx(styles.headerIcon)}
            onClick={handleClickTrue}
            onKeyPress={handleKeyPressTrue}
            role="button"
            tabIndex={0}
          >
            <img src={editSettingsImage} alt="Edit" width={16} height={16} />
          </div>
        )}
      </div> */}
      {/* editStatus */}
      <>
        {editHeader ? (
          <>
            {isMobile ? (
              ''
            ) : (
              <div className={cx(styles.header)}>
                <div
                  className={cx(styles.headerIcon)}
                  onClick={toggleHeaderDesktop}
                  onKeyPress={toggleHeaderDesktop}
                  role="button"
                  tabIndex={0}
                >
                  <img src={arrowLeftImage} alt="Edit" />
                </div>
                <div className={cx(styles.header__titleInner)}>
                  <Translate id="shared_containers_usersettingsprofile_yourprofile_edit" />
                </div>
              </div>
            )}

            <div className={cx(styles.subheader)}>
              <p>
                <Translate id="shared_containers_usersettingsprofile_yourprofile_edit_subheader" />
              </p>
            </div>

            <div className={styles.changeAvatar}>
              <div
                className={styles.changeAvatar__img}
                style={{
                  backgroundImage: `url(${defaultAvatarImage})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: avatar === '' ? '50%' : '100%',
                }}
              >
                <input
                  className={styles.changeAvatar__input}
                  type="file"
                  onChange={changeAvatar}
                  accept="image/*"
                />
                <img className={styles.changeAvatar__add} src={cameraIcon} alt="changeAvatar" />
              </div>
            </div>

            <UserSettingsEditProfile />
          </>
        ) : (
          <>
            <div className={cx(styles.info)}>
              {isMobile ? (
                ''
              ) : (
                <div className={cx(styles.header)}>
                  <div className={cx(styles.header__title)}>
                    <Translate id="shared_containers_usersettings_myprofile" />
                  </div>
                  <div
                    className={cx(styles.headerIcon)}
                    // onClick={handleClickTrue}
                    onClick={toggleHeader}
                    onKeyPress={toggleHeader}
                    role="button"
                    tabIndex={0}
                  >
                    <img src={penIcon} alt="Edit" width={20} height={20} />
                  </div>
                </div>
              )}

              {isMobile ? (
                <div
                  // data-key={EDITPROFILE}
                  className={cx(styles.headerIcon)}
                  onClick={toggleHeader}
                  onKeyPress={toggleHeader}
                  role="button"
                  tabIndex={0}
                >
                  <img
                    src={penIcon}
                    alt="Edit"
                    width={16}
                    height={16}
                  />
                </div>
              ) : (
                ''
              )}

              <Col>
                <div
                  className={cx(styles.infoAvatar, {
                    [styles.infoAvatarMobile]: isMobile,
                  })}
                >
                  <input
                    onChange={changeAvatar}
                    className={styles.changeAvatar__input}
                    type="file"
                    accept="image/*"
                  />
                  <div
                    className={cx(styles.avatar)}
                    style={{
                      backgroundImage: `url(${defaultAvatarImage})`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: avatar === '' ? '50%' : '100%',
                    }}
                  />
                </div>
              </Col>
              <Col>
                <div className={cx(styles.infoPropsList)}>
                  <div className={styles.infoId}>
                    <div>ID: {userId}</div>
                  </div>
                  <div className={styles.infoFio}>
                    <div>{`${lastName}`}</div>
                    <div>{`${firstName}`}</div>
                    <div>{`${middleName}`}</div>
                  </div>
                  <Button
                    onClick={() => history.push('?tariff=1')}
                    style={{
                      display: 'inline',
                      fontSize: '12px',
                      fontWeight: '900',
                      padding: '0px 8px',
                      height: '22px',
                      marginBottom: '15px',
                    }}
                    className={cx(stylesTarif.badge + cssTarif, {
                      [stylesTarif.success]: !isFree,
                      [stylesTarif.danger]: isFree,
                    })}
                  >
                    <CrownOutlined />{userSubscribe}
                  </Button>
                  <div className={styles.infoPhone}>{securePhone}</div>
                  <div className={styles.infoEmail}>{emailSecure}</div>
                  {/* <div className={cx(styles.listEl)}>
                    <div className={cx(styles.listElPropName)}>
                      <Translate id="shared_containers_usersettingsprofile_lastname" />
                    </div>
                    <div className={cx(styles.listElPropValue)}>
                      <div>{`${lastName}`}</div>
                      <div>
                        <img src={approvedTransparentImage} alt="Approved" />
                      </div>
                    </div>
                  </div>
                  <div className={cx(styles.listEl)}>
                    <div className={cx(styles.listElPropName)}>
                      <Translate id="shared_containers_usersettingsprofile_firstname" />
                    </div>
                    <div className={cx(styles.listElPropValue)}>
                      <div>{`${firstName}`}</div>
                      <div>
                        <img src={approvedTransparentImage} alt="Approved" />
                      </div>
                    </div>
                  </div>
                  <div className={cx(styles.listEl)}>
                    <div className={cx(styles.listElPropName)}>
                      <Translate id="shared_containers_usersettingsprofile_middlename" />
                    </div>
                    <div className={cx(styles.listElPropValue)}>
                      <div>{`${middleName}`}</div>
                      <div>
                        <img src={approvedTransparentImage} alt="Approved" />
                      </div>
                    </div>
                  </div>
                  <div className={cx(styles.listEl)}>
                    <div className={cx(styles.listElPropName)}>
                      <Translate id="shared_containers_usersettingsprofile_phone" />
                    </div>
                    <div className={cx(styles.listElPropValue)}>
                      <div>{securePhone}</div>
                      <div>
                        <img src={approvedTransparentImage} alt="Approved" />
                      </div>
                    </div>
                  </div>
                  <div className={cx(styles.listEl)}>
                    <div className={cx(styles.listElPropName)}>E-Mail</div>
                    <div className={cx(styles.listElPropValue)}>
                      <div>{emailSecure}</div>
                      <div>
                        <img src={approvedTransparentImage} alt="Approved" />
                      </div>
                    </div>
                  </div> */}
                </div>
              </Col>
            </div>

            {/* баланс рейтинг статус */}
            <div className={styles.indicators}>
              <div className={styles.indicators__elem}>
                <div className={cx(styles.indicators__label, styles.indicators__labelColor)}>
                  <img src={fincoinImage} alt="fincoin" />
                </div>
                <div className={styles.indicators__title}>
                  <Translate id="shared_containers_usersettings_mybalance" />
                </div>
                <div className={styles.indicators__value}>{balance}</div>
              </div>

              <div className={styles.indicators__elem}>
                <div className={styles.indicators__label}>
                  {isMobile ? (
                    <Progress
                      type="circle"
                      percent={75}
                      width={44}
                      format={() => ''}
                      trailColor="#E8F1FD"
                      strokeColor="#10B97B"
                    />
                  ) : (
                    <Progress
                      type="circle"
                      percent={detailsData.rating}
                      width={70}
                      format={() => ''}
                      trailColor="#E8F1FD"
                      strokeColor="#10B97B"
                    />
                  )}
                  <img src={smileImage} alt="smile" />
                </div>
                <div className={styles.indicators__title}>
                  <Translate id="shared_containers_usersettings_myrating" />
                </div>
                <div className={styles.indicators__value}>{detailsData.rating}</div>
              </div>

              <div className={styles.indicators__elem}>
                <div className={styles.indicators__label}>
                  {isMobile ? (
                    <Progress
                      type="circle"
                      percent={levelPercent}
                      width={44}
                      format={() => ''}
                      trailColor="#E8F1FD"
                    />
                  ) : (
                    <Progress
                      type="circle"
                      percent={levelPercent}
                      width={70}
                      format={() => ''}
                      trailColor="#E8F1FD"
                    />
                  )}
                  <img src={statusImage} alt="status" />
                </div>
                <div className={styles.indicators__title}>
                  <Translate id="shared_containers_usersettings_mylevel" />
                </div>
                <div className={styles.indicators__value}>{defaultLevel}</div>
              </div>
            </div>

            {isMobile ? (
              <>
                {/* Tabs */}
                <div className={styles.wrapTabs}>
                  {/* Subscription tab */}
                  <Col span={24}>
                    <div
                      data-key={SUBSCRIPTION}
                      className={cx(styles.tab, styles.mobileTab, {
                        // [styles.tabActive]: activeTab === SUBSCRIPTION,
                      })}
                      onClick={handleClickTab}
                      onKeyPress={handleKeyPressTab}
                      role="menuitem"
                      tabIndex={0}
                    >
                      <img src={notificationImage} alt="notification" />
                      <span>
                        <Translate id="shared_containers_usersettings_subscription" />
                      </span>
                      <img className={styles.arrowRight} src={arrowRightImage} alt="arrowRight" />
                    </div>
                  </Col>
                  {/* Activity */}
                  <Col span={24}>
                    <div
                      data-key={ACTIVITY}
                      className={cx(styles.tab, styles.mobileTab, {
                        // [styles.tabActive]: activeTab === ACTIVITY,
                      })}
                      onClick={handleClickTab}
                      onKeyPress={handleKeyPressTab}
                      role="menuitem"
                      tabIndex={0}
                    >
                      <img src={clockImage} alt="activity" />
                      <span>
                        <Translate id="shared_containers_usersettings_activity" />
                      </span>
                      <img className={styles.arrowRight} src={arrowRightImage} alt="arrowRight" />
                    </div>
                  </Col>
                  {/* Shoping and Limits */}
                  <Col span={24}>
                    <div
                      data-key={SHOPPING}
                      className={cx(styles.tab, styles.mobileTab, {
                        // [styles.tabActive]: activeTab === SHOPPING,
                      })}
                      onClick={handleClickTab}
                      onKeyPress={handleKeyPressTab}
                      role="menuitem"
                      tabIndex={0}
                    >
                      <img src={bagImage} alt="shopAndLimits" />
                      <span>
                        <Translate id="shared_containers_usersettings_shopping" />
                      </span>
                      <img className={styles.arrowRight} src={arrowRightImage} alt="arrowRight" />
                    </div>
                  </Col>
                  {/* Settings */}
                  <Col span={24}>
                    <div
                      data-key={SETTINGS}
                      className={cx(styles.tab, styles.mobileTab, {
                        // [styles.tabActive]: activeTab === SETTINGS,
                      })}
                      onClick={handleClickTab}
                      onKeyPress={handleKeyPressTab}
                      role="menuitem"
                      tabIndex={0}
                    >
                      <img src={settingsImage} alt="settings" />
                      <span>
                        <Translate id="shared_containers_usersettings_settings" />
                      </span>
                      <img className={styles.arrowRight} src={arrowRightImage} alt="arrowRight" />
                    </div>
                  </Col>
                </div>

                <Button
                  className={cx(styles.sidebarLogout, styles.sidebarLogoutMobile)}
                  onClick={() => dispatch({ type: logoutRequest.toString() })}
                  onKeyPress={handleKeyPressLogout}
                  type="text"
                >
                  <img src={logoutImage} alt="logout" width={16} height={16} />
                  <span>
                    <Translate id="shared.containers.usersettings.logout" />
                  </span>
                </Button>
              </>
            ) : (
              ''
            )}

            <div className={cx(styles.lawText)}>
              <p>
                <Translate
                  id={`user.settings.profile.law.${userCountryCode}`}
                />
              </p>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default UserSettingsProfile;
