import { put, takeLatest, call, select, takeEvery } from 'redux-saga/effects';
import get from 'lodash/get';
import * as E from 'fp-ts/lib/Either';

import { articlesAPI } from '../../../../services/Articles/api';

import {
  articlesRequest,
  articlesResult,
  articlesSuccess,
  articlesFailure,
  articlesSecondRequest,
  articlesSecondResult,
  articlesArticleRequest,
  articlesArticleSuccess,
  articlesArticleFailure,
  articlesArticleResult,
  articlesCategoriesRequest,
  articlesCategoriesFailure,
  articlesCategoriesResult,
  articlesFilteredRequest,
  articlesFilteredSuccess,
  articlesFilteredFailure,
  articlesFilteredResult,
  articlesSecondFilteredRequest,
  articlesSecondFilteredResult,
} from '../duck';

import { articlesList, dataArticle } from '../selectors';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';

export function* getData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const articlesRes = yield call(articlesAPI.getData, {
    ...completePayload,
  });

  yield put({
    type: articlesResult.toString(),
    payload: createResultV2(articlesRes),
  });
} // getData =========

export function* checkResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const dataRes = get(successData, 'data', []) || [];
    const data = Array.isArray(dataRes) ? dataRes : [];

    yield put({
      type: articlesSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: articlesFailure.toString(),
    });
  }
} // checkResult =========

export function* getFilteredData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const articlesRes = yield call(articlesAPI.getData, {
    ...completePayload,
  });

  yield put({
    type: articlesFilteredResult.toString(),
    payload: createResultV2(articlesRes),
  });
} // getFilteredData =========

export function* checkFilteredResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const dataRes = get(successData, 'data', []) || [];
    const data = Array.isArray(dataRes) ? dataRes : [];

    yield put({
      type: articlesFilteredSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: articlesFilteredFailure.toString(),
    });
  }
} // checkFilteredResult =========

export function* getSecondData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const articlesRes = yield call(articlesAPI.getData, {
    ...completePayload,
  });

  yield put({
    type: articlesSecondResult.toString(),
    payload: createResultV2(articlesRes),
  });
} // getSecondData =========

export function* checkSecondResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const resivedArticles: any = yield select(articlesList);
    const { right: successData } = payload;
    const dataRes = get(successData, 'data', []) || [];
    const data = Array.isArray(dataRes) ? dataRes : [];
    const payloadTmp = {
      data: [...resivedArticles, ...data],
    };

    yield put({
      type: articlesSuccess.toString(),
      payload: payloadTmp,
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: articlesFailure.toString(),
    });
  }
} // checkSecondResult =========

export function* getSecondFilteredData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const articlesRes = yield call(articlesAPI.getData, {
    ...completePayload,
  });

  yield put({
    type: articlesSecondFilteredResult.toString(),
    payload: createResultV2(articlesRes),
  });
} // getSecondFilteredData =========

export function* checkSecondFilteredResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const resivedArticles: any = yield select(articlesList);
    const { right: successData } = payload;
    const dataRes = get(successData, 'data', []) || [];
    const data = Array.isArray(dataRes) ? dataRes : [];
    const payloadTmp = {
      data: [...resivedArticles, ...data],
    };

    yield put({
      type: articlesFilteredSuccess.toString(),
      payload: payloadTmp,
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: articlesFilteredFailure.toString(),
    });
  }
} // checkSecondFilteredResult =========

export function* getArticleData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const articleRes = yield call(articlesAPI.getDataArticle, {
    ...completePayload,
  });

  yield put({
    type: articlesArticleResult.toString(),
    payload: createResultV2(articleRes),
  });
} // getArticleData =========

export function* checkArticleResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const cachedArticles: any = yield select(dataArticle);
    const { right: successData } = payload;
    const dataRes = get(successData, 'data', { id: -1 }) || { id: -1 };
    const { id } = dataRes;

    let payloadTmp = {
      ...cachedArticles,
    };

    if (!cachedArticles[id]) {
      payloadTmp = {
        ...payloadTmp,
        [id]: dataRes,
      };
    }

    yield put({
      type: articlesArticleSuccess.toString(),
      payload: payloadTmp,
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: articlesArticleFailure.toString(),
    });
  }
} // checkArticleResult =========

export function* getCategoriesData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const res = yield call(articlesAPI.getDataСategories, {
    ...completePayload,
  });

  yield put({
    type: articlesCategoriesResult.toString(),
    payload: createResultV2(res),
  });
} // getCategoriesData =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    articlesRequest,
    safe(onError, getData, {
      terminator: articlesFailure,
    }),
  );

  yield takeLatest(
    articlesFilteredRequest,
    safe(onError, getFilteredData, {
      terminator: articlesFilteredFailure,
    }),
  );

  yield takeLatest(
    articlesSecondRequest,
    safe(onError, getSecondData, {
      terminator: articlesFailure,
    }),
  );

  yield takeLatest(
    articlesSecondFilteredRequest,
    safe(onError, getSecondFilteredData, {
      terminator: articlesFilteredFailure,
    }),
  );

  yield takeLatest(
    articlesArticleRequest,
    safe(onError, getArticleData, {
      terminator: articlesArticleFailure,
    }),
  );

  yield takeLatest(
    articlesCategoriesRequest,
    safe(onError, getCategoriesData, {
      terminator: articlesCategoriesFailure,
    }),
  );

  // Either checkers
  yield takeEvery(articlesResult, safe(onError, checkResult));
  yield takeEvery(articlesSecondResult, safe(onError, checkSecondResult));
  yield takeEvery(articlesArticleResult, safe(onError, checkArticleResult));
  yield takeEvery(articlesFilteredResult, safe(onError, checkFilteredResult));
  yield takeEvery(
    articlesSecondFilteredResult,
    safe(onError, checkSecondFilteredResult),
  );
}
