import ReactGA from 'react-ga';

import { getData as getToken, setData } from '../commonLocalStorage.helper';
import { LOCAL_STORAGE_AUTH_KEY, LOCAL_STORAGE_YA_CLIENT_ID } from '../../constants/Defaults/constants';
import { environment } from '../../../../environment';

import { userProfile as UserProfileApi } from '../../../services/UserProfile/api';

const GoogleId = 'UA-159591024-1';
const YandexId = 61547410;
const TmrId = 3226510;

let counterYm = 0;
const ym = (category: string, action: any): any => {
  if (window?.ym && counterYm <= 10) {
    return window?.ym(YandexId, category, action);
  }
  counterYm++;
  setTimeout(() => ym(category, action), 500);
};

let counterVK = 0;
const VK = (cb: any): any => {
  if (window?.VK && counterVK <= 10) {
    return cb();
  }
  counterVK++;
  setTimeout(() => VK(cb), 500);
};

let counterTmr = 0;
const tmr = (category: string, action: any) => {
  // eslint-disable-next-line no-underscore-dangle
  if (window?._tmr && counterTmr <= 10) {
    // eslint-disable-next-line no-underscore-dangle
    const tmr = window?._tmr || [];
    return tmr.push({ id: TmrId, type: 'reachGoal', goal: action, value: 1 });
  }
  counterVK++;
  setTimeout(() => tmr(category, action), 500);
};

export const init = () => {
  ReactGA.initialize(GoogleId);

  ym('getClientID', (clientID: any) => setData(LOCAL_STORAGE_YA_CLIENT_ID, clientID));
};

export const event = async (category: string, action: string) => {
  if (!environment.PRODUCTION) {
    // return;
  }

  // Отправка события в гугл
  ReactGA.event({
    category,
    action,
  });

  // Отправка события в яндекс
  ym('reachGoal', action);

  // Отправка события в вк
  VK(() => window?.VK.Goal(action));

  // Отправка таргета
  tmr(category, action);

  // отправка на бек
  if (window?.ym) {
    try {
      const token = getToken(LOCAL_STORAGE_AUTH_KEY);

      await UserProfileApi.postEventTarget({
        google: action,
        yandex: action,
        vk: action,
        mailru: action,
        token,
      });
    } catch (e) {
      // Разрешенный игнор
      // eslint-disable-next-line no-console
      console.error('Error send event target');
    }
  }
};

export default {
  GoogleId,
  YandexId,
  event,
};
