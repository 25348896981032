import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export interface UseQueryProps {
  observer?: (query: string | undefined) => void
  name?: string
}

export const useQuery = (props: UseQueryProps = {}) => {
  const {
    observer,
    name,
  } = props;
  const history = useHistory();
  const location = useLocation();

  const [query, _setQuery] = useState<string | undefined>(() => {
    if (!name) { return; }
    const params = new URLSearchParams(location.search);
    return params.get(name) as string | undefined;
  });

  useEffect(() => {
    if (!name) { return; }

    const params = new URLSearchParams(location.search);
    const getParam = params.get(name) as string | undefined;
    _setQuery(getParam);

    if (typeof observer === 'function') {
      observer(getParam);
    }
  }, [location?.search]);

  const setQuery = useCallback((value: string | number, nameQuery?: string) => {
    const $name = nameQuery || name || '';
    const params = new URLSearchParams(location.search);
    params?.set($name, value.toString());
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  }, [location]);

  const deleteQuery = useCallback((name: string) => {
    const params = new URLSearchParams(location.search);
    params?.delete(name);
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  }, [location]);

  return {
    query,
    setQuery,
    deleteQuery,
  };
};
