import moment from 'moment';

interface IParsRulesForm {
  field: Record<string, any>,
  value: string | number,
  name: string,
  setError: (...arg: any) => void,
}

export const parsRulesForm = ({
  field,
  value,
  name,
  setError,
}: IParsRulesForm): void => {
  if (field && field.type === 'date') {
    const { value: valueRules } = field?.rules?.date_less_than || {};
    if (valueRules && moment(value) > moment(valueRules).endOf('day')) {
      setError(name, {
        type: 'manual',
        message: 'offer.date.ban',
      });
    }
  }
};
