import { takeEvery, put, call } from 'redux-saga/effects';

import { logoutRequest, logoutSuccess } from '../duck';

import { removeData as removeTokenLS } from '../../../../shared/utils/commonLocalStorage.helper';
import { removeData as removeTokenC } from '../../../../shared/utils/commonCookies.helper';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';

import { LOCAL_STORAGE_AUTH_KEY } from '../../../../shared/constants/Defaults/constants';
import {
  SUPPORT_MESSAGES_CHAN,
  LAWYER_MESSAGES_CHAN,
} from '../../../../shared/constants/Socket/constants';

import { terminateSocketChan } from '../../../Socket/state/saga';

// Sagas

export function* logoutUser(): IterableIterator<any> {
  yield call(terminateSocketChan, SUPPORT_MESSAGES_CHAN);
  yield call(terminateSocketChan, LAWYER_MESSAGES_CHAN);

  yield call(removeTokenLS, LOCAL_STORAGE_AUTH_KEY);
  yield call(removeTokenC, LOCAL_STORAGE_AUTH_KEY);

  yield put({
    type: logoutSuccess.toString(),
  });
} // logoutUser =========

// Root Saga
export default function* rootSaga() {
  yield takeEvery(logoutRequest, safe(onError, logoutUser));
}
