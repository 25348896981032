import AxiosInstance from '../../../core/api/axiosinstance';
import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

export default {
  /**
   * Login API
   */
  postData(data: any) {
    return AxiosInstance('').post(
      `${apiPrefix}${versionOnePrefix}/login/token`,
      data,
    );
  },

  /**
   * Login API
   */
  postDataSocial(data: any) {
    return AxiosInstance('').post(
      // eslint-disable-next-line no-irregular-whitespace
      `${apiPrefix}${versionOnePrefix}/auth/third-party-v2/login`,
      data,
    );
  },

  // Login API
};
