import { createSelector } from 'reselect';

export const isFetching = (store: any) => store.adBlocksReducer.isFetching;

export const isAdBlocksAlreadySet = (store: any) =>
  store.adBlocksReducer.isAdBlocksAlreadySet;

export const result = createSelector(
  (store: any) => store.adBlocksReducer.result,
  result => result,
);

export const list = createSelector(
  (store: any) => store.adBlocksReducer.list,
  (list = []) => {
    const result = [...list].reduce((acc, item) => {
      const { position } = item;
      acc = { ...acc, [position]: item };
      return acc;
    }, {});

    return result;
  },
);
