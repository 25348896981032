import { put, takeLatest, call, takeEvery, select } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';
import { get } from 'lodash';

import { userSettingsActivityAPI } from '../../../../services/UserSettingsActivity/api';

import {
  userSettingsActivityRequest,
  userSettingsActivityResult,
  userSettingsActivitySuccess,
  userSettingsActivityFailure,
  userSettingsActivityScrollingRequest,
  userSettingsActivityScrollingResult,
} from '../duck';
import { logList } from '../selectors';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';

export function* getData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const srvRes = yield call(userSettingsActivityAPI.getData, {
    ...completePayload,
  });

  yield put({
    type: userSettingsActivityResult.toString(),
    payload: createResultV2(srvRes),
  });
} // getData =========

// TODO:  Реализовать проверку контракта! Формы
export function* checkResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { headers } = successData;

    const dataRes = get(successData, 'data', []) || [];
    const data = Array.isArray(dataRes) ? dataRes : [];
    const totalPages = Number(headers['x-pagination-page-count']) || 1;
    const currentPage = Number(headers['x-pagination-current-page']) || 1;

    const payloadTmp = {
      data: [...data],
      totalPages,
      currentPage,
    };

    yield put({
      type: userSettingsActivitySuccess.toString(),
      payload: payloadTmp,
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: userSettingsActivityFailure.toString(),
    });
  }
} // checkResult =========

export function* getDataScrolling(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const srvRes = yield call(userSettingsActivityAPI.getData, {
    ...completePayload,
  });

  yield put({
    type: userSettingsActivityScrollingResult.toString(),
    payload: createResultV2(srvRes),
  });
} // getDataScrolling =========

// TODO:  Реализовать проверку контракта! Формы
export function* checkResultScrolling(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const resivedLogs: any = yield select(logList);
    const { right: successData } = payload;
    const { headers, data } = successData;
    const totalPages = Number(headers['x-pagination-page-count']) || 1;
    const currentPage = Number(headers['x-pagination-current-page']) || 1;
    const payloadTmp = {
      data: [...resivedLogs, ...data],
      totalPages,
      currentPage,
    };

    yield put({
      type: userSettingsActivitySuccess.toString(),
      payload: payloadTmp,
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: userSettingsActivityFailure.toString(),
      payload: error,
    });
  }
} // checkResultScrolling =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    userSettingsActivityRequest,
    safe(onError, getData, {
      terminator: userSettingsActivityFailure,
    }),
  );

  yield takeEvery(userSettingsActivityResult, safe(onError, checkResult));

  yield takeLatest(
    userSettingsActivityScrollingRequest,
    safe(onError, getDataScrolling, {
      terminator: userSettingsActivityFailure,
    }),
  );

  yield takeEvery(
    userSettingsActivityScrollingResult,
    safe(onError, checkResultScrolling),
  );
}
