import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

const preFetchedInitialState = {
  countOfFetches: 0,
  iteration: 0,
  total: 0,
  messages: [],
};

// Actions ==========================

const CHAT_LIST_LAWYER = 'CHAT_LIST_LAWYER';
const CHAT = 'CHAT';
const SOCKET = 'SOCKET';

// Запрос информации обо всех сообщениях в чате из АПИ
export const chatListLawyerFetchChatRequest = createAction(
  `${CHAT_LIST_LAWYER}/FETCH_CHAT_REQUEST`,
);
export const chatListLawyerFetchChatRequestFirstTime = createAction(
  `${CHAT_LIST_LAWYER}/FETCH_CHAT_REQUEST_FIRST_TIME`,
);
export const chatListLawyerFetchChatSuccess = createAction(
  `${CHAT_LIST_LAWYER}/FETCH_CHAT_SUCCESS`,
);
export const chatListLawyerFetchChatFailure = createAction(
  `${CHAT_LIST_LAWYER}/FETCH_CHAT_FAILURE`,
);

// PRE-FETCH Запрос информации обо всех сообщениях в системе из АПИ
export const chatListLawyerPreFetchChatRequest = createAction(
  `${CHAT_LIST_LAWYER}/PRE_FETCH_CHAT_REQUEST`,
);
export const chatListLawyerPreFetchChatSuccess = createAction(
  `${CHAT_LIST_LAWYER}/PRE_FETCH_CHAT_SUCCESS`,
);
export const chatListLawyerPreFetchChatFailure = createAction(
  `${CHAT_LIST_LAWYER}/PRE_FETCH_CHAT_FAILURE`,
);

// Обновить данные в редьюсере
export const chatListLawyerUpdateDataRequest = createAction(
  `${CHAT_LIST_LAWYER}/UPDATE_DATA_REQUEST`,
);
export const chatListLawyerUpdateDataSuccess = createAction(
  `${CHAT_LIST_LAWYER}/UPDATE_DATA_SUCCESS`,
);

export const chatListLawyerFirstPreFetchChatRequest = createAction(
  `${CHAT_LIST_LAWYER}/FIRST_PRE_FETCH_CHAT_REQUEST`,
);
export const chatListLawyerFirstPreFetchChatSuccess = createAction(
  `${CHAT_LIST_LAWYER}/FIRST_PRE_FETCH_CHAT_SUCCESS`,
);
export const chatListLawyerFirstPreFetchChatFailure = createAction(
  `${CHAT_LIST_LAWYER}/FIRST_PRE_FETCH_CHAT_FAILURE`,
);

// PRE-FETCH удалить все предзагруженные данные
export const chatListLawyerPreFetchChatClear = createAction(
  `${CHAT_LIST_LAWYER}/PRE_FETCH_CHAT_CLEAR`,
);

// Отправить сообшение чата
export const chatListLawyerSendMsgRequest = createAction(
  `${CHAT_LIST_LAWYER}/SEND_MSG_REQUEST`,
);
export const chatListLawyerSendMsgSuccess = createAction(
  `${CHAT_LIST_LAWYER}/SEND_MSG_SUCCESS`,
);
export const chatListLawyerSendMsgFailure = createAction(
  `${CHAT_LIST_LAWYER}/SEND_MSG_FAILURE`,
);

// Создать структуру, на основе кторой будет формироваться строка ввода в чате
export const chatListLawyerChatInputRequest = createAction(
  `${CHAT_LIST_LAWYER}/CHAT_INPUT_REQUEST`,
);
export const chatListLawyerChatInputSuccess = createAction(
  `${CHAT_LIST_LAWYER}/CHAT_INPUT_SUCCESS`,
);
export const chatListLawyerChatInputFailure = createAction(
  `${CHAT_LIST_LAWYER}/CHAT_INPUT_FAILURE`,
);

// Создать структуру, на основе кторой будет формироваться строка ввода в чате
export const chatListLawyerActiveButtonRequest = createAction(
  `${CHAT_LIST_LAWYER}/ACTIVE_BUTTON_REQUEST`,
);
export const chatListLawyerActiveButtonSuccess = createAction(
  `${CHAT_LIST_LAWYER}/ACTIVE_BUTTON_SUCCESS`,
);
export const chatListLawyerActiveButtonFailure = createAction(
  `${CHAT_LIST_LAWYER}/ACTIVE_BUTTON_FAILURE`,
);

// Запрос информации о самом последнем сообщении в чате
export const chatListLawyerLastMessageRequest = createAction(
  `${CHAT_LIST_LAWYER}/LAST_MESSAGE_REQUEST`,
);
export const chatListLawyerLastMessageSuccess = createAction(
  `${CHAT_LIST_LAWYER}/LAST_MESSAGE_SUCCESS`,
);
export const chatListLawyerLastMessageFailure = createAction(
  `${CHAT_LIST_LAWYER}/LAST_MESSAGE_FAILURE`,
);

// Отправить сообшение чата c вложенными файлами
export const chatLawyerSendAttachRequest = createAction(
  `${CHAT}/SEND_ATTACH_REQUEST`,
);
export const chatSendAttachSuccess = createAction(
  `${CHAT}/SEND_ATTACH_SUCCESS`,
);
export const chatSendAttachFailure = createAction(
  `${CHAT}/SEND_ATTACH_FAILURE`,
);

// Soket server Action
export const chatListLawyerResMsgFromSocket = createAction(
  `${CHAT_LIST_LAWYER}/RES_MSG_FROM_SOCKET`,
);
export const socketLawyerClearBeforeRes = createAction(
  `${SOCKET}/SUPPORT_CLEAR_BEFORE_RES`,
);
export const socketLawyerAllowedAcceptData = createAction(
  `${SOCKET}/SUPPORT_ALLOWED_ACCEPT_DATA`,
);

// Reducers ==========================

// Статус запроса данных из АПИ
const isFetching = createReducer(false, {
  [chatListLawyerFetchChatRequestFirstTime.toString()]: () => true,
  [chatListLawyerFetchChatSuccess.toString()]: () => false,
  [chatListLawyerFetchChatFailure.toString()]: () => false,
});

// PRE FETCH Статус запроса данных из АПИ
const isFetchingPreFetch = createReducer(false, {
  [chatListLawyerPreFetchChatRequest.toString()]: () => true,
  [chatListLawyerPreFetchChatSuccess.toString()]: () => false,
  [chatListLawyerPreFetchChatFailure.toString()]: () => false,
});

// FIRST PRE FETCH Статус запроса данных из АПИ
const isFetchingFirstPreFetch = createReducer(false, {
  [chatListLawyerFirstPreFetchChatRequest.toString()]: () => true,
  [chatListLawyerFirstPreFetchChatSuccess.toString()]: () => false,
  [chatListLawyerFirstPreFetchChatFailure.toString()]: () => false,
  // Если сфейлится один из пяти первых запросов, то сбросить статус
  [chatListLawyerPreFetchChatFailure.toString()]: () => false,
});

// SEND CHAT MESSAGE Статус запроса данных из АПИ
const isFetchingChatSendMsg = createReducer(false, {
  [chatListLawyerResMsgFromSocket.toString()]: () => true,
  [chatListLawyerSendMsgRequest.toString()]: () => true,
  [chatLawyerSendAttachRequest.toString()]: () => true,
  [chatListLawyerSendMsgSuccess.toString()]: () => false,
  [chatListLawyerSendMsgFailure.toString()]: () => false,
});

// Обновление данных после отправки сообщения
const isUpdatingDataAfterChatSendMsg = createReducer(false, {
  [chatListLawyerResMsgFromSocket.toString()]: () => true,
  [chatListLawyerSendMsgRequest.toString()]: () => true,
  [chatLawyerSendAttachRequest.toString()]: () => true,
  [chatListLawyerUpdateDataSuccess.toString()]: () => false,
  [chatListLawyerSendMsgFailure.toString()]: () => false,
});

// Успешный результат запроса из АПИ. Влияет на состояние инпута в чате
const isChatInputNeedClear = createReducer(false, {
  [chatListLawyerChatInputRequest.toString()]: () => false,
  [chatListLawyerChatInputSuccess.toString()]: () => true,
  [chatListLawyerChatInputFailure.toString()]: () => false,
  [chatListLawyerSendMsgRequest.toString()]: () => false,
  [chatListLawyerResMsgFromSocket.toString()]: () => false,
  [chatListLawyerSendMsgSuccess.toString()]: () => true,
});

// Запрос данных о последнем сообщении
const isFetchingLastMsg = createReducer(false, {
  [chatListLawyerLastMessageRequest.toString()]: () => true,
  [chatListLawyerLastMessageSuccess.toString()]: () => false,
  [chatListLawyerLastMessageFailure.toString()]: () => false,
});

// Успешный результат запроса из АПИ
const data = createReducer([], {
  [chatListLawyerFetchChatRequest.toString()]: () => [],
  [chatListLawyerFetchChatSuccess.toString()]: (_state, action) =>
    action.payload,
});

// Успешный результат запроса из АПИ
const dataLastMsg = createReducer([], {
  [chatListLawyerLastMessageRequest.toString()]: () => [],
  [chatListLawyerLastMessageSuccess.toString()]: (_state, action) =>
    action.payload,
});

// Успешный результат запроса из АПИ
const dataChatInput = createReducer([], {
  [chatListLawyerChatInputRequest.toString()]: () => [],
  [chatListLawyerChatInputSuccess.toString()]: (_state, action) =>
    action.payload,
});

// Успешный результат запроса из АПИ
const dataActiveButton = createReducer('', {
  [chatListLawyerChatInputSuccess.toString()]: () => '',
  [chatListLawyerActiveButtonRequest.toString()]: () => '',
  [chatListLawyerActiveButtonSuccess.toString()]: (_state, action) =>
    action.payload,
});

// Ошибка при запросе данных из АПИ
const error = createReducer([], {
  [chatListLawyerFetchChatRequest.toString()]: () => [],
  [chatListLawyerFetchChatFailure.toString()]: (_state, action) =>
    action.payload,
  [chatListLawyerPreFetchChatFailure.toString()]: (_state, action) =>
    action.payload,
});

// Ошибка при запросе данных из АПИ
const errorLastMsg = createReducer([], {
  [chatListLawyerLastMessageRequest.toString()]: () => [],
  [chatListLawyerLastMessageFailure.toString()]: (_state, action) =>
    action.payload,
});

// PRE FETCHED DATA
const preFetchedData = createReducer(
  { ...preFetchedInitialState },
  {
    [chatListLawyerPreFetchChatRequest.toString()]: (
      state = preFetchedInitialState,
    ) => ({
      ...state,
    }),
    [chatListLawyerPreFetchChatSuccess.toString()]: (_state, action) =>
      action.payload,
    [chatListLawyerPreFetchChatClear.toString()]: () => ({
      ...preFetchedInitialState,
    }),
    // Есть информация о высоте от псевдорендера, обновить данные в редьюсере
    [chatListLawyerUpdateDataSuccess.toString()]: (
      state = preFetchedInitialState,
      action,
    ) => ({
      ...state,
      messages: [...action.payload.messages],
    }),
    // Успешно пришел ответ от сервера, на текушее сообщение пользователя
    [chatListLawyerSendMsgSuccess.toString()]: (
      state = preFetchedInitialState,
      action,
    ) => ({
      ...state,
      callFrom: 'createNewMessage',
      messages: [...action.payload.messages],
      total: action.payload.total,
    }),
  },
);

// Статус обработки сообщения от сокет сервера
const isResponseFromSocketServer = createReducer(false, {
  [chatListLawyerResMsgFromSocket.toString()]: () => true,
  [socketLawyerClearBeforeRes.toString()]: () => false,
});

// Статус обработки сообщения от сокет сервера
const isAllowedAcceptFromSocketServer = createReducer(false, {
  [socketLawyerAllowedAcceptData.toString()]: () => true,
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  error,
  preFetchedData,
  isFetchingPreFetch,
  isFetchingFirstPreFetch,
  isFetchingChatSendMsg,
  dataChatInput,
  dataActiveButton,
  isUpdatingDataAfterChatSendMsg,
  isChatInputNeedClear,
  isFetchingLastMsg,
  dataLastMsg,
  errorLastMsg,
  isResponseFromSocketServer,
  isAllowedAcceptFromSocketServer,
});

export default reducer;
