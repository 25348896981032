import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * AdBlocks API
 */
export default {
  /**
   * Получить данные всех рекламных блоков
   *
   * @returns
   */
  getData() {
    return AxiosInstance('').get(`${apiPrefix}${versionOnePrefix}/ad-blocks`);
  },
};
