import { put, takeLatest, call, takeEvery, all, cancel } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';

import {
  userDebtsRequest,
  userDebtsSuccess,
  userDebtsFailure,
  userDebtsResult,
  userDebtsStatusRequest,
  userDebtsStatusSuccess,
  userDebtsStatusFailure,
  userDebtsStatusResult,
  userDebtsLimitsRequest,
  userDebtsLimitsSuccess,
  userDebtsLimitsFailure,
  userDebtsLimitsResult,
  userDebtsUpdateRequest,
  userDebtsUpdateSuccess,
  userDebtsUpdateFailure,
  userDebtsUpdateResult,
} from '../duck';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import {
  DEBTS__DETAILS_RES,
  DEBTS__DATA_RES,
} from './constants';

import { userDebts as userDebtsApi } from '../../../../services/UserDebts/api';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';

import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { logoutRequest } from '../../../Logout/state/duck';

export function* getData(action: any) {
  const completePayload: any = yield call(checkToken, action);

  const [
    userDebtsDetails,
    userDebtsData,
  ]: any = yield all([
    call(userDebtsApi.getDetails, completePayload),
    call(userDebtsApi.getData, completePayload),
  ]);

  const input = [
    { ...userDebtsDetails, init: DEBTS__DETAILS_RES },
    { ...userDebtsData, init: DEBTS__DATA_RES },
  ];

  const result = pipe(input, A.map(createResultV2), A.separate);
  const { left: errors, right: success } = result;

  if (errors.length > 0) {
    // NB: Если есть хоть одна 401, то разлогингить пользователя
    // и закончить работу саги
    for (let index = 0; index < errors.length; index++) {
      const element = errors[index];
      const { status } = element;

      if (status === 401) {
        yield put({
          type: logoutRequest.toString(),
        });
        yield cancel();
      }
    }

    yield put({
      type: userDebtsFailure.toString(),
    });
    yield cancel();
  }

  if (success.length > 0 && success.length === input.length) {
    const { data: listDetails } = userDebtsDetails;
    const { data } = userDebtsData;

    const payload = {
      listDetails,
      data,
    };

    yield put({
      type: userDebtsSuccess.toString(),
      payload,
    });
  }

  // sfddssdfdfssdfsd
  // const userDebtsRes = yield call(userDebtsApi.getData, completePayload);
  //
  // yield put({
  //   type: userDebtsResult.toString(),
  //   payload: createResultV2(userDebtsRes),
  // });
} // getData =========

export function* checkResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: userDebtsSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: userDebtsFailure.toString(),
      payload: error,
    });
  }
} // checkResult =========

export function* getStatus(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const res = yield call(userDebtsApi.getStatus, completePayload);

  yield put({
    type: userDebtsStatusResult.toString(),
    payload: createResultV2(res),
  });
} // getStatus =========

export function* checkStatusResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    // yield call(checkContract, {
    //  contract: okStatusRatingDebtsStatusContract,
    //  data,
    //  direction: DIRECTION.B_F,
    // });

    yield put({
      type: userDebtsStatusSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    // const { left: error } = payload;
    // const { data } = error;

    // yield call(checkContract, {
    //  contract: errorStatusRatingDebtsStatusContract,
    //  data,
    //  direction: DIRECTION.B_F,
    // });

    yield put({
      type: userDebtsStatusFailure.toString(),
    });
  }
} // checkStatusResult =========

export function* getLimits(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const res = yield call(userDebtsApi.getLimitsData, completePayload);

  yield put({
    type: userDebtsLimitsResult.toString(),
    payload: createResultV2(res),
  });
} // getLimits =========

export function* checkLimitsResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    // yield call(checkContract, {
    //  contract: okUserDebtsLimitsContract,
    //  data,
    //  direction: DIRECTION.B_F,
    // });

    yield put({
      type: userDebtsLimitsSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    // const { left: error } = payload;
    // const { data } = error;

    // yield call(checkContract, {
    //  contract: errorUserDebtsLimitsContract,
    //  data,
    //  direction: DIRECTION.B_F,
    // });

    yield put({
      type: userDebtsLimitsFailure.toString(),
    });
  }
} // checkLimitsResult =========

export function* updateData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const res = yield call(userDebtsApi.postDebtsRequest, completePayload);

  yield put({
    type: userDebtsUpdateResult.toString(),
    payload: createResultV2(res),
  });
} // updateData =========

export function* checkUpdateResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    // yield call(checkContract, {
    //  contract: okUserDebtsUpdateContract,
    //  data,
    //  direction: DIRECTION.B_F,
    // });

    yield put({
      type: userDebtsUpdateSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;
    const { data } = error;

    // yield call(checkContract, {
    //  contract: errorUserDebtsUpdateContract,
    //  data,
    //  direction: DIRECTION.B_F,
    // });

    yield put({
      type: userDebtsUpdateFailure.toString(),
      payload: { data },
    });
  }
} // checkUpdateResult =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    userDebtsRequest,
    safe(onError, getData, { terminator: userDebtsFailure }),
  );

  yield takeLatest(
    userDebtsStatusRequest,
    safe(onError, getStatus, { terminator: userDebtsStatusFailure }),
  );

  yield takeEvery(userDebtsResult, safe(onError, checkResult));

  yield takeEvery(userDebtsStatusResult, safe(onError, checkStatusResult));

  yield takeLatest(
    userDebtsLimitsRequest,
    safe(onError, getLimits, { terminator: userDebtsLimitsFailure }),
  );

  yield takeEvery(userDebtsLimitsResult, safe(onError, checkLimitsResult));

  yield takeLatest(
    userDebtsUpdateRequest,
    safe(onError, updateData, { terminator: userDebtsUpdateFailure }),
  );

  yield takeEvery(userDebtsUpdateResult, safe(onError, checkUpdateResult));

  yield takeEvery(
    userDebtsUpdateSuccess,
    safe(onError, getStatus, { terminator: userDebtsStatusFailure }),
  );
}
