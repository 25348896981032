import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';
import promiseToResultHelper from '../../../shared/utils/promiseToResult.helper';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

export interface Subscribe {
  type: string
  status: number
  userId: number
  userSubscribesId: number
  startSate: string
  endDate: string
  name: string
  sum: string
  card: string
  expMonth: string
  expYear: string
  period: string
}

export type GetSubscribeRes = Subscribe[]

export interface DeleteSubscribeReq {
  id: number
}
export type DeleteSubscribeRes = string

/**
 * UserSubscription API
 */
export default {
  /**
   * Получить данные подписок пользователя
   *
   * @returns
   */
  getData(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/settings`,
    );
  },

  /**
   * Изменить подписку пользователя
   *
   * @returns
   */
  putData(data: any) {
    const { token, name, value } = data;

    return AxiosInstance(token).put(
      `${apiPrefix}${versionOnePrefix}/user/settings/${name}/${value}`,
    );
  },

  getSubscribe() {
    return promiseToResultHelper(AxiosInstance().get<GetSubscribeRes>(
      `${apiPrefix}${versionOnePrefix}/payment/subscribe`,
    ));
  },

  deleteSubscribe(data: DeleteSubscribeReq) {
    const { id } = data;
    return promiseToResultHelper(AxiosInstance().delete<DeleteSubscribeRes>(
      `${apiPrefix}${versionOnePrefix}/payment/subscribe/${id}`,
    ));
  },
};
