import AxiosInstance from '../../../core/api/axiosinstance';
import paramsSerializer from '../../../shared/utils/paramsSerializer';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * Feedbacks API
 */
export default {
  /**
   * Получить данные всех отзывов
   *
   * @returns
   */
  getData(data: any) {
    const { token, sort = '-created_at', page = 1, filterData = {} } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/credit-products/reviews?${paramsSerializer(
        {
          sort,
          page,
          ...filterData,
        },
      )}`,
    );
  },

  /**
   * Получить данные всех организаций
   *
   * @returns
   */
  getDataOrgs(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/credit-products/banks`,
    );
  },

  /**
   * Получить данные всех городов
   *
   * @returns
   */
  getDataCities(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/cities?${paramsSerializer({
        'per-page': -1,
      })}`,
    );
  },

  /**
   * Создать новый отзыв
   *
   * @param {*} data
   * @returns
   */
  postDataReview(data: any) {
    const { token } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/credit-products/reviews`,
      data,
    );
  },

  /**
   * Создать новый комментарий
   *
   * @param {*} data
   * @returns
   */
  postDataComment(data: any) {
    const { token } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/credit-products/review-comments`,
      data,
    );
  },

  /**
   * Получить данные с кредитными продуктами
   *
   * @param {*} data
   * @returns
   */
  postDataCredProdsList(data: any) {
    const { token } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/credit-products/list`,
      data,
    );
  },

  /**
   * Добавить лайк
   *
   * @param {*} data
   * @returns
   */
  postDataAddLike(data: any) {
    const { token, ID } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/credit-products/review-likes/${ID}`,
    );
  },

  /**
   * Получить данные всех отзывов, в которых пользователь оставлял лайк
   *
   * @returns
   */
  getDataAllUserLikes(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/credit-products/review-likes`,
    );
  },

  /**
   * Elfkbnm hfytt gjcnfdktyysq kfqr
   *
   * @returns
   */
  deleteDataRemoveLike(data: any) {
    const { token, ID } = data;

    return AxiosInstance(token).delete(
      `${apiPrefix}${versionOnePrefix}/credit-products/review-likes/${ID}`,
    );
  },
};
