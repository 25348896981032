import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { notification } from 'antd';

import NotifyDescription from '../../componets/NotifyDescription';

import {
  notifyData as notifyDataSelector,
  notifyActive as notifyActiveSelector,
} from '../../../states/NotificationSystem/state/selectors';
import { language as languageSelector } from '../../../states/Languages/state/selectors';

import {
  notifyClearInfo,
  notifyRemoveActiveInfo,
} from '../../../states/NotificationSystem/state/duck';

import { isAuthorized as isAuthorizedSelector } from '../../../states/Auth/state/selectors';

const openNotificationWithIcon = (
  msgType: 'error' | 'info',
  msgTranslated: string,
  titleTranslated: string,
  dispatch: any,
  ID: number,
  duration: number,
  isAuthorized: boolean,
  supportInfo: string,
) => {
  notification[msgType]({
    duration,
    message: titleTranslated,
    description: (
      <NotifyDescription
        description={msgTranslated}
        isAuthorized={isAuthorized}
        supportInfo={supportInfo}
      />
    ),
    onClose: () => {
      dispatch({
        type: notifyClearInfo.toString(),
      });
      dispatch({
        type: notifyRemoveActiveInfo.toString(),
        payload: { notifyID: ID },
      });
    },
  });
};

/**
 * Уведомление с информацией, которое всплывает в виде тостера
 * в верхнем правом углу
 *
 * Уведомляет об ошибках, которые система получает при общении с АПИ
 *
 * @returns
 */
const NotifyToast: React.FC<any> = () => {
  const dispatch = useDispatch();
  const notifyData = useSelector(notifyDataSelector);
  const language = useSelector(languageSelector);
  const notifyActive = useSelector(notifyActiveSelector);
  const isAuthorized = useSelector(isAuthorizedSelector);

  React.useEffect(
    () => {
      const msg = get(notifyData, 'msg', null);
      const msgType = get(notifyData, 'msgType', null);
      const title = get(notifyData, 'title', null);
      const ID = get(notifyData, 'ID', null);
      const duration = get(notifyData, 'duration', 5.5);
      const supportInfo = get(notifyData, 'supportInfo', '') || '';

      let sprtInfo = '';
      if (supportInfo !== '') {
        sprtInfo = language[supportInfo];
      }

      const activeIDs = notifyActive.map((item: any) => {
        const { ID } = item;

        return ID;
      });

      if (activeIDs.includes(ID)) {
        if (msgType && msg && title) {
          openNotificationWithIcon(
            msgType,
            language[msg],
            language[title],
            dispatch,
            ID,
            duration,
            isAuthorized,
            sprtInfo,
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifyData, notifyActive],
  );

  return null;
};

export default NotifyToast;
