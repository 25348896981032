import AxiosInstance from '../../../core/api/axiosinstance';
// import paramsSerializer from '../../../shared/utils/paramsSerializer';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * User Settings LogsForm
 */
export default {
  /**
   * Получить данные о посещениях сайта с учетными данными пользователя
   *
   * @returns
   */
  getDataLog(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/order`,
    );
  },
};
