import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';

import { offerFormAPI } from '../../../../services/OfferForm/api';

import {
  offerFormRequest,
  offerFormResult,
  offerFormSuccess,
  offerFormFailure,
  offerFormPostRequest,
  offerFormPostSuccess,
  offerFormPostFailure,
  offerFormPostResult,
} from '../duck';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';
import { mapValues } from 'lodash';
import { creditRatingRequest } from '../../../CreditRating/state/duck';

export function* getData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const srvRes = yield call(offerFormAPI.getData, {
    ...completePayload,
  });

  yield put({
    type: offerFormResult.toString(),
    payload: createResultV2({ ...srvRes, ...completePayload }),
  });
} // getData =========

export function* checkResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data, offerId } = successData;

    yield put({
      type: offerFormSuccess.toString(),
      payload: { ...data },
    });

    const { form } = data;
    const { is_valid: isValid, fields } = form;

    if (isValid) {
      const values = mapValues(fields, 'value');

      yield put({
        type: offerFormPostRequest.toString(),
        payload: { offerId, ...values },
      });
    }
  }

  if (E.isLeft(payload)) {
    const { left: errorData } = payload;
    const { data } = errorData;

    yield put({
      type: offerFormFailure.toString(),
      payload: { ...data },
    });
  }
} // checkResult =========

export function* postData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const srvRes = yield call(offerFormAPI.postData, {
    ...completePayload,
  });

  yield put({
    type: offerFormPostResult.toString(),
    payload: createResultV2(srvRes),
  });
} // PostData =========

// TODO: Добавить контракт
export function* checkResultPost(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: offerFormPostSuccess.toString(),
      payload: { ...data },
    });

    yield put({
      type: creditRatingRequest.toString(),
      payload: {
        notCached: true,
      },
    });
  }

  if (E.isLeft(payload)) {
    const { left: errorData } = payload;
    const { data } = errorData;

    yield put({
      type: offerFormPostFailure.toString(),
      payload: { ...data },
    });
  }
} // checkResultPost =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    offerFormRequest,
    safe(onError, getData, {
      terminator: offerFormFailure,
    }),
  );

  yield takeEvery(offerFormResult, safe(onError, checkResult));

  yield takeLatest(
    offerFormPostRequest,
    safe(onError, postData, {
      terminator: offerFormPostFailure,
    }),
  );

  yield takeEvery(
    offerFormPostResult,
    safe(onError, checkResultPost),
  );
}
