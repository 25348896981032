/**
 * Подготовка номера телефона для передачи в АПИ, ввиде логина
 *
 * '+7 (922) 333-22-11' -> '79223332211'
 *
 * @export
 * @param {String} phone
 * @returns
 */
export default function preparePhoneForAPI({
  phone,
}: {
  phone: string;
}) {
  if (phone !== '') {
    return phone.replace(/\D+/g, '');
  }

  return '';
}
