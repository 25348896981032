/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Popover } from 'antd';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';

import styles from './MultiloginCabinet.module.scss';

import { CabinetMfo, MfoStatus } from '../../../../services/Multilogin/api';
import { ModalType } from '../../../enum/ModalType.enum';

import ImageWrapper from '../../ImageWrapper';
import Spinner from '../../../../../assets/images/Spinner';
import CloseCircul from '../../../../../assets/images/CloseCircul';
import MenuBlock from '../Menublock';
import ThreeDots from '../../../../../assets/images/ThreeDots';
import Button from '../../../UIkit/Button';
import MultiLoginTable from '../MultiLoginTable';
import Arrow from '../../../../../assets/images/ArrLeft';
import MultiloginCabinetSkeleton from './MultiloginCabinet.skeleton';

import { useQuery } from '../../../hooks/useQuery';

export interface MultiloginCabinetProps extends CabinetMfo {
  isLoading?: boolean
}

const maxLengthLoans = 4;

const MultiloginCabinet: React.FC<MultiloginCabinetProps> = ({
  mfo,
  status,
  loans,
  isLoading,
  id,
}) => {
  let loansSlice = loans;
  const { setQuery } = useQuery();

  const [isMenu, setMenu] = useState<boolean>(false);
  const [isFullLoans, setFullLoans] = useState<boolean>(false);

  if (!isFullLoans && loans?.length) {
    loansSlice = loans.slice(0, maxLengthLoans);
  }

  const handlerAddMfo = () => {
    setQuery(mfo.id, ModalType.ADDMFO);
  };

  if (isLoading) {
    return <>
      <MultiloginCabinetSkeleton />
      <MultiloginCabinetSkeleton />
      <MultiloginCabinetSkeleton />
    </>;
  }
  return (
    <div className={styles.root}>
      <div className={styles.bank}>

        <div className={styles.head}>

          {(status === MfoStatus.ACTIVE || status === MfoStatus.NONE) &&
          <div className={styles.logo}>
            <ImageWrapper image={mfo.logoImage} alt="" />
          </div>
          }

          {status === MfoStatus.PROCESS &&
          <div className={cx(styles.logo, styles.spinner)}>
            <Spinner />
          </div>
          }

          {status === MfoStatus.ERROR &&
          <div className={styles.logo}>
            <CloseCircul width={32} height={32} />
          </div>
          }

          <div className={styles.brand}>
            {mfo.name}
          </div>

          <div className={styles.info}>
            <Popover
              placement="bottomRight"
              content={<MenuBlock handlerClose={() => setMenu(false)} id={id} />}
              defaultVisible
              trigger="click"
              visible={isMenu}
              overlayClassName={styles.menu}
              overlayInnerStyle={{ borderRadius: '5px', marginTop: '-55px' }}
              getPopupContainer={(e) => e.parentElement as HTMLElement}
            >
              <ThreeDots
                onClick={() => setMenu(true)}
                className={styles.dots}
              />
            </Popover>
          </div>

          {status === MfoStatus.ACTIVE &&
          <div className={cx(styles.mode, styles.connected)}>
            <Translate id="multilogin.mfo.status.active" />
          </div>
          }

          {status === MfoStatus.PROCESS &&
          <div className={cx(styles.mode, styles.connecting)}>
            <Translate id="multilogin.mfo.status.process" />
          </div>
          }

          {status === MfoStatus.ERROR &&
          <div className={cx(styles.mode, styles.error)}>
            <Translate id="multilogin.mfo.status.error" />
          </div>
          }

        </div>

        {status === MfoStatus.ERROR &&
          <div className={styles.buttons}>
            <Button
              size="small"
              style={{ padding: '14px 20px' }}
              onClick={handlerAddMfo}
            >
              <Translate id="multilogin.mfo.again" />
            </Button>
          </div>
        }

        <MultiLoginTable loans={loansSlice} />

        <div className={styles.buttons}>
          {status === MfoStatus.NONE &&
          <div className={styles.plug}>
            <Button
              mode="secondary"
              size="small"
              style={{ padding: '14px 20px' }}
              onClick={handlerAddMfo}
            >
              <Translate id="multilogin.mfo.add" />
            </Button>
          </div>
          }
          {(status === MfoStatus.ACTIVE || status === MfoStatus.NONE) &&
          <div className={styles.apply}>
            <NavLink to={mfo.createNewLoanUrl}>
              <Button
                size="small"
                style={{ padding: '14px 20px' }}
              >
                <Translate id="multilogin.loan.new" />
              </Button>
            </NavLink>
          </div>
          }
        </div>

        {(loans && loans?.length > maxLengthLoans) && !isFullLoans &&
          <div className={styles.show} onClick={() => setFullLoans(true)}>
            <Translate id="multilogin.loan.more" />
            <Arrow width={16} height={16} fill="#1373E5" />
          </div>
        }

      </div>
    </div>
  );
};

export default MultiloginCabinet;
