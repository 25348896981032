import styled from 'styled-components';
import { AutoComplete } from 'antd';

const DadataUniversal = styled(AutoComplete)`
  height: 100%;

  .ant-select-selector {
    height: calc(100% - 2px) !important;
    border: none !important;
    padding: 23px !important;
    padding-bottom: 8px !important;
    background-color: transparent !important;
    font-size: 15px;
    line-height: 28px ;
  }

  .ant-select-selection-placeholder {
    border: none;
    color: #87919C;
    font-size: 15px;
    letter-spacing: 0;
  }

  .ant-select-selection-item {
    height: 100%;
    border: none;
    color: #0F1011;
    font-size: 15px;
    letter-spacing: 0;
  }

  .ant-select-selection-search {
    padding: 23px !important;
    padding-bottom: 8px !important;
    height: 100%;
    border: none;
    right: 0 !important;
    left: 0 !important;
  }

  .ant-select-selection-search-input {
    height: 100% !important;
  }

  &.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none !important;
  }
  &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) {
    border: 1px solid #1373E5 !important;
  }
`;

export default DadataUniversal;
