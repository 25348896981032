import CryptoJS from 'crypto-js';
import { Base64 } from 'js-base64';

import { EXCHANGE_RATE } from '../../../../../shared/constants/Defaults/constants';

const JsonFormatter = {
  stringify(cipherParams: any) {
    const jsonObj: any = {
      ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
    };

    if (cipherParams.iv) {
      jsonObj.iv = cipherParams.iv.toString();
    }
    if (cipherParams.salt) {
      jsonObj.s = cipherParams.salt.toString();
    }

    return JSON.stringify(jsonObj);
  },

  parse(jsonStr: any) {
    const jsonObj = JSON.parse(jsonStr);
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct),
    });

    if (jsonObj.iv) {
      cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
    }
    if (jsonObj.s) {
      cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
    }

    return cipherParams;
  },
};

/**
 * Функция для шифрования запросов передаваемых на сервер
 *
 * На входе: данные запроса в виде объекта.
 * На выходе: объект с единственным свойством req, в котором строка с зашифрованными данными
 *
 * @export
 * @param {*} data
 * @returns {Object} {req: 'BASE64 string'}
 */
export function encryptRequest(data: any) {
  const cipher: any = CryptoJS.AES.encrypt(
    JSON.stringify({
      ...data,
    }),
    EXCHANGE_RATE,
    {
      format: JsonFormatter,
    },
  );

  const result = Base64.encode(
    `${cipher.ciphertext.toString(
      CryptoJS.enc.Base64,
    )}:${cipher.iv.toString()}:${cipher.salt.toString()}`,
  );

  // NB: Для того чтобы проверять, что было ранее зашифровано
  // const decrypted = CryptoJS.AES.decrypt(cipher, EXCHANGE_RATE, {
  //   format: JsonFormatter,
  // });
  // console.log('decrypted=', decrypted.toString(CryptoJS.enc.Utf8));

  return result;
}
