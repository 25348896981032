import React from 'react';

import Award from '../../../../assets/images/Award';

import styles from './LevelBadge.module.scss';
import { Translate } from 'react-localize-redux';

const LevelBadge: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <Translate id="user.my.level" />
      </div>
      <div className={styles.badge}>
        <Award style={{ minWidth: 16, marginRight: 5 }} />
        1
      </div>
    </div>
  );
};

export default LevelBadge;
