import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// DEPRECATED: FIXME: Remove in future
import { reducer as form } from 'redux-form';

// IDEA: NB: Редьюсеры использующие новый стейт
import { loginReducer } from '../../states/Login/state/duck';
import { authReducer } from '../../states/Auth/state/duck';
import { loanSelectionChatReducer } from '../../states/LoanSelection/state/duck';
import { creditRatingReducer } from '../../states/CreditRating/state/duck';
import { userProfileReducer } from '../../states/UserProfile/state/duck';
import { selectionLoanProductReducer } from '../../states/SelectionLoanProduct/state/duck';
import { userDebtsReducer } from '../../states/UserDebts/state/duck';
import { feedbacksReducer } from '../../states/Feedbacks/state/duck';
import { faqReducer } from '../../states/Faq/state/duck';
import { initReducer } from '../../states/Init/state/duck';
import { chatListSupportReducer } from '../../states/ChatListSupport/state/duck';
import { notificationSystemReducer } from '../../states/NotificationSystem/state/duck';
import { languagesReducer } from '../../states/Languages/state/duck';
import { articlesReducer } from '../../states/Articles/state/duck';
import { socketReducer } from '../../states/Socket/state/duck';
import { userSubscriptionReducer } from '../../states/UserSubscription/state/duck';
import { userSettingsChangePswdReducer } from '../../states/UserSettingsChangePswd/state/duck';
import { userSettingsActivityReducer } from '../../states/UserSettingsActivity/state/duck';
import { userSettingsLogsFormReducer } from '../../states/UserSettingsLogsForm/state/duck';
import { userSettingsChangeEmailReducer } from '../../states/UserSettingsChangeEmail/state/duck';
import { emailConfirmationReducer } from '../../states/EmailConfirmation/state/duck';
import { userSettingsChangeAvatarReducer } from '../../states/UserSettingsChangeAvatar/state/duck';
import { userSettingsChangePhoneReducer } from '../../states/UserSettingsChangePhone/state/duck';
import { recoveryPasswordReducer } from '../../states/RecoveryPassword/state/duck';
import { registerReducer } from '../../states/Register/state/duck';
import { chatListLawyerReducer } from '../../states/ChatListLawyer/state/duck';
import { countriesReducer } from '../../states/Countries/state/duck';
import { masksReducer } from '../../states/Masks/state/duck';
import { paymentReducer } from '../../states/Payment/state/duck';
import { adBlocksReducer } from '../../states/AdBlocks/state/duck';
import { productsReducer } from '../../states/Products/state/duck';
import { modalSystemReducer } from '../../states/ModalSystem/state/duck';
import { shopReducer } from '../../states/Shop/state/duck';
import { userTaskReducer } from '../../states/UserTask/state/duck';
import { balanceReducer } from '../../states/Balance/state/duck';
import { userLimitsReducer } from '../../states/UserLimits/state/duck';
import { userSettingsChangeDataReducer } from '../../states/UserSettingsChangeData/state/duck';
import { offerFormReducer } from '../../states/OfferForm/state/duck';

const rootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    loginReducer,
    authReducer,
    loanSelectionChatReducer,
    creditRatingReducer,
    userProfileReducer,
    selectionLoanProductReducer,
    userDebtsReducer,
    feedbacksReducer,
    faqReducer,
    initReducer,
    chatListSupportReducer,
    notificationSystemReducer,
    languagesReducer,
    articlesReducer,
    socketReducer,
    userLimitsReducer,
    userSubscriptionReducer,
    userSettingsActivityReducer,
    userSettingsLogsFormReducer,
    emailConfirmationReducer,
    recoveryPasswordReducer,
    registerReducer,
    chatListLawyerReducer,
    countriesReducer,
    masksReducer,
    paymentReducer,
    adBlocksReducer,
    productsReducer,
    modalSystemReducer,
    shopReducer,
    userTaskReducer,
    balanceReducer,
    userSettingsChangeAvatarReducer,
    userSettingsChangePhoneReducer,
    userSettingsChangePswdReducer,
    userSettingsChangeEmailReducer,
    userSettingsChangeDataReducer,
    offerFormReducer,
    // DEPRECATED: Старые редьюсеры ↓↓↓
    form,
  });

export default rootReducer;
