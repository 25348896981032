/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import AvatarWrapper from '../AvatarWrapper';

import { data as dataSelector } from '../../../states/UserProfile/state/selectors';
import { userProfileRequest } from '../../../states/UserProfile/state/duck';

import { useWindowSize } from '../../hooks/useWindowSize';

import { HeaderStyled } from './theme';
import styles from './MobilePhoneHeader.module.scss';

import logoImage from '../../../../assets/images/logo.svg';
import defaultUserAvatar from '../../../../assets/images/defaultUserAvatar.svg';

import { environment } from '../../../../environment';
import { LAYOUT_MINIMUM_ALLOWABLE_WIDTH } from '../../constants/Defaults/constants';
import { HELP_URL } from '../../constants/Routes/constants';

interface IProps {
  handleShowUserSettings: (status: boolean) => void;
  isHelpChatList: boolean;
}

/**
 * Хеадер для мобильных телефонов
 *
 * @returns
 */
const MobilePhoneHeader: React.FC<IProps> = props => {
  const { handleShowUserSettings, isHelpChatList } = props;
  // Hook
  const windowSize = useWindowSize();
  const { width = 0 } = windowSize;

  // По умолчанию считаем что запущено на устройстве с шириной экрана шире чем 320px
  let isNormalSize = true;

  if (width < LAYOUT_MINIMUM_ALLOWABLE_WIDTH) {
    isNormalSize = false;
  }

  const dispatch = useDispatch();
  const data = useSelector(dataSelector);
  const { avatar } = data;
  const { avatar: avatarStr } = avatar;

  React.useEffect(() => {
    dispatch({ type: userProfileRequest.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HeaderStyled
      style={{
        position: isNormalSize ? 'fixed' : 'unset',
        width: '100%',
        justifyContent: isHelpChatList ? 'flex-start' : 'space-between',
      }}
    >
      {/* NB: When Inside at Help Chat */}
      {isHelpChatList && (
        <Link className={cx(styles.backward)} to={HELP_URL}>
          <LeftOutlined style={{ color: '#1373e5', fontSize: '28px' }} />
        </Link>
      )}

      {/* Logo */}
      <a
        className={cx(styles.logo, { [styles.logoHelpChat]: isHelpChatList })}
        href={environment.MAIN_LANDING_URL}
      >
        <img src={logoImage} alt="my.finanso.com" />
      </a>

      {/* UserAvatar */}
      <div
        className={cx(styles.avatar, {
          [styles.avatarHelpChat]: isHelpChatList,
        })}
        onClick={() => handleShowUserSettings(true)}
      >
        {avatarStr !== '' ? (
          <AvatarWrapper
            style={{ height: 32, width: 32 }}
            image={avatarStr}
          />
        ) : (
          <Avatar size={32} icon={<img src={defaultUserAvatar} alt="" />} />
        )}
      </div>
    </HeaderStyled>
  );
};

export default MobilePhoneHeader;
