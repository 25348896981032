import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import AdBlockGoogleAds from '../AdBlockGoogleAds';
import AdBlockLink from '../AdBlockLink';

import { list } from '../../../states/AdBlocks/state/selectors';

interface IProps {
  type: string;
  wrapperStyle: Record<string, string>;
}

interface IAdBlockDispatcherStore {
  list: any;
}

const mapStateToProps = (state: any) => ({
  list: list(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const LINK = 'link';
const GOOGLEADS = 'googleads';
const ZERO_SIZES = '0x0';

type IAdBlockDispatcherTypes = IAdBlockDispatcherStore & IProps;

/**
 * Диспетчер рекланмных блоков.
 * Разделяет блок на кастомную рекламу и на рекламу гугл
 *
 * Через wrapperStyle задаются инлайн стили для стилизации враппера
 * рекламного блока
 *
 * @class AdBlockDispatcher
 * @extends {React.Component<IAdBlockDispatcherTypes>}
 */
class AdBlockDispatcher extends React.Component<IAdBlockDispatcherTypes> {
  render() {
    const { type, wrapperStyle, list } = this.props;
    const adData = list?.[type] ?? null;
    const adType = adData?.item?.type ?? 'link';
    const size = adData?.size ?? ZERO_SIZES;

    return (
      <>
        {size !== ZERO_SIZES ? (
          <div style={{ ...wrapperStyle }}>
            {adType === GOOGLEADS && <AdBlockGoogleAds type={type} />}
            {adType === LINK && <AdBlockLink type={type} />}
          </div>
        ) : null}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdBlockDispatcher);
