import { takeEvery, put, cancel, select, call } from 'redux-saga/effects';
import has from 'lodash/has';
import tail from 'lodash/tail';
import take from 'lodash/take';
import get from 'lodash/get';
import { isLeft, isRight } from 'fp-ts/lib/Either';

import {
  notifyClearInfo,
  notifyPutErrorInfo,
  notifyPutActiveInfo,
  notifyPutQueueInfo,
  notifyUpdateQueueInfo,
  notifyRemoveActiveInfo,
  notifyPutErrorFromQueueInfo,
} from '../duck';
import { logoutRequest } from '../../../Logout/state/duck';
import { notifyActive, notifyQueue } from '../selectors';

// NB: Для этих экшенов устанавливаются отдельные правила оповещения
import { articlesArticleResult } from '../../../Articles/state/duck';
import { loginGetJwtResult } from '../../../Login/state/duck';
import { userSubscriptionPutResult } from '../../../UserSubscription/state/duck';
import { userSettingsChangePswdPutResult } from '../../../UserSettingsChangePswd/state/duck';
import { userSettingsChangeEmailPutResult } from '../../../UserSettingsChangeEmail/state/duck';
import { emailConfirmationResult } from '../../../EmailConfirmation/state/duck';
import { userSettingsChangeAvatarPostResult } from '../../../UserSettingsChangeAvatar/state/duck';
import { userSettingsChangePhonePutResult } from '../../../UserSettingsChangePhone/state/duck';
import { productsChecklistResult } from '../../../Products/state/duck';

import { isFormValid as isFormValidSelector } from '../../../UserSettingsChangePswd/state/selectors';
import { isFormValid as isFormChngEmailValidSelector } from '../../../UserSettingsChangeEmail/state/selectors';
import { isFormValid as isFormChngAvatarValidSelector } from '../../../UserSettingsChangeAvatar/state/selectors';
import { isFormValidPut as isFormChngPhoneValidSelector } from '../../../UserSettingsChangePhone/state/selectors';

// Объект экшенов, которые нужно фильтровать, как полезные
import { resultsActions } from './constants';

import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';

import { removeData } from '../../../../shared/utils/commonLocalStorage.helper';
import { LOCAL_STORAGE_EMAIL_CONFIRMATION } from '../../../../shared/constants/Defaults/constants';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';
import { chatListLawyerSendMsgFailure } from '../../../ChatListLawyer/state/duck';
import { shopPurchaseResult } from '../../../Shop/state/duck';
import { registerEmailCodeFailure, registerSMSCodeFailure } from '../../../Register/state/duck';
import { userSettingsChangeDataPutResult } from '../../../UserSettingsChangeData/state/duck';

const MAX_SHOWED_TOASTERS = 3;

interface IErrorContract {
  error: any;
  msg: string;
  title: string;
  supportInfo: string;
  msgType: string;
  ID: number;
  duration: number;
}

/**
 * Вспомогательная функция
 * Удаляет дубли из очереди сообщений с ошибками
 *
 * @param {IErrorContract[]} arr
 * @returns
 */
const removeDoubles = (arr: IErrorContract[]) => {
  if (arr.length === 0) {
    return [];
  }

  const firstArrItem = arr[0];
  const { duration, msg, msgType, supportInfo, title } = firstArrItem;

  const result = [
    { ...firstArrItem },
    ...arr.filter((item: IErrorContract) => {
      const {
        duration: durationFltr,
        msg: msgFltr,
        msgType: msgTypeFltr,
        supportInfo: supportInfoFltr,
        title: titleFltr,
      } = item;

      if (
        duration === durationFltr &&
        msg === msgFltr &&
        msgType === msgTypeFltr &&
        supportInfo === supportInfoFltr &&
        title === titleFltr
      ) {
        return false;
      }

      return true;
    }),
  ];

  return result;
};

/**
 * Сага notificationObserver пропускает через себя все экшены приложения
 * фильтрует только экшены в которых записывается результат возвращаемый из АПИ
 * Проверяет результат на наличие ошибок, логирует этот результат
 * и отображает уведомление пользователю
 *
 * NB: Отсюда ничего отправлять в сентри не нужно!
 *
 * @export
 * @param {*} action
 * @returns
 */
export function* notificationObserver(action: IResultEiterAction) {
  const { type, payload } = action;

  if (has(resultsActions, type)) {
    // NB: Любой экшен c результатами чистит сообшение об ошибке
    yield put({ type: notifyClearInfo.toString() });
    // NB: И только экшен с ошибкой формирует сообшение об ошибке
    if (isLeft(payload)) {
      const { left: error } = payload;

      const { status, data: { data } } = error;
      // Поля `msg` и `title` это метки для переводов
      const commonErrorData: IErrorContract = {
        error,
        msg: 'notification_system_api_error',
        title: 'notification_system_api_error_title',
        supportInfo: 'notification_system_api_support_service',
        msgType: 'error',
        ID: Date.now(),
        duration: 5.5,
      };

      if (status === 401) {
        yield put({
          type: notifyPutErrorInfo.toString(),
          payload: {
            ...commonErrorData,
            msg: 'notification_system_session_end',
            title: 'notification_system_session_end_title',
            msgType: 'info',
            supportInfo: '',
          },
        });
        yield put({ type: logoutRequest.toString() });

        yield cancel();
      }

      // NB: правила оповещения для эешенов
      switch (type) {
        case articlesArticleResult.toString():
          yield put({
            type: notifyPutErrorInfo.toString(),
            payload: {
              ...commonErrorData,
              msg: 'notification_system_article_error',
              title: 'notification_system_api_error_title',
              supportInfo: '',
            },
          });
          break;
        case loginGetJwtResult.toString():
          if (
            data.message.password[0] === 'error.password.user.not.found' ||
            data.message.password[0] === 'error.password.invalid'
          ) {
            commonErrorData.title = 'notification_invalid_username_or_password_title';
            commonErrorData.msg = 'notification_invalid_username_or_password';
            commonErrorData.supportInfo = '';
          }
          yield put({
            type: notifyPutErrorInfo.toString(),
            payload: {
              ...commonErrorData,
            },
          });
          break;
        case userSubscriptionPutResult.toString():
          yield put({
            type: notifyPutErrorInfo.toString(),
            payload: {
              ...commonErrorData,
              msg: 'notification_system_subscription_put_error',
            },
          });
          break;
        case emailConfirmationResult.toString(): {
          break;
        }
        case chatListLawyerSendMsgFailure.toString(): {
          yield put({
            type: notifyPutErrorInfo.toString(),
            payload: {
              ...commonErrorData,
              msg: 'notification.wait.lawyer.response',
              supportInfo: '',
            },
          });
          break;
        }
        case shopPurchaseResult.toString(): {
          yield put({
            type: notifyPutErrorInfo.toString(),
            payload: {
              ...commonErrorData,
              msg: 'notification.purchase.failed',
              supportInfo: '',
            },
          });
          break;
        }
        case registerSMSCodeFailure.toString():
        case registerEmailCodeFailure.toString(): {
          yield put({
            type: notifyPutErrorInfo.toString(),
            payload: {
              ...commonErrorData,
              msg: error?.data?.message,
              supportInfo: '',
            },
          });
          break;
        }

        case userSettingsChangeDataPutResult.toString(): {
          break;
        }

        default:
          yield put({
            type: notifyPutErrorInfo.toString(),
            payload: {
              ...commonErrorData,
            },
          });
          break;
      }
    }

    // FIXME: Требуется рефакторинг,
    // FIXME: так как при Either isRight уже обрабатывается не ошибка, а сообщение!
    // FIXME: должно быть событие не `notifyPutErrorInfo`, а что-то типа `notifyPutSuccessInfo`
    //        (хотя смысл будет одинаковым)
    // NB: Информационные сообщения, если результат положительный
    if (isRight(payload)) {
      const { right: successData } = payload;
      const { data } = successData;

      // Поля `msg` и `title` это метки для переводов
      const commonInfoData: IErrorContract = {
        error: data,
        msg: 'notification_system_api_error',
        title: 'notification_system_api_info_title',
        supportInfo: 'notification_system_api_support_service',
        msgType: 'info',
        ID: Date.now(),
        duration: 5.5,
      };

      // NB: правила оповещения для экшенов
      switch (type) {
        case userSettingsChangePswdPutResult.toString(): {
          const isFormValid = yield select(isFormValidSelector);

          if (isFormValid) {
            yield put({
              type: notifyPutErrorInfo.toString(),
              payload: {
                ...commonInfoData,
                msg: 'notification_system_change_pswd_info',
                title: 'notification_system_api_info_title',
                supportInfo: '',
                msgType: 'info',
              },
            });
          }
          break;
        }
        case userSettingsChangeEmailPutResult.toString(): {
          const isFormChngEmailValid = yield select(
            isFormChngEmailValidSelector,
          );

          if (isFormChngEmailValid) {
            yield put({
              type: notifyPutErrorInfo.toString(),
              payload: {
                ...commonInfoData,
                msg: 'notification_system_change_email_info',
                title: 'notification_system_api_info_title',
                supportInfo: '',
                msgType: 'info',
              },
            });
          }
          break;
        }
        case emailConfirmationResult.toString(): {
          const success = data?.success ?? false;

          if (success) {
            yield put({
              type: notifyPutErrorInfo.toString(),
              payload: {
                ...commonInfoData,
                msg: 'notification_system_confirm_email_info',
                title: 'notification_system_api_info_title',
                supportInfo: '',
                msgType: 'info',
              },
            });

            yield call(removeData, LOCAL_STORAGE_EMAIL_CONFIRMATION);
          }
          break;
        }
        case userSettingsChangeAvatarPostResult.toString(): {
          const isFormChngAvatarValid = yield select(
            isFormChngAvatarValidSelector,
          );

          if (isFormChngAvatarValid) {
            yield put({
              type: notifyPutErrorInfo.toString(),
              payload: {
                ...commonInfoData,
                msg: 'notification_system_change_avatar_info',
                title: 'notification_system_api_info_title',
                supportInfo: '',
                msgType: 'info',
              },
            });
          }
          break;
        }
        case userSettingsChangePhonePutResult.toString(): {
          const isFormChngPhoneValid = yield select(
            isFormChngPhoneValidSelector,
          );

          if (isFormChngPhoneValid) {
            yield put({
              type: notifyPutErrorInfo.toString(),
              payload: {
                ...commonInfoData,
                msg: 'notification_system_change_phone_info',
                title: 'notification_system_api_info_title',
                supportInfo: '',
                msgType: 'info',
              },
            });
          }
          break;
        }
        case productsChecklistResult.toString(): {
          yield put({
            type: notifyPutErrorInfo.toString(),
            payload: {
              ...commonInfoData,
              msg: 'notification_system_send_checklist_info',
              title: 'notification_system_api_info_title',
              supportInfo: '',
              msgType: 'info',
            },
          });
          break;
        }

        case shopPurchaseResult.toString(): {
          yield put({
            type: notifyPutErrorInfo.toString(),
            payload: {
              ...commonInfoData,
              msg: 'notification_system_send_checklist_info',
              title: 'notification.purchase.success',
              supportInfo: '',
              msgType: 'info',
            },
          });
          break;
        }

        case userSettingsChangeDataPutResult.toString(): {
          yield put({
            type: notifyPutErrorInfo.toString(),
            payload: {
              ...commonInfoData,
              msg: 'notification_system_confirm_data_info',
              title: 'notification_system_api_info_title',
              supportInfo: '',
              msgType: 'info',
            },
          });
          break;
        }

        default:
          break;
      }
    }
  }
}

/**
 * Создать очередь из сообщений
 *
 * @export
 * @param {*} action
 */
export function* createQueue(action: any) {
  const { payload: errorData } = action;
  const queue = yield select(notifyQueue);
  const queueTmp = removeDoubles([...queue, { ...errorData }]);

  yield put({ type: notifyPutQueueInfo.toString(), payload: queueTmp });
}

/**
 * Вывести на экран сообщение из очереди сообщений
 *
 * @export
 */
export function* showFromQueue() {
  const active = yield select(notifyActive);
  const queue = yield select(notifyQueue);

  let activeTmp = [];
  let queueHead = take(queue);
  let queueTail = tail(queue);

  if (active.length < MAX_SHOWED_TOASTERS) {
    activeTmp = removeDoubles([...active, ...queueHead]);

    yield put({ type: notifyPutActiveInfo.toString(), payload: activeTmp });
    yield put({
      type: notifyUpdateQueueInfo.toString(),
      payload: [...queueTail],
    });
  }
}

/**
 * Удалить сообщение из очереди и убрать с экрана
 *
 * @export
 * @param {*} action
 */
export function* removeFromActive(action: any) {
  const { payload } = action;
  const { notifyID } = payload;
  const active = yield select(notifyActive);
  const queue = yield select(notifyQueue);

  let queueHead = take(queue);
  let queueTail = tail(queue);
  let activeTmp = [...active].filter((item: any) => item.ID !== notifyID);

  if (activeTmp.length < MAX_SHOWED_TOASTERS) {
    activeTmp = [...activeTmp, ...queueHead];
  }

  yield put({ type: notifyPutActiveInfo.toString(), payload: activeTmp });
  yield put({
    type: notifyUpdateQueueInfo.toString(),
    payload: [...queueTail],
  });
  yield put({
    type: notifyPutErrorFromQueueInfo.toString(),
    payload: get(queueHead, '[0]', {}) || {},
  });
}

// Root Saga
export default function* rootSaga() {
  yield takeEvery('*', notificationObserver);
  yield takeEvery(notifyPutErrorInfo, safe(onError, createQueue));
  yield takeEvery(notifyPutQueueInfo, safe(onError, showFromQueue));
  yield takeEvery(notifyRemoveActiveInfo, safe(onError, removeFromActive));
}
