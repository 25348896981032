import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { logoutSuccess } from '../../../Logout/state/duck';

const initData = { data: [] };

// Actions ==========================

const FAQ = 'FAQ';

// Запрос информации по разделу "Отзывы" из АПИ
export const faqRequest = createAction(`${FAQ}/REQUEST`);
export const faqSuccess = createAction(`${FAQ}/SUCCESS`);
export const faqFailure = createAction(`${FAQ}/FAILURE`);

// Результат запроса
export const faqResult = createAction(`${FAQ}/RESULT`);

export const faqCancelRequest = createAction(`${FAQ}/CANCEL_REQUEST`);

// Reducers ==========================

// Статус запроса данных из АПИ
const isFetching = createReducer(false, {
  [faqRequest.toString()]: () => true,
  [faqSuccess.toString()]: () => false,
  [faqFailure.toString()]: () => false,
  [faqCancelRequest.toString()]: () => false,
});

// Успешный результат запроса из АПИ
const data = createReducer(
  { ...initData },
  {
    [faqRequest.toString()]: (state = initData) => ({
      ...state,
    }),
    [faqSuccess.toString()]: (_state, action) => action.payload,
  },
);

// Ошибка при запросе данных из АПИ
const error = createReducer([], {
  [faqRequest.toString()]: () => [],
  [faqSuccess.toString()]: () => [],
  [faqFailure.toString()]: (_state, action) => action.payload,
});

// Результат запроса данных из АПИ
const result = createReducer(
  {},
  {
    [faqRequest.toString()]: () => ({}),
    [faqResult.toString()]: (_state, action) => action.payload,
  },
);

const isCached = createReducer(false, {
  [faqSuccess.toString()]: () => true,
  [logoutSuccess.toString()]: () => false,
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  error,
  result,
  isCached,
});

export default reducer;
