import styled from 'styled-components';
import { Select } from 'antd';

export const SelectStyled = styled(Select)`
  min-width: 160px;

  &.ant-select-focused, .ant-select-selector,
  &.ant-select-selector:focus,
  &.ant-select-selector:active,
  &.ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
  &.ant-select-selector {
    height: 26px !important;
    border: 0 !important;
    border-radius: 4px !important;
    background-color: transparent !important;
    box-shadow: none !important;

    &.ant-select-selection-item {
      line-height: 24px !important;
      & img {
        margin-right: 10px !important;
      }
    }

    .ant-select-selection-item {
      opacity: 0.5 !important;
      color: #0F1011 !important;
      font-size: 13px !important;
      letter-spacing: 0 !important;
    }
  }
`;
