import {
  put,
  takeLatest,
  takeEvery,
  all,
  call,
  select,
  cancel,
} from 'redux-saga/effects';
import * as A from 'fp-ts/lib/Array';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';

import { creditRating as creditRatingAPI } from '../../../../services/CreditRating/api';

import {
  creditRatingRequest,
  creditRatingSuccess,
  creditRatingFailure,
  creditRatingUpdate,
  creditRatingResult,
  creditRatingLiteRequest,
  creditRatingLiteSuccess,
  creditRatingLiteFailure,
  creditRatingLiteResult,
  creditRatingStatusRequest,
  creditRatingStatusSuccess,
  creditRatingStatusFailure,
  creditRatingStatusResult,
  creditRatingCancelRequest,
  bankruptcyRequest,
  bankruptcySendRequest,
  bankruptcySendSuccess,
  bankruptcySuccess,
  bankruptcyFailure,
} from '../duck';
import {
  isCached as isCachedSelector,
  data as dataSelector,
} from '../selectors';
import { logoutRequest } from '../../../Logout/state/duck';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import {
  CREDIT_RATING__SCORING_RES,
  CREDIT_RATING__SCORING_NXT_DATE_RES,
  CREDIT_RATING__AVERAGE_SCORING_RES,
  CREDIT_RATING__OFFERS_TABS_RES,
  CREDIT_RATING__DETAILS_RES,
} from './constants';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';
import { paymentRatingUpdateRequests } from '../../../Payment/state/duck';
import {
  modalFields,
  modalClearInfo,
  modalFieldsUnSetLoading,
  modalFieldsSetLoading,
} from '../../../ModalSystem/state/duck';

export function* getData(action: any) {
  const isCached: boolean = yield select(isCachedSelector);
  if (isCached && !action?.payload?.notCached) {
    yield put({
      type: creditRatingCancelRequest.toString(),
    });

    return;
  }

  yield put({
    type: creditRatingStatusRequest.toString(),
  });

  const completePayload: any = yield call(checkToken, action);

  const [
    userRatingScoring,
    userRatingScoringNxtDate,
    allUsersRatingAverageScoring,
    offersTabs,
    details,
    // FIXME: Отключить линк на шаринг КР - FIN-1981
    // share,
  ]: any = yield all([
    call(creditRatingAPI.getRatingScoring, completePayload),
    call(creditRatingAPI.getRatingScoringNxtDate, completePayload),
    call(creditRatingAPI.getRatingAverageScoring, completePayload),
    call(creditRatingAPI.postDataFilter, completePayload),
    call(creditRatingAPI.getDetails, completePayload),
    // FIXME: Отключить линк на шаринг КР - FIN-1981
    // call(creditRatingAPI.getShare, completePayload),
  ]);

  const input = [
    { ...userRatingScoring, init: CREDIT_RATING__SCORING_RES },
    { ...userRatingScoringNxtDate, init: CREDIT_RATING__SCORING_NXT_DATE_RES },
    {
      ...allUsersRatingAverageScoring,
      init: CREDIT_RATING__AVERAGE_SCORING_RES,
    },
    { ...offersTabs, init: CREDIT_RATING__OFFERS_TABS_RES },
    { ...details, init: CREDIT_RATING__DETAILS_RES },
    // FIXME: Отключить линк на шаринг КР - FIN-1981
    // { ...share, init: CREDIT_RATING__REFERRAL_RES },
  ];

  const result = pipe(input, A.map(createResultV2), A.separate);
  const { left: errors, right: success } = result;

  if (errors.length > 0) {
    // NB: Если есть хоть одна 401, то разлогингить пользователя
    // и закончить работу саги
    for (let index = 0; index < errors.length; index++) {
      const element = errors[index];
      const { status } = element;

      if (status === 401) {
        yield put({
          type: creditRatingResult.toString(),
          payload: createResultV2(element),
        });
        yield cancel();
      }
    }

    yield put({
      type: creditRatingFailure.toString(),
    });
  }

  if (success.length > 0 && success.length === input.length) {
    // userRatingScoring
    const { data: userRatingScoringData } = userRatingScoring;
    // userRatingScoringNxtDate
    const { data: userRatingScoringNxtDateData } = userRatingScoringNxtDate;
    // allUsersRatingAverageScoring
    const {
      data: allUsersRatingAverageScoringData,
    } = allUsersRatingAverageScoring;
    // offersTabs
    const { status: offersTabsStatus, data: offersTabsData } = offersTabs;
    const { data: detailsData } = details;
    // FIXME: Отключить линк на шаринг КР - FIN-1981
    // const { data: shareData } = share;
    let tempOffersList = {};
    let callsArr: any[] = [];

    // FIXME: подумать как эту императивщину превратить в декларативщину
    // Если есть информация о табах
    if (offersTabsStatus === 200) {
      // На основе табов создадим массив с замыканиями "call" для вызовов в АПИ
      for (let i = 0; i < offersTabsData.length; i++) {
        const tempOffer = offersTabsData[i];
        const { id = 0 } = tempOffer;

        callsArr = [
          ...callsArr,
          {
            id,
            call: call(creditRatingAPI.postDataList, {
              ...completePayload,
              type: id,
              limit: -1,
            }),
          },
        ];
      }

      // Выполним параллельный запрос всех данных офферов из АПИ
      const offersRes: any = yield all([
        ...callsArr.map((item: any) => {
          const { call } = item;

          return call;
        }),
      ]);

      // Если хоть один из запросов вернул 401, то разлогиним пользователя из кабинета
      for (let i = 0; i < offersRes.length; i++) {
        const tempOfferRes = offersTabsData[i];
        const { status } = tempOfferRes;

        if (status === 401) {
          yield put({ type: logoutRequest.toString() });
        }
      }

      // Создадим из массива с результатами запроса офферов
      // объект с офферами у которого ключами будут являться ID табов
      for (let i = 0; i < callsArr.length; i++) {
        const tempOffer = callsArr[i];
        const tempRes = offersRes[i];
        const { id } = tempOffer;
        const { data } = tempRes;

        tempOffersList = { ...tempOffersList, [id]: { data } };
      }
    }

    const payload = {
      userRatingScoringData,
      userRatingScoringNxtDateData,
      allUsersRatingAverageScoringData,
      offersTabsData,
      offersList: tempOffersList,
      detailsData,
      // FIXME: Отключить линк на шаринг КР - FIN-1981
      // shareData,
    };

    yield put({
      type: creditRatingSuccess.toString(),
      payload,
    });
  }
} // getData =========

export function* getRatingLiteData(action: any) {
  const isCached: boolean = yield select(isCachedSelector);
  if (isCached) {
    yield put({
      type: creditRatingCancelRequest.toString(),
    });

    return;
  }

  const completePayload: any = yield call(checkToken, action);
  const [userRatingScoring]: any = yield all([
    call(creditRatingAPI.getRatingScoring, completePayload),
  ]);

  yield put({
    type: creditRatingLiteResult.toString(),
    payload: createResultV2({ ...userRatingScoring }),
  });
} // getRatingLiteData =========

export function* checkRatingLiteResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    const dataFromReducer: any = yield select(dataSelector);

    yield put({
      type: creditRatingLiteSuccess.toString(),
      payload: { ...dataFromReducer, userRatingScoringData: data },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: creditRatingLiteFailure.toString(),
      payload: error,
    });
  }
} // checkRatingLiteResult =========

export function* getStatus(action: any) {
  const isCached: boolean = yield select(isCachedSelector);
  if (isCached && !action.nonCashed) {
    yield put({
      type: creditRatingCancelRequest.toString(),
    });

    return;
  }

  const completePayload: any = yield call(checkToken, action);
  const res = yield call(creditRatingAPI.getStatus, completePayload);

  yield put({
    type: creditRatingStatusResult.toString(),
    payload: createResultV2(res),
  });
} // getStatus =========

export function* checkStatusResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: creditRatingStatusSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    // const { left: error } = payload;
    // const { data } = error;

    yield put({
      type: creditRatingStatusFailure.toString(),
    });
  }
} // checkStatusResult =========

export function* ratingUpdate(
  action: IResultEiterAction,
): IterableIterator<any> {
  const completePayload: any = yield call(checkToken, action);
  const res: any = yield call(creditRatingAPI.postUpdate, completePayload);

  if (res.status === 200) {
    yield put({
      type: creditRatingStatusRequest.toString(),
      nonCashed: true,
    });
  }

  yield put({
    type: paymentRatingUpdateRequests.toString(),
    payload: createResultV2(res),
  });
}

export function* getBankruptcy(action: IResultEiterAction): IterableIterator<any> {
  const completePayload: any = yield call(checkToken, action);
  const res: any = yield call(creditRatingAPI.getBankruptcy, completePayload);

  const result = createResultV2(res);

  if (E.isRight(result)) {
    const { right: successData } = result;
    const { data } = successData;

    yield put({
      type: modalFields.toString(),
      payload: {
        handler: completePayload?.purpose,
        form: data?.form || {},
        callbackType: bankruptcySendRequest.toString(),
        callbackPayload: {
          purpose: completePayload?.purpose,
        },
      },
    });

    yield put({
      type: bankruptcySuccess.toString(),
      payload: data,
    });
  }

  if (E.isLeft(result)) {
    const { left: errorData } = result;
    const { data } = errorData;

    yield put({
      type: bankruptcyFailure.toString(),
      payload: data,
    });
  }
}

export function* postBankruptcy(action: IResultEiterAction): IterableIterator<any> {
  yield put({
    type: modalFieldsSetLoading.toString(),
  });

  const completePayload: any = yield call(checkToken, action);
  const res: any = yield call(creditRatingAPI.postBankruptcy, completePayload);

  const result = createResultV2(res);

  yield put({
    type: modalFieldsUnSetLoading.toString(),
  });

  if (E.isRight(result)) {
    const { right: successData } = result;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data } = successData;

    yield put({
      type: modalClearInfo.toString(),
    });

    yield put({
      type: bankruptcySendSuccess.toString(),
    });

    yield put({
      type: creditRatingRequest.toString(),
      payload: {
        notCached: true,
      },
    });
  }

  if (E.isLeft(result)) {
    const { left: error } = result;
    const { data } = error;

    yield put({
      type: modalFields.toString(),
      payload: {
        handler: completePayload?.purpose,
        form: data?.form || {},
        callbackType: '',
      },
    });
  }
}

// Root Saga
export default function* rootSaga() {
  yield takeEvery(
    creditRatingRequest,
    safe(onError, getData, {
      terminator: creditRatingFailure,
    }),
  );

  yield takeEvery(
    creditRatingLiteRequest,
    safe(onError, getRatingLiteData, {
      terminator: creditRatingLiteFailure,
    }),
  );

  yield takeEvery(
    creditRatingLiteResult,
    safe(onError, checkRatingLiteResult, {
      terminator: creditRatingLiteFailure,
    }),
  );

  yield takeLatest(
    creditRatingStatusRequest,
    safe(onError, getStatus, { terminator: creditRatingStatusFailure }),
  );

  yield takeEvery(creditRatingStatusResult, safe(onError, checkStatusResult));

  yield takeEvery(
    creditRatingUpdate,
    safe(onError, ratingUpdate),
  );

  yield takeLatest(
    bankruptcyRequest,
    safe(onError, getBankruptcy),
  );

  yield takeLatest(
    bankruptcySendRequest,
    safe(onError, postBankruptcy),
  );
}
