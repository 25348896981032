import { createEffect } from 'effector';
import {
  MultiloginAPI,
  GetMfoReq,
  PostAddMfoReq,
  GetCabinetsMfoReq,
  DeleteMfoReq, MfoSortType,
} from '../../services/Multilogin/api';
import { APIError, APIResult } from '../../core/api/Result';

export const $getMfoAll = createEffect<null, APIResult<typeof MultiloginAPI['getMfoAll']>, APIError>(
  MultiloginAPI.getMfoAll,
);

export const $getMfo = createEffect<GetMfoReq, APIResult<typeof MultiloginAPI['getMfo']>, APIError>(
  MultiloginAPI.getMfo,
);

export const $getCabinetsMfo = createEffect<GetCabinetsMfoReq, APIResult<typeof MultiloginAPI['getCabinetsMfo']>, APIError>(
  MultiloginAPI.getCabinetsMfo,
);

export const $addMfo = createEffect<PostAddMfoReq, APIResult<typeof MultiloginAPI['postAddMfo']>, APIError>(
  MultiloginAPI.postAddMfo,
);
$addMfo.done.watch(() => $getCabinetsMfo({ filter: MfoSortType.COMPANY }));

export const $deleteMfo = createEffect<DeleteMfoReq, APIResult<typeof MultiloginAPI['deleteMfo']>, APIError>(
  MultiloginAPI.deleteMfo,
);
$deleteMfo.done.watch(() => $getCabinetsMfo({ filter: MfoSortType.COMPANY }));

export const $getTotalMfo = createEffect<null, APIResult<typeof MultiloginAPI['getTotalMfo']>, APIError>(
  MultiloginAPI.getTotalMfo,
);
