import React from 'react';
import cx from 'classnames';

import UserSettingsChangePhoneForm from '../UserSettingsChangePhoneForm';
import UserSettingsChangeDataForm from '../UserSettingsChangeDataForm';

import styles from './UserSettingsEditProfile.module.scss';

import UserSettingsChangeEmailForm from '../UserSettingsChangeEmailForm';

/**
 * Окно настройки: Мой профиль
 *
 * @returns
 */
const UserSettingsEditProfile: React.FC = () => {
  return (
    <>
      <div className={cx(styles.formWrapper)}>
        <UserSettingsChangeDataForm />
      </div>

      <div
        className={cx(styles.formWrapper)}
        style={{ borderTop: '1px solid #E8F1FD', marginTop: '20px' }}
      >
        <UserSettingsChangePhoneForm />
      </div>
      <div
        className={cx(styles.formWrapper)}
        style={{ borderTop: '1px solid #E8F1FD', marginTop: '20px' }}
      >
        <UserSettingsChangeEmailForm />
      </div>
    </>

  );
};

export default UserSettingsEditProfile;
