import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * CreditRating API
 */
export default {
  /**
   * Получить данные с рейтингом текущего пользователя
   *
   * @param {*} data
   * @returns
   */
  getRatingScoring(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/rating/scorings`,
    );
  },

  /**
   * Получить дату следующего запланированного обновления рейтинга
   *
   * @param {*} data
   * @returns
   */
  getRatingScoringNxtDate(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/rating/scorings/next-date-of-update`,
    );
  },

  /**
   * Средние значения рейтинга всех пользователей системы
   *
   * @param {*} data
   * @returns
   */
  getRatingAverageScoring(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/rating/average-scoring`,
    );
  },

  /**
   * Получить табы с предложениями
   *
   * @param {*} data
   * @returns
   */
  postDataFilter(data: any) {
    const { token } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/credit-products/filters`,
      data,
    );
  },

  /**
   * Получить данные с предложениями по всем табам системы
   *
   * @param {*} data
   * @returns
   */
  postDataList(data: any) {
    const { token } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/credit-products/list`,
      data,
    );
  },

  /**
   * Получить статус запроса в сервисе кредитных историй,
   * по кредитному рейтингу текущего пользователя
   *
   * @param {*} data
   * @returns
   */
  getStatus(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/rating/scorings/status`,
    );
  },

  /**
   * Запрос на обновление
   * по кредитному рейтингу текущего пользователя
   *
   * @param {*} data
   * @returns
   */
  postUpdate(data: any) {
    const { token } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/rating/request`,
    );
  },

  /**
   * Получить статус рейтинга в процентах,
   * сколько пользователей обогнал в процентах,
   * по кредитному рейтингу текущего пользователя
   *
   * @param {*} data
   * @returns
   */
  getDetails(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/rating/scorings/details`,
    );
  },

  /**
   * Получить рефельный линк
   *
   * @returns
   */
  getShare(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/referral`,
    );
  },

  /**
   * Получить форму для банкротства
   *
   * @returns
   */
  getBankruptcy(data: any) {
    const { token, purpose } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/gateway/main-request/${purpose}`,
    );
  },

  /**
   * Отправить форму для банкротства
   *
   * @returns
   */
  postBankruptcy(data: any) {
    const { token, purpose, ...body } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/gateway/main-request/${purpose}`,
      body,
    );
  },
};
