import { put, takeLatest, call, select, takeEvery } from 'redux-saga/effects';
import get from 'lodash/get';
import * as E from 'fp-ts/lib/Either';

import { faq } from '../../../../services/Faq/api';

import {
  faqRequest,
  faqResult,
  faqSuccess,
  faqFailure,
  faqCancelRequest,
} from '../duck';
import { isCached as isCachedSelector } from '../selectors';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';

export function* getData(action: any) {
  const isCached: boolean = yield select(isCachedSelector);
  if (isCached) {
    yield put({
      type: faqCancelRequest.toString(),
    });

    return;
  }

  const completePayload: any = yield call(checkToken, action);
  const faqRes = yield call(faq.getData, { ...completePayload });

  yield put({
    type: faqResult.toString(),
    payload: createResultV2(faqRes),
  });
} // getData =========

export function* checkResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const dataRes = get(successData, 'data', []) || [];
    const data = Array.isArray(dataRes) ? dataRes : [];

    yield put({
      type: faqSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: faqFailure.toString(),
      payload: error,
    });
  }
} // checkResult =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    faqRequest,
    safe(onError, getData, {
      terminator: faqFailure,
    }),
  );
  yield takeEvery(faqResult, safe(onError, checkResult));
}
