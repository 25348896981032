import { takeEvery, put, call } from 'redux-saga/effects';

import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';

import {
  authSetAuthStatusRequest,
  authSetAuthStatusSuccess,
  authSetAuthStatusFailure,
} from '../duck';

import { getData as getTokenLS } from '../../../../shared/utils/commonLocalStorage.helper';
import { getData as getTokenC } from '../../../../shared/utils/commonCookies.helper';

import { LOCAL_STORAGE_AUTH_KEY } from '../../../../shared/constants/Defaults/constants';

// Sagas

export function* setAuthStatus(): IterableIterator<any> {
  const authTokenLS = yield call(getTokenLS, LOCAL_STORAGE_AUTH_KEY);
  const authTokenC = yield call(getTokenC, LOCAL_STORAGE_AUTH_KEY);

  const authToken = authTokenLS || authTokenC;

  if (authToken) {
    yield put({
      type: authSetAuthStatusSuccess.toString(),
      payload: {
        authToken,
        isAuthorized: true,
      },
    });
  } else {
    yield put({
      type: authSetAuthStatusFailure.toString(),
      payload: { error: 'Access denied!' },
    });
  }
} // setAuthStatus =========

// Root Saga
export default function* rootSaga() {
  yield takeEvery(
    authSetAuthStatusRequest,
    safe(onError, setAuthStatus, { terminator: authSetAuthStatusFailure }),
  );
}
