import { createSelector } from 'reselect';

export const isFetching = (store: any) => store.shopReducer.isFetching;

export const data = createSelector(
  (store: any) => store.shopReducer.data,
  data => data,
);

export const isFetchingCurrentPrice = (store: any) => store.shopReducer.isFetchingCurrentPrice;

export const currentPrice = createSelector(
  (store: any) => store.shopReducer.currentPrice,
  currentPrice => currentPrice,
);

export const isFetchingPermissions = (store: any) => store.shopReducer.isFetchingPermissions;

export const permissions = createSelector(
  (store: any) => store.shopReducer.permissions,
  permissions => permissions,
);

export const isFetchingRestrictions = (store: any) => store.shopReducer.isFetchingRestrictions;

export const restrictionsFields = createSelector(
  (store: any) => store.shopReducer.restrictions,
  (restrictions) => {
    const res = restrictions?.fields ?? {};

    return Object.entries(res);
  },
);

export const fields = createSelector(
  (store: any) => store.shopReducer.restrictions,
  (restrictions) => restrictions?.fields,
);
