import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { logoutSuccess } from '../../../Logout/state/duck';

// Actions ==========================

const CREDIT_RATING = 'CREDIT_RATING';

// Запрос информации по разделу "Кредитный рейтинг" из АПИ
export const creditRatingRequest = createAction(`${CREDIT_RATING}/REQUEST`);
export const creditRatingSuccess = createAction(`${CREDIT_RATING}/SUCCESS`);
export const creditRatingFailure = createAction(`${CREDIT_RATING}/FAILURE`);

// Запрос формы банкротства
export const bankruptcyRequest = createAction(`${CREDIT_RATING}/BANKRUPTCY_REQUEST`);
export const bankruptcySuccess = createAction(`${CREDIT_RATING}/BANKRUPTCY_SUCCESS`);
export const bankruptcyFailure = createAction(`${CREDIT_RATING}/BANKRUPTCY_FAILURE`);

// Отправка формы банкротства
export const bankruptcySendRequest = createAction(`${CREDIT_RATING}/BANKRUPTCY_SEND_REQUEST`);
export const bankruptcySendSuccess = createAction(`${CREDIT_RATING}/BANKRUPTCY_SEND_SUCCESS`);
export const bankruptcySendFailure = createAction(`${CREDIT_RATING}/BANKRUPTCY_SEND_FAILURE`);

// Запрос на обновление Кредитного рейтинга
export const creditRatingUpdate = createAction(`${CREDIT_RATING}/UPDATE`);

// Запрос базовой информации о "Кредитном рейтинге" из АПИ
export const creditRatingLiteRequest = createAction(
  `${CREDIT_RATING}/LITE_REQUEST`,
);
export const creditRatingLiteSuccess = createAction(
  `${CREDIT_RATING}/LITE_SUCCESS`,
);
export const creditRatingLiteFailure = createAction(
  `${CREDIT_RATING}/LITE_FAILURE`,
);

// Результат запроса
export const creditRatingResult = createAction(`${CREDIT_RATING}/RESULT`);

// Результат запроса
export const creditRatingLiteResult = createAction(
  `${CREDIT_RATING}/LITE_RESULT`,
);

// Запрос статуса "Кредитного рейтинга" из АПИ
export const creditRatingStatusRequest = createAction(
  `${CREDIT_RATING}/STATUS_REQUEST`,
);
export const creditRatingStatusSuccess = createAction(
  `${CREDIT_RATING}/STATUS_SUCCESS`,
);
export const creditRatingStatusFailure = createAction(
  `${CREDIT_RATING}/STATUS_FAILURE`,
);

export const creditRatingStatusResult = createAction(
  `${CREDIT_RATING}/STATUS_RESULT`,
);

export const creditRatingCancelRequest = createAction(
  `${CREDIT_RATING}/CANCEL_REQUEST`,
);

// Reducers ==========================

// Статус запроса данных из АПИ
const isFetching = createReducer(false, {
  [creditRatingRequest.toString()]: () => true,
  [creditRatingSuccess.toString()]: () => false,
  [creditRatingFailure.toString()]: () => false,
  [creditRatingLiteRequest.toString()]: () => true,
  [creditRatingLiteSuccess.toString()]: () => false,
  [creditRatingLiteFailure.toString()]: () => false,
  [creditRatingCancelRequest.toString()]: () => false,
});

// Успешный результат запроса из АПИ
const data = createReducer({}, {
  [creditRatingSuccess.toString()]: (_state, action) => action.payload,
  [creditRatingLiteSuccess.toString()]: (_state, action) => action.payload,
});

// Результат запроса данных из АПИ
const resultCredRateLite = createReducer({},
  {
    [creditRatingLiteResult.toString()]: (_state, action) => action.payload,
  });

// Статус кредитного рейтинга =====

// Статус запроса данных из АПИ
const isFetchingStatus = createReducer(false, {
  [creditRatingStatusRequest.toString()]: () => true,
  [creditRatingStatusSuccess.toString()]: () => false,
  [creditRatingStatusFailure.toString()]: () => false,
  [creditRatingCancelRequest.toString()]: () => false,
});

// Успешный результат запроса из АПИ
const dataStatus = createReducer({}, {
  [creditRatingStatusSuccess.toString()]: (_state, action) => action.payload,
});

// Результат запроса из АПИ
const resultStatus = createReducer({}, {
  [creditRatingStatusRequest.toString()]: () => ({}),
  [creditRatingStatusResult.toString()]: (_state, action) => action.payload,
});

const isCached = createReducer(false, {
  [creditRatingSuccess.toString()]: () => true,
  [logoutSuccess.toString()]: () => false,
});

const isFetchingBankruptcy = createReducer(false, {
  [bankruptcyRequest.toString()]: () => true,
  [bankruptcySuccess.toString()]: () => false,
  [bankruptcyFailure.toString()]: () => false,
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  resultCredRateLite,
  isFetchingStatus,
  resultStatus,
  dataStatus,
  isCached,
  isFetchingBankruptcy,
});

export default reducer;
