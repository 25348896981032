import React, { useEffect } from 'react';
import cx from 'classnames';
import { Button, Empty } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-localize-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import UserSettingsActiveSubscribe from '../UserSettingsActiveSubscribe';

import moment from 'moment';

import { DEVICE_NETBOOKS_WIDTH } from '../../constants/Defaults/constants';
import { useWindowSize } from '../../hooks/useWindowSize';

import InfinitePreloader from '../../componets/InfinitePreloader';
import Preloader from '../../componets/Preloader';

import styles from './UserSettingsShopping.module.scss';

import {
  logList as payListSelector,
  isFetching as isFetchingSelector,
  totalPages as totalPagesSelector,
  currentPage as currentPageSelector,
} from '../../../states/UserSettingsLogsForm/state/selectors';

import {
  limitsData as limitsDataSelector,
  isFetching as isFetchingLimitsSelector,
} from '../../../states/UserLimits/state/selectors';

import {
  userSettingsLogsFormRequest,
  userSettingsLogsFormScrollingRequest,
} from '../../../states/UserSettingsLogsForm/state/duck';

import fileImage from './assets/images/file.svg';
import limitsImage from './assets/images/limits.svg';

import {
  userLimitsRequest,
  userLimitsRating,
  userLimitsDebt,
  userLimitsLawyer,
} from '../../../states/UserLimits/state/duck';
import { $getSubscribe } from '../../../states/UserSubscription/event';

const LIMITS = 'LIMITS';
const PAYMENTS = 'PAYMENTS';

const initState = {
  activeTab: LIMITS,
};

/**
 * покупки и лимиты
 *
 * @returns
 */
const UserSettingsShopping: React.FC = () => {
  const payList = useSelector(payListSelector);
  const isFetching = useSelector(isFetchingSelector);
  const totalPages = useSelector(totalPagesSelector);
  const currentPage = useSelector(currentPageSelector);
  const limitsData = useSelector(limitsDataSelector);
  const isFetchingLimits = useSelector(isFetchingLimitsSelector);
  const dispatch = useDispatch();

  const [state, setState] = React.useState(initState);
  const { activeTab } = state;

  const windowSize = useWindowSize();
  const { width = 0 } = windowSize;

  // По умолчанию считаем что запущено на десктопе, мобила = false
  let isMobile = false;

  if (width < DEVICE_NETBOOKS_WIDTH) {
    isMobile = true;
  }

  useEffect(() => {
    dispatch({
      type: userLimitsRequest.toString(),
    });
    dispatch({
      type: userSettingsLogsFormRequest.toString(),
    });
    $getSubscribe(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchNewData = React.useCallback(() => {
    if (currentPage - totalPages !== 0) {
      dispatch({
        type: userSettingsLogsFormScrollingRequest.toString(),
        payload: { page: currentPage + 1 },
      });
    }
  }, [dispatch, currentPage, totalPages]);

  return (
    <>
      <div className={styles.shopping}>
        {isMobile ? (
          ''
        ) : (
          <div className={cx(styles.header)}>
            <div className={cx(styles.headerText)}>
              <Translate id="shared_containers_usersettings_shopping" />
            </div>
          </div>
        )}

        <div className={styles.menu}>
          <button
            className={cx(styles.menu__item, {
              [styles.active]: activeTab === LIMITS,
            })}
            onClick={() => setState({ activeTab: LIMITS })}
            type="button"
          >
            <img src={limitsImage} alt="limits" />
            <span><Translate id="shared_containers_usersettings_limits_btn" /></span>
          </button>

          <button
            className={cx(styles.menu__item, {
              [styles.active]: activeTab === PAYMENTS,
            })}
            onClick={() => setState({ activeTab: PAYMENTS })}
            type="button"
          >
            <img src={fileImage} alt="payment" />
            <span><Translate id="shared_containers_usersettings_shopping_btn" /></span>
          </button>
        </div>

        <div className={styles.shopping__subtitle}>
          <Translate id="shared_containers_usersettings_shopping_subtitle" />
        </div>

        {activeTab === 'LIMITS' ? (
          <div className={styles.limit}>
            <div className={styles.limit__header}>
              <span><Translate id="shared_containers_usersettings_shopping_service" /></span>
              <span><Translate id="shared_containers_usersettings_shopping_limit" /></span>
            </div>

            {isFetchingLimits ?
              <div className={styles.center}><Preloader size="large" /></div>
              :
              <div className={styles.limit__list}>
                <div className={styles.limit__item}>
                  <div className={styles.limit__col}>
                    <div className={styles.limit__item_title}>
                      <Translate id="shared_containers_usersettings_scoring_check" />
                    </div>
                    <div className={styles.limit__item_text}>
                      <Translate id="shared_containers_usersettings_scoring_text" />
                    </div>
                    {limitsData.rating <= 0 &&
                    <Button
                      type="default"
                      shape="round"
                      className={styles.btn}
                      onClick={() => dispatch({
                        type: userLimitsRating.toString(),
                      })}
                    >
                      <Translate id="shared_containers_usersettings_request_fssp_repeat_order" />
                    </Button>
                    }
                  </div>
                  <div className={cx(styles.limit__col, styles.limit__count)}>
                    {limitsData.rating}
                  </div>
                </div>

                <div className={styles.limit__item}>
                  <div className={styles.limit__col}>
                    <div className={styles.limit__item_title}>
                      <Translate id="shared_containers_usersettings_request_fssp" />
                    </div>
                    <div className={styles.limit__item_text}>
                      <Translate id="shared_containers_usersettings_request_fssp_text" />
                    </div>
                    {limitsData.debt <= 0 &&
                      <Button
                        type="default"
                        shape="round"
                        className={styles.btn}
                        onClick={() => dispatch({
                          type: userLimitsDebt.toString(),
                        })}
                      >
                        <Translate id="shared_containers_usersettings_request_fssp_repeat_order" />
                      </Button>
                    }
                  </div>
                  <div className={cx(styles.limit__col, styles.limit__count)}>
                    {limitsData.debt}
                  </div>
                </div>

                <div className={styles.limit__item}>
                  <div className={styles.limit__col}>
                    <div className={styles.limit__item_title}>
                      <Translate id="shared_containers_usersettings_question_lawyer" />
                    </div>
                    <div className={styles.limit__item_text}>
                      <Translate id="shared_containers_usersettings_question_lawyer_text" />
                    </div>
                    {limitsData.lawyer <= 0 &&
                    <Button
                      type="default"
                      shape="round"
                      className={styles.btn}
                      onClick={() => dispatch({
                        type: userLimitsLawyer.toString(),
                      })}
                    >
                      <Translate id="shared_containers_usersettings_request_fssp_repeat_order" />
                    </Button>
                    }
                  </div>
                  <div className={cx(styles.limit__col, styles.limit__count)}>
                    {limitsData.lawyer}
                  </div>
                </div>
              </div>
            }

            <div className={styles.limit__ad}>
              <div className={styles.limit__ad_title}>
                <Translate id="shared_containers_usersettings_shoppings_ad_title" />
              </div>
              <div className={styles.limit__ad_text}>
                <Translate id="shared_containers_usersettings_shoppings_ad_text" />
              </div>
              <Button
                type="primary"
                shape="round"
                style={{
                  height: '48px',
                }}
              >
                <Translate id="shared_containers_usersettings_shoppings_ad_button" />
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.payment}>

            <UserSettingsActiveSubscribe />

            <div className={cx(styles.listDesktop)}>
              <div
                className={cx(styles.listDesktopElHeader, styles.listDesktopEl)}
              >
                <div className={styles.flex1}>
                  <Translate id="shared_containers_usersettings_shoppings_date" />
                </div>
                <div className={styles.flex2}>
                  <Translate id="shared_containers_usersettings_shoppings_method" />
                </div>
                <div className={styles.flex3}>
                  <Translate id="shared_containers_usersettings_shoppings_sum" />
                </div>
              </div>

              <>
                {isFetching ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Preloader size="large" />
                  </div>
                ) : (
                  <>
                    <InfiniteScroll
                      dataLength={payList.length}
                      next={handleFetchNewData}
                      hasMore={totalPages - currentPage !== 0}
                      loader={<InfinitePreloader />}
                      height={300}
                    >
                      {payList.length === 0 ? (
                        <Empty />
                      ) : (
                        <>
                          {payList.map((item: any) => {
                            const {
                              id,
                              currency,
                              sum,
                              createdAt,
                              cardBin,
                            } = item;
                            return (
                              <div
                                key={id}
                                className={cx(styles.listDesktopEl)}
                              >
                                <div className={styles.flex1}>
                                  {moment(createdAt).format('DD.MM.YYYY')}{', '}
                                  {moment(createdAt).format('hh:mm')}
                                </div>
                                <div className={styles.flex2}>
                                  {/* <img src={maestroImage} alt="visa" /> */}
                                  {cardBin}
                                </div>
                                <div className={styles.flex3}>{`${sum} ${currency}`}</div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </InfiniteScroll>
                  </>
                )}
              </>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserSettingsShopping;
