import { put, takeLatest, call, takeEvery, select, takeLeading } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';

import { userSubscriptionAPI } from '../../../../services/UserSubscription/api';

import {
  userSubscriptionRequest,
  userSubscriptionSuccess,
  userSubscriptionFailure,
  userSubscriptionResult,
  userSubscriptionPutRequest,
  userSubscriptionPutSuccess,
  userSubscriptionPutFailure,
  userSubscriptionPutResult,
} from '../duck';
import { simpleDataFromReducer } from '../selectors';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import {
// checkContract,
// DIRECTION,
} from '../../../../core/state/utils/checkContract/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';

import {
// okUserSubscriptionContract,
// errorUserSubscriptionContract,
// okUserSubscriptionPutContract,
// errorUserSubscriptionPutContract,
// sendUserSubscriptionPutContract,
} from '../../../../contracts/UserSubscription';

interface ISubscriptionPut {
  name: string;
  value: string;
}

export function* getData() {
  const completePayload: any = yield call(checkToken, {});
  const { token } = completePayload;

  if (!token) {
    return;
  }

  const userSubscriptionRes: any = yield call(
    userSubscriptionAPI.getData,
    completePayload,
  );

  yield put({
    type: userSubscriptionResult.toString(),
    payload: createResultV2(userSubscriptionRes),
  });
} // getData =========

export function* checkResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    // yield call(checkContract, {
    //  contract: okUserSubscriptionContract,
    //  data,
    //  direction: DIRECTION.B_F,
    // });

    yield put({
      type: userSubscriptionSuccess.toString(),
      payload: data,
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;
    // const { data } = error;

    // yield call(checkContract, {
    //  contract: errorUserSubscriptionContract,
    //  data,
    //  direction: DIRECTION.B_F,
    // });

    yield put({
      type: userSubscriptionFailure.toString(),
      payload: error,
    });
  }
} // checkResult =========

export function* putData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const userCurrentSubscriptionRes: any = yield call(
    userSubscriptionAPI.putData,
    completePayload,
  );
  // const { name, value } = completePayload;

  // yield call(checkContract, {
  //  contract: sendUserSubscriptionPutContract,
  //  data: { name, value },
  //  direction: DIRECTION.F_B,
  // });

  yield put({
    type: userSubscriptionPutResult.toString(),
    payload: createResultV2(userCurrentSubscriptionRes),
  });
} // putData =========

export function* checkResultPut(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const simpleData: any = yield select(simpleDataFromReducer);
    const { right: successData } = payload;
    const { data } = successData;

    // yield call(checkContract, {
    //  contract: okUserSubscriptionPutContract,
    //  data,
    //  direction: DIRECTION.B_F,
    // });

    const { name, value } = data;
    const newData = [...simpleData].map((item: ISubscriptionPut) => {
      const { name: innerName, value: innerValue } = item;

      if (name === innerName) {
        return {
          name,
          value,
        };
      }

      return {
        name: innerName,
        value: innerValue,
      };
    });

    yield put({
      type: userSubscriptionPutSuccess.toString(),
      payload: newData,
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;
    // const { data } = error;

    // yield call(checkContract, {
    //  contract: errorUserSubscriptionPutContract,
    //  data,
    //  direction: DIRECTION.B_F,
    // });

    yield put({
      type: userSubscriptionPutFailure.toString(),
      payload: error,
    });
  }
} // checkResultPut =========

// Root Saga
export default function* rootSaga() {
  yield takeLeading(
    userSubscriptionRequest,
    safe(onError, getData, { terminator: userSubscriptionFailure }),
  );

  yield takeEvery(userSubscriptionResult, safe(onError, checkResult));

  yield takeLatest(
    userSubscriptionPutRequest,
    safe(onError, putData, { terminator: userSubscriptionPutFailure }),
  );

  yield takeEvery(userSubscriptionPutResult, safe(onError, checkResultPut));
}
