import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';

import { recoveryPasswordAPI } from '../../../../services/RecoveryPassword/api';

import {
  recoveryPasswordPutRequest,
  recoveryPasswordPutSuccess,
  recoveryPasswordPutFailure,
  recoveryPasswordPutResult,
  recoveryPasswordPostRequest,
  recoveryPasswordPostSuccess,
  recoveryPasswordPostFailure,
  recoveryPasswordPostResult,
} from '../duck';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';

// NB: PUT это смена пароля,
// в форму отправляются новый пароль,
// его подтверждение и токен из СМС или email
export function* putData(action: any) {
  const { payload } = action;

  const srvRes = yield call(recoveryPasswordAPI.putDataForm, {
    ...payload,
  });

  yield put({
    type: recoveryPasswordPutResult.toString(),
    payload: createResultV2(srvRes),
  });
} // putData =========

// TODO: Добавить контракт
export function* checkResultPut(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: recoveryPasswordPutSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: recoveryPasswordPutFailure.toString(),
    });
  }
} // checkResultPut =========

// NB: POST это отправка запроса на получение токена для смены пароля
export function* postData(action: any) {
  const completePayload: any = yield call(checkToken, action);

  const srvRes = yield call(recoveryPasswordAPI.postDataLogin, {
    ...completePayload,
  });

  yield put({
    type: recoveryPasswordPostResult.toString(),
    payload: createResultV2(srvRes),
  });
} // postData =========

// TODO: Добавить контракт
export function* checkResultPost(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: recoveryPasswordPostSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: recoveryPasswordPostFailure.toString(),
    });
  }
} // checkResultPost =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    recoveryPasswordPutRequest,
    safe(onError, putData, {
      terminator: recoveryPasswordPutFailure,
    }),
  );

  yield takeEvery(recoveryPasswordPutResult, safe(onError, checkResultPut));

  yield takeLatest(
    recoveryPasswordPostRequest,
    safe(onError, postData, {
      terminator: recoveryPasswordPostFailure,
    }),
  );

  yield takeEvery(recoveryPasswordPostResult, safe(onError, checkResultPost));
}
