import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import { isLeft, isRight } from 'fp-ts/lib/Either';

import { login as loginAPI } from '../../../../services/Login/api';
import { setData as setToken } from '../../../../shared/utils/commonLocalStorage.helper';

import {
  loginGetJwtRequest,
  loginGetJwtSuccess,
  loginGetJwtFailure,
  loginGetJwtResult, loginSocialRequest, loginSocialFailure, loginSocialSuccess,
} from '../duck';
import { authSetAuthStatusSuccess } from '../../../Auth/state/duck';

import { LOCAL_STORAGE_AUTH_KEY } from '../../../../shared/constants/Defaults/constants';

import preparePhoneForAPI from '../../../../core/state/utils/preparePhoneForAPI/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResult } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';

// Sagas

export function* getJwtToken(action: any): IterableIterator<any> {
  const { payload } = action;
  const { login: authLogin } = payload;
  const preparedLogin = yield call(preparePhoneForAPI, {
    phone: authLogin,
  });

  const tempPayload = {
    ...payload,
    login: preparedLogin,
  };

  const serverResponse: any = yield call(loginAPI.postData, tempPayload);
  const { data, status } = serverResponse;

  yield put({
    type: loginGetJwtResult.toString(),
    payload: createResult(status, { data }),
  });
} // getJwtToken =========

export function* checkResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (isRight(payload)) {
    const { right: successData } = payload;
    const { data: wrappedData } = successData;
    const { data } = wrappedData;
    const { token } = data;

    yield call(setToken, LOCAL_STORAGE_AUTH_KEY, token);

    const isAuthorized = true;

    yield put({
      type: loginGetJwtSuccess.toString(),
      payload: data,
    });
    yield put({
      type: authSetAuthStatusSuccess.toString(),
      payload: {
        isAuthorized,
      },
    });
  }

  if (isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: loginGetJwtFailure.toString(),
      payload: error,
    });
  }
} // checkResult =========

export function* getSocialToken(action: any): IterableIterator<any> {
  const { payload } = action;
  const {
    token,
    phone = '',
    email = '',
  } = payload;

  const tempPayload = {
    access_token: token,
  };
  const { data }: any = yield call(loginAPI.postDataSocial, tempPayload);

  // пользователь найден, авторизируем его
  if (data.form.result && data.form.result.token) {
    const { token } = data.form.result;
    const isAuthorized = true;

    yield call(setToken, LOCAL_STORAGE_AUTH_KEY, token);

    yield put({
      type: loginGetJwtSuccess.toString(),
      payload: data.form,
    });
    yield put({
      type: authSetAuthStatusSuccess.toString(),
      payload: {
        isAuthorized,
        authToken: token,
      },
    });

    yield put({
      type: loginSocialSuccess.toString(),
      payload: {
        login: true,
        token,
      },
    });
    return;
  }

  // пользовательн е найден
  if (data.form && data.form.error === 'error_user_not_found') {
    yield put({
      type: loginSocialSuccess.toString(),
      payload: {
        register: true,
        phone,
        email,
        token,
      },
    });
  }
} // getSocialToken =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    loginGetJwtRequest,
    safe(onError, getJwtToken, { terminator: loginGetJwtFailure }),
  );
  yield takeEvery(loginGetJwtResult, safe(onError, checkResult));

  yield takeLatest(
    loginSocialRequest,
    safe(onError, getSocialToken, { terminator: loginSocialFailure }),
  );
}
