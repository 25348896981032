import { createSelector } from 'reselect';
import get from 'lodash/get';
import moment from 'moment';

export const isFetching = (store: any) => store.creditRatingReducer.isFetching;

export const data = createSelector(
  (store: any) => store.creditRatingReducer.data,
  data => data,
);

export const userRatingActual = createSelector(
  (store: any) => store.creditRatingReducer.data.userRatingScoringData,
  (userRatingScoringData = []) => {
    const tempUserRatingScoringData = [...userRatingScoringData];

    if (tempUserRatingScoringData.length === 0) {
      return undefined;
    }

    const { local_rating: localRating } = tempUserRatingScoringData[0];
    const result = localRating;

    return result;
  },
);

export const userRatingScoringNxtDateData = createSelector(
  (store: any) => store.creditRatingReducer.data.userRatingScoringNxtDateData,
  userRatingScoringNxtDateData => userRatingScoringNxtDateData,
);

export const userRatingActualExtend = createSelector(
  userRatingScoringNxtDateData,
  (store: any) => store.creditRatingReducer.data.userRatingScoringData,
  (userRatingScoringNxtDateData = '1900-01-01', userRatingScoringData = []) => {
    const tempUserRatingScoringData = [...userRatingScoringData];

    if (tempUserRatingScoringData.length === 0) {
      return {
        ficoCoefficient: -1,
        deltaCount: 0,
        date: '1900-01-01',
        nxtDate: '1900-01-01',
      };
    }

    const ficoCoefficient = get(
      tempUserRatingScoringData,
      '[0].local_rating',
      0,
    );
    const date = get(tempUserRatingScoringData, '[0].month', '1900-01-01');
    const prevFicoCoefficient = get(
      tempUserRatingScoringData,
      '[1].local_rating',
      0,
    );
    const result = {
      ficoCoefficient,
      deltaCount: ficoCoefficient - prevFicoCoefficient,
      date,
      nxtDate: userRatingScoringNxtDateData,
    };

    return result;
  },
);

export const allUsersRatingAverageScoringData = createSelector(
  (store: any) =>
    store.creditRatingReducer.data.allUsersRatingAverageScoringData,
  (allUsersRatingAverageScoringData = []) => {
    let result: any = [];
    for (let i = 0; i < 10; i++) {
      const temp = get(allUsersRatingAverageScoringData, `[${i}]`, {
        rating: 0,
        month: `2000-0${9 - i}-01`,
      });

      result = [...result, temp];
    }

    return result;
  },
);

export const userRatingScoringDataGraph = createSelector(
  (store: any) => store.creditRatingReducer.data.userRatingScoringData,
  (userRatingScoringData = []) => {
    let result: any = [];
    for (let i = 0; i < 10; i++) {
      const temp = get(userRatingScoringData, `[${i}]`, {
        local_rating: 0,
        month: '2000-01-01',
      });

      result = [...result, temp];
    }

    return result;
  },
);

export const dataGraph = createSelector(
  allUsersRatingAverageScoringData,
  userRatingScoringDataGraph,
  (allUsersRatingAverageScoringData, userRatingScoringDataGraph) => {
    let result: any = [];
    for (let i = 0; i < 10; i++) {
      const monthYearDate = get(
        allUsersRatingAverageScoringData,
        `[${i}].month`,
        `20.0${i}`,
      );
      const allUsersRating = get(
        allUsersRatingAverageScoringData,
        `[${i}].rating`,
        0,
      );
      const customerRating = get(
        userRatingScoringDataGraph,
        `[${i}].local_rating`,
        0,
      );

      const temp = {
        monthYearDate:
          monthYearDate.length > 4
            ? moment(monthYearDate, 'YYYY-MM-DD').format('YY.MM')
            : monthYearDate,
        allUsersRating,
        customerRating,
        fullDate: moment(monthYearDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      };

      result = [...result, temp];
    }

    return result.reverse();
  },
);

export const offersTabsData = createSelector(
  (store: any) => store.creditRatingReducer.data.offersTabsData,
  offersTabsData => offersTabsData,
);

export const offersList = createSelector(
  (store: any) => store.creditRatingReducer.data.offersList,
  offersList => offersList,
);

export const detailsData = createSelector(
  (store: any) => store.creditRatingReducer.data.detailsData,
  (detailsData) => {
    const rating = get(detailsData, 'rating', 0) || 0;
    const percentage = get(detailsData, 'percentage', 0) || 0;
    const ratingScale = get(detailsData, 'rating_scale', []) || [];
    const ratingDiff = get(detailsData, 'rating_diff', 0) || 0;
    const hide = get(detailsData, 'hide', false) || false;
    const hasStopFactor = get(detailsData, 'has_stop_factor', 0) || 0;
    const isBankruptcyOfferAvailable = get(detailsData, 'is_bankruptcy_offer_available', false) || false;
    const serviceMessage = get(detailsData, 'service_message', '') || '';

    const descriptionRating = (ratingScale.map((item: any) => {
      const isIncludes = (rating >= Number(item.from) && rating <= Number(item.to));
      return isIncludes && item;
    }).filter(Boolean)[0] || {}).description_mark;

    const result = {
      rating,
      percentage,
      ratingScale,
      ratingDiff,
      hide,
      hasStopFactor,
      isBankruptcyOfferAvailable,
      serviceMessage,
      descriptionRating,
    };

    return result;
  },
);

export const shareData = createSelector(
  (store: any) => store.creditRatingReducer.data.shareData,
  (shareData) => shareData || {
    url: 'https://financo.com/',
  },
);

// Status

export const isFetchingStatus = (store: any) =>
  store.creditRatingReducer.isFetchingStatus;

export const dataStatus = createSelector(
  (store: any) => store.creditRatingReducer.dataStatus,
  dataStatus => {
    const result = get(dataStatus, 'data', { status: null }) || {
      status: null,
    };

    return result;
  },
);

export const isCached = createSelector(
  (store: any) => store.creditRatingReducer.isCached,
  isCached => isCached,
);

export const isFetchingBankruptcy = createSelector(
  (store: any) => store.creditRatingReducer.isFetchingBankruptcy,
  isFetchingBankruptcy => isFetchingBankruptcy,
);
