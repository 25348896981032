/* eslint-disable no-unused-expressions */

export const APP_ID = 'lljjwgjs';

enum IntercomSelect {
  BUTTON = '.intercom-launcher',
  CONTAINER = '#intercom-container',
  LAYOUT = '.intercom-messenger-frame',
  IFRAME = '.intercom-launcher-frame'
}

export const load = () => {
  // eslint-disable-next-line no-var,vars-on-top,prefer-rest-params,func-names
  (() => { let w = window as any; let ic = w.Intercom; if (typeof ic === 'function') { ic('reattach_activator'); ic('update', w.intercomSettings); } else { let d = document; var i: any = function () { i.c(arguments); }; i.q = []; i.c = function (args: any) { i.q.push(args); }; w.Intercom = i; let l = function () { let s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = `https://widget.intercom.io/widget/${APP_ID}`; let x = d.getElementsByTagName('script')[0] as any; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();

  const interval = setInterval(() => {
    const intercom = document.querySelector(IntercomSelect.BUTTON) as HTMLDivElement;
    const helpD = document.querySelector('#help_d') as HTMLDivElement;
    const helpM = document.querySelector('#help_m') as HTMLDivElement;
    if (intercom && (helpD || helpM)) {
      clearInterval(interval);

      intercom.style.right = '-200px';

      const clickHelp = () => {
        intercom.dispatchEvent(new Event('click'));
        const iframe = document.querySelector(IntercomSelect.IFRAME) as HTMLIFrameElement;
        if (iframe) {
          iframe.style.display = 'block';
        }

        const a = setInterval(() => {
          const iframe = document.querySelector(IntercomSelect.IFRAME) as HTMLIFrameElement;
          const container = document.querySelector(IntercomSelect.LAYOUT) as HTMLIFrameElement;

          if (iframe && (window.innerWidth <= 450 || !container)) {
            clearInterval(a);
            iframe.style.display = 'none';
          }
        }, 200);
      };

      helpD && helpD.addEventListener('click', clickHelp);
      helpM && helpM.addEventListener('click', clickHelp);
    }
  }, 200);
};
load();

export const update = () => {
  const w = window as any;
  w && w.Intercom && w.Intercom('update');
};

export const shutdown = () => {
  const w = window as any;
  w && w.Intercom && w.Intercom('shutdown');
};

export const boot = (options = {}) => {
  const w = window as any;
  w && w.Intercom && w.Intercom('boot', { app_id: APP_ID, ...options });
};

export const Intercom = {
  load,
  update,
  shutdown,
  boot,
};
