import { createStore } from 'effector';
import { $getMfoAll, $getMfo, $addMfo, $getCabinetsMfo, $getTotalMfo } from './event';
import { APIError } from '../../core/api/Result';
import { arrayWithObjectToCamelCase, objectToCamelCase } from '../../shared/utils/object.helper';
import { GetMfoAllRes, GetMfoRes, PostAddMfoRes, GetCabinetsMfoRes, GetTotalMfoRes } from '../../services/Multilogin/api';

export const $mfoAll = createStore<GetMfoAllRes>([])
  .on($getMfoAll.done, (_, { result }) => arrayWithObjectToCamelCase(result.data));
export const $mfoAllError = createStore<Partial<APIError>>({})
  .on($getMfoAll.fail, (_, { error }) => error.errors);
export const $isLoadingMfoAll = createStore(false)
  .on($getMfoAll.pending, (_, pending) => pending);

export const $mfo = createStore<GetMfoRes | null>(null)
  .on($getMfo.done, (_, { result }) => objectToCamelCase(result.data));
export const $mfoError = createStore<Partial<APIError>>({})
  .on($getMfo.fail, (_, { error }) => error.errors);
export const $isLoadingMfo = createStore(true)
  .on($getMfo.pending, (_, pending) => pending);

export const $mfoAdd = createStore<PostAddMfoRes | null>(null)
  .on($addMfo.done, (_, { result }) => objectToCamelCase(result.data));
export const $mfoAddError = createStore<Partial<APIError>>({})
  .on($addMfo.fail, (_, { error }) => error.errors);
export const $isLoadingMfoAdd = createStore(false)
  .on($addMfo.pending, (_, pending) => pending);

export const $mfoCabinets = createStore<GetCabinetsMfoRes>([])
  .on($getCabinetsMfo.done, (_, { result }) => arrayWithObjectToCamelCase(result.data));
export const $mfoCabinetsError = createStore<Partial<APIError>>({})
  .on($getCabinetsMfo.fail, (_, { error }) => error.errors);
export const $isLoadingMfoCabinets = createStore(false)
  .on($getCabinetsMfo.pending, (_, pending) => pending);

export const $mfoTotal = createStore<Partial<GetTotalMfoRes>>({})
  .on($getTotalMfo.done, (_, { result }) => objectToCamelCase(result.data));
export const $mfoTotalError = createStore<Partial<APIError>>({})
  .on($getTotalMfo.fail, (_, { error }) => error.errors);
export const $isLoadingMfoTotal = createStore(false)
  .on($getTotalMfo.pending, (_, pending) => pending);
