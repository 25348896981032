import { paymentRatingUpdateRequests } from '../../../Payment/state/duck';
import { userDebtsUpdateResult } from '../../../UserDebts/state/duck';
import { userTaskInstructionsApiOffer } from '../../../UserTask/state/duck';

// Список экшенов c результатами из АПИ
// для отслеживания системой уведомлений
export const resultsActions = Object.freeze({
  // Payment
  [paymentRatingUpdateRequests.toString()]: paymentRatingUpdateRequests.toString(),
  [userDebtsUpdateResult.toString()]: userDebtsUpdateResult.toString(),
  [userTaskInstructionsApiOffer.toString()]: userTaskInstructionsApiOffer.toString(),
});
