import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

import styles from './MultiLogin.module.scss';

import {
  CABINET__MULTTILOGIN_MY_URL,
  CABINET__MULTTILOGIN_URL,
} from '../../shared/constants/Routes/constants';

import RootLayout from '../RootLayout';
import Question from '../../../assets/images/Question';
import MultiloginMy from '../../shared/componets/MultiLogin/MultiloginMy';
import MultiloginMfo from '../../shared/componets/MultiLogin/MultiloginMfo';
import MultiloginAddMfoModal from '../../shared/componets/MultiLogin/MultiloginAddMfoModal';

const MultiloginController = () => {
  return (
    <RootLayout>
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <Translate id="multilogin.title" />
          </div>
          <div className={styles.how}>
            <div className={styles.work}>
              <Translate id="shared.componets.sidebar.howitworks" />
            </div>
            <Question />
          </div>
        </div>
        <div className={styles.subtitle}>
          <Translate id="multilogin.description" />
        </div>

        <Switch>
          <Route exact path={CABINET__MULTTILOGIN_URL} component={MultiloginMfo} />

          <Route exact path={CABINET__MULTTILOGIN_MY_URL} component={MultiloginMy} />

          <Redirect path={`${CABINET__MULTTILOGIN_MY_URL}*`} to={`${CABINET__MULTTILOGIN_URL}`} />
        </Switch>

        <MultiloginAddMfoModal />
      </div>
    </RootLayout>
  );
};

export default MultiloginController;
