import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { logoutSuccess } from '../../../Logout/state/duck';

// Actions ==========================

const SELECTION_LOAN_PRODUCT = 'SELECTION_LOAN_PRODUCT';

// Запрос информации по разделу "Подбор кредитного продукта" из АПИ
export const selectionLoanProductRequest = createAction(
  `${SELECTION_LOAN_PRODUCT}/REQUEST`,
);
export const selectionLoanProductSuccess = createAction(
  `${SELECTION_LOAN_PRODUCT}/SUCCESS`,
);
export const selectionLoanProductFailure = createAction(
  `${SELECTION_LOAN_PRODUCT}/FAILURE`,
);

// Запрос отфильтрованного списка предложений из АПИ
export const selectionLoanProductFilteredListRequest = createAction(
  `${SELECTION_LOAN_PRODUCT}/FILTERED_LIST_REQUEST`,
);
export const selectionLoanProductFilteredListSuccess = createAction(
  `${SELECTION_LOAN_PRODUCT}/FILTERED_LIST_SUCCESS`,
);
export const selectionLoanProductFilteredListFailure = createAction(
  `${SELECTION_LOAN_PRODUCT}/FILTERED_LIST_FAILURE`,
);

// Запрос nested тегов из АПИ
export const selectionLoanProductTagsNestedRequest = createAction(
  `${SELECTION_LOAN_PRODUCT}/TAGS_NESTED_REQUEST`,
);
export const selectionLoanProductTagsNestedSuccess = createAction(
  `${SELECTION_LOAN_PRODUCT}/TAGS_NESTED_SUCCESS`,
);
export const selectionLoanProductTagsNestedFailure = createAction(
  `${SELECTION_LOAN_PRODUCT}/TAGS_NESTED_FAILURE`,
);

export const selectionLoanProductCancelRequest = createAction(
  `${SELECTION_LOAN_PRODUCT}/CANCEL_REQUEST`,
);
export const selectionLoanProductResetFilters = createAction(
  `${SELECTION_LOAN_PRODUCT}/RESET_FILTERS`,
);

// Reducers ==========================

// Статус запроса данных из АПИ
const isFetching = createReducer(false, {
  [selectionLoanProductRequest.toString()]: () => true,
  [selectionLoanProductSuccess.toString()]: () => false,
  [selectionLoanProductFailure.toString()]: () => false,
  [selectionLoanProductCancelRequest.toString()]: () => false,
});

// Статус отображения установлен фильтр или нет
const isFilterSettingUp = createReducer(false, {
  [selectionLoanProductRequest.toString()]: () => false,
  [selectionLoanProductSuccess.toString()]: () => false,
  [selectionLoanProductFailure.toString()]: () => false,
  [selectionLoanProductFilteredListRequest.toString()]: () => true,
  [selectionLoanProductFilteredListSuccess.toString()]: () => true,
  [selectionLoanProductFilteredListFailure.toString()]: () => false,
  [selectionLoanProductResetFilters.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isFetchingFilteredList = createReducer(false, {
  [selectionLoanProductFilteredListRequest.toString()]: () => true,
  [selectionLoanProductFilteredListSuccess.toString()]: () => false,
  [selectionLoanProductFilteredListFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isFetchingNestedTags = createReducer(false, {
  [selectionLoanProductTagsNestedRequest.toString()]: () => true,
  [selectionLoanProductTagsNestedSuccess.toString()]: () => false,
  [selectionLoanProductTagsNestedFailure.toString()]: () => false,
});

// Успешный результат запроса из АПИ
const data = createReducer(
  {},
  {
    [selectionLoanProductSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

// Успешный результат запроса из АПИ
const dataFilteredList = createReducer(
  {},
  {
    [selectionLoanProductFilteredListRequest.toString()]: () => ({}),
    [selectionLoanProductFilteredListSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

// Успешный результат запроса nested tags из АПИ
const dataNestedTags = createReducer([], {
  [selectionLoanProductTagsNestedRequest.toString()]: () => [],
  [selectionLoanProductTagsNestedSuccess.toString()]: (_state, action) =>
    action.payload,
});

// Ошибка при запросе данных из АПИ
const error = createReducer([], {
  [selectionLoanProductSuccess.toString()]: () => [],
  [selectionLoanProductFailure.toString()]: (_state, action) => action.payload,
});

// Ошибка при запросе данных из АПИ
const errorFilteredList = createReducer([], {
  [selectionLoanProductFilteredListRequest.toString()]: () => [],
  [selectionLoanProductFilteredListSuccess.toString()]: () => [],
  [selectionLoanProductFilteredListFailure.toString()]: (_state, action) =>
    action.payload,
});

// Ошибка при запросе nested tags из АПИ
const errorNestedTags = createReducer([], {
  [selectionLoanProductTagsNestedRequest.toString()]: () => [],
  [selectionLoanProductTagsNestedSuccess.toString()]: () => [],
  [selectionLoanProductTagsNestedFailure.toString()]: (_state, action) =>
    action.payload,
});

const isCached = createReducer(false, {
  [selectionLoanProductSuccess.toString()]: () => true,
  [logoutSuccess.toString()]: () => false,
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  error,
  isFetchingFilteredList,
  dataFilteredList,
  errorFilteredList,
  isFilterSettingUp,
  isFetchingNestedTags,
  dataNestedTags,
  errorNestedTags,
  isCached,
});

export default reducer;
