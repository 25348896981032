import { put, call, select, takeLeading } from 'redux-saga/effects';

import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { checkToken } from '../../../../core/state/utils/checkToken/saga';

import {
  initSocketConnection,
  watchSocketChan,
  createSocketConnection,
  createSocketChannel,
  terminateSocketChan,
} from './chanSaga';
import { socketSaveSocket } from '../duck';
import { authSetAuthStatusSuccess } from '../../../Auth/state/duck';
import { socket as socketSelector } from '../selectors';
import { socketCallback as chatListSupportSocketCallback } from '../../../ChatListSupport/state/saga';
import { socketCallback as chatListLawyerSocketCallback } from '../../../ChatListLawyer/state/saga';

import {
  API_SUPPORT_MESSAGES_SOCKET_AUTH,
  API_LAWYER_MESSAGES_SOCKET_AUTH,
} from '../../../../shared/constants/Routes/constants';
import {
  SUPPORT_MESSAGES_CHAN,
  LAWYER_MESSAGES_CHAN,
} from '../../../../shared/constants/Socket/constants';

import { IInitData } from '../../../../models/Socket';

// Sagas

export function* createWebSocketChannels() {
  const completePayload: any = yield call(checkToken, {});
  const { token } = completePayload;

  const res: IInitData | any = yield call(initSocketConnection, {
    userToken: token,
    path: API_SUPPORT_MESSAGES_SOCKET_AUTH,
  });
  const { status, data } = res;

  // NB: Внимание! Прежде чем создавать канал с сокетами, его нужно очистить
  if (status === 'SUCCESS') {
    const scktSelector: any = yield select(socketSelector);

    yield call(terminateSocketChan, SUPPORT_MESSAGES_CHAN);

    yield put({
      type: socketSaveSocket.toString(),
      payload: {},
    });

    const socket = yield call(createSocketConnection, data);
    const channel = yield call(createSocketChannel, {
      data,
      socket,
      outerCallback: chatListSupportSocketCallback,
    });

    yield put({
      type: socketSaveSocket.toString(),
      payload: {
        ...scktSelector,
        [SUPPORT_MESSAGES_CHAN]: socket,
      },
    });

    yield call(watchSocketChan, channel);
  }
} // createWebSocketChannels =========

export function* createWebSocketChannelsLawyer() {
  const completePayload: any = yield call(checkToken, {});
  const { token } = completePayload;

  const res: IInitData | any = yield call(initSocketConnection, {
    userToken: token,
    path: API_LAWYER_MESSAGES_SOCKET_AUTH,
  });
  const { status, data } = res;

  // NB: Внимание! Прежде чем создавать канал с сокетами, его нужно очистить
  if (status === 'SUCCESS') {
    const scktSelector: any = yield select(socketSelector);

    yield call(terminateSocketChan, LAWYER_MESSAGES_CHAN);

    yield put({
      type: socketSaveSocket.toString(),
      payload: {},
    });

    const socket = yield call(createSocketConnection, data);
    const channel = yield call(createSocketChannel, {
      data,
      socket,
      outerCallback: chatListLawyerSocketCallback,
    });

    yield put({
      type: socketSaveSocket.toString(),
      payload: {
        ...scktSelector,
        [LAWYER_MESSAGES_CHAN]: socket,
      },
    });

    yield call(watchSocketChan, channel);
  }
} // createWebSocketChannelsLawyer =========

// Root Saga
export default function* rootSaga() {
  yield takeLeading(
    authSetAuthStatusSuccess,
    safe(onError, createWebSocketChannels),
  );

  yield takeLeading(
    authSetAuthStatusSuccess,
    safe(onError, createWebSocketChannelsLawyer),
  );
}
