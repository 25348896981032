import { put, call, take, CallEffect, fork, select } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import * as E from 'fp-ts/lib/Either';
import Centrifuge from 'centrifuge';
import get from 'lodash/get';
import omit from 'lodash/omit';

import { socketAPI } from '../../../../services/Socket/api';

import { socketInitDataContract } from '../../../../contracts/Socket/initRes';

import { socketSaveSocket } from '../duck';
import { socket as socketSelector } from '../selectors';

import {
  IPayload,
  IData,
  IRes,
  IInitData,
  InitStatusEnum,
} from '../../../../models/Socket';
import { AxiosResponse } from 'axios';

import { environment } from '../../../../../environment';

/**
 * Создает канал событий для указанного подключения к сокет серверу
 *
 * @param {{ data: any; socket: any }} { data, socket }
 * @returns
 */
export function createSocketChannel({
  data,
  socket,
  outerCallback,
}: {
  data: any;
  socket: any;
  outerCallback: (params: any) => void;
}) {
  const { token, channel } = data;

  return eventChannel((emit: any) => {
    socket.setToken(token);

    const publishHandlerFunction = (message: any) => {
      outerCallback({ message, emit });
    };

    const subscribeErrorHandlerFunction = (errorEvent: any) => {
      emit(new Error(errorEvent.reason));
    };

    const subscription = socket.subscribe(channel);

    subscription.on('publish', publishHandlerFunction);
    subscription.on('error', subscribeErrorHandlerFunction);

    socket.connect();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    socket.on('disconnect', (_context: any) => {
      emit(END);
    });

    // unsubscribe
    return () => {
      socket.disconnect();
    };
  });
}

/**
 * Получает инициализационные данные о сокет сервере из АПИ,
 * для последующего создания соединения с сокет сервером
 *
 * @export
 * @param {IPayload} payload
 * @returns {(Generator<CallEffect<Promise<AxiosResponse<any>> | null>, IInitData, IRes>)}
 */
export function* initSocketConnection(
  payload: IPayload,
): Generator<CallEffect<Promise<AxiosResponse<any>> | null>, IInitData, IRes> {
  const { userToken: token, path } = payload;
  const socketInitData: IRes = yield call(socketAPI.postDataInitSocket, {
    token,
    path,
  });
  const { data } = socketInitData;
  const contractStatus = socketInitDataContract.decode(data);

  if (E.isLeft(contractStatus)) {
    // NB: Разрешенный игнор
    // eslint-disable-next-line
    console.log('Socket init: contract error, path:', path);
    return { status: InitStatusEnum.ERROR };
  }

  return { status: InitStatusEnum.SUCCESS, data };
}

/**
 * Создать подключение к сокет серверу.
 *
 * Клиент сокет сервера: Centrifuge
 *
 * @export
 * @param {IData} data
 * @returns
 */
export function createSocketConnection(data: IData) {
  const { socket } = data;
  const { proto, host, port } = socket;
  const suffix = environment.WEB_SOCKET_SUFFIX;
  const centrifuge = new Centrifuge(`${proto}${host}:${port}${suffix}`);

  return centrifuge;
}

/**
 * Отключиться от сокет сервера, а так же закрыть канал `eventChannel`
 *
 * @export
 * @param {string} socketChanName
 */
export function* terminateSocketChan(socketChanName: string) {
  const scktSelector = yield select(socketSelector);
  const centrifuge =
    get(scktSelector, `${socketChanName}`, 'NO_CHAN') || 'NO_CHAN';

  if (centrifuge === 'NO_CHAN') {
    return null;
  }

  centrifuge.disconnect();

  const tmpScktData = omit(scktSelector, `${socketChanName}`);

  yield put({
    type: socketSaveSocket.toString(),
    payload: {
      ...tmpScktData,
    },
  });
}

/**
 * Вотчер.
 *
 * Следит за каналом принимая сообщения от сокет сервера или команду останова.
 *
 * Прерывается через вызов функции terminateSocketChan из других саг:
 * `yield call(terminateSocketChan, `${channelName}`);`
 *
 * @export
 * @param {} chanName
 */
export function* watchSocketChan(chanName: any) {
  while (true) {
    try {
      const action = yield take(chanName);
      yield put(action);
      yield fork(terminateSocketChan, '');
    } catch (err) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('socket error:', err);
      chanName.close();
    }
  }
}
