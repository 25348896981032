import React from 'react';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';

import UserSettingsChangePswdForm from '../UserSettingsChangePswdForm';
// import UserSettingsChangeSMSForm from '../UserSettingsChangeSMSForm';
import UserSettingsLogsForm from '../UserSettingsLogsForm';
import UserSettingsChangeLanguageForm from '../UserSettingsChangeLanguageForm';
import UserSettingsRemoveAccauntForm from '../UserSettingsRemoveAccauntForm';

import { DEVICE_NETBOOKS_WIDTH } from '../../constants/Defaults/constants';
import { useWindowSize } from '../../hooks/useWindowSize';

import styles from './UserSettingsSettings.module.scss';

/**
 * Окно настройки: Настройки
 *
 * @returns
 */

interface IProps {
  handleModalStatusChange: any;
}

const UserSettingsSettings: React.FC<IProps> = (props) => {
  const windowSize = useWindowSize();
  const { width = 0 } = windowSize;

  const { handleModalStatusChange } = props;

  // По умолчанию считаем что запущено на десктопе, мобила = false
  let isMobile = false;

  if (width < DEVICE_NETBOOKS_WIDTH) {
    isMobile = true;
  }

  return (
    <>
      {isMobile ? (
        ''
      ) : (
        <div className={cx(styles.header)}>
          <div className={cx(styles.headerText)}>
            <Translate id="shared_containers_usersettings_settings" />
          </div>
        </div>
      )}
      <div className={cx(styles.subHeader)}>
        <div className={cx(styles.subHeaderText)}>
          <Translate id="shared_containers_usersettings_settings_subheader" />
        </div>
      </div>

      <div className={cx(styles.wrapperBloks)}>
        <div className={cx(styles.formWrapper)}>
          <UserSettingsChangePswdForm />
        </div>
        {/* <div className={cx(styles.formWrapper)}>
          <UserSettingsChangeSMSForm />
        </div> */}
        <div className={cx(styles.formWrapper)}>
          <UserSettingsLogsForm />
        </div>
        <div className={cx(styles.formWrapper)}>
          <UserSettingsChangeLanguageForm />
        </div>
        <div className={cx(styles.formWrapper)}>
          <UserSettingsRemoveAccauntForm
            handleModalStatusChange={handleModalStatusChange}
          />
        </div>
      </div>
    </>
  );
};

export default UserSettingsSettings;
