import React from 'react';
import styles from '../PaymentCardBinding/PaymentCardBinding.module.scss';
import { Translate } from 'react-localize-redux';
import { Button } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../componets/Input';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeProfileRequest } from '../../../states/UserProfile/state/duck';
import { SIGNUP_URL } from '../../constants/Routes/constants';

interface IFormInputs {
  removeCard: string;
}

const initState = {
  inputWorld: '',
};

const RemovingModal: React.FC = () => {
  const methods = useForm<IFormInputs>({ mode: 'all' });
  const {
    control,
    formState,
  } = methods;
  const { errors } = formState;
  const history = useHistory();
  const [state, setState] = React.useState(initState);
  const { inputWorld } = state;
  const dispatch = useDispatch();

  function handleChangeState(name: string, value: string | number | boolean) {
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  return (
    <>
      {/* Form */}
      <>
        <div className={styles.header}>
          <h2>
            <Translate id="shared_containers_removecard_header" />
          </h2>
        </div>

        <form>
          {/* Card number */}
          <div className={styles.input}>
            <Controller
              name="removeCard"
              defaultValue=""
              control={control}
              render={() => (
                <Input
                  type="text"
                  label=""
                  placeholder=""
                  isSuffix={false}
                  suffix=""
                  value={inputWorld}
                  onChange={(e: any) => {
                    handleChangeState('inputWorld', e.currentTarget.value);
                  }}
                />
              )}
            />
            <div className={styles.errorMessage}>
              {errors?.removeCard?.message && (
                <Translate id={`${errors?.removeCard?.message}`} />
              )}
            </div>
          </div>

          <div className={styles.button}>
            <Button
              onClick={() => {
                if (inputWorld.toLowerCase() === 'удалить' || inputWorld.toLowerCase() === 'delete') {
                  dispatch({ type: removeProfileRequest.toString() });
                  history.push(SIGNUP_URL);
                }
              }}
              type="primary"
              shape="round"
              style={{
                height: '43px',
                width: '100%',
              }}
            >
              <Translate id="shared_containers_removecard_remove" />{' '}
            </Button>
          </div>
        </form>
      </>
    </>
  );
};

export default RemovingModal;
