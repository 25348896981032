import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { Translate, withLocalize } from 'react-localize-redux';
import Preloader from '../../componets/Preloader';
import FormWrapper from '../../componets/FormWrapper';

import {
  isFetching as isFetchingSelector,
} from '../../../states/UserSettingsLogsForm/state/selectors';

import styles from './UserSettingsChangeLanguageForm.module.scss';

import checkImg from '../../../../assets/images/check.svg';
import { languagesSetAppActiveLangRequest } from '../../../states/Languages/state/duck';
import { list } from '../../../states/Languages/state/selectors';

/**
 * Форма со списком языков для смены
 *
 * @returns
 */
const UserSettingsLogsForm: React.FC<any> = (props) => {
  const isFetching = useSelector(isFetchingSelector);
  const languages = useSelector(list);
  const dispatch = useDispatch();

  const {
    activeLanguage: { code: defaultValue = 'ru' },
  } = props;

  const handleChangeAppLang = (code: any) => {
    dispatch({
      type: languagesSetAppActiveLangRequest.toString(),
      payload: { lang: code },
    });
  };

  return (
    <>
      <Translate>
        {({ translate }) => (
          <FormWrapper
            name={`${translate(
              'shared_containers_usersettingsprofile_security_language',
            ).toString()}`}
          >
            <div>
              <>
                {isFetching ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Preloader size="large" />
                  </div>
                ) : (
                  <>
                    <div className={cx(styles.listLanguage)}>
                      {languages.map(({ name, title, code, flagImage }: Record<string, string>) => (
                        <div
                          className={cx(
                            styles.listLanguage__item,
                            defaultValue === code && styles.listLanguage__select,
                          )}
                          role="button"
                          tabIndex={0}
                          onClick={() => handleChangeAppLang(code)}
                          onKeyPress={() => handleChangeAppLang(code)}
                          key={code}
                        >
                          <div className={styles.listLanguage__item_img}>
                            <img height={20} width={20} src={flagImage} alt="" />
                          </div>
                          <div className={styles.listLanguage__item_name}>
                            {name || title}
                          </div>
                          <div className={styles.listLanguage__item_check}>
                            <img src={checkImg} alt="check" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            </div>
          </FormWrapper>
        )}
      </Translate>
    </>
  );
};

export default withLocalize(UserSettingsLogsForm);
