import React from 'react';
import { createTextMask } from 'redux-form-input-masks';
import { Translate } from 'react-localize-redux';
import { Col, Row } from 'antd';
import cx from 'classnames';

import styles from './FieldsWrapper.module.scss';

import OfferAllFields from '../../componets/OfferAllFields';

interface IProps {
  categoryFields: [string, unknown][],
  methods: Record<string, any>,
  handlerBlur: any,
}

const FieldsWrapper: React.FC<IProps> = (props) => {
  const {
    categoryFields,
    methods,
    handlerBlur,
  } = props;
  const {
    watch,
    getValues,
    control,
  } = methods;

  const [address, setAddress] = React.useState(
    {
      region: '',
      сity: '',
      street: '',
      house: '',
      region_fias_id: '',
      city_fias_id: '',
      street_fias_id: '',
    },
  );
  const changeAddress = (value: any, label: any, field: any) => {
    const name = `${value}_fias_id`;
    setAddress({ ...address, [value]: label, [name]: field });
  };

  return (
    <Translate>
      {({ translate }) => (
        <>
          {categoryFields.map((category: Array<any>, keyIdx: number) => {
            const categoryName = category[0];

            return (
              <div key={`category_${keyIdx + 10}`}>
                <div className={cx(styles.title)}>
                  <Translate id={categoryName.toLowerCase()} />
                </div>
                <Row gutter={15}>
                  {category[1].map((item: [any, any]) => {
                    const [key, field] = item;
                    const name = field?.name;
                    const isRequired = field?.rules?.required ?? false;
                    const placeholder = field?.placeholder || `eg.${name}.placeholder`;
                    const title = field?.title || `eg.${name}.title`;
                    const patternField = field?.mask;
                    const errors = { [name]: { message: field?.error_messages[0] } };
                    const offerCompendiums =
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  Object.entries(field?.values).map(([id, value]: any) => (
                    { id, value }),
                  );

                    const value = field?.value ?? undefined;

                    const mask: any = patternField ? createTextMask({
                      pattern: patternField.replace(/[0]/g, '9'),
                      guide: false,
                      stripMask: false,
                    }) : null;

                    // eslint-disable-next-line no-restricted-syntax
                    for (const el of field.visible_conditions) {
                      const value = watch(el.target_field_name);
                      if (value !== el.target_field_value) {
                        return null;
                      }
                    }

                    return (
                      <Col
                        key={key}
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={12}
                        className={styles.field}
                      >
                        <OfferAllFields
                          name={name}
                          field={field}
                          errors={errors}
                          address={address}
                          label={translate(title.toLowerCase()).toString()}
                          changeAddress={changeAddress}
                          mask={mask}
                          patternField={patternField}
                          placeholder={translate(placeholder.toLowerCase()).toString()}
                          getValues={getValues}
                          offerCompendiums={offerCompendiums}
                          isRequired={isRequired}
                          control={control}
                          keyValue={key}
                          defaultValue={value}
                          onBlur={handlerBlur}
                        />
                      </Col>
                    );
                  })
              }
                </Row>
              </div>);
          })}
        </>
      )}
    </Translate>
  );
};

export default FieldsWrapper;
