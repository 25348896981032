import { createSelector } from 'reselect';
import { get } from 'lodash';

export const isFetching = (store: any) => store.userLimitsReducer.isFetching;

export const data = createSelector(
  (store: any) => store.userLimitsReducer.data,
  data => data,
);

export const limitsData = createSelector(
  data,
  (data) => {
    const { limitsData } = data;
    const debt = get(limitsData, 'debt_requests', 0) || 0;
    const rating = get(limitsData, 'rating_requests', 0) || 0;
    const lawyer = get(limitsData, 'lawyer_requests', 0) || 0;

    return { debt, rating, lawyer };
  },
);
