import { createSelector } from 'reselect';

// Login
export const isFetching = (store: any) => store.loginReducer.isFetching;

export const data = createSelector(
  (store: any) => store.loginReducer.data,
  data => data,
);

export const error = createSelector(
  (store: any) => store.loginReducer.error,
  error => error,
);

export const dataSocial = createSelector(
  (store: any) => store.loginReducer.dataSocial,
  dataSocial => dataSocial,
);

export const redirect = createSelector(
  (store: any) => store.loginReducer.redirect,
  redirect => redirect,
);
