import styled from 'styled-components';
import { Button } from 'antd';

export const ButtonStyled = styled(Button)`
  height: 100%;
  border: 0;
  border-right: 1px solid rgba(214, 220, 227, 0.4);
  box-shadow: none;
  padding: 0 20px;

  img {
    margin-right: 10px;
  }

  p {
    font-size: 11px;
    color: #9CA5B0;
    margin-bottom: 3px;
  }

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    border-bottom: 0 !important;
    border-left: 0 !important;
    box-shadow: none !important;
    color: rgba(0, 0, 0, 0.65) !important;
    border-color: rgba(214, 220, 227, 0.4);
  }

  @media (max-width: 1199.98px) {
    padding: 0;
    margin-right: 32px;
    border-right: 0;

    p {
      margin-bottom: 2px;
    }
  }

  /* IDEA: пока это не ссылка, то не нужно устанавливать отступ сверху */
  /* line-height: 1.5715 !important;
  padding-top: 11px !important; */
`;
