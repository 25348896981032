import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * Offer form API
 */
export default {
  /**
   * Получить данные формы
   *
   * @returns
   */
  getData(data: any) {
    const { token, offerId } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/credit-products/request-offer/${offerId}`,
    );
  },

  /**
   * Отправить данные формы для изменения пароля
   *
   * @returns
   */
  postData(data: any) {
    const { token, offerId, ...body } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/credit-products/request-offer/${offerId}`,
      body,
    );
  },
};
