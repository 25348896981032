import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';

import { userSettingsChangeDataAPI } from '../../../../services/UserSettingsChangeData/api';

import {
  userSettingsChangeDataRequest,
  userSettingsChangeDataResult,
  userSettingsChangeDataSuccess,
  userSettingsChangeDataFailure,
  userSettingsChangeDataPutRequest,
  userSettingsChangeDataPutSuccess,
  userSettingsChangeDataPutFailure,
  userSettingsChangeDataPutResult,
} from '../duck';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';
import { userProfileRequest } from '../../../UserProfile/state/duck';

export function* getData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const srvRes = yield call(userSettingsChangeDataAPI.getData, {
    ...completePayload,
  });

  yield put({
    type: userSettingsChangeDataResult.toString(),
    payload: createResultV2(srvRes),
  });
} // getData =========

// TODO:  Реализовать проверку контракта! Формы
export function* checkResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: userSettingsChangeDataSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: userSettingsChangeDataFailure.toString(),
      payload: error,
    });
  }
} // checkResult =========

export function* putData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const srvRes = yield call(userSettingsChangeDataAPI.putData, {
    ...completePayload,
  });

  yield put({
    type: userSettingsChangeDataPutResult.toString(),
    payload: createResultV2(srvRes),
  });
} // putData =========

// TODO: Добавить контракт
export function* checkResultPut(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: userSettingsChangeDataPutSuccess.toString(),
      payload: { data },
    });

    yield put({
      type: userProfileRequest.toString(),
      payload: { nonCached: true },
    });
  }

  if (E.isLeft(payload)) {
    const { left: errorData } = payload;
    const { data } = errorData;

    yield put({
      type: userSettingsChangeDataPutFailure.toString(),
      payload: { data },
    });
  }
} // checkResultPut =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    userSettingsChangeDataRequest,
    safe(onError, getData, {
      terminator: userSettingsChangeDataFailure,
    }),
  );

  yield takeEvery(userSettingsChangeDataResult, safe(onError, checkResult));

  yield takeLatest(
    userSettingsChangeDataPutRequest,
    safe(onError, putData, {
      terminator: userSettingsChangeDataPutFailure,
    }),
  );

  yield takeEvery(
    userSettingsChangeDataPutResult,
    safe(onError, checkResultPut),
  );
}
