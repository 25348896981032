import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';

import styles from './InputUniversal.module.scss';
import { ControllerRenderProps } from 'react-hook-form';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import cx from 'classnames';
import SelectUniversal from './components/SelectUniversal';
import DadataUniversal from './components/DadataUniversal';
import MaskedInput from 'react-input-mask';
import DatePicker from '../../../DatePicker';

const Option = SelectUniversal;

interface IInputUniversalProps {
  type: string,
  label: string,
  error: any,
  values: Record<string, string>,
  rules: Record<string, any>
  mask: string,
  onChange: (...event: any[]) => void,
  onBlur: any,
  value: string,
}

type IInputUniversal =
  IInputUniversalProps &
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
  ControllerRenderProps<Record<string, string>, any> &
  LocalizeContextProps

const InputUniversal: React.FC<IInputUniversal> = (props) => {
  const {
    type,
    label,
    translate,
    placeholder = '',
    error,
    values,
    mask,
    onChange,
    value,
    name,
    ...input
  } = props;

  return (
    <div className={styles.wrapper}>
      {type === 'string' &&
      <input
        value={value}
        type="text"
        className={cx(styles.input, error && styles.inputError)}
        placeholder={translate(placeholder).toString()}
        onChange={onChange}
      />
      }

      {type === 'date' &&
      <DatePicker
        value={value}
        onChange={(value) => onChange(value?.format('YYYY-MM-DD'))}
        onBlur={input?.onBlur}
        name={name}
        className={cx(styles.input, error && styles.inputError)}
        showToday={false}
        picker="date"
        placeholder={translate(placeholder).toString()}
        format="DD.MM.YYYY"
        allowClear={false}
      />
      }

      {type === 'select' &&
      <SelectUniversal
        value={values[value]}
        placeholder={translate(placeholder).toString()}
        className={cx(styles.select, error && styles.inputError)}
        onChange={onChange}
        style={{
          height: '60px !important',
          width: '100% !important',
        }}
      >
        {Object.entries(values).map(([id, value]: any) => (
          { id, value }),
        ).map(({ id, value: label }) =>
          <Option key={id} value={id}>
            {label}
          </Option>,
        )}
      </SelectUniversal>
      }

      {type === 'dadata_address' &&
        <DadataUniversal
          placeholder={translate(placeholder).toString()}
          name={name}
          className={cx(styles.select, error && styles.inputError)}
          value={value}
          onChange={onChange}
        />
      }

      {type === 'mask' &&
        <MaskedInput
          value={value}
          onChange={onChange}
          mask={mask}
          placeholder={translate(placeholder).toString()}
          inputRef={input.ref}
          className={cx(styles.input, error && styles.inputError)}
        >
          {(props: any) =>
            <input {...props} />
          }
        </MaskedInput>
      }

      <div className={styles.label}>
        {translate(label).toString()}
      </div>

      {error &&
      <div className={styles.error}>
        {translate(error).toString()}
      </div>
      }
    </div>
  );
};

export default withLocalize(InputUniversal);
