import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Row } from 'antd';
import { ConnectedRouter } from 'connected-react-router';
import { withLocalize } from 'react-localize-redux';

import { history } from '../../core/state';

import {
  CABINET__CREDIT_RATING_URL,
  SELECTION_LOAN_PRODUCT_URL,
  CABINET__USER_DEBTS_URL,
  FEEDBACKS_URL,
  ARTICLES_URL,
  FAQ_URL,
  LOGIN_URL,
  REGISTER_URL,
  HELP_URL,
  CABINET__DEFENDER_URL,
  CABINET__CASHBACK_URL,
  SIGNUP_URL,
  CABINET__BALANCE_URL,
  RECOVERY_NEW_URL,
  CABINET__MULTTILOGIN_URL,
  CABINET__DOCUMENT_URL,
} from '../../shared/constants/Routes/constants';

import AuthViewLoadable from '../Auth';
import CreditRatingViewLoadable from '../CreditRating';
import HelpChatViewLoadable from '../HelpChat';
import SelectionLoanProductLoadable from '../SelectionLoanProduct';
import UserDebtsViewLoadable from '../UserDebts';
import FeedbacksViewLoadable from '../Feedbacks';
import ArticlesViewLoadable from '../Articles';
import FaqViewLoadable from '../Faq';
import DefenderViewLoadable from '../Defender';
import UserCashbackViewLoadable from '../UserCashback';
import BalanceViewLoadable from '../Balance';
import DocumentLoadable from '../Document';
import MultiloginRouter from '../MultiLogin';

import Preloader from '../../shared/componets/Preloader';
import useLang from '../../shared/hooks/useLang';

import { authSetAuthStatusRequest } from '../../states/Auth/state/duck';
import { data, isFetching } from '../../states/Auth/state/selectors';

import { environment } from '../../../environment';
import { countries as countriesSelector } from '../../states/Countries/state/selectors';
import { redirect as redirectSelector } from '../../states/Login/state/selectors';
import {
  activeRegion as activeRegionSelector,
  activeLanguage as activeLanguageSelector,
} from '../../states/Languages/state/selectors';

import ModalWrapper from '../../shared/componets/Modal/ModalWrapper';
import OfferModal from '../../shared/componets/OfferModal';
import { InstructionModal } from '../../shared/componets/Modal/InstructionModal';

export interface IAppRouterStore {
  data: any;
  isFetching: boolean;
  countries: [any];
  redirect: string;
}

export interface IAppRouterDispatch {
  authSetAuthStatusRequest: () => void;
}

/**
 * Private Router. Show content only for the authorized users
 *
 * @param {*} { component: Component, permited, ...rest }
 */
const PrivateRoute = ({ component: Component, permited, ...rest }: any) => (
  <Route
    {...rest}
    render={(props: any) => {
      if (!permited) {
        return <Redirect to={LOGIN_URL} />;
      }
      return <Component {...props} />;
    }}
  />
);

const mapStateToProps = (state: any) => ({
  data: data(state),
  isFetching: isFetching(state),
  countries: countriesSelector(state),
  redirect: redirectSelector(state),
  activeRegion: activeRegionSelector(state),
  activeLanguage: activeLanguageSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      authSetAuthStatusRequest,
    },
    dispatch,
  );

type IAppRouterTypes = IAppRouterDispatch & IAppRouterStore & any;

const AppRouter: React.FC<IAppRouterTypes> = (props) => {
  const {
    data,
    isFetching,
    languages,
    authSetAuthStatusRequest,
    countries,
    redirect,
    activeRegion,
    activeLanguage,
  } = props;
  const { isAuthorized } = data;

  const [paths, setPaths] = useState<Array<any>>([]);

  const cods = countries.map((item: any) => item.code).join();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { paths: usePaths } = useLang({
      languages,
      countries,
    });
    setPaths(usePaths);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cods]);

  const root = (() => {
    if (activeRegion === activeLanguage) {
      return activeLanguage;
    }
    if (activeRegion !== activeLanguage) {
      if (activeRegion && activeLanguage) {
        return `${activeRegion}-${activeLanguage}`;
      }
      return activeRegion || activeLanguage;
    }
  })();

  useEffect(() => {
    const { isAuthorized } = data;
    if (!isAuthorized) {
      authSetAuthStatusRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (redirect && isAuthorized) {
      history.replace(redirect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, redirect]);

  return (
    <>
      {/* Root routes, after Loader */}
      {isFetching || !paths.length ? (
        <Row justify="center" align="middle" style={{ height: '100vh' }}>
          <Preloader size="large" />
        </Row>
      ) : (
        <ConnectedRouter history={history}>
          <Switch>
            {/* Old Auth */}
            <Route path={LOGIN_URL}>
              <Redirect to={`/${root}${REGISTER_URL}`} />
            </Route>
            <Route exact path={REGISTER_URL}>
              <Redirect to={`/${root}${REGISTER_URL}`} />
            </Route>
            <Route path={SIGNUP_URL}>
              <Redirect to={`/${root}${LOGIN_URL}`} />
            </Route>

            {paths.map((item) => (
              <Route
                path={[`/${item}${LOGIN_URL}`, `/${item}${REGISTER_URL}`, `/${item}${RECOVERY_NEW_URL}`]}
                key={item}
                component={AuthViewLoadable}
              />
            ))}

            {/* Credit rating */}
            <PrivateRoute
              permited={isAuthorized}
              path={CABINET__CREDIT_RATING_URL}
              component={CreditRatingViewLoadable}
            />
            {/* Help */}
            <PrivateRoute
              permited={isAuthorized}
              path={HELP_URL}
              component={HelpChatViewLoadable}
            />
            {/* Selection loan product */}
            <PrivateRoute
              permited={isAuthorized}
              path={SELECTION_LOAN_PRODUCT_URL}
              component={SelectionLoanProductLoadable}
            />
            {/* User debts */}
            <PrivateRoute
              permited={isAuthorized}
              path={CABINET__USER_DEBTS_URL}
              component={UserDebtsViewLoadable}
            />
            {/* Feedbacks */}
            <PrivateRoute
              permited={isAuthorized}
              path={FEEDBACKS_URL}
              component={FeedbacksViewLoadable}
            />
            {/* Articles */}
            <PrivateRoute
              permited={isAuthorized}
              path={ARTICLES_URL}
              component={ArticlesViewLoadable}
            />
            {/* Faq */}
            <PrivateRoute
              permited={isAuthorized}
              path={FAQ_URL}
              component={FaqViewLoadable}
            />
            {/* Defender */}
            {!environment.PRODUCTION && (
            <PrivateRoute
              permited={isAuthorized}
              path={CABINET__DEFENDER_URL}
              component={DefenderViewLoadable}
            />
            )}
            {/* Cashback */}
            <PrivateRoute
              permited={isAuthorized}
              path={CABINET__CASHBACK_URL}
              component={UserCashbackViewLoadable}
            />
            {/* Balance */}
            <PrivateRoute
              permited={isAuthorized}
              path={CABINET__BALANCE_URL}
              component={BalanceViewLoadable}
            />

            {/* Document */}
            <PrivateRoute
              permited={isAuthorized}
              path={CABINET__DOCUMENT_URL}
              component={DocumentLoadable}
            />

            {/* MultiLogin */}
            <PrivateRoute
              permited={isAuthorized}
              path={CABINET__MULTTILOGIN_URL}
              component={MultiloginRouter}
            />

            <Redirect to={`/${root}${LOGIN_URL}`} />
          </Switch>

          {/* Система модалок */}
          <ModalWrapper />

          {isAuthorized && <>
            <OfferModal />
            <InstructionModal />
          </>
          }
        </ConnectedRouter>
      )}
    </>
  );
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(AppRouter),
);
