/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  put,
  takeLatest,
  takeEvery,
  all,
  call,
  cancel,
} from 'redux-saga/effects';
import * as A from 'fp-ts/lib/Array';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';

import { userTask as userTaskAPI } from '../../../../services/UserTask/api';

import {
  userTaskRequest,
  userTaskSuccess,
  userTaskFailure,
  userTaskResult,
  userTaskObserverRequest,
  userTaskRefUrlRequest,
  userTaskRefUrlFailure,
  userTaskRefUrlSuccess,
  userTaskGetInstructionsRequest,
  userTaskGetInstructionsFailure,
  userTaskGetInstructionsSuccess,
  userTaskInstructionsApiOffer,
} from '../duck';
import { userProfileRequest } from '../../../UserProfile/state/duck';
import { creditRatingUpdate } from '../../../CreditRating/state/duck';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { history } from '../../../../core/state';

import {
  USER_TASK_RES,
} from './constants';

import { environment } from '../../../../../environment';
// import { isCached as isCachedSelector } from '../../../UserTask/state/selectors';

export function* getData(action: any) {
  // const isCached: boolean = yield select(isCachedSelector);
  // if (isCached) {
  //   yield put({
  //     type: userTaskCancelRequest.toString(),
  //   });
  //
  //   return;
  // }

  const completePayload: any = yield call(checkToken, action);

  const [
    bonusActions,
  ]: any = yield all([
    call(userTaskAPI.getBonusActions, completePayload),
  ]);

  const input = [
    { ...bonusActions, init: USER_TASK_RES },
  ];

  const result = pipe(input, A.map(createResultV2), A.separate);
  const { left: errors, right: success } = result;

  if (errors.length > 0) {
    // NB: Если есть хоть одна 401, то разлогингить пользователя
    // и закончить работу саги
    for (let index = 0; index < errors.length; index++) {
      const element = errors[index];
      const { status } = element;

      if (status === 401) {
        yield put({
          type: userTaskResult.toString(),
          payload: createResultV2(element),
        });
        yield cancel();
      }
    }

    yield put({
      type: userTaskFailure.toString(),
    });
  }

  if (success.length > 0 && success.length === input.length) {
    // userRatingScoring
    const { data: bonusActionsData } = bonusActions;

    const payload = {
      bonusActionsData,
    };

    yield put({
      type: userTaskSuccess.toString(),
      payload,
    });
  }
} // getData =========

export function* getRefUrl(action: any) {
  const completePayload: any = yield call(checkToken, action);

  const refUrl = yield call(userTaskAPI.getRefUrl, completePayload);

  const response = createResultV2(refUrl);

  if (E.isRight(response)) {
    const { right: successData } = response;
    const { data } = successData;

    yield put({
      type: userTaskRefUrlSuccess.toString(),
      payload: { ...data },
    });
  }

  if (E.isLeft(response)) {
    const { left: error } = response;

    yield put({
      type: userTaskRefUrlFailure.toString(),
      payload: error,
    });
  }
} // getRefUrl =========

export function* userShareSocialObserver(action: any) {
  const completePayload: any = yield call(checkToken, action);

  yield call(userTaskAPI.postShareSocialObserver, completePayload);

  yield put({
    type: userTaskRequest.toString(),
  });

  yield put({
    type: userProfileRequest.toString(),
    payload: {
      nonCached: true,
    },
  });
}

export function* getInstructions(action: any) {
  const completePayload: any = yield call(checkToken, {} || action.payload);

  const res = yield call(userTaskAPI.getInstructions, completePayload);

  const response = createResultV2(res);

  if (E.isRight(response)) {
    const { right: successData } = response;
    const { data } = successData || [];

    yield put({
      type: userTaskGetInstructionsSuccess.toString(),
      payload: data,
    });

    const params = new URLSearchParams();

    // eslint-disable-next-line no-restricted-syntax
    for (const item of data) {
      switch (item.handler.alias) {
        case 'first_api_offer_request':
          // if (environment.PRODUCTION) {
          //   yield put({
          //     type: userTaskInstructionsApiOffer.toString(),
          //     payload: item,
          //   });
          // } else {
          params.append('id', item.handler.data.credit_product_id);

          history.push({
            pathname: window.location.pathname,
            search: params.toString(),
          });
          // }
          break;
        case 'first_rating_request':
          yield put({
            type: creditRatingUpdate.toString(),
          });
          break;
        default:
          break;
      }
    }
  }

  if (E.isLeft(response)) {
    const { left: error } = response;

    yield put({
      type: userTaskGetInstructionsFailure.toString(),
      payload: error,
    });
  }
}

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    userTaskRequest,
    safe(onError, getData, {
      terminator: userTaskFailure,
    }),
  );

  yield takeEvery(
    userTaskObserverRequest,
    safe(onError, userShareSocialObserver),
  );

  yield takeLatest(
    userTaskRefUrlRequest,
    safe(onError, getRefUrl, {
      terminator: userTaskRefUrlFailure,
    }),
  );

  yield takeLatest(
    userTaskGetInstructionsRequest,
    safe(onError, getInstructions, {
      terminator: userTaskGetInstructionsFailure,
    }),
  );
}
