import React, { useEffect, useRef } from 'react';
import { Button, Col, Tooltip } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

import styles from './OfferElementWithForms.module.scss';

import OfferForm from '../OfferForm/OfferForm';
import lockImage from '../OfferElementCard/assets/images/lock.svg';
import {
  productsCancelResult,
  productsChecklistRequest,
} from '../../../states/Products/state/duck';

import {
  isFetchingChecklist as isFetchingChecklistSelector,
} from '../../../states/Products/state/selectors';
import { activeLanguage as activeLanguageSelector } from '../../../states/Languages/state/selectors';
import Preloader from '../Preloader';
import ImageWrapper from '../ImageWrapper';
import { environment } from '../../../../environment';
import BrokenImage from '../../constants/BrokenImage/BrokenImage.svg';
import StarsRating from '../StarsRating';

interface IProps {
  url: string;
  id: string | number;
  formFields: any;
  rawUrl: string;
  infoForUser: any;
  isFetching?: boolean;
  cancelClick: () => void | undefined;
  image?: string;
  currentOrg?: Record<string, any>;
  rating?: any;
  ratingReviewsCount?: number;
  title: string;
  description: string;
}

const OfferElementWithForms: React.FC<IProps> = (props) => {
  const {
    url,
    id,
    formFields,
    rawUrl,
    infoForUser,
    isFetching,
    cancelClick,
    image,
    currentOrg,
    rating,
    ratingReviewsCount,
    title,
    description,
  } = props;
  const methods = useForm();
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = methods;

  const mainFef = useRef() as any;

  const dispatch = useDispatch();
  const isFetchingChecklist = useSelector(isFetchingChecklistSelector);
  const activeLanguage = useSelector(activeLanguageSelector);
  const handleCancelClick = React.useCallback(() => {
    dispatch({
      type: productsCancelResult.toString(),
    });
    if (cancelClick) {
      cancelClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelClick]);

  const onSubmit = (data: any) => {
    dispatch({
      type: productsChecklistRequest.toString(),
      payload: { ...data, offerID: id },
    });
    cancelClick();
  };

  let currentTooltiptext = '';
  infoForUser?.forEach((key: any) => {
    if (key[0] === activeLanguage) {
      // eslint-disable-next-line prefer-destructuring
      currentTooltiptext = key[1];
    }
  });

  function createMarkup() {
    return {
      __html: currentTooltiptext,
    };
  }

  useEffect(() => {
    if (!isFetching && formFields) {
      mainFef.current.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} ref={mainFef}>
          <Col span={24} className={styles.wrapper}>
            <div className={styles.mainWrapperOffer}>
              {currentOrg?.title &&
              <div className={styles.header}>
                <div className={styles.headerLogo}>
                  {image ?
                    <ImageWrapper
                      image={image?.startsWith('https:') ? image : `${environment.STATIC_PREFIX}${image}`}
                      alt="logo"
                    />
                    :
                    <img
                      src={BrokenImage}
                      alt="logo"
                    />
                  }
                </div>
                <div>
                  <div className={styles.headerTitle}>
                    {currentOrg?.title}
                  </div>
                  <div>
                    <StarsRating rating={rating} count={ratingReviewsCount} />
                  </div>
                </div>
              </div>
              }

              <div className={styles.infoOffer}>
                <div className={styles.infoOfferHeader}>
                  <Translate id={title} />
                </div>
                <div className={styles.infoOfferSubHeader}>
                  <Translate id={description} />
                </div>
              </div>

              <div className={styles.formOffer}>
                {isFetching ?
                  <div style={{ margin: '0 auto' }}>
                    <Preloader size="large" />
                  </div>
                  :
                  <Translate>
                    {({ translate }) => (
                      <div style={{ width: '100%' }}>
                        <OfferForm
                          id={id}
                          formFields={formFields}
                          control={control}
                          translate={translate}
                          errors={errors}
                          handleSubmit={handleSubmit}
                          getValues={getValues}
                        />
                      </div>
                    )}
                  </Translate>
            }
              </div>

              <div className={styles.footerOffer}>
                <div className={styles.leftSideBtns}>
                  <Button
                    type="default"
                    shape="round"
                    style={{
                      width: '100%',
                      // maxWidth: '205px',
                      height: '38px',
                      color: '#1373e5',
                    }}
                    onClick={handleCancelClick}
                    disabled={isFetchingChecklist}
                  >
                    <Translate id="shared_components_offerelement_form_cancel" />
                  </Button>
                </div>
                <div className={styles.rightSideBtns}>
                  {rawUrl && <Button
                    className={styles.footerBtnApply}
                    type="default"
                    shape="round"
                    style={{
                      // width: '100%',
                      height: '38px',
                      // marginRight: '10px',
                      color: '#1373e5',
                    }}
                  >
                    {/* eslint-disable-next-line */}
                    <a href={url} rel="noopener noreferrer" target="_blank">
                      <Translate id="shared_components_offerelement_form_link" />
                    </a>
                    </Button>}
                  <Tooltip
                    title={
                  // eslint-disable-next-line react/no-danger
                  () => <div dangerouslySetInnerHTML={createMarkup()} />
                }
                    color="#FFFFFF"
                  >
                    <Button
                      className={styles.footerBtnSend}
                      type="primary"
                      shape="round"
                      style={{
                        width: '100%',
                        height: '38px',
                      }}
                      // disabled={!isValid}
                      // onClick={handleSubmit(onSubmit)}
                      loading={isFetchingChecklist}
                      htmlType="submit"
                    >
                      {!isFetchingChecklist && (
                        <img
                          src={lockImage}
                          alt="Lock"
                          className={styles.lockImg}
                        />
                      )}
                      <Translate id="shared_offer_element_send_request" />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </Col>
        </form>
      </FormProvider>
    </>
  );
};

export default OfferElementWithForms;
