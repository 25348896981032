import { call, takeEvery, put, select } from 'redux-saga/effects';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { emailConfirmationAPI } from '../../../../services/EmailConfirmation/api';

import { getData } from '../../../../shared/utils/commonLocalStorage.helper';
import { LOCAL_STORAGE_EMAIL_CONFIRMATION } from '../../../../shared/constants/Defaults/constants';

import {
  emailConfirmationCreateResult,
  emailConfirmationResult,
} from '../duck';
import { rawResult as rawResultSelector } from '../selectors';
import { authSetAuthStatusSuccess } from '../../../Auth/state/duck';
import { isLanguagesAlreadySet as isLanguagesAlreadySetSelector } from '../../../Languages/state/selectors';

// Sagas

export function* processingLoginURLParams(): IterableIterator<any> {
  const storedEmailConfirmation = yield call(
    getData,
    LOCAL_STORAGE_EMAIL_CONFIRMATION,
  );

  if (storedEmailConfirmation) {
    const completePayload: any = yield call(checkToken, {});
    const srvRes: any = yield call(emailConfirmationAPI.getData, {
      ...completePayload,
      emailConfirmationToken: storedEmailConfirmation,
    });

    yield put({
      type: emailConfirmationCreateResult.toString(),
      payload: srvRes,
    });

    const isLanguagesAlreadySet = yield select(isLanguagesAlreadySetSelector);
    if (isLanguagesAlreadySet) {
      const rawResult: any = yield select(rawResultSelector);
      yield put({
        type: emailConfirmationResult.toString(),
        payload: createResultV2(rawResult),
      });
    }
  }
} // processingLoginURLParams =========

export default function* rootSaga() {
  yield takeEvery(
    authSetAuthStatusSuccess,
    safe(onError, processingLoginURLParams),
  );
}
