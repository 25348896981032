import AxiosInstance from '../../../core/api/axiosinstance';
import paramsSerializer from '../../../shared/utils/paramsSerializer';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

export default {
  /**
   * HelpChat LoanSelection API
   */
  getData(data: any) {
    const { token, perPage, page = 1 } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/credit-products/selection-messages?${paramsSerializer(
        {
          page,
          'per-page': perPage,
          sort: '-id',
        },
      )}`,
    );
  },

  postData(data: any) {
    const { token } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/credit-products/selection-messages`,
      data,
    );
  },

  getDataLastMsg(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/credit-products/selection-messages/last`,
    );
  },

  // deleteNote(noteId) {
  //   return AxiosInstance(token).delete(`${apiPrefix}${versionOnePrefix}/notes/${noteId}`);
  // },
  // HelpChat LoanSelection API
};
