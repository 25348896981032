/* eslint-disable max-len */
/* eslint-disable require-yield */
import { takeEvery, put, takeLatest, select } from 'redux-saga/effects';

import {
  languagesSetActiveLangRequest,
  languagesSetActiveLangSuccess,
  languagesSetActiveLangFailure,
  languagesSetAppActiveLangRequest,
  languagesInitAlreadyRequest,
  languagesInitAlreadySucess,
  languagesSetActiveRegionRequest,
  languagesSetActiveRegionFailure,
  languagesSetActiveRegionSuccess,
  languagesListSuccess,
} from '../duck';
import {
  list as listSelector,
} from '../selectors';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { countries as countriesSelector } from '../../../Countries/state/selectors';

export function* setActiveLang(): IterableIterator<any> {
  // const lang = (navigator.language || navigator.languages[0]).split('-')[0].toString().toLowerCase();
  // console.log(lang2);
  const [lang] = ['ru'];

  // console.log(lang2);

  yield put({
    type: languagesSetActiveLangSuccess.toString(),
    payload: lang,
  });

  yield put({
    type: languagesInitAlreadyRequest.toString(),
  });

  yield put({
    type: languagesInitAlreadySucess.toString(),
  });

  yield put({
    type: languagesSetActiveRegionRequest.toString(),
    payload: { lang },
  });
} // setActiveLang =========

export function* setAppActiveLang(action: any): IterableIterator<any> {
  const { payload } = action;
  const { lang } = payload;

  yield put({
    type: languagesSetActiveLangSuccess.toString(),
    payload: lang,
  });
} // setAppActiveLang =========

export function* setAppActiveRegion(action: any): IterableIterator<any> {
  const { payload } = action;
  const { lang } = payload;

  const countries: Array<Record<string, any>> | [] = (yield select(countriesSelector)) || [];
  const list: Array<Record<string, any>> | [] = (yield select(listSelector)) || [];

  if (countries && Array.isArray(countries)) {
    const country = countries?.find((country: any) =>
      country.code.toLowerCase() === lang.toLowerCase());
    if (country && Array.isArray(country.languages)) {
      const languagesSort = country.languages
        .slice()
        .sort((a: Record<string, any>, b: Record<string, any>) =>
          a.is_main < b.is_main ? 1 : -1);

      const languages = languagesSort.length ? languagesSort : list;

      yield put({
        type: languagesListSuccess.toString(),
        payload: languages,
      });

      if (Array.isArray(languages) && languages.length) {
        yield put({
          type: languagesSetAppActiveLangRequest.toString(),
          payload: { lang: languages[0].code.toLowerCase() },
        });
      }

      yield put({
        type: languagesSetActiveRegionSuccess.toString(),
        payload: lang,
      });
    } else {
      yield put({
        type: languagesSetActiveRegionSuccess.toString(),
        payload: countries[0].code.toLowerCase(),
      });
    }
  }
} // setAppActiveRegion =========

// Root Saga
export default function* rootSaga() {
  yield takeEvery(
    languagesSetActiveLangRequest,
    safe(onError, setActiveLang, { terminator: languagesSetActiveLangFailure }),
  );

  yield takeLatest(
    languagesSetAppActiveLangRequest,
    safe(onError, setAppActiveLang, {
      terminator: languagesSetActiveLangFailure,
    }),
  );

  yield takeLatest(
    languagesSetActiveRegionRequest,
    safe(onError, setAppActiveRegion, {
      terminator: languagesSetActiveRegionFailure,
    }),
  );
}
