import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const USER_SETTINGS_CHANGE_PHONE = 'USER_SETTINGS_CHANGE_PHONE';

export const userSettingsChangePhonePutRequest = createAction(
  `${USER_SETTINGS_CHANGE_PHONE}/PUT_REQUEST`,
);
export const userSettingsChangePhonePutSuccess = createAction(
  `${USER_SETTINGS_CHANGE_PHONE}/PUT_SUCCESS`,
);
export const userSettingsChangePhonePutFailure = createAction(
  `${USER_SETTINGS_CHANGE_PHONE}/PUT_FAILURE`,
);

export const userSettingsChangePhonePutResult = createAction(
  `${USER_SETTINGS_CHANGE_PHONE}/PUT_RESULT`,
);

export const userSettingsChangePhoneClearForm = createAction(
  `${USER_SETTINGS_CHANGE_PHONE}/CLEAR_FORM`,
);

export const userSettingsChangePhonePostRequest = createAction(
  `${USER_SETTINGS_CHANGE_PHONE}/POST_REQUEST`,
);
export const userSettingsChangePhonePostSuccess = createAction(
  `${USER_SETTINGS_CHANGE_PHONE}/POST_SUCCESS`,
);
export const userSettingsChangePhonePostFailure = createAction(
  `${USER_SETTINGS_CHANGE_PHONE}/POST_FAILURE`,
);

export const userSettingsChangePhonePostResult = createAction(
  `${USER_SETTINGS_CHANGE_PHONE}/POST_RESULT`,
);

// Reducers ==========================

const isFetchingPut = createReducer(false, {
  [userSettingsChangePhonePutRequest.toString()]: () => true,
  [userSettingsChangePhonePutSuccess.toString()]: () => false,
  [userSettingsChangePhonePutFailure.toString()]: () => false,
});

const dataPut = createReducer(
  {},
  {
    [userSettingsChangePhoneClearForm.toString()]: () => ({}),
    [userSettingsChangePhonePutRequest.toString()]: () => ({}),
    [userSettingsChangePhonePutSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

const resultPut = createReducer(
  {},
  {
    [userSettingsChangePhonePutRequest.toString()]: () => ({}),
    [userSettingsChangePhonePutResult.toString()]: (_state, action) =>
      action.payload,
  },
);

const isFetchingPost = createReducer(false, {
  [userSettingsChangePhonePostRequest.toString()]: () => true,
  [userSettingsChangePhonePostSuccess.toString()]: () => false,
  [userSettingsChangePhonePostFailure.toString()]: () => false,
});

const dataPost = createReducer(
  {},
  {
    [userSettingsChangePhoneClearForm.toString()]: () => ({}),
    [userSettingsChangePhonePostRequest.toString()]: () => ({}),
    [userSettingsChangePhonePostSuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

const resultPost = createReducer(
  {},
  {
    [userSettingsChangePhonePostRequest.toString()]: () => ({}),
    [userSettingsChangePhonePostResult.toString()]: (_state, action) =>
      action.payload,
  },
);

// Root Reducer
const reducer = combineReducers({
  isFetchingPut,
  dataPut,
  resultPut,
  isFetchingPost,
  dataPost,
  resultPost,
});

export default reducer;
