import { lazy } from 'react';

import withSuspense from '../../shared/hooks/withSuspense';

const DocumentController = lazy(() => import('./Document.controller'));

// Main Render
const DocumentLoadable = withSuspense(DocumentController);

export default DocumentLoadable;
