/* eslint-disable react/button-has-type */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Button.module.scss';

interface IProps {
  mode?: 'secondary' | 'primary',
  size?: 'big' | 'small',
  loading?: boolean,
  disabled?: boolean,
  suffix?: JSX.Element,
  positionSuffix?: 'left' | 'right',
  enableSuffixStyle?: boolean,
}

type IButton = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement> & IProps;

const Button: React.FC<IButton> = ({
  mode,
  size,
  loading,
  disabled,
  children,
  suffix,
  positionSuffix,
  enableSuffixStyle,
  className,
  ...props
}) => {
  return (
    <button
      {...props}
      className={cx(
        styles.button,
        mode === 'primary' ? styles.primary : styles.secondary,
        size === 'big' ? styles.big : styles.small,
        (loading || disabled) && styles.disabled,
        positionSuffix === 'right' && styles.wrapReverse,
        className,
      )}
      disabled={loading || disabled}
    >
      {suffix &&
        <div
          className={cx(
            styles.suffix,
            !enableSuffixStyle && styles.svg,
            positionSuffix === 'left' ? styles.suffixL : styles.suffixR,
          )}
        >
          {suffix}
        </div>
      }
      {children}

    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
};

Button.defaultProps = {
  type: 'button',
  size: 'big',
  mode: 'primary',
  positionSuffix: 'left',
};

export default Button;
