/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import cx from 'classnames';
import { Tooltip } from 'antd';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useTimer } from '@finanso/react-uikit';

import styles from './Application.module.scss';

import { Loan, LoanStatus, LoanType } from '../../../../services/Multilogin/api';

import InfoFill from '../../../../../assets/images/InfoFill';
import Time from '../../../../../assets/images/Time';
import Arrow from '../../../../../assets/images/ArrLeft';
import Clock from '../../../../../assets/images/Clock';
import Fincoin from '../../../../../assets/images/Fincoin';
import CheckCircul from '../../../../../assets/images/CheckCircul';
import CloseCircul from '../../../../../assets/images/CloseCircul';
import Issued from '../../../../../assets/images/Issued';

import Button from '../../../UIkit/Button';
import { useSelector } from 'react-redux';
import { userCountry as userCountrySelector } from '../../../../states/UserProfile/state/selectors';
import { Translate } from 'react-localize-redux';

const Application: React.FC<Loan> = ({
  status,
  sum,
  percent,
  sumToReturnToday,
  fromDate,
  deadlineDate,
  type,
  cancelLoanUrl,
  closeLoanUrl,
}) => {
  const { code: userCountryCode } = useSelector(userCountrySelector);

  const [state, setState] = React.useState(false);

  const timer = useTimer({ to: deadlineDate });

  return (
    <div className={cx(styles.root, state ? styles.active : '')}>

      <div className={cx(styles.status, state ? styles.active : '')}>
        <div className={styles.cell}>
          <span><Translate id="multilogin.loan.status" /></span>

          <div className={styles.title}>
            {status === LoanStatus.CREATED &&
            <><Time /> <Translate id="multilogin.loan.status.create" /></>
            }
            {status === LoanStatus.DECLINED &&
            <><CloseCircul /> <Translate id="multilogin.loan.status.declined" /></>
            }
            {status === LoanStatus.ACTIVE &&
            <><Issued /> <Translate id="multilogin.loan.status.active" /></>
            }
            {status === LoanStatus.COMPLETED &&
            <><CheckCircul /> <Translate id="multilogin.loan.status.completed" /></>
            }
          </div>
        </div>
        {/* eslint-disable-next-line */}
        <div
          className={styles.arrow}
          onClick={() => setState(!state)}
          onKeyDown={() => setState(!state)}
          role="button"
          tabIndex={0}
        >
          <Arrow
            width="20px"
            height="20px"
            fill="#1373E5"
          />
        </div>

      </div>

      <div className={cx(styles.cell, styles.sum)}>
        <span><Translate id="multilogin.loan.count" /></span>
        <div className={styles.title}>
          {sum ? <>{sum} <Translate id={`currency.symbol.${userCountryCode}`} /></> : null}
        </div>
      </div>

      <div className={cx(styles.cell, styles.percent)}>
        <span><Translate id="multilogin.loan.percent" /></span>
        <div className={styles.title}>
          {percent ? `${percent}%` : null}
        </div>
      </div>

      <div className={cx(styles.cell, styles.returnSum)}>
        <span><Translate id="multilogin.loan.return" /></span>
        <div className={styles.title}>
          {sumToReturnToday ? <>{sumToReturnToday} <Translate id={`currency.symbol.${userCountryCode}`} /></> : null}
        </div>
      </div>

      <div className={cx(styles.cell, styles.applyDate)}>
        <span><Translate id="multilogin.loan.date.create" /></span>
        <div className={styles.title}>
          {fromDate && moment(fromDate).format('DD.MM.YYYY')}
        </div>
      </div>

      <div className={cx(styles.cell, styles.returnDate)}>
        <span><Translate id="multilogin.loan.date.return" /></span>
        <div className={cx(
          styles.title,
          styles.date,
          timer.total > 0 && styles.timer,
        )}
        >
          {!deadlineDate ? null : timer.total > 0 ?
            <>
              <Clock
                width="20px"
                height="20px"
                fill="#E3405E"
              />
              {timer.days}Д {timer.hours}:{timer.minutes}:{timer.seconds}
            </>
            : moment(deadlineDate).format('DD.MM.YYYY')
          }
        </div>
      </div>

      <div className={cx(styles.cell, styles.way)}>
        <span><Translate id="multilogin.loan.method" /></span>

        <div className={styles.title}>
          {type === LoanType.REFERRAL &&
          <>
            <Translate id="multilogin.loan.method.mfo" />
            <Tooltip
              placement="top"
              title={<Translate id="multilogin.loan.method.mfo.tooltip" />}
              arrowPointAtCenter
              trigger="hover"
              color="white"
              overlayInnerStyle={{
                borderRadius: '5px',
                width: '310px',
                color: '#0F1011',
                padding: '15px 20px',
              }}
            >
              <InfoFill className={styles.info} />
            </Tooltip>
          </>
          }
          {type === LoanType.INTERNAL &&
          <>
            <Translate id="multilogin.loan.method.finanso" />
            <Fincoin />
          </>
          }
        </div>

      </div>

      <div className={cx(styles.cell, styles.button)}>
        {status === LoanStatus.CREATED && cancelLoanUrl &&
          <NavLink to={cancelLoanUrl}>
            <Button
              mode="secondary"
              size="small"
            >
              <Translate id="multilogin.loan.cancel" />
            </Button>
          </NavLink>
        }
        {status === LoanStatus.ACTIVE && closeLoanUrl &&
          <NavLink to={closeLoanUrl}>
            <Button
              mode="secondary"
              size="small"
            >
              <Translate id="multilogin.loan.close" />
            </Button>
          </NavLink>
        }
      </div>
    </div>
  );
};

export default Application;
