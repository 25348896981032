import { createSelector } from 'reselect';
import { get, startsWith, snakeCase } from 'lodash';

interface ISettings {
  name: string;
  value: string;
}

// Инициализационные данные на основе документации
// https://api.finanso.com/#/User/api%5Cmodules%5Cv1%5Cmodules%5Cuser%5Ccontrollers%5CSettingController%3A%3AactionIndex
const initData = [
  {
    name: '',
    value: '',
  },
];

const SUBSCRIBE = 'subscribe';
const NOTICE = 'notice';
const NOTICE_CHANNEL_SMS = 'notice_channel_sms';

export const isFetching = (store: any) =>
  store.userSubscriptionReducer.isFetching;

export const data = createSelector(
  (store: any) => store.userSubscriptionReducer,
  userSubscriptionReducer => {
    const data = get(userSubscriptionReducer, 'data', initData) || initData;

    try {
      const subscribeArr = data
        .map((item: ISettings) => {
          const { name } = item;

          if (startsWith(name, SUBSCRIBE)) {
            return { ...item, translate: snakeCase(name) };
          }

          return null;
        })
        .filter(Boolean);

      const noticeArr = data
        .map((item: ISettings) => {
          const { name } = item;

          if (startsWith(name, NOTICE)) {
            if (name === NOTICE_CHANNEL_SMS) {
              return null;
            }

            return { ...item, translate: snakeCase(name) };
          }

          return null;
        })
        .filter(Boolean);

      const noticeChannelSMSArr = data
        .map((item: ISettings) => {
          const { name } = item;

          if (name === NOTICE_CHANNEL_SMS) {
            return { ...item, translate: name };
          }

          return null;
        })
        .filter(Boolean);

      const result = {
        [SUBSCRIBE]:
          subscribeArr.length > 0 ? [...subscribeArr] : [...initData],
        [NOTICE]: noticeArr.length > 0 ? [...noticeArr] : [...initData],
        [NOTICE_CHANNEL_SMS]:
          noticeChannelSMSArr.length > 0
            ? [...noticeChannelSMSArr]
            : [...initData],
      };

      return result;
    } catch (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('ERROR: Selector userSubscriptionReducer data');
      return {
        [SUBSCRIBE]: [...initData],
        [NOTICE]: [...initData],
        [NOTICE_CHANNEL_SMS]: [...initData],
      };
    }
  },
);

export const dataState = createSelector(
  (store: any) => store.userSubscriptionReducer,
  userSubscriptionReducer => {
    const data = get(userSubscriptionReducer, 'data', initData) || initData;

    try {
      const result = data.reduce(
        (acc: Record<string, string>, item: ISettings) => {
          const { name, value } = item;

          acc = { ...acc, [name]: value };
          return acc;
        },
        {},
      );

      return result;
    } catch (error) {
      // NB: Разрешенный игнор
      // eslint-disable-next-line
      console.log('ERROR: Selector userSubscriptionReducer dataState');
      return {};
    }
  },
);

export const error = createSelector(
  (store: any) => store.userSubscriptionReducer.error,
  error => error,
);

export const simpleDataFromReducer = createSelector(
  (store: any) => store.userSubscriptionReducer,
  userSubscriptionReducer => {
    const data: ISettings[] =
      get(userSubscriptionReducer, 'data', initData) || initData;

    return data;
  },
);

export const result = createSelector(
  (store: any) => store.userSubscriptionReducer.result,
  result => result,
);
