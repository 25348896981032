import React, { useContext } from 'react';
import PayModal from '../PayModal';
import { motion } from 'framer-motion';

import styles from './ModalMain.module.scss';
import { ModalContext } from '../ModalWrapper/ModalWrapper';
import CloseImage from './assets/images/CloseImage';
import RequiredFieldsModal from '../RequiredFieldsModal';
import ApiOfferModal from '../ApiOfferModal';
import ModalFields from '../ModalFields';

const ModalMain: React.FC<any> = props => {
  const { mod, type, className, tag } = props;
  const { onClose } = useContext(ModalContext);

  const onClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <>
      <div
        className={className}
        role="button"
        onClick={onClick}
        onKeyPress={onClick}
        tabIndex={0}
      >
        <div className={styles.close}>
          <motion.div
            whileHover={{ scale: 1.2, rotate: 90 }}
            whileTap={{ scale: 0.8, rotate: -180 }}
            onClick={onClose}
          >
            <CloseImage />
          </motion.div>
        </div>

        {mod === 'userRequiredField' &&
        <RequiredFieldsModal tag={tag} />
      }

        {mod === 'payRequest' &&
        <PayModal type={type} tag={tag} />
      }

        {mod === 'apiOffer' &&
        <ApiOfferModal type={type} />
      }

        {mod === 'fields' &&
        <ModalFields />
      }
      </div>
    </>
  );
};

export default ModalMain;
