export enum InitStatusEnum {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export enum DataStatusEnum {
  CONNECT = 'CONNECT',
  DISCONNECT = 'DISCONNECT',
}

export interface IPayload {
  userToken: string;
  path: string;
}

export interface IData {
  socket: {
    proto: string;
    host: string;
    port: string;
  };
  token: string;
  channel: string;
  status?: DataStatusEnum;
  socketChanName?: string;
}

export interface IInitData {
  status: InitStatusEnum;
  data?: IData;
}

export interface IRes {
  data: IData;
}
