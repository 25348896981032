import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';

import { userSettingsChangePswdAPI } from '../../../../services/UserSettingsChangePswd/api';

import {
  userSettingsChangePswdRequest,
  userSettingsChangePswdResult,
  userSettingsChangePswdSuccess,
  userSettingsChangePswdFailure,
  userSettingsChangePswdPutRequest,
  userSettingsChangePswdPutSuccess,
  userSettingsChangePswdPutFailure,
  userSettingsChangePswdPutResult,
} from '../duck';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';

export function* getData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const srvRes = yield call(userSettingsChangePswdAPI.getData, {
    ...completePayload,
  });

  yield put({
    type: userSettingsChangePswdResult.toString(),
    payload: createResultV2(srvRes),
  });
} // getData =========

// TODO:  Реализовать проверку контракта! Формы
export function* checkResult(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: userSettingsChangePswdSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    const { left: error } = payload;

    yield put({
      type: userSettingsChangePswdFailure.toString(),
      payload: error,
    });
  }
} // checkResult =========

export function* putData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const srvRes = yield call(userSettingsChangePswdAPI.putData, {
    ...completePayload,
  });

  yield put({
    type: userSettingsChangePswdPutResult.toString(),
    payload: createResultV2(srvRes),
  });
} // putData =========

// TODO: Добавить контракт
export function* checkResultPut(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: userSettingsChangePswdPutSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: userSettingsChangePswdPutFailure.toString(),
    });
  }
} // checkResultPut =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    userSettingsChangePswdRequest,
    safe(onError, getData, {
      terminator: userSettingsChangePswdFailure,
    }),
  );

  yield takeEvery(userSettingsChangePswdResult, safe(onError, checkResult));

  yield takeLatest(
    userSettingsChangePswdPutRequest,
    safe(onError, putData, {
      terminator: userSettingsChangePswdPutFailure,
    }),
  );

  yield takeEvery(
    userSettingsChangePswdPutResult,
    safe(onError, checkResultPut),
  );
}
