import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const OFFER_FORM = 'OFFER_FORM';

export const offerFormRequest = createAction(
  `${OFFER_FORM}/REQUEST`,
);
export const offerFormSuccess = createAction(
  `${OFFER_FORM}/SUCCESS`,
);
export const offerFormFailure = createAction(
  `${OFFER_FORM}/FAILURE`,
);

export const offerFormResult = createAction(
  `${OFFER_FORM}/RESULT`,
);

export const offerFormPostRequest = createAction(
  `${OFFER_FORM}/POST_REQUEST`,
);
export const offerFormPostSuccess = createAction(
  `${OFFER_FORM}/POST_SUCCESS`,
);
export const offerFormPostFailure = createAction(
  `${OFFER_FORM}/POST_FAILURE`,
);

export const offerFormPostResult = createAction(
  `${OFFER_FORM}/POST_RESULT`,
);

export const offerFormClearForm = createAction(
  `${OFFER_FORM}/CLEAR_FORM`,
);

export const offerFormClearFormPost = createAction(
  `${OFFER_FORM}/POST_CLEAR_FORM`,
);

// Reducers ==========================

const isFetching = createReducer(false, {
  [offerFormRequest.toString()]: () => true,
  [offerFormSuccess.toString()]: () => false,
  [offerFormFailure.toString()]: () => false,
});

const data = createReducer({}, {
  [offerFormRequest.toString()]: () => ({}),
  [offerFormSuccess.toString()]: (_state, action) => action.payload,
  [offerFormFailure.toString()]: (_state, action) => action.payload,
});

const result = createReducer({}, {
  [offerFormRequest.toString()]: () => ({}),
  [offerFormResult.toString()]: (_state, action) => action.payload,
});

const isFetchingPost = createReducer(false, {
  [offerFormPostRequest.toString()]: () => true,
  [offerFormPostSuccess.toString()]: () => false,
  [offerFormPostFailure.toString()]: () => false,
});

const dataPost = createReducer({}, {
  // [offerFormPostRequest.toString()]: () => ({}),
  [offerFormPostSuccess.toString()]: (_state, action) => action.payload,
  [offerFormPostFailure.toString()]: (_state, action) => action.payload,
  [offerFormClearFormPost.toString()]: () => ({}),
});

const resultPost = createReducer({}, {
  [offerFormPostRequest.toString()]: () => ({}),
  [offerFormPostResult.toString()]: (_state, action) => action.payload,
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  result,
  isFetchingPost,
  dataPost,
  resultPost,
});

export default reducer;
