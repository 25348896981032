import React, { FormEventHandler } from 'react';
import { useStore } from 'effector-react';
import { Input } from '@finanso/react-uikit';

import styles from './MultiloginAddMfoForm.module.scss';

import Button from '../../../UIkit/Button';
import ImageWrapper from '../../ImageWrapper';

import {
  $isLoadingMfo,
  $mfo,
  $isLoadingMfoAdd,
} from '../../../../states/Multilogin/store';
import { useForm } from 'react-hook-form';
import { $addMfo } from '../../../../states/Multilogin/event';
import { useQuery } from '../../../hooks/useQuery';
import { ModalType } from '../../../enum/ModalType.enum';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';

const MultiloginAddMfoForm: React.FC = () => {
  const isLoadingMfo = useStore($isLoadingMfo);
  const isLoadingMfoAdd = useStore($isLoadingMfoAdd);
  const mfo = useStore($mfo);

  const form = useForm({
    defaultValues: {
      login: '',
      password: '',
    },
  });
  const {
    getValues,
    register,
    watch,
    reset,
  } = form;
  const values = watch();

  useQuery({
    name: ModalType.ADDMFO,
    observer: (query) => {
      if (!query) {
        reset();
      }
    },
  });

  const handlerAddMfo: FormEventHandler = (e) => {
    e.preventDefault();
    const { login, password } = getValues();

    $addMfo({
      cabinetId: Number(mfo?.id),
      login,
      password,
    });
  };

  if (!mfo || !mfo?.id) {
    return <div>Error 404. Page not found</div>;
  }
  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <Translate id="multilogin.add.title" />
      </div>
      <div className={styles.subtitle}>
        <Translate id="multilogin.add.description" />
      </div>

      <div className={styles.company}>
        {isLoadingMfo
          ? <Skeleton className={styles.logo} />
          : <ImageWrapper className={styles.logo} image={mfo?.logoImage as string} alt="" />
        }
        <div className={styles.col}>
          {isLoadingMfo
            ? <Skeleton count={3} width={100} height={16} />
            : <>
              <div className={styles.brand}>{mfo?.name}</div>
              <div className={styles.site}>{mfo?.url}</div>
              <div className={styles.law}>{mfo?.legalName}</div>
            </>
          }
        </div>
      </div>

      <form onSubmit={handlerAddMfo} className={styles.form}>
        <div className={styles.fields}>
          <div className={styles.login}>
            {isLoadingMfo
              ? <Skeleton width="100%" height={60} inline />
              : <Input
                  {...register('login')}
                  title={<Translate id="multilogin.add.login" />}
                  size="big"
                  disabled={isLoadingMfo}
              />
            }
          </div>
          <div className={styles.password}>
            {isLoadingMfo
              ? <Skeleton width="100%" height={60} inline />
              : <Input
                  {...register('password')}
                  title={<Translate id="multilogin.add.password" />}
                  type="password"
                  size="big"
                  disabled={isLoadingMfo}
              />
            }
          </div>
        </div>
        <div className={styles.button}>
          <Button
            disabled={isLoadingMfo || !values.password || !values.login || isLoadingMfoAdd}
            type="submit"
          >
            <Translate id="multilogin.add.button" />
          </Button>
        </div>
      </form>

      <p className={styles.attention} dangerouslySetInnerHTML={{ __html: mfo?.description }} />
    </div>
  );
};

export default MultiloginAddMfoForm;
