import React from 'react';
import Slider from 'react-slick';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';

import styles from './MultiloginSlider.module.scss';

import Button from '../../../UIkit/Button';
import Arrow from '../../../../../assets/images/ArrLeft';
import ImageWrapper from '../../ImageWrapper';

import { Mfo } from '../../../../services/Multilogin/api';
import { useQuery } from '../../../hooks/useQuery';
import { ModalType } from '../../../enum/ModalType.enum';

type Div = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const settings = {
  arrows: false,
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  centerMode: true,
  responsive: [
    {
      breakpoint: 2400,
      settings: {
        slidesToShow: 3,
        initialSlide: 0,
        infinite: false,
        centerMode: false,
        arrows: true,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 1060,
      settings: {
        slidesToShow: 2,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        initialSlide: 0,
      },
    },
  ],
};

const NextArrow: React.FC<Div> = (props) => (
  <div {...props} className={styles.nextArrow} style={{}}>
    <Arrow />
  </div>
);

const PrevArrow: React.FC<Div> = (props) => (
  <div {...props} className={styles.prevArrow} style={{}}>
    <Arrow />
  </div>
);

export interface SliderMultiloginProps {
  mfoAll: Mfo[]
  isLoading?: boolean
}

const MultiloginSlider: React.FC<SliderMultiloginProps> = ({
  mfoAll,
  isLoading,
}) => {
  const { setQuery } = useQuery();

  if (!mfoAll.length) {
    return null;
  }
  return (
    <div className={styles.root}>
      <Slider
        {...settings}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
      >
        {mfoAll.map((mfo) => {
          if (isLoading) {
            return <Skeleton className={styles.slide} key={mfo.id} />;
          }
          return (
            <div className={styles.slide} key={mfo.id}>
              <img className={styles.img} src={mfo.backgroundImage} alt="" />
              <div className={styles.wrap}>
                <div className={styles.row}>
                  <ImageWrapper image={mfo.logoImage} alt="" className={styles.logo} />
                  <div className={styles.brand}>{mfo.name}</div>
                </div>
                <div className={styles.wrapper}>
                  <span className={styles.info}>{mfo.url}</span>
                  <span className={styles.info}>{mfo.legalName}</span>
                </div>
                <div className={styles.info}>{mfo.inn}</div>
                <div className={styles.info}>{mfo.ogrn}</div>
                <Button
                  size="small"
                  style={{ padding: '15px 20px', marginTop: '22px' }}
                  onClick={() => setQuery(mfo.id, ModalType.ADDMFO)}
                >
                  <Translate id="multilogin.mfo.add.button" />
                </Button>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default MultiloginSlider;
