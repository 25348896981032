import { createSelector } from 'reselect';
import { IStore } from '../../../../core/state/IStore';
import { get } from 'lodash';

export const isFetching = (store: any) => store.productsReducer.isFetching;

export const data = createSelector(
  (store: any) => store.productsReducer.data,
  data => data,
);

export const organization = createSelector(
  (store: any) => store.productsReducer.fetchOrganization,
  (fetchOrganization) => {
    const result = get(fetchOrganization, 'right.data', {}) || {};
    return result;
  },
);

export const formFields = createSelector(
  (store: IStore) => store.productsReducer.data,
  data => {
    const res = data?.form?.fields ?? {};

    return Object.entries(res);
  },
);

export const formText = createSelector(
  (store: IStore) => store.productsReducer.data.form,
  form => {
    const title = get(form, 'title_mark', '') || 'shared_components_offerelement_form_info';
    const description = get(form, 'description_mark', '') || 'shared_components_offerelement_form_subinfo';

    return { title, description };
  },
);

export const infoForUser = createSelector(
  (store: IStore) => store.productsReducer.data,
  data => {
    const res = data?.api_info_for_user ?? {};
    return Object.entries(res);
  },
);

export const activeOfferID = createSelector(
  (store: any) => store.productsReducer.activeOfferID,
  activeOfferID => activeOfferID,
);

export const formContractSuccessLoaded = createSelector(
  (store: any) => store.productsReducer.formContractSuccessLoaded,
  formContractSuccessLoaded => formContractSuccessLoaded,
);

export const isFetchingChecklist = (store: any) =>
  store.productsReducer.isFetchingChecklist;

export const dataChecklist = createSelector(
  (store: any) => store.productsReducer.dataChecklist,
  dataChecklist => dataChecklist,
);

export const tooltipInfo = createSelector(
  (store: any) => store.productsReducer.data,
  data => {
    const res = data?.api_info_for_user ?? '<div>Send information...</div>';

    return res;
  },
);

export const formChecklistSuccessSend = createSelector(
  (store: any) => store.productsReducer.formChecklistSuccessSend,
  formChecklistSuccessSend => formChecklistSuccessSend,
);

export const alreadySuccessSendID = createSelector(
  (store: any) => store.productsReducer.alreadySuccessSendID,
  alreadySuccessSendID => alreadySuccessSendID,
);
