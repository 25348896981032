/* eslint-disable dot-notation */

import axios from 'axios';
import has from 'lodash/has';

import { encryptRequest } from '../state/utils/encryptRequest/saga';

import { environment } from '../../../environment';

import { MAIN_HEADER_REQUEST, ENC_METHODS } from './constants';

import { getData as getDataLS } from '../../shared/utils/commonLocalStorage.helper';
import { getData as getDataC } from '../../shared/utils/commonCookies.helper';
import { LOCAL_STORAGE_AUTH_KEY } from '../../shared/constants/Defaults/constants';

const apiURL = environment.API_PREFIX;
const JSON_TYPE = 'JSON';
const FORM_DATA_TYPE = 'FORM_DATA';

/**
 * Настройки для axios
 *
 * Если токен установлен, то условие : token !== '' вернет true
 *
 * @param {string} token
 * @param {string} type 'JSON' | 'FORM_DATA'
 * @returns
 */
const AxiosInstance = (token = '', type = JSON_TYPE) => {
  let contentType = 'application/json';
  if (type === FORM_DATA_TYPE) {
    contentType = 'multipart/form-data';
  }

  if (!token) {
    token = getDataLS(LOCAL_STORAGE_AUTH_KEY) || getDataC(LOCAL_STORAGE_AUTH_KEY);
  }

  // Set config defaults when creating the instance
  const instance = axios.create({
    baseURL: apiURL,
    headers: {
      ...MAIN_HEADER_REQUEST,
      'Content-Type': contentType,
      'x-referer-url': window.location.pathname,
    },
    withCredentials: !token,
    validateStatus: status => {
      return status < 500; // Reject only if the status code is greater than or equal to 500
    },
  });

  if (token !== '') {
    // Alter defaults after instance has been created
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  // NB: ISF-184: Request interceptor for encryption
  instance.interceptors.request.use(
    config => {
      const { method = '', data = {} } = config;

      if (
        has(ENC_METHODS, method) &&
        environment.EXCHANGE_RECOMPUTE &&
        config?.headers?.['Content-Type'] !== 'multipart/form-data'
      ) {
        const req = encryptRequest(data);

        const updatedConfig = {
          ...config,
          data: JSON.stringify({ req }),
        };

        return updatedConfig;
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  return instance;
};

export default AxiosInstance;
