import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * UserProfile API
 */
export default {
  /**
   * Получить данные пользователя
   *
   * @returns
   */
  getData(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/profile`,
    );
  },

  postEventTarget(data: any) {
    const { token, ...body } = data;

    return AxiosInstance(token ? token : undefined).post(
      `${apiPrefix}${versionOnePrefix}/user/goal/log`,
      body,
    );
  },
};
