import AxiosInstance from '../../../core/api/axiosinstance';
import paramsSerializer from '../../../shared/utils/paramsSerializer';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * Payment API
 */
export default {
  /**
   * Отправить запрос на генерацию настроек и транзакции,
   * для последующего запроса на генерацию IFrame
   *
   * @returns
   */
  postDataForLinkForm(data: any) {
    const { token } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/payment/card/link-form`,
      {},
    );
  },

  /**
   * Получить статус запроса по привязке карты (статус транзакции)
   *
   * @param {*} data
   * @returns
   */
  getTransactionStatus(data: any) {
    const { token, transactionID } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/transactions/transaction-status?${paramsSerializer(
        {
          transaction_id: transactionID,
        },
      )}`,
    );
  },

  /**
   * Получить список привязанных карт для профиля пользователя
   *
   * @param {*} data
   * @returns
   */
  getBindedCardsList(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/payment/card/list`,
    );
  },
};
