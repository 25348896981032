import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * Socket API
 */
export default {
  /**
   * Инициализация канала для обмена сокетами
   * Раздел системы: Чат поддержки пользователей
   *
   * @param {*} data
   * @returns
   */
  postDataInitSocket(data: any) {
    const { token, path = '' } = data;

    if (path === '') {
      return null;
    }

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}${path}`,
      data,
    );
  },
};
