import React from 'react';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { Button, Row, Col } from 'antd';
import { mapKeys } from 'lodash';

import Input from '../../componets/Input';
import FormWrapper from '../../componets/FormWrapper';

import {
  isFetchingPut as isFetchingPutSelector,
  dataPut as dataPutSelector,
} from '../../../states/UserSettingsChangeEmail/state/selectors';
import {
  userSettingsChangeEmailPutRequest,
  userSettingsChangeEmailClearForm,
} from '../../../states/UserSettingsChangeEmail/state/duck';

import styles from './UserSettingsChangeEmailForm.module.scss';

interface IFormInputs {
  email: string;
}

/**
 * Форма сменить пароль
 *
 * @returns
 */
const UserSettingsChangeEmailForm: React.FC = () => {
  const dataPut = useSelector(dataPutSelector);
  const isFetchingPut = useSelector(isFetchingPutSelector);
  const dispatch = useDispatch();

  // cdM
  React.useEffect(() => {
    dispatch({
      type: userSettingsChangeEmailClearForm.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm<IFormInputs>({ mode: 'all' });
  const {
    handleSubmit,
    control,
    getValues,
    formState,
    setError,
    reset,
  } = methods;
  const { errors } = formState;
  const { isValid } = useFormState({ control });
  const onSubmit = (data: IFormInputs) =>
    dispatch({
      type: userSettingsChangeEmailPutRequest.toString(),
      payload: data,
    });

  // eslint-disable-next-line camelcase
  const isValidationUndefined = typeof dataPut?.form?.is_valid === 'undefined';

  // cdU
  React.useEffect(() => {
    if (!isValidationUndefined) {
      const { form } = dataPut;
      const { is_valid: isFormValid, fields } = form;

      if (isFormValid) {
        mapKeys(fields, (_: any, key: any) => {
          reset({
            [key]: '',
          });
        });

        return undefined;
      }

      mapKeys(fields, (val: any, key: any) => {
        const { is_valid: isFieldValid, error_messages: errMsgs } = val;

        if (!isFieldValid) {
          setError(key, {
            type: 'manual',
            message: errMsgs[0],
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPut]);

  return (
    <>
      <Translate>
        {({ translate }) => (
          <FormWrapper
            name={`${translate(
              'shared_containers_usersettingsprofile_yourprofile_edit_email',
            ).toString()}`}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row gutter={15}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className={styles.fieldWrapper}
                >
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'error_field_required',
                      maxLength: {
                        value: 255,
                        message: 'error_field_max_length_is_255',
                      },
                      pattern: {
                        value: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'error_field_is_not_email',
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        style={{
                          width: '100%',
                        }}
                        type="text"
                        isError={errors?.email ? true : false}
                        isGood={
                          !errors?.email && getValues('email') !== ''
                            ? true
                            : false
                        }
                        label={`${translate(
                          'shared_containers_usersettingsprofile_yourprofile_edit_type_email',
                        ).toString()}`}
                        placeholder={`${translate(
                          'shared_containers_usersettings_change_email_placeholder',
                        ).toString()}`}
                        isSuffix={false}
                        suffix=""
                      />
                    )}
                  />

                  <div className={styles.errorMessage}>
                    {errors?.email?.message && (
                      <Translate id={`${errors?.email?.message}`} />
                    )}
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={12}
                  className={styles.fieldWrapper}
                  style={{
                    paddingBottom: '2px',
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    marginTop: 10,
                  }}
                >
                  <Button
                    type="primary"
                    shape="round"
                    style={{
                      height: '48px',
                    }}
                    disabled={!isValid}
                    onClick={handleSubmit(onSubmit)}
                    loading={isFetchingPut}
                  >
                    <Translate id="shared_containers_usersettingsprofile_yourprofile_edit_save" />
                  </Button>
                </Col>
              </Row>
            </form>
          </FormWrapper>
        )}
      </Translate>
    </>
  );
};

export default UserSettingsChangeEmailForm;
