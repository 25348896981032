import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * UserCashbackBonusTask API
 */
export default {
  /**
   * Получить данные по бонусным задачам для пользователя
   *
   * @param {*} data
   * @returns
   */
  getBonusActions(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/bonus-actions`,
    );
  },

  /**
   * Выполнение бонусной задачи
   *
   * @param {*} data
   * @returns
   */

  postShareSocialObserver(data: any) {
    const { token, id } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/user/bonus-actions/share-in-social/${id}`,
    );
  },

  /**
   * Получение реферальной сылки
   *
   * @param {*} data
   * @returns
   */

  getRefUrl(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/referral`,
    );
  },

  getInstructions(data: any) {
    const { token } = data;
    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/instructions`,
    );
  },

};
