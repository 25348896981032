import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';
import promiseToResultHelper from '../../../shared/utils/promiseToResult.helper';

const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

export interface SetTariffReq {
  subscribesId: number
}
export interface SetTariffRes {
  status: boolean
  link: string
}
const setTariff = (data: SetTariffReq) => {
  const { subscribesId } = data;
  return promiseToResultHelper(AxiosInstance().get<SetTariffRes>(
    `/${versionOnePrefix}/payment/link-subscribe/${subscribesId}`,
  ));
};

export const TariffAPI = {
  setTariff,
};
