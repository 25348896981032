/* eslint-disable array-callback-return */
import React from 'react';
import { Tariff } from '@finanso/widget';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import styles from './TariffModal.module.scss';

import Header from '../../../../views/Auth/components/Header';

import { userProfile } from '../../../../states/UserProfile/state/selectors';
import { instructions as instructionsSelector } from '../../../../states/UserTask/state/selectors';
import { Instructions } from '../../../enum/Instructions.enum';
import { setTariffFx } from '../../../../states/Tariff/event';

export const TariffModal = () => {
  const profile = useSelector(userProfile);
  const instructions = useSelector(instructionsSelector);
  const instruction = instructions
    .find(({ handler: { alias } }: Record<string, any>) => alias === Instructions.TARIFF);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const urlsTariff = query.get('tariff') || false;

  if (!instruction && !urlsTariff) {
    return null;
  }
  return (
    <div className={styles.root}>
      <Header hideClose />

      <Tariff
        authToken=""
        country={profile?.country_info?.code}
        language={profile?.language?.code}
        onTariff={(id) => setTariffFx({ subscribesId: id })}
      />
    </div>
  );
};
