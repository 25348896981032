import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * User Settings Change Phone API
 */
export default {
  /**
   * Получить данные формы о коде подтверждения
   *
   * @returns
   */
  getDataPhoneCode(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/phone-code`,
    );
  },

  /**
   * Отправить данные формы для получения кода подтверждения
   *
   * @returns
   */
  postDataPhoneCode(data: any) {
    const { token, phone } = data;

    return AxiosInstance(token).post(
      `${apiPrefix}${versionOnePrefix}/user/phone-code`,
      {
        phone,
      },
    );
  },

  /**
   * Получить данные формы
   *
   * @returns
   */
  getDataForm(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/user/phone`,
    );
  },

  /**
   * Отправить данные формы для изменения номера телефона
   *
   * @returns
   */
  putDataForm(data: any) {
    const { token, phone, code } = data;

    return AxiosInstance(token).put(
      `${apiPrefix}${versionOnePrefix}/user/phone`,
      {
        phone,
        code,
      },
    );
  },
};
