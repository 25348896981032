import { APIDone } from '../../core/api/Result';

const promiseToResultHelper = <T> (promise: Promise<T>): Promise<APIDone<T>> => {
  return promise
    .then((v: any) => {
      if (v.status !== 200) {
        throw v;
      }

      return v;
    }) as any;
};

export default promiseToResultHelper;
