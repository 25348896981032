import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { Translate } from 'react-localize-redux';
import { Button } from 'antd';

import { ModalContext } from '../ModalWrapper/ModalWrapper';

import stylesFields from '../../OfferElementCard/OfferElementCard.module.scss';
import styles from './ModalFields.module.scss';

import {
  isFetchingFields as isFetchingFieldsSelector,
} from '../../../../states/ModalSystem/state/selectors';

import protection from '../../../../../assets/images/protection.svg';
import FieldsWrapper from '../../../containers/FieldsWrapper/FieldsWrapper';
import { parsRulesForm } from '../../../utils/parsRulesForm.helper';

const ModalFields = () => {
  const { payload: payloadData } = useContext(ModalContext);
  const payload: Record<string, any> = payloadData;

  const isFetchingFields = useSelector(isFetchingFieldsSelector);

  const dispatch = useDispatch();

  const methods = useForm();
  const {
    handleSubmit,
    control,
    getValues,
    setError,
  } = methods;
  const { isValid } = useFormState({ control });

  const onSubmit = () => {
    dispatch({
      type: payload?.callbackType.toString(),
      payload: { ...payload?.callbackPayload, ...getValues() },
    });
  };

  const restrictionsFields = Object.entries(payload?.form?.fields);
  const categoryFields = Object.entries(restrictionsFields.reduce((curr: any, acc: any) => {
    curr[acc[1].category] = curr[acc[1].category] || [];
    curr[acc[1].category].push(acc);
    return curr;
  }, Object.create(null)));

  const [
    title,
    description,
  ] = [
    payload?.form?.title_mark,
    payload?.form?.description_mark,
  ];

  const handlerBlur = (e: React.FocusEvent<HTMLInputElement, Element>): void => {
    const { name } = e.target;
    const value = getValues(name);
    const field = payload?.form?.fields[name];

    parsRulesForm({ field, value, name, setError });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={stylesFields.infoOffer}>
          <div className={stylesFields.infoOfferHeader}>
            <Translate id={title} />
          </div>
          <div className={stylesFields.infoOfferSubHeader}>
            <Translate id={description} />
          </div>
        </div>

        <FieldsWrapper
          categoryFields={categoryFields}
          methods={methods}
          handlerBlur={handlerBlur}
        />

        <div className={styles.btnGroup}>
          <Button
            className={styles.btn}
            type="primary"
            disabled={!isValid}
            htmlType="submit"
            loading={isFetchingFields}
          >
            <Translate id="shared_offer_element_send_request" />
          </Button>
          <div className={styles.secure}>
            <img src={protection} alt="" />
            <div>
              <Translate id="deposit.stripe.protection" />
            </div>
          </div>
        </div>
        <p className={styles.law}>
          <Translate id="auth.options.lwc" />
        </p>
      </form>
    </FormProvider>
  );
};

export default ModalFields;
