import styled from 'styled-components';
import { Button } from 'antd';

export const ButtonStyled = styled(Button)`
  height: 100%;
  border-top-width: 0;
  border-bottom-width: 0;
  border: 1px solid rgba(214, 220, 227, 0.4);
  border-bottom: 0px;
  box-shadow: none;
  color: #37404b;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Montserrat, sans-serif;

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    border-bottom: 0 !important;
    box-shadow: none !important;
    color: #37404b !important;
    border-color: rgba(214, 220, 227, 0.4) !important;
  }
`;
