import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const USER_DEBTS = 'USER_DEBTS';

// Запрос информации по разделу "Мои задолженности" из АПИ
export const userDebtsRequest = createAction(`${USER_DEBTS}/REQUEST`);
export const userDebtsSuccess = createAction(`${USER_DEBTS}/SUCCESS`);
export const userDebtsFailure = createAction(`${USER_DEBTS}/FAILURE`);

export const userDebtsResult = createAction(`${USER_DEBTS}/RESULT`);

// Запрос на обновление Задолжностей
export const userDebtsUpdate = createAction(`${USER_DEBTS}/UPDATE`);

// Запрос информации по разделу "Мои задолженности" из АПИ
export const userDebtsStatusRequest = createAction(
  `${USER_DEBTS}/STATUS_REQUEST`,
);
export const userDebtsStatusSuccess = createAction(
  `${USER_DEBTS}/STATUS_SUCCESS`,
);
export const userDebtsStatusFailure = createAction(
  `${USER_DEBTS}/STATUS_FAILURE`,
);

export const userDebtsStatusResult = createAction(
  `${USER_DEBTS}/STATUS_RESULT`,
);

export const userDebtsLimitsRequest = createAction(
  `${USER_DEBTS}/LIMITS_REQUEST`,
);
export const userDebtsLimitsSuccess = createAction(
  `${USER_DEBTS}/LIMITS_SUCCESS`,
);
export const userDebtsLimitsFailure = createAction(
  `${USER_DEBTS}/LIMITS_FAILURE`,
);

export const userDebtsLimitsResult = createAction(
  `${USER_DEBTS}/LIMITS_RESULT`,
);

export const userDebtsUpdateRequest = createAction(
  `${USER_DEBTS}/UPDATE_REQUEST`,
);
export const userDebtsUpdateSuccess = createAction(
  `${USER_DEBTS}/UPDATE_SUCCESS`,
);
export const userDebtsUpdateFailure = createAction(
  `${USER_DEBTS}/UPDATE_FAILURE`,
);

export const userDebtsUpdateResult = createAction(
  `${USER_DEBTS}/UPDATE_RESULT`,
);

// Reducers ==========================

// Статус запроса данных из АПИ
const isFetching = createReducer(false, {
  [userDebtsRequest.toString()]: () => true,
  [userDebtsSuccess.toString()]: () => false,
  [userDebtsFailure.toString()]: () => false,
});

// Статус запроса данных из АПИ
const isFetchingStatus = createReducer(false, {
  [userDebtsStatusRequest.toString()]: () => true,
  [userDebtsStatusSuccess.toString()]: () => false,
  [userDebtsStatusFailure.toString()]: () => false,
});

const isFetchingLimits = createReducer(false, {
  [userDebtsLimitsRequest.toString()]: () => true,
  [userDebtsLimitsSuccess.toString()]: () => false,
  [userDebtsLimitsFailure.toString()]: () => false,
});

const isFetchingUpdate = createReducer(false, {
  [userDebtsUpdateRequest.toString()]: () => true,
  [userDebtsUpdateSuccess.toString()]: () => false,
  [userDebtsUpdateFailure.toString()]: () => false,
});

// Успешный результат запроса из АПИ
const data = createReducer(
  {},
  {
    [userDebtsRequest.toString()]: () => ({}),
    [userDebtsSuccess.toString()]: (_state, action) => action.payload,
  },
);

// Успешный результат запроса из АПИ
const dataStatus = createReducer(
  {},
  {
    [userDebtsStatusRequest.toString()]: () => ({}),
    [userDebtsStatusSuccess.toString()]: (_state, action) => action.payload,
  },
);

const dataLimits = createReducer(
  {},
  {
    [userDebtsLimitsRequest.toString()]: () => ({}),
    [userDebtsLimitsSuccess.toString()]: (_state, action) => action.payload,
  },
);

const dataUpdate = createReducer(
  {},
  {
    [userDebtsUpdateRequest.toString()]: () => ({}),
    [userDebtsUpdateSuccess.toString()]: (_state, action) => action.payload,
  },
);

// Результат запроса из АПИ
const result = createReducer(
  {},
  {
    [userDebtsRequest.toString()]: () => ({}),
    [userDebtsResult.toString()]: (_state, action) => action.payload,
  },
);

// Результат запроса из АПИ
const resultStatus = createReducer(
  {},
  {
    [userDebtsStatusRequest.toString()]: () => ({}),
    [userDebtsStatusResult.toString()]: (_state, action) => action.payload,
  },
);

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  result,
  isFetchingStatus,
  resultStatus,
  dataStatus,
  isFetchingLimits,
  dataLimits,
  isFetchingUpdate,
  dataUpdate,
});

export default reducer;
