import { createSelector } from 'reselect';

export const isFetchingValidation = (store: any) =>
  store.registerReducer.isFetchingValidation;

export const isFetchingSMSCode = (store: any) =>
  store.registerReducer.isFetchingSMSCode;

export const isFetchingCallCode = (store: any) =>
  store.registerReducer.isFetchingCallCode;

export const isFetchingEmailCode = (store: any) =>
  store.registerReducer.isFetchingEmailCode;

export const recaptchaToken = createSelector(
  (store: any) => store.registerReducer.recaptchaToken,
  recaptchaToken => recaptchaToken,
);

export const errorValidationMessage = createSelector(
  (store: any) => store.registerReducer.errorValidation.message,
  message => message || null,
);

export const validationStatus = createSelector(
  (store: any) => store.registerReducer.dataValidation.result,
  result => result || null,
);

export const errorSMSCodeMessage = createSelector(
  (store: any) => store.registerReducer.errorSMSCode.message,
  message => message || null,
);

export const smsCodeStatus = createSelector(
  (store: any) => store.registerReducer.dataSMSCode.result,
  result => result || null,
);

export const errorCallCodeMessage = createSelector(
  (store: any) => store.registerReducer.errorCallCode.message,
  message => message || null,
);

export const callCodeStatus = createSelector(
  (store: any) => store.registerReducer.dataCallCode.result,
  result => result || null,
);

export const errorEmailCodeMessage = createSelector(
  (store: any) => store.registerReducer.errorEmailCode,
  errorEmailCode => {
    const message = errorEmailCode?.message?.email?.[0] ?? null;

    return message;
  },
);

export const emailCode = createSelector(
  (store: any) => store.registerReducer.dataEmailCode,
  dataEmailCode => dataEmailCode,
);

export const emailCodeStatus = createSelector(
  (store: any) => store.registerReducer.dataEmailCode.result,
  result => result || null,
);

export const isFetchingPhoneInfo = (store: any) =>
  store.registerReducer.isFetchingPhoneInfo;

export const errorPhoneInfoMessage = createSelector(
  (store: any) => store.registerReducer.errorPhoneInfo.message,
  message => message || null,
);

export const phoneInfoStatus = createSelector(
  (store: any) => store.registerReducer.dataPhoneInfo.result,
  result => result || null,
);

export const phoneInfo = createSelector(
  (store: any) => store.registerReducer.dataPhoneInfo,
  dataPhoneInfo => dataPhoneInfo,
);

export const isFetchingRegister = (store: any) =>
  store.registerReducer.isFetchingRegister;

export const errorRegisterMessage = createSelector(
  (store: any) => store.registerReducer.errorRegister.message,
  message => message || null,
);

export const registerInfoStatus = createSelector(
  (store: any) => store.registerReducer.dataRegister.result,
  result => result || null,
);

export const registerData = createSelector(
  (store: any) => store.registerReducer.dataRegister,
  dataRegister => dataRegister,
);

export const dialogue = createSelector(
  (store: any) => store.registerReducer.dialogue,
  dialogue => dialogue,
);

export const questionsStepOne = createSelector(
  (store: any) => store.registerReducer.questionsStepOne,
  questionsStepOne => questionsStepOne,
);

export const questionsStepTwo = createSelector(
  (store: any) => store.registerReducer.questionsStepTwo,
  questionsStepTwo => questionsStepTwo,
);

export const Script = createSelector(
  (store: any) => store.registerReducer.Script,
  Script => Script,
);

export const currentStep = createSelector(
  (store: any) => store.registerReducer.currentStep,
  currentStep => currentStep,
);

export const stepsTotal = createSelector(
  (store: any) => store.registerReducer.stepsTotal,
  stepsTotal => stepsTotal,
);

export const currentActiveButton = createSelector(
  (store: any) => store.registerReducer.currentActiveButton,
  currentActiveButton => currentActiveButton,
);

export const historyOfActiveButtons = createSelector(
  (store: any) => store.registerReducer.historyOfActiveButtons,
  historyOfActiveButtons => historyOfActiveButtons,
);

export const listOfActiveButtons = createSelector(
  (store: any) => store.registerReducer.listOfActiveButtons,
  listOfActiveButtons => listOfActiveButtons,
);
