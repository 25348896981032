/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { productsRequest } from '../../../../states/Products/state/duck';
import {
  activeOfferID as activeOfferIDSelector,
  formFields as formFieldsSelector, formText as formTextSelector,
  infoForUser as infoForUserSelector,
  isFetching as isFetchingSelector,
} from '../../../../states/Products/state/selectors';

import OfferElementWithForms from '../../OfferElementWithForms';
import { ModalContext } from '../ModalWrapper/ModalWrapper';

const ApiOfferModal = (props: any) => {
  const {
    type,
    productsRequest,
    offerID,
    formFields,
    infoForUser,
    isFetching,
    formText,
  } = props;
  const { onClose } = useContext(ModalContext);

  const { handler: { data } } = type;

  useEffect(() => {
    productsRequest({ offerID: data?.credit_product_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.credit_product_id]);

  return (
    <OfferElementWithForms
      id={offerID}
      formFields={formFields}
      infoForUser={infoForUser}
      rawUrl={''}
      url={''}
      isFetching={isFetching}
      cancelClick={onClose}
      title={formText.title}
      description={formText.description}
    />
  );
};

const mapStateToProps = (state: any) => ({
  offerID: activeOfferIDSelector(state),
  formFields: formFieldsSelector(state),
  infoForUser: infoForUserSelector(state),
  isFetching: isFetchingSelector(state),
  formText: formTextSelector(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    productsRequest,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ApiOfferModal);
