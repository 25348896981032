/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'antd';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';
import { get } from 'lodash';

import { chatSendMsgRequest } from '../../../states/LoanSelection/state/duck';
import {
  isChatInputNeedClear as isChatInputNeedClearSelector,
  activeServerMessage as activeServerMessageSelector,
  dataActiveButton as dataActiveButtonSelector,
} from '../../../states/LoanSelection/state/selectors';

import { useWindowSize } from '../../hooks/useWindowSize';

import { DEVICE_NETBOOKS_WIDTH } from '../../constants/Defaults/constants';

import styles from './ChatLoanSelectionMobileFooter.module.scss';
import { InputStyled } from './theme';

import newDialogueImage from './assets/images/newDialogue.svg';
import rightArrowImage from './assets/images/rightArrow.svg';

const initState = {
  text: '',
};

/**
 * Мобильная версия. Используется в лейауте приложения!
 *
 * Футер выбранного чата в разделе помощь
 *
 * @returns
 */
const ChatLoanSelectionMobileFooter: React.FC<any> = () => {
  const isChatInputNeedClear = useSelector(isChatInputNeedClearSelector);
  const dataActiveButton = useSelector(dataActiveButtonSelector);
  const activeServerMessage = useSelector(activeServerMessageSelector);
  const msgType = get(activeServerMessage, 'value.type', 'NO_DATA');
  const isReference = msgType === 'reference' ? true : false;
  const isNumber = msgType === 'number' ? true : false;

  const dispatch = useDispatch();

  const windowSize = useWindowSize();
  const { width = 0 } = windowSize;

  // По умолчанию считаем что запущено на десктопе, мобила = false
  let isMobile = false;

  if (width < DEVICE_NETBOOKS_WIDTH) {
    isMobile = true;
  }

  const [state, setState] = React.useState(initState);
  const { text } = state;
  const formValid = text.trim() !== '' ? true : false;

  function handleChangeState(name: string, value: number) {
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  const handleChangeText = React.useCallback(event => {
    const { name, value } = event.target;

    handleChangeState(name, value);
  }, []);

  const handleCreateNewScenario = React.useCallback(() => {
    dispatch({
      type: chatSendMsgRequest.toString(),
      payload: { text: 'pick_me_a_loan' },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSentChatText = React.useCallback((event: any) => {
    const { currentTarget } = event;
    const text = currentTarget.getAttribute('data-input-value');

    dispatch({
      type: chatSendMsgRequest.toString(),
      payload: { text },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    // NB: Очищать инпут после успешной отправки сообщения на сервер
    if (isChatInputNeedClear && dataActiveButton === '') {
      setState(prevState => ({ ...prevState, text: dataActiveButton }));
    }

    // NB: Добавлять в инпут значение, выбранное по кнопке
    if (isNumber && dataActiveButton !== '') {
      setState(prevState => ({ ...prevState, text: dataActiveButton }));
    }

    // NB: Добавлять в инпут значение, выбранное по кнопке
    if (isReference && dataActiveButton !== '') {
      setState(prevState => ({ ...prevState, text: dataActiveButton }));
    }
  }, [isReference, dataActiveButton, isChatInputNeedClear, isNumber]);

  return (
    <div className={cx(styles.footerChat)}>
      <Translate>
        {({ translate }) => {
          let tempValue = text;

          return (
            <InputStyled
              disabled={isReference}
              className={styles.searchInput}
              placeholder={translate(
                'shared.componets.helpselectedchatfooter.message',
              ).toString()}
              suffix={
                <img
                  src={newDialogueImage}
                  alt="+"
                  height={18}
                  onClick={handleCreateNewScenario}
                />
              }
              value={tempValue}
              onChange={handleChangeText}
              // NB: Виртуальная клавиатура, пока в чате подбора кредита, не нужна.
              inputMode="none"
            />
          );
        }}
      </Translate>
      <Button
        style={{
          height: '40px',
          paddingLeft: isMobile ? '20px' : '30px',
          paddingRight: isMobile ? '20px' : '30px',
        }}
        type="primary"
        shape="round"
        data-input-value={text}
        onClick={handleSentChatText}
        disabled={!formValid}
      >
        <img src={rightArrowImage} alt="➜" height={18} />
      </Button>
    </div>
  );
};

export default ChatLoanSelectionMobileFooter;
