import React from 'react';
import cx from 'classnames';
import { Translate, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';

import styles from './Description.module.scss';

import { activeRegion as activeRegionSelector } from '../../../../states/Languages/state/selectors';
import { useLocation } from 'react-router-dom';

const Description: React.FC = ({ translate, children }: any) => {
  const activeRegion = useSelector(activeRegionSelector);

  const location = useLocation();

  const pathArray = location.pathname.split('/').filter(Boolean);

  const isRecovery = pathArray[1] === 'recovery';

  return (
    <>
      {!children ?
        <div className={cx(styles.mainInfo, styles.info)}>
          <img
            src={translate(`${isRecovery ? 'recovery' : 'auth'}.faq.img.${activeRegion}`)}
            alt=""
          />
          <h2 className={styles.infoTitle}>
            <Translate id="auth.faq.title" />
          </h2>
          <ul className={styles.infoList}>
            <li>
              <Translate id="auth.faq.res1" />
            </li>
            <li>
              <Translate id="auth.faq.res2" />
            </li>
            <li>
              <Translate id="auth.faq.res3" />
            </li>
          </ul>
        </div>
        :
        children
      }
    </>
  );
};

export default withLocalize(Description);
