import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const NOTIFY = 'NOTIFY';

// Получение списка
export const notifyClearInfo = createAction(`${NOTIFY}/CLEAR_INFO`);
export const notifyPutErrorInfo = createAction(`${NOTIFY}/PUT_ERROR_INFO`);

export const notifyPutActiveInfo = createAction(`${NOTIFY}/PUT_ACTIVE_INFO`);
export const notifyPutQueueInfo = createAction(`${NOTIFY}/PUT_QUEUE_INFO`);
export const notifyUpdateQueueInfo = createAction(
  `${NOTIFY}/UPDATE_QUEUE_INFO`,
);
export const notifyRemoveActiveInfo = createAction(
  `${NOTIFY}/REMOVE_ACTIVE_INFO`,
);
export const notifyPutErrorFromQueueInfo = createAction(
  `${NOTIFY}/PUT_ERROR_FROM_QUEUE_INFO`,
);

// Reducers ==========================

// Информация об уведомлении
const notifyData = createReducer(
  {},
  {
    [notifyClearInfo.toString()]: () => ({}),
    [notifyPutErrorInfo.toString()]: (_state, action) => action.payload,
    [notifyPutErrorFromQueueInfo.toString()]: (_state, action) =>
      action.payload,
  },
);

// Активные уведомления
const notifyActive = createReducer([], {
  [notifyPutActiveInfo.toString()]: (_state, action) => action.payload,
});

// Очередь уведомлений
const notifyQueue = createReducer([], {
  [notifyPutQueueInfo.toString()]: (_state, action) => action.payload,
  [notifyUpdateQueueInfo.toString()]: (_state, action) => action.payload,
});

// Root Reducer
const reducer = combineReducers({
  notifyData,
  notifyActive,
  notifyQueue,
});

export default reducer;
