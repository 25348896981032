import * as Sentry from '@sentry/react';

interface IParams {
  level: Sentry.Severity;
  tag: string;
  user: Record<any, any>;
  payload: Record<any, any>;
  message: string;
}

/**
 * Формирует и передает сообщение об ошибке в сервис:
 * https://sentry.leadbrothers.co/leadbrothers/react/
 *
 * @params {Object}
 * @user {Object} пользователь у которого возникла ошибка в рантайме
 * @level {Sentry.Severity} уровень критичности собщения: warning | error
 * @tag {String} тег значение, для последующего удобства поиска и сортировки в sentri.io
 * @tag {String} сообщение, для последующего удобства поиска и сортировки в sentri.io
 * @payload {Object} дополнительные данные для анализа проблемы
 *
 * @returns Sentry
 */
export const sentryEnchancedMessage = (params: IParams) => {
  const {
    level = Sentry.Severity.Warning,
    tag = '',
    user = {},
    payload = {},
    message = '',
  } = params;

  return Sentry.withScope(scope => {
    scope.setLevel(level);
    scope.setTag('ErrorTag', tag);
    scope.setExtra('Report', {
      payload: JSON.stringify(payload),
      'Application user info': JSON.stringify(user),
      UA: JSON.stringify(window.navigator.userAgent),
    });
    Sentry.captureMessage(`${message}: ${tag}`);
  });
};
