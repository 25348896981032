import { put, takeLatest, call, takeEvery, delay } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';

import { paymentAPI } from '../../../../services/Payment/api';

import {
  paymentPrepareRequest,
  paymentPrepareSuccess,
  paymentPrepareFailure,
  paymentPrepareResult,
  paymentCheckTransactionRequest,
  paymentCheckTransactionSuccess,
  paymentCheckTransactionFailure,
  paymentCheckTransactionResult,
  paymentWaitOn,
  paymentCheckBindedCardsRequest,
  paymentCheckBindedCardsSuccess,
  paymentCheckBindedCardsFailure,
  paymentCheckBindedCardsResult,
} from '../duck';
import {
  transactionID as transactionIDSelector,
  isStillWaiting as isStillWaitingSelector,
} from '../selectors';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';
import { select } from 'redux-saga-test-plan/matchers';

export function* prepareDataForIFrame(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const res: any = yield call(paymentAPI.postDataForLinkForm, {
    ...completePayload,
  });

  yield put({
    type: paymentPrepareResult.toString(),
    payload: createResultV2(res),
  });
}

export function* checkPrepareDataForIFrame(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const data = successData?.data ?? {};

    yield put({
      type: paymentPrepareSuccess.toString(),
      payload: { ...data },
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: paymentPrepareFailure.toString(),
    });
  }
}

export function* getTransactionData(action: any) {
  yield put({
    type: paymentWaitOn.toString(),
  });

  yield delay(60000);

  const isStillWaiting = yield select(isStillWaitingSelector);
  if (!isStillWaiting) {
    return;
  }

  const completePayload: any = yield call(checkToken, action);
  const transactionID = yield select(transactionIDSelector);
  const res: any = yield call(paymentAPI.getTransactionStatus, {
    ...completePayload,
    transactionID,
  });

  yield put({
    type: paymentCheckTransactionResult.toString(),
    payload: createResultV2(res),
  });
}

export function* checkTransactionData(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const data = successData?.data ?? {};

    yield put({
      type: paymentCheckTransactionSuccess.toString(),
      payload: { ...data },
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: paymentCheckTransactionFailure.toString(),
    });
  }
}

export function* getBindedCards(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const res: any = yield call(paymentAPI.getBindedCardsList, {
    ...completePayload,
  });

  yield put({
    type: paymentCheckBindedCardsResult.toString(),
    payload: createResultV2(res),
  });
}

export function* checkBindedCards(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const data = successData?.data ?? [];

    yield put({
      type: paymentCheckBindedCardsSuccess.toString(),
      payload: data,
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: paymentCheckBindedCardsFailure.toString(),
    });
  }
}

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    paymentPrepareRequest,
    safe(onError, prepareDataForIFrame, {
      terminator: paymentPrepareFailure,
    }),
  );

  yield takeEvery(
    paymentPrepareResult,
    safe(onError, checkPrepareDataForIFrame),
  );

  yield takeLatest(
    paymentCheckTransactionRequest,
    safe(onError, getTransactionData, {
      terminator: paymentCheckTransactionFailure,
    }),
  );

  yield takeEvery(
    paymentCheckTransactionResult,
    safe(onError, checkTransactionData),
  );

  yield takeLatest(
    paymentCheckBindedCardsRequest,
    safe(onError, getBindedCards, {
      terminator: paymentCheckBindedCardsFailure,
    }),
  );

  yield takeEvery(
    paymentCheckBindedCardsResult,
    safe(onError, checkBindedCards),
  );
}
