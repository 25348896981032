import AxiosInstance from '../../../core/api/axiosinstance';
import paramsSerializer from '../../../shared/utils/paramsSerializer';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * Articles API
 */
export default {
  /**
   * Получить данные всех статей
   *
   * @returns
   */
  getData(data: any) {
    const { token, page = 1, filterData = {} } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/blog/articles?${paramsSerializer({
        page,
        ...filterData,
        'per-page': 12,
      })}`,
    );
  },

  /**
   * Получить данные конкретной статьи
   *
   * @returns
   */
  getDataArticle(data: any) {
    const { token, id } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/blog/articles/${id}`,
    );
  },

  /**
   * Получить данные всех категорий
   *
   * @returns
   */
  getDataСategories(data: any) {
    const { token } = data;

    return AxiosInstance(token).get(
      `${apiPrefix}${versionOnePrefix}/blog/articles/categories`,
    );
  },
};
