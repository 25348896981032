import React, { useState } from 'react';
import AutoComplete from './DadataUniversal.styles';
import { makeRequest } from '../../../../../../../core/api/dadata';

interface IDadataUniversalProps {
  placeholder: string,
  name: string,
  className: any,
  value: string,
  onChange: (data: string) => void,
}

const DadataUniversal: React.FC<IDadataUniversalProps> = (props) => {
  const {
    placeholder,
    className,
    name,
    value,
    onChange,
  } = props;

  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [delay, setDelay] = useState<any>(null);

  const address = {
    region: '',
    city: '',
    street: '',
    house: '',
    region_fias_id: '',
    city_fias_id: '',
    street_fias_id: '',
  };

  let query: any;
  if (name.includes('region')) {
    query = {
      field: 'region',
      isRestrict: false,
    };
  }
  if (name.includes('city')) {
    query = {
      query: address.region_fias_id && [{ region_fias_id: address.region_fias_id }],
      isRestrict: address.region_fias_id ? true : false,
      field: 'city',
    };
  }
  if (name.includes('street')) {
    query = {
      query: address.city_fias_id && [{ city_fias_id: address.city_fias_id }],
      isRestrict: address.city_fias_id ? true : false,
      field: 'street',
    };
  }
  if (name.includes('house')) {
    query = {
      query: address.street_fias_id && [{ street_fias_id: address.street_fias_id }],
      isRestrict: address.street_fias_id ? true : false,
      field: 'house',
    };
  }

  const handleInputChange = (
    newValue: string,
    query: { query:
        { region: string; }[] |
        { city: string; }[] |
        { street: string; }[] |
        { house: string; }[];
      field: string;
      isRestrict: boolean}) => {
    if (delay) {
      clearTimeout(delay);
      setDelay(null);
    }

    const fetch = () => {
      const result = Promise.resolve(
        makeRequest(
          newValue,
          'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
          'address',
          query.query,
          query.isRestrict,
          query.field,
        ),
      );
      result.then((suggestionsList: any) => {
        const suggestionsListParse = JSON.parse(suggestionsList);
        const fieldName = `${query.field}_fias_id`;
        const valuesList = suggestionsListParse.suggestions.reduce((acc: any, curr: any) => {
          acc = [...acc, {
            label: curr.value, value: curr.value, [fieldName]: curr.data[fieldName],
          }];
          return acc;
        }, []);

        setOptions(valuesList);
      });
    };
    setDelay(setTimeout(fetch, 500));
  };

  const onSearch = (value: string) => {
    onChange(value);
    handleInputChange(value, query);
  };

  const onSelect = (value: string) => {
    onChange(value);
  };

  return (
    <AutoComplete
      className={className}
      options={options}
      onSelect={onSelect}
      onSearch={onSearch}
      placeholder={placeholder}
      value={value}
    />
  );
};
export default DadataUniversal;
