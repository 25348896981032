import React, { useState, useEffect, createContext } from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ModalWrapper.module.scss';
import {
  modalData as modalDataSelector,
} from '../../../../states/ModalSystem/state/selectors';

import { modalClearInfo, modalRemoveActiveInfo } from '../../../../states/ModalSystem/state/duck';

import ModalMain from '../ModalMain/ModalMain';
import { ModalStyled } from '../../ModalWrapper/theme';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { DEVICE_MOBILE_WIDTH_480 } from '../../../constants/Defaults/constants';

interface IProps {
  any?: React.ReactNode;
}

/**
 * Обертка для модальных окон
 *
 * @param {*} props
 * @returns
 */
const ModalWrapper: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(modalDataSelector);

  const [isOpen, setIsOpen] = useState(false);

  const initState = { type: '', ID: null, mod: '', callbackType: '', tag: '', payload: {} };
  const [state, setState] = useState(initState);

  useEffect(() => {
    const ID = get(modalData, 'ID', null);
    const type = get(modalData, 'type', null);
    const mod = get(modalData, 'mod', null);
    const callbackType = get(modalData, 'callbackType', null);
    const tag = get(modalData, 'tag', null);
    const payload: Record<string, any> = get(modalData, 'payload', {});

    if (ID) {
      setState({ mod, type, ID, callbackType, tag, payload });
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData]);

  const onClick = (): any => {
    setIsOpen(!isOpen);
    dispatch({
      type: modalClearInfo.toString(),
    });
    dispatch({
      type: modalRemoveActiveInfo.toString(),
      payload: { modalID: state.ID },
    });
    setState(initState);
  };

  // const onKeyPress = (e: any) => {
  //   if (e.key === 'Esc') {
  //     onClick();
  //   }
  // };

  const windowSize = useWindowSize();
  const { width = 0 } = windowSize;

  let isMobile = false;

  if (width < DEVICE_MOBILE_WIDTH_480) {
    isMobile = true;
  }

  return (
    <>
      <ModalStyled
        centered
        width={isMobile ? 330 : 'auto'}
        visible={isOpen}
        onOk={onClick}
        onCancel={onClick}
        footer={[null, null]}
        destroyOnClose
        className={styles.wrapper}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
        <ModalContext.Provider
          value={{ onClose: onClick, callbackType: state.callbackType, payload: state.payload }}
        >
          <ModalMain
            mod={state.mod}
            type={state.type}
            className={styles.content}
            onClose={onClick}
            tag={state.tag}
          />
        </ModalContext.Provider>
        {/* </div> */}
      </ModalStyled>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = (): any => {};

export const ModalContext = createContext({
  onClose: noop,
  callbackType: '',
  payload: {},
});

export default ModalWrapper;
