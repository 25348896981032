import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const USER_SETTINGS_ACTIVITY = 'USER_SETTINGS_ACTIVITY';

export const userSettingsActivityRequest = createAction(
  `${USER_SETTINGS_ACTIVITY}/REQUEST`,
);
export const userSettingsActivitySuccess = createAction(
  `${USER_SETTINGS_ACTIVITY}/SUCCESS`,
);
export const userSettingsActivityFailure = createAction(
  `${USER_SETTINGS_ACTIVITY}/FAILURE`,
);

export const userSettingsActivityResult = createAction(
  `${USER_SETTINGS_ACTIVITY}/RESULT`,
);

export const userSettingsActivityScrollingRequest = createAction(
  `${USER_SETTINGS_ACTIVITY}/SCROLLING_REQUEST`,
);

export const userSettingsActivityScrollingResult = createAction(
  `${USER_SETTINGS_ACTIVITY}/SCROLLING_RESULT`,
);

// Reducers ==========================

const isFetching = createReducer(false, {
  [userSettingsActivityRequest.toString()]: () => true,
  [userSettingsActivitySuccess.toString()]: () => false,
  [userSettingsActivityFailure.toString()]: () => false,
});

const data = createReducer(
  {},
  {
    [userSettingsActivityRequest.toString()]: () => ({}),
    [userSettingsActivitySuccess.toString()]: (_state, action) =>
      action.payload,
  },
);

const result = createReducer(
  {},
  {
    [userSettingsActivityRequest.toString()]: () => ({}),
    [userSettingsActivityResult.toString()]: (_state, action) => action.payload,
  },
);

const isFetchingScrolling = createReducer(false, {
  [userSettingsActivityScrollingRequest.toString()]: () => true,
  [userSettingsActivitySuccess.toString()]: () => false,
  [userSettingsActivityFailure.toString()]: () => false,
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  result,
  isFetchingScrolling,
});

export default reducer;
