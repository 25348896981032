import React from 'react';
import cx from 'classnames';
import { Translate } from 'react-localize-redux';
import { data as userProfile, isFetching as isFetchingSelector } from '../../../states/UserProfile/state/selectors';

import { formatSum } from '../../utils/formatSum.helper';

import { ButtonStyled } from './theme';
import styles from './BalanceBadge.module.scss';
import { useSelector } from 'react-redux';
import Preloader from '../Preloader';
import { useHistory } from 'react-router-dom';

import iconBalance from './assets/images/fincoin.svg';

const BalanceBadge = (): any => {
  const isFetching = useSelector(isFetchingSelector);
  const { balance } = useSelector(userProfile);

  const history = useHistory();

  const isNegative = balance <= 0;

  return (
    <ButtonStyled
      onClick={() => history.push('/cabinet/balance/deposit')}
    >
      <p>
        <Translate id="shared.componets.mobilephonebalancebadge.mybalance" />
      </p>
      <div
        className={cx(styles.badge, {
          [styles.success]: !isNegative,
          [styles.danger]: isNegative,
        })}
      >
        <img className={styles.iconBalance} src={iconBalance} alt="bagde-balans" />
        {isFetching ? (
          <Preloader size="small" />
        ) : formatSum(balance)}
      </div>
    </ButtonStyled>
  );
};

export default BalanceBadge;
