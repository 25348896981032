import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { logoutSuccess } from '../../../Logout/state/duck';

// Actions ==========================

const USER_PROFILE = 'USER_PROFILE';

// Запрос информации по разделу "Кредитный рейтинг" из АПИ
export const userProfileRequest = createAction(`${USER_PROFILE}/REQUEST`);
export const userProfileSuccess = createAction(`${USER_PROFILE}/SUCCESS`);
export const userProfileFailure = createAction(`${USER_PROFILE}/FAILURE`);

export const removeProfileRequest = createAction(`${USER_PROFILE}/PUT_REQUEST`);
export const removeProfileSuccess = createAction(`${USER_PROFILE}/PUT_SUCCESS`);
export const removeProfileFailure = createAction(`${USER_PROFILE}/PUT_FAILURE`);
export const removeProfileResult = createAction(`${USER_PROFILE}/PUT_RESULT`);

export const userProfileResult = createAction(`${USER_PROFILE}/RESULT`);

export const userProfileChangeAvatarSuccess = createAction(
  `${USER_PROFILE}/CHANGE_AVATAR_SUCCESS`,
);
export const userProfileCancelRequest = createAction(
  `${USER_PROFILE}/CANCEL_REQUEST`,
);

// Reducers ==========================

// Статус запроса данных из АПИ
const isFetching = createReducer(false, {
  [userProfileRequest.toString()]: () => true,
  [userProfileSuccess.toString()]: () => false,
  [userProfileFailure.toString()]: () => false,
  [userProfileCancelRequest.toString()]: () => false,
});

const isRemoveProfile = createReducer(false, {
  [removeProfileRequest.toString()]: () => true,
  [removeProfileSuccess.toString()]: () => false,
  [removeProfileFailure.toString()]: () => false,
});

// Успешный результат запроса из АПИ
const data = createReducer(
  {},
  {
    [userProfileSuccess.toString()]: (_state, action) => action.payload,
    [userProfileChangeAvatarSuccess.toString()]: (_state, action) =>
      action.payload,
    [logoutSuccess.toString()]: () => ({}),
  },
);

// Успешный результат запроса из АПИ
const result = createReducer(
  {},
  {
    [userProfileResult.toString()]: (_state, action) => action.payload,
    [logoutSuccess.toString()]: () => ({}),
  },
);

// Успешный результат запроса из АПИ
const removeProfileData = createReducer(
  {},
  {
    [removeProfileRequest.toString()]: () => ({}),
    [removeProfileResult.toString()]: (_state, action) =>
      action.payload,
  },
);

// Успешный результат запроса из АПИ
const removeProfileDataResult = createReducer(
  {},
  {
    [removeProfileRequest.toString()]: () => ({}),
    [removeProfileResult.toString()]: (_state, action) =>
      action.payload,
  },
);

// Ошибка при запросе данных из АПИ
const error = createReducer(
  {},
  {
    [userProfileSuccess.toString()]: () => ({}),
    [userProfileFailure.toString()]: (_state, action) => action.payload,
    [logoutSuccess.toString()]: () => ({}),
  },
);

// Ошибка при запросе данных из АПИ
const errorRemoveProfile = createReducer([], {
  [removeProfileRequest.toString()]: () => [],
  [removeProfileSuccess.toString()]: () => [],
  [removeProfileFailure.toString()]: (_state, action) => action.payload,
});

const isCached = createReducer(false, {
  [userProfileSuccess.toString()]: () => true,
  [logoutSuccess.toString()]: () => false,
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  error,
  result,
  isCached,
  isRemoveProfile,
  removeProfileData,
  errorRemoveProfile,
  removeProfileDataResult,
});

export default reducer;
