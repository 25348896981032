export const FEEDBACKS__REWIEWS_RES = 'FEEDBACKS__REWIEWS_RES'.toLowerCase();
export const FEEDBACKS__ORGANIZATIONS_RES = 'FEEDBACKS__ORGANIZATIONS_RES'.toLowerCase();
export const FEEDBACKS__CITIES_RES = 'FEEDBACKS__CITIES_RES'.toLowerCase();
export const FEEDBACKS__PRODUCTS_RES = 'FEEDBACKS__PRODUCTS_RES'.toLowerCase();
export const FEEDBACKS__ALL_USER_LIKES_RES = 'FEEDBACKS__ALL_USER_LIKES_RES'.toLowerCase();
export const FEEDBACKS__DATA_FILTERED_RES = 'FEEDBACKS__DATA_FILTERED_RES'.toLowerCase();
export const FEEDBACKS__DATA_RES = 'FEEDBACKS__DATA_RES'.toLowerCase();
export const FEEDBACKS__CREATE_REWIEW_RES = 'FEEDBACKS__CREATE_REWIEW_RES'.toLowerCase();
export const FEEDBACKS__GET_ORG_DATA_RES = 'FEEDBACKS__GET_ORG_DATA_RES'.toLowerCase();
export const FEEDBACKS__GET_CITIES_DATA_RES = 'FEEDBACKS__GET_CITIES_DATA_RES'.toLowerCase();
export const FEEDBACKS__CREATE_COMMENT_RES = 'FEEDBACKS__CREATE_COMMENT_RES'.toLowerCase();
export const FEEDBACKS__ADD_LIKE_RES = 'FEEDBACKS__ADD_LIKE_RES'.toLowerCase();
export const FEEDBACKS__REMOVE_LIKE_RES = 'FEEDBACKS__REMOVE_LIKE_RES'.toLowerCase();
