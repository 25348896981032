import React, { useEffect } from 'react';
import { Tabs } from '@finanso/react-uikit';
import { useStore } from 'effector-react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

import styles from './MultiloginMy.module.scss';

import Button from '../../../UIkit/Button';

import Dashboard from '../Dashboard';
import Alert from '../Alert';
import MultiloginCabinet from '../MultiloginCabinet';

import { useQuery } from '../../../hooks/useQuery';

import { CABINET__MULTTILOGIN_URL } from '../../../constants/Routes/constants';

import { $getCabinetsMfo, $getTotalMfo } from '../../../../states/Multilogin/event';
import { $mfoCabinets, $isLoadingMfoCabinets } from '../../../../states/Multilogin/store';

import { MfoSortType } from '../../../../services/Multilogin/api';

const MultiloginMy: React.FC = () => {
  const mfoCabinets = useStore($mfoCabinets);
  const isLoadingMfoCabinets = useStore($isLoadingMfoCabinets);

  const { setQuery, query } = useQuery({
    name: 'filter',
    observer: (filter) => {
      if (!filter) {
        $getCabinetsMfo({});
      } else {
        $getCabinetsMfo({ filter });
      }
    },
  });

  useEffect(() => {
    $getTotalMfo(null);
    if (!query) {
      setQuery(MfoSortType.COMPANY);
    }
  }, []);

  return (
    <div className={styles.root}>
      <Dashboard />

      <div className={styles.wrap}>
        <div className={styles.button}>
          <Link to={CABINET__MULTTILOGIN_URL}>
            <Button
              size="small"
              style={{ padding: '14px 20px' }}
            >
              <Translate id="multilogin.my.mfo" />
            </Button>
          </Link>
        </div>

        <div className={styles.alert}>
          <Alert />
        </div>

        <div className={styles.title}>
          <Translate id="multilogin.my.title" />
        </div>
      </div>

      <Tabs
        activeTab={query as string}
        tabs={[
          { tab: MfoSortType.COMPANY, value: <Translate id="multilogin.my.filter.all" /> },
          { tab: MfoSortType.ACTIVE, value: <Translate id="multilogin.my.filter.active" /> },
          { tab: MfoSortType.CREATED, value: <Translate id="multilogin.my.filter.created" /> },
        ]}
        onTab={(tab) => setQuery(tab)}
      />

      {mfoCabinets.map(item => <MultiloginCabinet
        {...item}
        key={item.id}
        isLoading={isLoadingMfoCabinets}
      />)}

    </div>

  );
};

export default MultiloginMy;
