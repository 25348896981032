import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

const preFetchedInitialState = {
  countOfFetches: 0,
  iteration: 0,
  total: 0,
  messages: [],
};

// Actions ==========================

const LOAN_SELECTION = 'LOAN_SELECTION';
const CHAT = 'CHAT';

// Запрос информации обо всех сообщениях в чате из АПИ
export const loanSelectionFetchChatRequest = createAction(
  `${LOAN_SELECTION}/FETCH_CHAT_REQUEST`,
);
export const loanSelectionFetchChatRequestFirstTime = createAction(
  `${LOAN_SELECTION}/FETCH_CHAT_REQUEST_FIRST_TIME`,
);
export const loanSelectionFetchChatSuccess = createAction(
  `${LOAN_SELECTION}/FETCH_CHAT_SUCCESS`,
);
export const loanSelectionFetchChatFailure = createAction(
  `${LOAN_SELECTION}/FETCH_CHAT_FAILURE`,
);

// PRE-FETCH Запрос информации обо всех сообщениях в системе из АПИ
export const loanSelectionPreFetchChatRequest = createAction(
  `${LOAN_SELECTION}/PRE_FETCH_CHAT_REQUEST`,
);
export const loanSelectionPreFetchChatSuccess = createAction(
  `${LOAN_SELECTION}/PRE_FETCH_CHAT_SUCCESS`,
);
export const loanSelectionPreFetchChatFailure = createAction(
  `${LOAN_SELECTION}/PRE_FETCH_CHAT_FAILURE`,
);

// Обновить данные в редьюсере
export const loanSelectionUpdateDataRequest = createAction(
  `${LOAN_SELECTION}/UPDATE_DATA_REQUEST`,
);
export const loanSelectionUpdateDataSuccess = createAction(
  `${LOAN_SELECTION}/UPDATE_DATA_SUCCESS`,
);

export const loanSelectionFirstPreFetchChatRequest = createAction(
  `${LOAN_SELECTION}/FIRST_PRE_FETCH_CHAT_REQUEST`,
);
export const loanSelectionFirstPreFetchChatSuccess = createAction(
  `${LOAN_SELECTION}/FIRST_PRE_FETCH_CHAT_SUCCESS`,
);
export const loanSelectionFirstPreFetchChatFailure = createAction(
  `${LOAN_SELECTION}/FIRST_PRE_FETCH_CHAT_FAILURE`,
);

// PRE-FETCH удалить все предзагруженные данные
export const loanSelectionPreFetchChatClear = createAction(
  `${LOAN_SELECTION}/PRE_FETCH_CHAT_CLEAR`,
);

// Отправить сообшение чата
export const chatSendMsgRequest = createAction(`${CHAT}/SEND_MSG_REQUEST`);
export const chatSendMsgSuccess = createAction(`${CHAT}/SEND_MSG_SUCCESS`);
export const chatSendMsgFailure = createAction(`${CHAT}/SEND_MSG_FAILURE`);

// Создать структуру, на основе кторой будет формироваться строка ввода в чате
export const loanSelectionChatInputRequest = createAction(
  `${LOAN_SELECTION}/CHAT_INPUT_REQUEST`,
);
export const loanSelectionChatInputSuccess = createAction(
  `${LOAN_SELECTION}/CHAT_INPUT_SUCCESS`,
);
export const loanSelectionChatInputFailure = createAction(
  `${LOAN_SELECTION}/CHAT_INPUT_FAILURE`,
);

// Создать структуру, на основе кторой будет формироваться строка ввода в чате
export const loanSelectionActiveButtonRequest = createAction(
  `${LOAN_SELECTION}/ACTIVE_BUTTON_REQUEST`,
);
export const loanSelectionActiveButtonSuccess = createAction(
  `${LOAN_SELECTION}/ACTIVE_BUTTON_SUCCESS`,
);
export const loanSelectionActiveButtonFailure = createAction(
  `${LOAN_SELECTION}/ACTIVE_BUTTON_FAILURE`,
);

// Запрос информации о самом последнем сообщении в чате
export const loanSelectionLastMessageRequest = createAction(
  `${LOAN_SELECTION}/LAST_MESSAGE_REQUEST`,
);
export const loanSelectionLastMessageSuccess = createAction(
  `${LOAN_SELECTION}/LAST_MESSAGE_SUCCESS`,
);
export const loanSelectionLastMessageFailure = createAction(
  `${LOAN_SELECTION}/LAST_MESSAGE_FAILURE`,
);

// Reducers ==========================

// Статус запроса данных из АПИ
const isFetching = createReducer(false, {
  [loanSelectionFetchChatRequestFirstTime.toString()]: () => true,
  [loanSelectionFetchChatSuccess.toString()]: () => false,
  [loanSelectionFetchChatFailure.toString()]: () => false,
});

// PRE FETCH Статус запроса данных из АПИ
const isFetchingPreFetch = createReducer(false, {
  [loanSelectionPreFetchChatRequest.toString()]: () => true,
  [loanSelectionPreFetchChatSuccess.toString()]: () => false,
  [loanSelectionPreFetchChatFailure.toString()]: () => false,
});

// FIRST PRE FETCH Статус запроса данных из АПИ
const isFetchingFirstPreFetch = createReducer(false, {
  [loanSelectionFirstPreFetchChatRequest.toString()]: () => true,
  [loanSelectionFirstPreFetchChatSuccess.toString()]: () => false,
  [loanSelectionFirstPreFetchChatFailure.toString()]: () => false,
  // Если сфейлится один из пяти первых запросов, то сбросить статус
  [loanSelectionPreFetchChatFailure.toString()]: () => false,
});

// SEND CHAT MESSAGE Статус запроса данных из АПИ
const isFetchingChatSendMsg = createReducer(false, {
  [chatSendMsgRequest.toString()]: () => true,
  [chatSendMsgSuccess.toString()]: () => false,
  [chatSendMsgFailure.toString()]: () => false,
});

// Обновление данных после отправки сообщения
const isUpdatingDataAfterChatSendMsg = createReducer(false, {
  [chatSendMsgRequest.toString()]: () => true,
  [loanSelectionUpdateDataSuccess.toString()]: () => false,
  [chatSendMsgFailure.toString()]: () => false,
});

// Успешный результат запроса из АПИ. Влияет на состояние инпута в чате
const isChatInputNeedClear = createReducer(false, {
  [loanSelectionChatInputRequest.toString()]: () => false,
  [loanSelectionChatInputSuccess.toString()]: () => true,
  [loanSelectionChatInputFailure.toString()]: () => false,
});

// Запрос данных о последнем сообщении
const isFetchingLastMsg = createReducer(false, {
  [loanSelectionLastMessageRequest.toString()]: () => true,
  [loanSelectionLastMessageSuccess.toString()]: () => false,
  [loanSelectionLastMessageFailure.toString()]: () => false,
});

// Успешный результат запроса из АПИ
const data = createReducer([], {
  [loanSelectionFetchChatRequest.toString()]: () => [],
  [loanSelectionFetchChatSuccess.toString()]: (_state, action) =>
    action.payload,
});

// Успешный результат запроса из АПИ
const dataLastMsg = createReducer([], {
  [loanSelectionLastMessageRequest.toString()]: () => [],
  [loanSelectionLastMessageSuccess.toString()]: (_state, action) =>
    action.payload,
});

// Успешный результат запроса из АПИ
const dataChatInput = createReducer([], {
  [loanSelectionChatInputRequest.toString()]: () => [],
  [loanSelectionChatInputSuccess.toString()]: (_state, action) =>
    action.payload,
});

// Успешный результат запроса из АПИ
const dataActiveButton = createReducer('', {
  [loanSelectionChatInputSuccess.toString()]: () => '',
  [loanSelectionActiveButtonRequest.toString()]: () => '',
  [loanSelectionActiveButtonSuccess.toString()]: (_state, action) =>
    action.payload,
});

// Ошибка при запросе данных из АПИ
const error = createReducer([], {
  [loanSelectionFetchChatRequest.toString()]: () => [],
  [loanSelectionFetchChatFailure.toString()]: (_state, action) =>
    action.payload,
  [loanSelectionPreFetchChatFailure.toString()]: (_state, action) =>
    action.payload,
});

// Ошибка при запросе данных из АПИ
const errorLastMsg = createReducer([], {
  [loanSelectionLastMessageRequest.toString()]: () => [],
  [loanSelectionLastMessageFailure.toString()]: (_state, action) =>
    action.payload,
});

// PRE FETCHED DATA
const preFetchedData = createReducer(
  { ...preFetchedInitialState },
  {
    [loanSelectionPreFetchChatRequest.toString()]: (
      state = preFetchedInitialState,
    ) => ({
      ...state,
    }),
    [loanSelectionPreFetchChatSuccess.toString()]: (_state, action) =>
      action.payload,
    [loanSelectionPreFetchChatClear.toString()]: () => ({
      ...preFetchedInitialState,
    }),
    // Есть информация о высоте от псевдорендера, обновить данные в редьюсере
    [loanSelectionUpdateDataSuccess.toString()]: (
      state = preFetchedInitialState,
      action,
    ) => ({
      ...state,
      messages: [...action.payload.messages],
    }),
    // Успешно пришел ответ от сервера, на текушее сообщение пользователя
    [chatSendMsgSuccess.toString()]: (
      state = preFetchedInitialState,
      action,
    ) => ({
      ...state,
      callFrom: 'createNewMessage',
      messages: [...action.payload.messages],
      total: action.payload.total,
    }),
  },
);

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
  error,
  preFetchedData,
  isFetchingPreFetch,
  isFetchingFirstPreFetch,
  isFetchingChatSendMsg,
  dataChatInput,
  dataActiveButton,
  isUpdatingDataAfterChatSendMsg,
  isChatInputNeedClear,
  isFetchingLastMsg,
  dataLastMsg,
  errorLastMsg,
});

export default reducer;
