/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Translate } from 'react-localize-redux';

import styles from './MenuBlock.module.scss';

import Close from '../../../../../assets/images/Close';
import Trash from '../../../../../assets/images/Trash';
// import Info from '../../../../../assets/images/Info';
// import Message from '../../../../../assets/images/Message';

import { $deleteMfo } from '../../../../states/Multilogin/event';

interface MenuBlockProps {
  handlerClose: () => void
  id: string | number
}

const MenuBlock: React.FC<MenuBlockProps> = ({
  handlerClose,
  id,
}) => {
  const handlerDelete = () => {
    $deleteMfo({ id });
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.item} onClick={handlerDelete}>
          <Trash className={styles.icon} style={{ fill: '#E3405E' }} />
          <Translate id="multilogin.mfo.delete" />
        </div>

        {/* <div className={styles.item}> */}
        {/*  <Info className={styles.icon} /> */}
        {/*  Подробнее о компании */}
        {/* </div> */}

        {/* <div className={styles.item}> */}
        {/*  <Message className={styles.icon} /> */}
        {/*  Оставить отзыв */}
        {/* </div> */}
      </div>

      <Close
        className={styles.close}
        onClick={handlerClose}
        role="presentation"
      />
    </div>
  );
};

export default MenuBlock;
