interface Interface {
  languages: Record<string, string | number>[];
  countries: any[];
}

const useLang = (props: Interface) => {
  const {
    languages = [],
    countries = [],
  } = props;

  const getLangPath = () => {
    const paths = countries.map((region: any) => {
      return languages.map((language: any) => {
        const regCode = region.code.toLowerCase();
        const langCode = language.code.toLowerCase();
        return regCode === langCode ? regCode : `${regCode}-${langCode}`;
      });
    });
    return paths.reduce((acc, val) => acc.concat(val), []);
  };

  const paths = getLangPath();

  return { paths };
};

export default useLang;
