import { createSelector } from 'reselect';

export const notifyData = createSelector(
  (store: any) => store.notificationSystemReducer.notifyData,
  notifyData => notifyData,
);

export const notifyActive = createSelector(
  (store: any) => store.notificationSystemReducer.notifyActive,
  notifyActive => notifyActive,
);

export const notifyQueue = createSelector(
  (store: any) => store.notificationSystemReducer.notifyQueue,
  notifyQueue => notifyQueue,
);
