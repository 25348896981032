import AxiosInstance from '../../../core/api/axiosinstance';

import { environment } from '../../../../environment';

const apiPrefix = environment.API_PREFIX;
const versionOnePrefix = environment.API_VERSION_ONE_PREFIX;

/**
 * Languages API
 */
export default {
  /**
   * Получить данные о странах сопровождаемых приложением
   *
   * @param {*} data
   * @returns
   */
  getList() {
    return AxiosInstance('').get(`${apiPrefix}${versionOnePrefix}/translations/languages`);
  },
};
