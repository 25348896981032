// NB: Разрешенный игнор
/* eslint-disable no-console */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';
import * as firebase from 'firebase/app';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ResizeObserver from 'resize-observer-polyfill';

import { store } from './app/core/state';

import ApplicationWrapper from './app/views/ApplicationWrapper';

import * as serviceWorker from './serviceWorker';

import { SENTRY_RELEASE_VERSION } from './app/shared/constants/SentryReleaseVersion/constants';
import metrica from './app/shared/utils/metrica.helper';

import './index.css';
import './App.scss';
import { environment } from './environment';
import { SkeletonTheme } from 'react-loading-skeleton';

window.FIN_WIDGET__PRODUCTION = environment.PRODUCTION;

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

Sentry.init({
  dsn: environment.PRODUCTION ?
    'https://308b3e2a55c44ceb84ef02aae9dfa6c1@sentry.finanso.com/4' :
    'https://308b3e2a55c44ceb84ef02aae9dfa6c1@sentry.finanso.com/4',
  integrations: [new Integrations.BrowserTracing()],
  release: SENTRY_RELEASE_VERSION,
  tracesSampleRate: 0.1,
});

firebase.initializeApp({
  apiKey: 'AIzaSyB0VQljmtcS7yekH67PMvg4XLHQiRnrT08',
  authDomain: 'finanso-354610.firebaseapp.com',
});

metrica.init();

ReactDOM.render(
  <Provider store={store}>
    <LocalizeProvider>
      <SkeletonTheme baseColor="#F5F7FA" highlightColor="#E0E3E6" borderRadius={5}>
        <ApplicationWrapper />
      </SkeletonTheme>
    </LocalizeProvider>
  </Provider>,
  document.getElementById('root'),
);

// EASTER EGG
console.log(
  `%c
 ███╗█╗█╗  █╗ ██╗ █╗  █╗████╗ ███╗
 █╔═╝█║██╗ █║█╔═█╗██╗ █║█╔══╝█╔══█╗
 ██╗ █║█╔█╗█║████║█╔█╗█║████╗█║  █║
 █╔╝ █║█║╚██║█╔═█║█║╚██║╚══█║█║  █║
 █║  █║█║ ╚█║█║ █║█║ ╚█║████║╚███╔╝
 ╚╝  ╚╝╚╝  ╚╝╚╝ ╚╝╚╝  ╚╝╚═══╝ ╚══╝
 ----- https://my.finanso.com -----`,
  'color: #1373E5; font-weight: bold;',
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
