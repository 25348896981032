import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { useWindowSize } from '../../hooks/useWindowSize';

import styles from './FeedbackModalFormWrapper.module.scss';
import { ModalStyled } from './theme';

import { DEVICE_NETBOOKS_WIDTH } from '../../constants/Defaults/constants';

interface IProps {
  isModalVisible: boolean;
  handleModalStatusChange: (status: boolean) => void;
}

const FeedbackModalFormWrapper: React.FC<IProps> = props => {
  const { isModalVisible, handleModalStatusChange, children } = props;
  const windowSize = useWindowSize();
  const { width = 0 } = windowSize;

  let isMobile = false;

  if (width < DEVICE_NETBOOKS_WIDTH) {
    isMobile = true;
  }

  return (
    <>
      {isMobile ? (
        <ModalStyled
          style={{ top: 20 }}
          width={720}
          visible={isModalVisible}
          onOk={() => handleModalStatusChange(false)}
          onCancel={() => handleModalStatusChange(false)}
          maskClosable={false}
          footer={[
            <Button
              key="back"
              type="ghost"
              shape="round"
              onClick={() => handleModalStatusChange(false)}
            >
              <CloseOutlined
                style={{
                  fontSize: '14px',
                  color: '#ffffff',
                  fontWeight: 600,
                }}
              />
            </Button>,
          ]}
          destroyOnClose
        >
          <div className={styles.mobileWrapper}>{children}</div>
        </ModalStyled>
      ) : (
        <ModalStyled
          style={{ top: 40 }}
          width={880}
          visible={isModalVisible}
          onOk={() => handleModalStatusChange(false)}
          onCancel={() => handleModalStatusChange(false)}
          footer={[null]}
          maskClosable={false}
          destroyOnClose
        >
          <div className={styles.desctopWrapper}>
            <Button
              key="back"
              type="ghost"
              shape="round"
              onClick={() => handleModalStatusChange(false)}
              className={styles.desctopClose}
            >
              <CloseOutlined
                style={{
                  fontSize: '14px',
                  color: '#ffffff',
                  fontWeight: 600,
                }}
              />
            </Button>

            {children}
          </div>
        </ModalStyled>
      )}
    </>
  );
};

export default FeedbackModalFormWrapper;
