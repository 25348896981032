import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const COUNTRIES = 'COUNTRIES';

export const countriesRequest = createAction(`${COUNTRIES}/REQUEST`);
export const countriesSuccess = createAction(`${COUNTRIES}/SUCCESS`);
export const countriesFailure = createAction(`${COUNTRIES}/FAILURE`);

export const countriesResult = createAction(`${COUNTRIES}/RESULT`);

// Reducers ==========================

const isCountriesFetching = createReducer(false, {
  [countriesRequest.toString()]: () => true,
  [countriesSuccess.toString()]: () => false,
  [countriesFailure.toString()]: () => false,
});

const result = createReducer(
  {},
  {
    [countriesResult.toString()]: (_state, action) => action.payload,
  },
);

const isCountriesAlreadySet = createReducer(false, {
  [countriesResult.toString()]: () => false,
  [countriesSuccess.toString()]: () => true,
});

const list = createReducer([], {
  [countriesSuccess.toString()]: (_state, action) => action.payload,
  [countriesFailure.toString()]: () => [],
});

// Root Reducer
const reducer = combineReducers({
  isCountriesFetching,
  result,
  isCountriesAlreadySet,
  list,
});

export default reducer;
