import { createSelector } from 'reselect';
import get from 'lodash/get';

export const isFetching = (store: any) => store.feedbacksReducer.isFetching;

export const isFetchingDrawer = (store: any) =>
  store.feedbacksReducer.isFetchingDrawer;

export const isFetchingSecond = (store: any) =>
  store.feedbacksReducer.isFetchingSecond;

export const isCreateStatus = (store: any) =>
  store.feedbacksReducer.isCreateStatus;

export const isFetchingInit = (store: any) =>
  store.feedbacksReducer.isFetchingInit;

export const isFetchingInitDrawerStatus = (store: any) =>
  store.feedbacksReducer.isFetchingInitDrawerStatus;

export const isFetchingInitStatus = (store: any) =>
  store.feedbacksReducer.isFetchingInitStatus;

export const isFetchingInitDrawer = (store: any) =>
  store.feedbacksReducer.isFetchingInitDrawer;

export const isFetchingSecondDrawer = (store: any) =>
  store.feedbacksReducer.isFetchingSecondDrawer;

export const data = createSelector(
  (store: any) => store.feedbacksReducer.data,
  data => data,
);

export const dataDrawer = createSelector(
  (store: any) => store.feedbacksReducer.dataDrawer,
  dataDrawer => dataDrawer,
);

export const resResult = createSelector(
  (store: any) => store.feedbacksReducer.result,
  result => result,
);

export const error = createSelector(
  (store: any) => store.feedbacksReducer.error,
  error => error,
);

export const reviewsList = createSelector(data, (data = {}) => {
  const result = get(data, 'data', []) || [];

  return result;
});

export const reviewsListDrawer = createSelector(
  dataDrawer,
  (dataDrawer = {}) => {
    const result = get(dataDrawer, 'data', []) || [];

    return result;
  },
);

export const isCreatingReview = (store: any) =>
  store.feedbacksReducer.isCreatingReview;

export const newReviewData = createSelector(
  (store: any) => store.feedbacksReducer.newReviewData,
  newReviewData => newReviewData,
);

export const errorCreateReview = createSelector(
  (store: any) => store.feedbacksReducer.errorCreateReview,
  errorCreateReview => errorCreateReview,
);

export const totalPages = createSelector(
  (store: any) => store.feedbacksReducer.data,
  data => {
    const result = get(data, 'totalPages', 1) || 1;

    return result;
  },
);

export const totalPagesDrawer = createSelector(
  (store: any) => store.feedbacksReducer.dataDrawer,
  dataDrawer => {
    const result = get(dataDrawer, 'totalPages', 1) || 1;

    return result;
  },
);

export const currentPage = createSelector(
  (store: any) => store.feedbacksReducer.data,
  data => {
    const result = get(data, 'currentPage', 1) || 1;

    return result;
  },
);

export const currentPageDrawer = createSelector(
  (store: any) => store.feedbacksReducer.dataDrawer,
  dataDrawer => {
    const result = get(dataDrawer, 'currentPage', 1) || 1;

    return result;
  },
);

export const cities = createSelector(
  (store: any) => store.feedbacksReducer.citiesData,
  citiesData => {
    let result = get(citiesData, 'data', []) || [];

    result = [
      ...result.map((item: any) => {
        const { city_id: id, name: value } = item;

        return { id, value };
      }),
    ];

    return result;
  },
);

export const orgs = createSelector(
  (store: any) => store.feedbacksReducer.orgData,
  orgData => {
    let result = get(orgData, 'data', []) || [];

    result = [
      ...result.map((item: any) => {
        const { id, title: value } = item;

        return { id, value };
      }),
    ];

    return result;
  },
);

export const orgsByRate = createSelector(
  (store: any) => store.feedbacksReducer.orgData,
  orgData => {
    let result = get(orgData, 'data', []) || [];

    result = [...result].sort((a, b) => {
      return Number(a.rating) - Number(b.rating);
    });

    return result;
  },
);

export const credProds = createSelector(
  (store: any) => store.feedbacksReducer.credProdsData,
  credProdsData => {
    let result = get(credProdsData, 'data', []) || [];

    result = [
      ...result.map((item: any) => {
        const { id, title: value, bank_id: orgID } = item;

        return { id, value, orgID };
      }),
    ];

    return result;
  },
);

export const credProdsFull = createSelector(
  (store: any) => store.feedbacksReducer.credProdsData,
  credProdsData => {
    let result = get(credProdsData, 'data', []) || [];

    result = [
      ...result.map((item: any) => {
        const {
          title: label,
          bank_id: bankID,
          approval_rating: approvalRating,
          rating_reviews_count: ratingReviewsCount,
          text_short: textShort,
          rating,
        } = item;

        return {
          ...item,
          label,
          bankID,
          approvalRating,
          ratingReviewsCount,
          textShort,
          rating: Number(rating),
        };
      }),
    ];

    return result;
  },
);

export const isCreatingComment = (store: any) =>
  store.feedbacksReducer.isCreatingComment;

export const isCreateCommentStatus = (store: any) =>
  store.feedbacksReducer.isCreateCommentStatus;

export const isCreateCommentErrorStatus = (store: any) =>
  store.feedbacksReducer.isCreateCommentErrorStatus;

export const newCommentData = createSelector(
  (store: any) => store.feedbacksReducer.newCommentData,
  newCommentData => newCommentData,
);

export const errorCreateComment = createSelector(
  (store: any) => store.feedbacksReducer.errorCreateComment,
  errorCreateComment => errorCreateComment,
);

export const allComments = createSelector(
  (store: any) => store.feedbacksReducer.data.comments,
  comments => comments || {},
);

export const allCommentsDrawer = createSelector(
  (store: any) => store.feedbacksReducer.dataDrawer.comments,
  comments => comments || {},
);

// Добавление лайка
export const isAddLike = (store: any) => store.feedbacksReducer.isAddLike;

export const addLikeData = createSelector(
  (store: any) => store.feedbacksReducer.addLikeData,
  addLikeData => addLikeData,
);

export const errorAddLike = createSelector(
  (store: any) => store.feedbacksReducer.errorAddLike,
  errorAddLike => errorAddLike,
);

// Список всех лайков
export const isFetchingAllUserLikes = (store: any) =>
  store.feedbacksReducer.isFetchingAllUserLikes;

export const allUserLikesData = createSelector(
  (store: any) => store.feedbacksReducer.allUserLikesData,
  allUserLikesData => allUserLikesData.data,
);

export const errorAllUserLikes = createSelector(
  (store: any) => store.feedbacksReducer.errorAllUserLikes,
  errorAllUserLikes => errorAllUserLikes,
);

// Удаление лайка
export const isRemoveLike = (store: any) => store.feedbacksReducer.isRemoveLike;

export const removeLikeData = createSelector(
  (store: any) => store.feedbacksReducer.removeLikeData,
  removeLikeData => removeLikeData,
);

export const errorRemoveLike = createSelector(
  (store: any) => store.feedbacksReducer.errorRemoveLike,
  errorRemoveLike => errorRemoveLike,
);

export const isAddLikeStatus = (store: any) =>
  store.feedbacksReducer.isAddLikeStatus;

export const isRemoveLikeStatus = (store: any) =>
  store.feedbacksReducer.isRemoveLikeStatus;

export const allLikes = createSelector(
  (store: any) => store.feedbacksReducer.data.likes,
  likes => likes || {},
);

export const allLikesDrawer = createSelector(
  (store: any) => store.feedbacksReducer.dataDrawer.likes,
  likes => likes || {},
);
