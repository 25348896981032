import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

// Actions ==========================

const USER_LIMITS = 'USER_LIMITS';

// Запрос информации по разделу "Мои задолженности" из АПИ
export const userLimitsRequest = createAction(`${USER_LIMITS}/REQUEST`);
export const userLimitsSuccess = createAction(`${USER_LIMITS}/SUCCESS`);
export const userLimitsFailure = createAction(`${USER_LIMITS}/FAILURE`);

export const userLimitsDebt = createAction(`${USER_LIMITS}/DEBT`);
export const userLimitsRating = createAction(`${USER_LIMITS}/RATING`);
export const userLimitsLawyer = createAction(`${USER_LIMITS}/LAWYER`);

// Reducers ==========================

// Статус запроса данных из АПИ
const isFetching = createReducer(false, {
  [userLimitsRequest.toString()]: () => true,
  [userLimitsSuccess.toString()]: () => false,
  [userLimitsFailure.toString()]: () => false,
});

// Успешный результат запроса из АПИ
const data = createReducer({}, {
  [userLimitsRequest.toString()]: () => ({}),
  [userLimitsSuccess.toString()]: (_state, action) => action.payload,
  [userLimitsFailure.toString()]: () => ({}),
});

// Root Reducer
const reducer = combineReducers({
  isFetching,
  data,
});

export default reducer;
