import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import * as E from 'fp-ts/lib/Either';

import { userSettingsChangePhoneAPI } from '../../../../services/UserSettingsChangePhone/api';

import {
  userSettingsChangePhonePutRequest,
  userSettingsChangePhonePutSuccess,
  userSettingsChangePhonePutFailure,
  userSettingsChangePhonePutResult,
  userSettingsChangePhonePostRequest,
  userSettingsChangePhonePostSuccess,
  userSettingsChangePhonePostFailure,
  userSettingsChangePhonePostResult,
} from '../duck';

import { checkToken } from '../../../../core/state/utils/checkToken/saga';
import { safe } from '../../../../core/state/utils/safe/saga';
import { onError } from '../../../../core/state/utils/onError/saga';
import { createResultV2 } from '../../../../core/state/utils/createResult/saga';

import { IResultEiterAction } from '../../../../models/ResultEiterAction';
import { userProfileRequest } from '../../../UserProfile/state/duck';

// NB: PUT это смена телефона, в форму отправляется и номер и код из СМС
export function* putData(action: any) {
  const completePayload: any = yield call(checkToken, action);
  const srvRes = yield call(userSettingsChangePhoneAPI.putDataForm, {
    ...completePayload,
  });

  yield put({
    type: userSettingsChangePhonePutResult.toString(),
    payload: createResultV2(srvRes),
  });
} // putData =========

// TODO: Добавить контракт
export function* checkResultPut(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: userSettingsChangePhonePutSuccess.toString(),
      payload: { data },
    });

    yield put({
      type: userProfileRequest.toString(),
      payload: { nonCached: true },
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: userSettingsChangePhonePutFailure.toString(),
    });
  }
} // checkResultPut =========

// NB: POST это отправка запроса на получение кода в СМС для смены телефона
export function* postData(action: any) {
  const completePayload: any = yield call(checkToken, action);

  const srvRes = yield call(userSettingsChangePhoneAPI.postDataPhoneCode, {
    ...completePayload,
  });

  yield put({
    type: userSettingsChangePhonePostResult.toString(),
    payload: createResultV2(srvRes),
  });
} // postData =========

// TODO: Добавить контракт
export function* checkResultPost(
  action: IResultEiterAction,
): IterableIterator<any> {
  const { payload } = action;

  if (E.isRight(payload)) {
    const { right: successData } = payload;
    const { data } = successData;

    yield put({
      type: userSettingsChangePhonePostSuccess.toString(),
      payload: { data },
    });
  }

  if (E.isLeft(payload)) {
    yield put({
      type: userSettingsChangePhonePostFailure.toString(),
    });
  }
} // checkResultPost =========

// Root Saga
export default function* rootSaga() {
  yield takeLatest(
    userSettingsChangePhonePutRequest,
    safe(onError, putData, {
      terminator: userSettingsChangePhonePutFailure,
    }),
  );

  yield takeEvery(
    userSettingsChangePhonePutResult,
    safe(onError, checkResultPut),
  );

  yield takeLatest(
    userSettingsChangePhonePostRequest,
    safe(onError, postData, {
      terminator: userSettingsChangePhonePostFailure,
    }),
  );

  yield takeEvery(
    userSettingsChangePhonePostResult,
    safe(onError, checkResultPost),
  );
}
