import { select } from 'redux-saga/effects';

import { data as authDataSelector } from '../../../../../states/Auth/state/selectors';
import { data as tokenDataSelector } from '../../../../../states/Login/state/selectors';

/**
 * Проверяет токен из редьюсеров: Auth, Login
 * выбирает установленный и возвращает его в нутри объекта с payload
 *
 * @export
 * @param {*} action
 * @returns
 */
export function* checkToken(action: any) {
  const authData: any = yield select(authDataSelector);
  const { authToken = '' } = authData;
  const tokenData: any = yield select(tokenDataSelector);
  const { token: loginToken = '' } = tokenData;
  let tempToken = '';
  if (authToken !== '') {
    tempToken = authToken;
  }
  if (loginToken !== '') {
    tempToken = loginToken;
  }

  const { payload = {} } = action;
  const tempPayload = {
    ...payload,
    token: tempToken,
  };

  return tempPayload;
}
