import { createSelector } from 'reselect';

// Init
export const isFetching = (store: any) => store.initReducer.isFetching;

export const isFetchingShadow = (store: any) =>
  store.initReducer.isFetchingShadow;

export const initSuccessStatus = (store: any) =>
  store.initReducer.initSuccessStatus;

export const list = createSelector(
  (store: any) => store.initReducer.list,
  list => list,
);

export const error = createSelector(
  (store: any) => store.initReducer.error,
  error => error,
);

const initSiteSettings = {
  cpa: {
    cpa_feed_src_url: '',
  },
};

export const siteSettings = createSelector(
  (store: any) => store.initReducer.siteSettings,
  siteSettings => {
    const result = siteSettings?.data ?? initSiteSettings;

    return result;
  },
);
